import { FC, useState } from 'react';
import Button from '../plButton/Button';
import { EBtnVariant } from '../plButton/useButtonClassName';
import { clstx } from '../utils';
import ShadowDomRenderer from './ShadowDomRenderer';

export interface IProps {
  html: string;
}

const TruncatedShadowDomRenderer: FC<IProps> = (props) => {
  const { html } = props;
  const [isShowingMore, setIsShowingMore] = useState(false);

  return (
    <>
      <div
        className={clstx(
          isShowingMore
            ? ''
            : 'container-bottom-shadow relative max-h-[200px] overflow-hidden'
        )}
      >
        <ShadowDomRenderer html={html} />
      </div>

      <div>
        <Button
          variant={EBtnVariant.LinkInline}
          onClick={() => setIsShowingMore((s) => !s)}
        >
          {isShowingMore ? 'Show less' : 'Show more'}
        </Button>
      </div>
    </>
  );
};

export default TruncatedShadowDomRenderer;
