import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import proposalKeys from '@/api/queries/proposal/keyFactor';
import { graphql } from '@/gql';
import { GetProposalInput } from '@/gql/graphql';

const GET_PROPOSAL = graphql(`
  query GetProposal($input: GetProposalInput!) {
    proposal(input: $input) {
      id
      emailTemplate {
        id
        preview
      }
    }
  }
`);

const useGetProposal = (id?: GetProposalInput['id']) => {
  return useQuery({
    enabled: !!id,
    queryKey: proposalKeys.proposal(id!),
    queryFn: () => {
      return gqlClient.request(GET_PROPOSAL, { input: { id: id! } });
    },
  });
};

export const useGetProposalEmailTemplate = (id?: GetProposalInput['id']) => {
  const { data, ...rest } = useGetProposal(id);

  return {
    data,
    emailTemplate: data?.proposal.emailTemplate,
    ...rest,
  };
};

export default useGetProposal;
