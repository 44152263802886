import { AccountsRoles } from './accounts/accounts.roles';
import { AddressRoles } from './addresses/addresses.roles';
import { AnalyticsRoles } from './analytics/analytics.roles';
import { AutomationsRoles } from './automations/automations.roles';
import { ContactRoles } from './contacts/contacts.roles';
import { CustomersRoles } from './customers/customers.roles';
import { DashboardRoles } from './dashboard/dashboard.roles';
import { DealsRoles } from './deals/deals.roles';
import { DispatchRoles } from './dispatch/dispatch.roles';
import { DocumentsRoles } from './documents/documents.roles';
import { IntegratedEmailsRoles } from './integrated-emails/integrated-emails.roles';
import { InvoicesRoles } from './invoices/invoices.roles';
import { JobRoles } from './jobs/job.roles';
import { LineItemsRoles } from './lineItems/line-items.roles';
import { MaterialsRoles } from './materials/materials.roles';
import { MaterialsListRoles } from './materialsList/materialsList.roles';
import { PaymentsRoles } from './payments/payments.roles';
import { PipelinesRoles } from './pipelines/pipelines.roles';
import { ProposalsRoles } from './proposals/proposals.roles';
import { ScheduledEventsRoles } from './scheduledEvents/scheduled-events.roles';
import { ServicePlanRoles } from './service-plans/service-plans.roles';
import { SuppliersRoles } from './suppliers/suppliers.roles';
import { TasksRoles } from './tasks/tasks.roles';
import { TaxRateRoles } from './tax-rates/tax-rates.roles';
import { TemplatesRoles } from './templates/templates.roles';
import { TimelogsRoles } from './timelogs/timelogs.roles';
import { UploadsRoles } from './uploads/uploads.roles';
import { UserRoles } from './user/user.roles';
import { UserAssignmentsRoles } from './userAssignments/userAssignments.roles';

export enum DefaultSystemRoles {
  SUPER_ADMIN = 'Super Admin',
  ADMIN = 'Admin',
  SALES_MANAGER = 'Sales Manager',
  SALES_AGENT = 'Sales Agent',
  FIELD_AGENT = 'Field Agent',
}

export enum CustomOvoSystemRoles {
  OVO_FRANCHISOR_ADMIN = 'OVO Franchisor Admin',

  OVO_FRANCHISEE_ADMIN = 'OVO Franchisee Admin',
  OVO_FRANCHISEE_SALES_AGENT = 'OVO Franchisee Sales Agent',
  OVO_FRANCHISEE_FIELD_AGENT = 'OVO Franchisee Field Agent',
}
export const CustomOvoFranchisorRoles = [
  CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN,
];
export const CustomOvoFranchiseeRoles = [
  CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN,
  CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT,
  CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT,
];

export type AllSystemRoles = DefaultSystemRoles | CustomOvoSystemRoles;

export const systemRoleHierarchyMap: Record<AllSystemRoles, number> = {
  [DefaultSystemRoles.SUPER_ADMIN]: 4,
  [DefaultSystemRoles.ADMIN]: 3,
  [DefaultSystemRoles.SALES_MANAGER]: 2,
  [DefaultSystemRoles.SALES_AGENT]: 1,
  [DefaultSystemRoles.FIELD_AGENT]: 0,

  [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: 5,
  [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: 2,
  [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: 1,
  [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: 0,
};

export enum SubscriptionLevels {
  SOLO = 'Solo',
  TEAM = 'Team', // deprecated
  BUSINESS = 'Business', // deprecated
  PRO = 'Pro', // deprecrating when we launch new subscriptions - pro v2 and business v2
  BUSINESS_V2 = 'Business_v2',
  PRO_V2 = 'Pro_v2',
}

export type SusbcriptionFeatureName =
  | 'hasZapier'
  | 'hasForms'
  | 'hasCustomAutomationsOnAnyPlan'
  | 'hasDispatch'
  | 'hasCustomAutomations'
  | 'hasClientPortal'
  | 'hasAdditionalWorkRequests'
  | 'hasIntegratedEmails'
  | 'hasENAConnectionApplication'
  | 'hasEmailTemplates';

// Once an account's free trial has expired, we only want to grant a limited
// subset of their users' roles. We will use this array to filter a given user's
// roles to only include those that might allow them to purchase a subscription
// and thereby reactivate.
export const expiredTrialRoles = [
  AccountsRoles.EDITOR,
  AccountsRoles.PERSONAL_EDITOR,
  AccountsRoles.USERS_VIEWER,
  AccountsRoles.VIEWER,
  AccountsRoles.SUBSCRIPTION_EDITOR,
  AnalyticsRoles.VIEWER,
  CustomersRoles.VIEWER,
  DashboardRoles.VIEWER,
  DealsRoles.VIEWER,
  DocumentsRoles.VIEWER,
  InvoicesRoles.VIEWER,
  JobRoles.VIEWER,
  LineItemsRoles.GROUP_VIEWER,
  LineItemsRoles.VIEWER,
  MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
  MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
  MaterialsRoles.VIEWER,
  MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  MaterialsListRoles.PURCHASE_RECORD_VIEWER,
  MaterialsListRoles.VIEWER,
  PaymentsRoles.VIEWER,
  PipelinesRoles.VIEWER,
  ProposalsRoles.VIEWER,
  ScheduledEventsRoles.ASSIGNEE_VIEWER,
  ScheduledEventsRoles.VIEWER,
  TasksRoles.ASSIGNEE_VIEWER,
  TasksRoles.VIEWER,
  TaxRateRoles.VIEWER,
  UploadsRoles.VIEWER,
  ServicePlanRoles.VIEWER,
];

export const rolesByAddOnProductNameAndSystemRole: Partial<
  Record<string, Record<AllSystemRoles, Array<string>>>
> = {
  'Service Plans': {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
    ],
    [DefaultSystemRoles.ADMIN]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
      ServicePlanRoles.DISCOUNT_MANAGER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
      ServicePlanRoles.DISCOUNT_MANAGER,
      ServicePlanRoles.PERSISTER,
    ],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      ServicePlanRoles.VIEWER,
      ServicePlanRoles.PERIOD_MANAGER,
      ServicePlanRoles.DISCOUNT_MANAGER,
      ServicePlanRoles.PERSISTER,
    ],
  },
};
export const rolesBySubscriptionFeatureNameAndSystemRole: Partial<
  Record<SusbcriptionFeatureName, Record<AllSystemRoles, Array<string>>>
> = {
  hasCustomAutomationsOnAnyPlan: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [],
    [DefaultSystemRoles.ADMIN]: [
      AutomationsRoles.EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.VIEWER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      AutomationsRoles.EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.VIEWER,
    ],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      AutomationsRoles.EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.VIEWER,
    ],
  },
  hasDispatch: {
    [DefaultSystemRoles.FIELD_AGENT]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SALES_AGENT]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SALES_MANAGER]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.ADMIN]: [DispatchRoles.VIEWER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DispatchRoles.VIEWER],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [DispatchRoles.VIEWER],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [DispatchRoles.VIEWER],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [DispatchRoles.VIEWER],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [DispatchRoles.VIEWER],
  },
  hasClientPortal: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
    [DefaultSystemRoles.ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      DealsRoles.UNSHARE_DEAL_FILES,
      CustomersRoles.CLIENT_PORTAL_SHARE_SETTINGS_MANAGER,
    ],
  },
  hasAdditionalWorkRequests: {
    [DefaultSystemRoles.FIELD_AGENT]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SALES_AGENT]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SALES_MANAGER]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.ADMIN]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DealsRoles.ADDITIONAL_WORK_REQUESTER],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      DealsRoles.ADDITIONAL_WORK_REQUESTER,
    ],
  },
  hasIntegratedEmails: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.SALES_MANAGER]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.ADMIN]: [IntegratedEmailsRoles.SENDER],
    [DefaultSystemRoles.SUPER_ADMIN]: [IntegratedEmailsRoles.SENDER],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [
      IntegratedEmailsRoles.SENDER,
    ],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [IntegratedEmailsRoles.SENDER],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [IntegratedEmailsRoles.SENDER],
  },
  hasENAConnectionApplication: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [],
    [DefaultSystemRoles.SALES_MANAGER]: [],
    [DefaultSystemRoles.ADMIN]: [DealsRoles.COMPLIANCE_FORM_VIEWER],
    [DefaultSystemRoles.SUPER_ADMIN]: [DealsRoles.COMPLIANCE_FORM_VIEWER],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [
      DealsRoles.COMPLIANCE_FORM_VIEWER,
    ],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      DealsRoles.COMPLIANCE_FORM_VIEWER,
    ],
  },
  hasEmailTemplates: {
    [DefaultSystemRoles.FIELD_AGENT]: [],
    [DefaultSystemRoles.SALES_AGENT]: [AccountsRoles.EMAIL_TEMPLATE_VIEWER],
    [DefaultSystemRoles.SALES_MANAGER]: [AccountsRoles.EMAIL_TEMPLATE_VIEWER],
    [DefaultSystemRoles.ADMIN]: [
      AccountsRoles.EMAIL_TEMPLATE_VIEWER,
      AccountsRoles.EMAIL_TEMPLATE_MANAGER,
    ],
    [DefaultSystemRoles.SUPER_ADMIN]: [
      AccountsRoles.EMAIL_TEMPLATE_VIEWER,
      AccountsRoles.EMAIL_TEMPLATE_MANAGER,
    ],

    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [
      AccountsRoles.EMAIL_TEMPLATE_VIEWER,
    ],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [
      AccountsRoles.EMAIL_TEMPLATE_VIEWER,
      AccountsRoles.EMAIL_TEMPLATE_MANAGER,
    ],
    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [
      AccountsRoles.EMAIL_TEMPLATE_VIEWER,
      AccountsRoles.EMAIL_TEMPLATE_MANAGER,
    ],
  },
};

// Field Agent Roles
const FieldAgentRolesSolo = [
  AccountsRoles.VIEWER,
  AccountsRoles.PERSONAL_EDITOR,
  AccountsRoles.USERS_VIEWER,
  ScheduledEventsRoles.ASSIGNEE_VIEWER,
  ScheduledEventsRoles.ASSIGNEE_CREATOR,
  ScheduledEventsRoles.ASSIGNEE_EDITOR,
  ScheduledEventsRoles.TAGGER,
  ScheduledEventsRoles.CUSTOM_CREATOR,
  TasksRoles.ASSIGNEE_COMPLETER,
  TasksRoles.ASSIGNEE_VIEWER,
  TasksRoles.CREATOR_DELETER,
  TasksRoles.CREATOR_PERSISTER,

  UploadsRoles.VIEWER,
  UploadsRoles.PERSISTER,
  DocumentsRoles.ASSIGNEE_EDITOR,
  TaxRateRoles.VIEWER,
  MaterialsListRoles.SELF_ASSIGNED_EVENT_DEAL_PURCHASE_ORDER_VIEWER,
  TimelogsRoles.ASSIGNEE_VIEWER,
  TimelogsRoles.ASSIGNEE_CREATOR,
  TimelogsRoles.TYPES_VIEWER,
  TimelogsRoles.ASSIGNEE_UPDATER,

  UserRoles.LEGACY_APP_USER,
];
const FieldAgentRolesPro = [...FieldAgentRolesSolo];
const FieldAgentRolesProv2 = FieldAgentRolesPro;
const FieldAgentRolesTeam = FieldAgentRolesPro;
const FieldAgentRolesBusiness = [...FieldAgentRolesPro];
const FieldAgentRolesBusinessV2 = [
  ...FieldAgentRolesPro,
  TimelogsRoles.ASSIGNEE_VIEWER,
  TimelogsRoles.ASSIGNEE_CREATOR,
  TimelogsRoles.TYPES_VIEWER,
  TimelogsRoles.ASSIGNEE_UPDATER,
  TasksRoles.EVENT_ASSIGNEE_VIEWER,
  TasksRoles.EVENT_ASSIGNEE_COMPLETER,
  AccountsRoles.CUSTOM_EMAIL_DOMAIN_SENDER,
];

// Sales Agent Roles
const SalesAgentRolesSolo = [
  ...FieldAgentRolesSolo,
  AddressRoles.MANAGER,

  ContactRoles.MANAGER,
  CustomersRoles.EDITOR,
  CustomersRoles.VIEWER,

  DealsRoles.ASSIGNEE_ARCHIVER,
  DealsRoles.ASSIGNEE_EDITOR,
  DealsRoles.ASSIGNEE_VIEWER,
  DealsRoles.DESCRIPTION_EDITOR,
  DealsRoles.PAYMENT_OPTIONS_MANAGER,
  DealsRoles.PRICE_VISIBILITY_MANAGER,
  DealsRoles.REFERENCE_EDITOR,
  DealsRoles.TEMPLATE_APPLIER,
  DealsRoles.TEMPLATE_CREATOR,

  DispatchRoles.PREVIEWER,

  DocumentsRoles.CREATOR,
  DocumentsRoles.EDITOR,
  DocumentsRoles.VIEWER,

  InvoicesRoles.DUE_IN_DAYS_EDITOR,
  InvoicesRoles.MANAGER,
  InvoicesRoles.PERSISTER_SENDER,
  InvoicesRoles.REFERENCE_EDITOR,
  InvoicesRoles.VALUE_EDITOR,
  InvoicesRoles.VIEWER,

  JobRoles.CREATOR,
  JobRoles.CUSTOM_CREATOR,
  JobRoles.DEPOSIT_EDITOR,
  JobRoles.DUPLICATOR,
  JobRoles.EDITOR,
  JobRoles.REFERENCE_EDITOR,
  JobRoles.VALID_FOR_DUE_IN_DAYS_EDITOR,
  JobRoles.VIEWER,

  LineItemsRoles.GROUP_VIEWER,
  LineItemsRoles.VIEWER,

  MaterialsListRoles.PERSISTER,
  MaterialsListRoles.POPULATOR,
  MaterialsListRoles.PURCHASE_ORDER_CONFIRMER,
  MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
  MaterialsListRoles.PURCHASE_ORDER_SENDER,
  MaterialsListRoles.PURCHASE_ORDER_VIEWER,
  MaterialsListRoles.PURCHASE_RECORD_DELETER,
  MaterialsListRoles.PURCHASE_RECORD_PERSISTER,
  MaterialsListRoles.PURCHASE_RECORD_VIEWER,
  MaterialsListRoles.VIEWER,

  MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
  MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
  MaterialsRoles.VIEWER,

  PaymentsRoles.RECORDER,
  PaymentsRoles.VIEWER,

  PipelinesRoles.VIEWER,

  ProposalsRoles.EXPIRES_IN_DAYS_EDITOR,
  ProposalsRoles.MANAGER,
  ProposalsRoles.PERSISTER_SENDER,
  ProposalsRoles.REFERENCE_EDITOR,
  ProposalsRoles.VIEWER,

  ScheduledEventsRoles.CUSTOMER_ON_LEGACY_APP_SHARER,
  ScheduledEventsRoles.DEAL_ASSIGNEE_CREATOR,
  ScheduledEventsRoles.DEAL_ASSIGNEE_EDITOR,
  ScheduledEventsRoles.DEAL_ASSIGNEE_VIEWER,

  SuppliersRoles.VIEWER,

  TasksRoles.DEAL_ASSIGNEE_COMPLETER,
  TasksRoles.DEAL_ASSIGNEE_PERSISTER,
  TasksRoles.DEAL_ASSIGNEE_VIEWER,

  TemplatesRoles.VIEWER,

  UserRoles.CONNECTION_EDITOR,
];
const SalesAgentRolesPro = [...FieldAgentRolesPro, ...SalesAgentRolesSolo];
const SalesAgentRolesProv2 = [...FieldAgentRolesProv2, ...SalesAgentRolesPro];
const SalesAgentRolesTeam = [...FieldAgentRolesTeam, ...SalesAgentRolesProv2];
const SalesAgentRolesBusiness = [
  ...FieldAgentRolesBusiness,
  ...SalesAgentRolesTeam,
];
const SalesAgentRolesBusinessV2 = [
  ...FieldAgentRolesBusinessV2,
  ...SalesAgentRolesBusiness,
  TemplatesRoles.PROPOSAL_TEMPLATES_USER,
  AccountsRoles.CUSTOM_FIELD_VALUE_MANAGER,
];

// Sales Manager Roles
const SalesManagerRolesSolo = [
  ...SalesAgentRolesSolo,
  ScheduledEventsRoles.VIEWER,
  ScheduledEventsRoles.EDITOR,
  ScheduledEventsRoles.CREATOR,
  CustomersRoles.BULK_IMPORTER,
  CustomersRoles.COLLECTION_MANAGER,
  DealsRoles.CREATOR,
  DealsRoles.VIEWER,
  DealsRoles.EDITOR,
  DealsRoles.ARCHIVER,
  DocumentsRoles.PREFILL_PREFERENCES_MANAGER,
  LineItemsRoles.CREATOR,
  LineItemsRoles.BASIC_EDITOR,
  LineItemsRoles.EDITOR,
  LineItemsRoles.COLLECTION_MANAGER,
  LineItemsRoles.DELETER,
  LineItemsRoles.GROUP_CREATOR,
  LineItemsRoles.GROUP_EDITOR,
  LineItemsRoles.BULK_IMPORTER,
  MaterialsRoles.PERSISTER,
  MaterialsRoles.ARCHIVER,
  MaterialsRoles.COLLECTION_MANAGER,
  MaterialsRoles.LINE_ITEM_MATERIALS_PERSISTER,
  MaterialsRoles.LINE_ITEM_MATERIALS_DELETER,
  MaterialsRoles.SUPPLIER_MATERIALS_PERSISTER,
  MaterialsRoles.SUPPLIER_MATERIALS_DELETER,
  MaterialsRoles.BULK_IMPORTER,
  MaterialsRoles.SUPPLIER_MATERIALS_BULK_IMPORTER,
  MaterialsListRoles.DELETER,
  SuppliersRoles.COLLECTION_MANAGER,
  ScheduledEventsRoles.BULK_IMPORTER,
  SuppliersRoles.ARCHIVER,
  SuppliersRoles.PERSISTER,
  TasksRoles.COMPLETER,
  TasksRoles.PERSISTER,
  TasksRoles.DELETER,
  TasksRoles.VIEWER,
  TaxRateRoles.PERSISTER,
  TaxRateRoles.DELETER,
  UserAssignmentsRoles.ASSIGNER,
  DispatchRoles.PREVIEWER,
  TimelogsRoles.ASSIGNEE_DELETER,
];
const SalesManagerRolesPro = [...SalesAgentRolesPro, ...SalesManagerRolesSolo];
const SalesManagerRolesTeam = SalesManagerRolesPro;
const SalesManagerRolesBusiness = SalesManagerRolesPro;
const SalesManagerRolesProV2 = SalesManagerRolesPro;
const SalesManagerRolesBusinessV2 = [
  ...SalesManagerRolesPro,
  ...SalesAgentRolesBusinessV2,
  DispatchRoles.VIEWER,
];

// Admin Roles
const AdminRolesSolo = [
  ...SalesManagerRolesSolo,
  InvoicesRoles.LISTED_VIEWER,
  PaymentsRoles.BULK_RECORDER,
  ServicePlanRoles.PREVIEWER,
  TimelogsRoles.PREVIEWER,
  AccountsRoles.EDITOR, // account & user management
  AccountsRoles.CONNECTION_EDITOR,
  AnalyticsRoles.VIEWER, // business performance page
  DashboardRoles.VIEWER, // dashboard
  TemplatesRoles.PERSISTER,
  TemplatesRoles.DELETER,
  TemplatesRoles.COLLECTION_MANAGER,
  MaterialsListRoles.PURCHASE_ORDER_VOIDER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_PERSISTER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_CONFIRMER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_SENDER,
  MaterialsListRoles.ISOLATED_PURCHASE_ORDER_VOIDER,
  TimelogsRoles.VIEWER,
  TimelogsRoles.UPDATER,
  TimelogsRoles.CREATOR,
  TimelogsRoles.DELETER,
  TimelogsRoles.TYPES_MANAGER,
  TimelogsRoles.COST_ADMINISTRATOR,
  AccountsRoles.DETAILED_USERS_VIEWER,
  AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
  AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
  CustomersRoles.BULK_EXPORTER,
  AccountsRoles.TAG_MANAGER,
];
const AdminRolesPro = [
  ...SalesManagerRolesPro,
  ...AdminRolesSolo,
  AutomationsRoles.VIEWER,
  AutomationsRoles.DEFAULT_VIEWER,
  AutomationsRoles.DEFAULT_MANAGER,
  UploadsRoles.DELETER,
  PipelinesRoles.CREATOR,
];
const AdminRolesTeam = AdminRolesPro;
const AdminRolesBusiness = AdminRolesPro;
const AdminRolesProV2 = AdminRolesPro;
const AdminRolesBusinessV2 = [
  ...AdminRolesPro,
  ...SalesManagerRolesBusinessV2,
  AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
  AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
  AutomationsRoles.VIEWER,
  AutomationsRoles.CREATOR,
  AutomationsRoles.EDITOR,
  AutomationsRoles.SIMPLE_EDITOR,
  AutomationsRoles.SIMPLE_CREATOR,
  AccountsRoles.CUSTOM_EMAIL_DOMAIN_EDITOR,
  AccountsRoles.CUSTOM_FIELD_GROUP_MANAGER,
];

// Super Admin Roles
const SuperAdminRolesSolo = [
  ...AdminRolesSolo,
  AccountsRoles.SUBSCRIPTION_EDITOR,
];
const SuperAdminRolesPro = [
  ...AdminRolesPro,
  ...SuperAdminRolesSolo,
  PipelinesRoles.EDITOR,
];
const SuperAdminRolesTeam = SuperAdminRolesPro;
const SuperAdminRolesBusiness = SuperAdminRolesPro;
const SuperAdminRolesProV2 = SuperAdminRolesPro;
const SuperAdminRolesBusinessV2 = [
  ...AdminRolesBusinessV2,
  ...SuperAdminRolesBusiness,
];

// Custom ovo roles
const ovoOmitRoles = [
  UserRoles.LEGACY_APP_USER,
  ScheduledEventsRoles.CUSTOMER_ON_LEGACY_APP_SHARER,
];
const omitRoles = (roles: string[], rolesToOmit: string[]) =>
  roles.filter((role) => !rolesToOmit.includes(role));

const CustomOvoFranchiseeFieldAgentRolesBusinessV2 = omitRoles(
  FieldAgentRolesBusinessV2,
  [...ovoOmitRoles, ScheduledEventsRoles.CUSTOM_CREATOR]
);
const CustomOvoFranchiseeSalesAgentRolesBusinessV2 = omitRoles(
  [
    ...CustomOvoFranchiseeFieldAgentRolesBusinessV2,

    AccountsRoles.CUSTOM_FIELD_VALUE_MANAGER,

    AddressRoles.MANAGER,

    ContactRoles.MANAGER,
    CustomersRoles.EDITOR,
    CustomersRoles.VIEWER,

    DealsRoles.ASSIGNEE_EDITOR,
    DealsRoles.ASSIGNEE_VIEWER,

    DispatchRoles.PREVIEWER,

    DocumentsRoles.CREATOR,
    DocumentsRoles.EDITOR,
    DocumentsRoles.VIEWER,

    InvoicesRoles.MANAGER,
    InvoicesRoles.PERSISTER_SENDER,
    InvoicesRoles.VIEWER,

    JobRoles.CREATOR,
    JobRoles.EDITOR,
    JobRoles.VIEWER,

    LineItemsRoles.GROUP_VIEWER,
    LineItemsRoles.VIEWER,

    MaterialsListRoles.PERSISTER,
    MaterialsListRoles.POPULATOR,
    MaterialsListRoles.PURCHASE_ORDER_CONFIRMER,
    MaterialsListRoles.PURCHASE_ORDER_PERSISTER,
    MaterialsListRoles.PURCHASE_ORDER_SENDER,
    MaterialsListRoles.PURCHASE_ORDER_VIEWER,
    MaterialsListRoles.PURCHASE_RECORD_DELETER,
    MaterialsListRoles.PURCHASE_RECORD_PERSISTER,
    MaterialsListRoles.PURCHASE_RECORD_VIEWER,
    MaterialsListRoles.VIEWER,

    MaterialsRoles.LINE_ITEM_MATERIALS_VIEWER,
    MaterialsRoles.SUPPLIER_MATERIALS_VIEWER,
    MaterialsRoles.VIEWER,

    PaymentsRoles.VIEWER,

    PipelinesRoles.VIEWER,

    ProposalsRoles.MANAGER,
    ProposalsRoles.PERSISTER_SENDER,
    ProposalsRoles.VIEWER,

    ScheduledEventsRoles.DEAL_ASSIGNEE_CREATOR,
    ScheduledEventsRoles.DEAL_ASSIGNEE_EDITOR,
    ScheduledEventsRoles.DEAL_ASSIGNEE_VIEWER,

    SuppliersRoles.VIEWER,

    TasksRoles.DEAL_ASSIGNEE_COMPLETER,
    TasksRoles.DEAL_ASSIGNEE_PERSISTER,
    TasksRoles.DEAL_ASSIGNEE_VIEWER,

    TemplatesRoles.PROPOSAL_TEMPLATES_USER,
    TemplatesRoles.VIEWER,

    UserRoles.CONNECTION_EDITOR,
  ],
  ovoOmitRoles
);
const CustomOvoFranchiseeAdminRolesBusinessV2 = omitRoles(
  [
    ...CustomOvoFranchiseeSalesAgentRolesBusinessV2,

    AccountsRoles.CONNECTION_ACCOUNTING_EDITOR,
    AccountsRoles.CONNECTION_AUTOMATION_EDITOR,
    AccountsRoles.DETAILED_USERS_VIEWER,
    AccountsRoles.USERS_COLOUR_EDITOR,
    AccountsRoles.USERS_COST_PER_HOUR_EDITOR,
    AccountsRoles.USERS_COST_PER_HOUR_VIEWER,
    AccountsRoles.USERS_ROLE_EDITOR,

    AnalyticsRoles.VIEWER,

    CustomersRoles.BULK_IMPORTER,
    CustomersRoles.COLLECTION_MANAGER,

    DashboardRoles.VIEWER,

    DealsRoles.EDITOR,
    DealsRoles.VIEWER,

    DispatchRoles.VIEWER,

    DocumentsRoles.PREFILL_PREFERENCES_MANAGER,

    InvoicesRoles.LISTED_VIEWER,

    LineItemsRoles.BASIC_EDITOR,
    LineItemsRoles.BULK_IMPORTER,
    LineItemsRoles.COLLECTION_MANAGER,
    LineItemsRoles.CREATOR,
    LineItemsRoles.DELETER,
    LineItemsRoles.EDITOR,
    LineItemsRoles.GROUP_CREATOR,
    LineItemsRoles.GROUP_EDITOR,

    MaterialsListRoles.DELETER,

    MaterialsRoles.ARCHIVER,
    MaterialsRoles.BULK_IMPORTER,
    MaterialsRoles.COLLECTION_MANAGER,
    MaterialsRoles.LINE_ITEM_MATERIALS_DELETER,
    MaterialsRoles.LINE_ITEM_MATERIALS_PERSISTER,
    MaterialsRoles.PERSISTER,
    MaterialsRoles.SUPPLIER_MATERIALS_BULK_IMPORTER,
    MaterialsRoles.SUPPLIER_MATERIALS_DELETER,
    MaterialsRoles.SUPPLIER_MATERIALS_PERSISTER,

    ScheduledEventsRoles.BULK_IMPORTER,
    ScheduledEventsRoles.CREATOR,
    ScheduledEventsRoles.EDITOR,
    ScheduledEventsRoles.VIEWER,

    SuppliersRoles.ARCHIVER,
    SuppliersRoles.COLLECTION_MANAGER,
    SuppliersRoles.PERSISTER,

    TasksRoles.COMPLETER,
    TasksRoles.DELETER,
    TasksRoles.PERSISTER,
    TasksRoles.VIEWER,

    TaxRateRoles.DELETER,
    TaxRateRoles.PERSISTER,

    TimelogsRoles.ASSIGNEE_CREATOR,
    TimelogsRoles.ASSIGNEE_DELETER,
    TimelogsRoles.ASSIGNEE_UPDATER,
    TimelogsRoles.ASSIGNEE_VIEWER,
    TimelogsRoles.COST_ADMINISTRATOR,
    TimelogsRoles.CREATOR,
    TimelogsRoles.DELETER,
    TimelogsRoles.PREVIEWER,
    TimelogsRoles.TYPES_MANAGER,
    TimelogsRoles.TYPES_VIEWER,
    TimelogsRoles.UPDATER,
    TimelogsRoles.VIEWER,

    UserAssignmentsRoles.ASSIGNER,
  ],
  ovoOmitRoles
);
const CustomOvoFranchisorAdminRolesBusinessV2 = omitRoles(
  [
    ...SuperAdminRolesBusinessV2,
    ...CustomOvoFranchiseeAdminRolesBusinessV2,
    AccountsRoles.CHILD_ACCOUNTS_VIEWER,
    AccountsRoles.CHILD_ACCOUNTS_JOINER,
  ],
  ovoOmitRoles
);

export const roleInheritanceMappings = {
  [SubscriptionLevels.SOLO]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesSolo,
    [DefaultSystemRoles.ADMIN]: AdminRolesSolo,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesSolo,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesSolo,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesSolo,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
  },
  [SubscriptionLevels.TEAM]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesTeam,
    [DefaultSystemRoles.ADMIN]: AdminRolesTeam,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesTeam,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesTeam,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesTeam,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
  },
  [SubscriptionLevels.BUSINESS]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesBusiness,
    [DefaultSystemRoles.ADMIN]: AdminRolesBusiness,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesBusiness,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesBusiness,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesBusiness,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
  },
  [SubscriptionLevels.PRO]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesPro,
    [DefaultSystemRoles.ADMIN]: AdminRolesPro,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesPro,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesPro,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesPro,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
  },
  [SubscriptionLevels.PRO_V2]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesProV2,
    [DefaultSystemRoles.ADMIN]: AdminRolesProV2,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesProV2,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesProv2,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesProv2,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]: [],
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]: [],
  },
  [SubscriptionLevels.BUSINESS_V2]: {
    [DefaultSystemRoles.SUPER_ADMIN]: SuperAdminRolesBusinessV2,
    [DefaultSystemRoles.ADMIN]: AdminRolesBusinessV2,
    [DefaultSystemRoles.SALES_MANAGER]: SalesManagerRolesBusinessV2,
    [DefaultSystemRoles.SALES_AGENT]: SalesAgentRolesBusinessV2,
    [DefaultSystemRoles.FIELD_AGENT]: FieldAgentRolesBusinessV2,

    [CustomOvoSystemRoles.OVO_FRANCHISOR_ADMIN]:
      CustomOvoFranchisorAdminRolesBusinessV2,
    [CustomOvoSystemRoles.OVO_FRANCHISEE_ADMIN]:
      CustomOvoFranchiseeAdminRolesBusinessV2,
    [CustomOvoSystemRoles.OVO_FRANCHISEE_SALES_AGENT]:
      CustomOvoFranchiseeSalesAgentRolesBusinessV2,
    [CustomOvoSystemRoles.OVO_FRANCHISEE_FIELD_AGENT]:
      CustomOvoFranchiseeFieldAgentRolesBusinessV2,
  },
};
