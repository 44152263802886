import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  getHydratedPurchaseOrder,
  requestGetPurchaseOrder,
} from '@payaca/store/materialsList/materialsListActions';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { backOff } from 'exponential-backoff';
import QueryString from 'qs';
import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import DirectPurchaseOrder from './components/DirectPurchaseOrder';
import IndirectPurchaseOrder from './components/IndirectPurchaseOrder';
import './PurchaseOrderPage.sass';

type Props = {
  purchaseOrderId: number;
};

const PurchaseOrderPage: FC<Props> = ({
  purchaseOrderId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [purchaseOrder, setPurchaseOrder] = useState<HydratedPurchaseOrder>();

  const fetchPdfRetry = useCallback(() => {
    return backOff(
      async () => {
        return new Promise<void>((resolve, reject) => {
          dispatch(
            requestGetPurchaseOrder(purchaseOrderId, (po) => {
              if (po.pdfUrl) {
                resolve();
              } else {
                // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                reject();
              }
            })
          );
        });
      },
      {
        numOfAttempts: 5,
        startingDelay: 1500,
        delayFirstAttempt: true,
      }
    );
  }, [purchaseOrderId]);

  useEffect(() => {
    if (!purchaseOrder?.pdfUrl) {
      void fetchPdfRetry();
    }
  }, []);

  const { projectId } = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }) as { projectId: string | undefined };

  const getPurchaseOrder = useCallback(() => {
    dispatch(
      getHydratedPurchaseOrder.request({
        purchaseOrderId,
        callback: (purchaseOrder) => {
          setPurchaseOrder(purchaseOrder);
        },
      })
    );
  }, []);

  useEffect(() => {
    if (projectId) {
      dispatch(requestGetDeal(+projectId));
    }
  }, [projectId]);

  useEffect(() => {
    getPurchaseOrder();
  }, []);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      previousPageNavigationConfig={{
        route: projectId
          ? `/deals/${projectId}/materials/purchase-orders`
          : `/suppliers/${purchaseOrder?.supplierId}?tab=purchase-orders`,
      }}
    >
      <div className="p-4">
        {purchaseOrder?.metadata?.orderId ? (
          <DirectPurchaseOrder purchaseOrder={purchaseOrder} />
        ) : (
          <IndirectPurchaseOrder
            projectId={projectId ? +projectId : undefined}
            purchaseOrder={purchaseOrder}
            getPurchaseOrder={getPurchaseOrder}
            fetchPdfRetry={fetchPdfRetry}
          />
        )}
      </div>
    </AuthenticatedPageWrapper>
  );
};
export default PurchaseOrderPage;
