import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { AccountTermsInput } from '@/gql/graphql';

const GET_MY_ACCOUNT_TERMS = graphql(`
  query GetMyAccountTerms($input: AccountTermsInput) {
    me {
      user {
        account {
          terms(input: $input) {
            id
            __typename
            ... on WrittenTerm {
              content
            }
          }
        }
      }
    }
  }
`);

const useGetMyAccountTerms = (input?: AccountTermsInput) => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountTerms(),
    queryFn: () => {
      return gqlClient.request(GET_MY_ACCOUNT_TERMS, { input });
    },
  });

  return { data, terms: data?.me.user.account.terms, ...rest };
};

export default useGetMyAccountTerms;
