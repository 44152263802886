import { Nullable, Nullish } from '@payaca/utilities/types';

export interface User {
  deactivatedAt: string | null;
  email: string;
  enableAllNotifications: any;
  firstname: string;
  id: number;
  inviteToken?: string;
  verifyToken?: string | null;
  isPayacaAdmin: boolean;
  isDemo: boolean;
  lastname: string;
  name: string;
  notificationSettings: any;
  oneTimeLoginToken?: string;
  verifiedAt: string | null;
  userColour?: string;
  systemRole?: string;
  gasSafeIdCardNumber?: number;
  userSignature?: string;
  pendingEmail: string | null;
  costPerHour?: Nullable<number>;
  termsAndPrivacyVersion?: number;
  referralToken?: string;
  isPrimaryAccountUser: boolean;
  contactNumber?: string;
  sortOrder?: Nullish<number>;
  emailSignature?: Nullish<string>;
  avatarUrl?: string;
}

export type UserRow = {
  id: number;
  // TODO
};

export enum UserStatus {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  DEACTIVATED = 'Deactivated',
  NOT_VERIFIED = 'Not verified',
}
