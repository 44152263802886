import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';
import { UploadAttachableEntityType } from '@payaca/types/uploadTypes';
import EditJobSection from '../editJob/EditJobSection';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';

const acceptFileTypes = getAcceptedFileTypes([
  'document',
  'image',
  'video',
  'other',
]);

type Props = {
  entityId: number;
  entityType: UploadAttachableEntityType;
  attachmentUploadRole: string;
  attachmentUploadIds: number[];
  prompt?: string;
  onPersistUploadSuccess?: () => void;
};

const AttachmentUploadsControl: FC<Props> = ({
  entityId,
  entityType,
  attachmentUploadIds,
  attachmentUploadRole,
  prompt,
  onPersistUploadSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const handlePersistUploadSuccess = useCallback(() => {
    onPersistUploadSuccess?.();
    dispatch(uploadsActions.requestGetUploadsForEntity(entityId, entityType));
  }, [entityId, onPersistUploadSuccess]);

  const persistUpload = useCallback(
    (file: File) => {
      return new Promise<void>((resolve, reject) => {
        dispatch(
          uploadsActions.requestPersistUploadAndLinkToEntity(
            {
              file: file,
              entityType: entityType,
              entityId: entityId,
              entityRole: attachmentUploadRole,
            },
            () => {
              resolve();
            }
          )
        );
      });
    },
    [entityId, entityType, attachmentUploadRole]
  );

  const removeUpload = (uploadId: number) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        uploadsActions.requestDeleteUpload(
          uploadId,
          (error) => {
            if (!error) {
              resolve();
            } else {
              reject();
            }
          },
          true
        )
      );
    });
  };

  return (
    <EditJobSection
      title={'Attachments'}
      className="attachment-uploads-control"
    >
      {prompt && <p>{prompt}</p>}
      <UploadCollectionControl
        uploadIds={attachmentUploadIds || []}
        customPersistUpload={persistUpload}
        customRemoveUpload={removeUpload}
        onUploadCollectionChange={handlePersistUploadSuccess}
        allowMultipleUploads={true}
        // 10mb
        fileSizeLimitBytes={10 * 1024 * 1024}
        acceptFileTypes={acceptFileTypes}
        showFileNames={true}
      />
    </EditJobSection>
  );
};
export default AttachmentUploadsControl;
