import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import {
  SupplierMaterialsCollectionFulfillment,
  SupplierMaterialsDeliveryFulfillment,
} from '@payaca/types/fulfillmentTypes';
import { differenceInDays, format, isToday, isTomorrow } from 'date-fns';

export type DeliveryFulfillmentOption = SupplierMaterialsDeliveryFulfillment & {
  __typename: 'SupplierMaterialsDeliveryFulfillment';
};
export type CollectionFulfillmentOption =
  SupplierMaterialsCollectionFulfillment & {
    __typename: 'SupplierMaterialsCollectionFulfillment';
  };
export type FulfillmentOption =
  | DeliveryFulfillmentOption
  | CollectionFulfillmentOption;

export const isCollectionFulfillment = (f: FulfillmentOption) =>
  f.__typename === 'SupplierMaterialsCollectionFulfillment';

export const isDeliveryFulfillment = (f: FulfillmentOption) =>
  f.__typename === 'SupplierMaterialsDeliveryFulfillment';

export const getFulfillmentDate = (f: FulfillmentOption) => {
  return isCollectionFulfillment(f) ? f.collectionFromDate : f.deliveryFromDate;
};

export const getFromAvailabilityDate = (
  d: Date,
  isCollection: boolean,
  locale?: string
) => {
  const time = format(d, 'HH:mm');
  if (isToday(d)) {
    return `${isCollection ? 'from ' : ''}today${
      isCollection ? ` ${time}` : ''
    }`;
  } else if (isTomorrow(d)) {
    return `${isCollection ? 'from ' : ''}tomorrow${
      isCollection ? ` ${time}` : ''
    }`;
  } else {
    return `from ${format(
      d,
      getInternationalDateFormatByLocale(
        isCollection
          ? DateFormats.TIMESTAMP_DAY_MONTH
          : DateFormats.DAY_MONTH_SHORT,
        locale
      )
    )}`;
  }
};

export const getBadgeColour = (fulfillmentOption?: FulfillmentOption) => {
  const fulfillmentDays = fulfillmentOption
    ? differenceInDays(getFulfillmentDate(fulfillmentOption), new Date())
    : null;

  if (fulfillmentOption?.isFullFulfillment && fulfillmentDays !== null) {
    if (fulfillmentDays === 0) {
      return 'green';
    } else if (fulfillmentDays < 7) {
      return 'yellow';
    } else {
      return 'orange';
    }
  } else {
    if (fulfillmentOption) {
      return 'orange';
    }
    return 'red';
  }
};
