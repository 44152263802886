import { FC, PropsWithChildren } from 'react';
import Field from '../plField/Field';
import { clstx } from '../utils';

const ViewFieldset: FC<
  PropsWithChildren<{
    className?: string;
  }>
> = (props) => {
  const { className, children } = props;

  return (
    <div
      className={clstx(
        'grid grid-cols-[max-content_auto] items-end [&:nth-last-child(n+3)]:*:border-b *:py-4 even:*:pl-4',
        className
      )}
    >
      {children}
    </div>
  );
};

const ViewField: FC<
  PropsWithChildren<{
    label: string;
  }>
> = (props) => {
  const { label, children } = props;

  return (
    <>
      <Field.Label className="mb-0">{label}</Field.Label>

      <p className="break-all">{children}</p>
    </>
  );
};

export default Object.assign(ViewFieldset, {
  Field: ViewField,
});
