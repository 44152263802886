import { assertUnreachable } from '@payaca/utilities/guards';
import { getReadableStringFromDurationString } from '@payaca/utilities/timeUtilities';
import Ajv from 'ajv';
import {
  EMAIL_VALIDATION_REGEX,
  URL_VALIDATION_REGEX,
} from '../constants/regex';

export const CUSTOM_FIELD_TYPE_VALUES = [
  'textarea',
  'duration',
  'multi-upload',
  'text',
  'email',
  'url',
  'select',
  'boolean',
  'number',
  'fieldset',
] as const;

export const CUSTOM_FIELD_TYPE_READABLE_NAME_MAP: Record<
  CustomFieldType,
  string
> = {
  textarea: 'Textarea',
  duration: 'Duration',
  'multi-upload': 'Multiple file upload',
  text: 'Text',
  email: 'Email',
  select: 'Drop-down list',
  boolean: 'True/false',
  fieldset: 'Fieldset',
  number: 'Number',
  url: 'URL',
};

export type CustomFieldType = (typeof CUSTOM_FIELD_TYPE_VALUES)[number];

export type CustomFieldHelperType = {
  ['textarea']: {
    value: string;
    definition: TextareaCustomFieldDefinition;
  };
  ['text']: {
    value: string;
    definition: TextCustomFieldDefinition;
  };
  ['number']: {
    value: number;
    definition: NumberCustomFieldDefinition;
  };
  ['email']: {
    value: string;
    definition: EmailCustomFieldDefinition;
  };
  ['duration']: {
    value: string;
    definition: DurationCustomFieldDefinition;
  };
  ['multi-upload']: {
    value: { uploadIds: string[]; uploadIntentPublicIds: string[] };
    definition: MultiUploadCustomFieldDefinition;
  };
  ['select']: {
    value: string;
    definition: SelectCustomFieldDefinition;
  };
  ['boolean']: {
    value: boolean;
    definition: BooleanCustomFieldDefinition;
  };
  ['url']: {
    value: { title?: string; url: string };
    definition: UrlCustomFieldDefinition;
  };
  ['fieldset']: {
    value: Record<
      string,
      | CustomFieldHelperType[Exclude<CustomFieldType, 'fieldset'>]['value']
      | null
    >;
    definition: FieldsetCustomFieldDefinition;
  };
};

export type CustomFieldFormatters = {
  [K in CustomFieldType]?: (value: CustomFieldHelperType[K]['value']) => string;
};

export type CustomFieldValue<T extends CustomFieldType> = {
  identifier: string;
  value: CustomFieldHelperType[T]['value'] | null;
};

export type CustomFieldWithValue<T extends CustomFieldType> = {
  field: CustomFieldHelperType[T]['definition'];
  value?: CustomFieldValue<T>['value'];
};

export const SCHEMA_TYPE_VALUES = [
  'string',
  'number',
  'boolean',
  'object',
  'null',
] as const;

type SchemaType = (typeof SCHEMA_TYPE_VALUES)[number];

export type Schema = Record<string, unknown> & {
  metadata: {
    'payaca:label': string;
    'payaca:field-type': CustomFieldType;
    'payaca:identifier': string;
  };
  type: SchemaType[] | SchemaType;
  enum?: (string | null)[];
};

type BasicCustomFieldDefinitionInput = {
  label: string;
  identifier: string;
  isRequired?: boolean;
};

type SelectCustomFieldDefinitionInput = BasicCustomFieldDefinitionInput & {
  options: string[];
  type: 'select';
};

type SimpleCustomFieldDefinitionInput = BasicCustomFieldDefinitionInput & {
  type:
    | 'number'
    | 'boolean'
    | 'textarea'
    | 'text'
    | 'email'
    | 'url'
    | 'duration'
    | 'multi-upload';
};

export type FieldsetCustomFieldDefinitionInput =
  BasicCustomFieldDefinitionInput & {
    children: (
      | SelectCustomFieldDefinitionInput
      | SimpleCustomFieldDefinitionInput
    )[];
    type: 'fieldset';
  };

export type CustomFieldDefinitionInput =
  | FieldsetCustomFieldDefinitionInput
  | SimpleCustomFieldDefinitionInput
  | SelectCustomFieldDefinitionInput;

const ajv = new Ajv({
  strict: 'log',
  strictSchema: 'log',
});
ajv.addFormat('email', EMAIL_VALIDATION_REGEX);
ajv.addFormat('url', URL_VALIDATION_REGEX);

export abstract class CustomFieldDefinition<
  T extends CustomFieldType = CustomFieldType,
> {
  readonly schema: Schema;
  readonly type: T;

  abstract getExampleValue(): CustomFieldHelperType[T]['value'];

  protected static getSharedSchemaPropertiesFromInput(
    input: CustomFieldDefinitionInput
  ) {
    return {
      metadata: {
        ['payaca:label']: input.label,
        ['payaca:field-type']: input.type,
        ['payaca:identifier']: input.identifier,
      },
      ['$schema']: 'http://json-schema.org/draft/2020-12/schema',
    };
  }

  protected getValidator() {
    const { $schema, ...schema } = this.schema;

    const validator = ajv.compile(schema);

    return validator;
  }

  public validateValue(value: any) {
    const validator = this.getValidator();
    const isValid = validator(value);

    return {
      isValid,
      validationMessages:
        validator.errors?.map((error) => error.message || '') ?? [],
    };
  }

  typecheckValue(value: any): value is CustomFieldHelperType[T]['value'] {
    return this.validateValue(value).isValid;
  }

  protected abstract defaultFormatter(
    value: CustomFieldHelperType[T]['value']
  ): string;

  formatValue(
    value: any,
    customFormatter?: (value: CustomFieldHelperType[T]['value']) => string
  ): string | null {
    if (this.typecheckValue(value)) {
      if (value === null) return null;
      if (customFormatter) {
        return customFormatter(value);
      } else {
        return this.defaultFormatter(value);
      }
    } else {
      return null;
    }
  }

  static fromInput(input: CustomFieldDefinitionInput) {
    switch (input.type) {
      case 'text':
        return TextCustomFieldDefinition.fromInput(input);
      case 'textarea':
        return TextareaCustomFieldDefinition.fromInput(input);
      case 'email':
        return EmailCustomFieldDefinition.fromInput(input);
      case 'select':
        return SelectCustomFieldDefinition.fromInput(input);
      case 'duration':
        return DurationCustomFieldDefinition.fromInput(input);
      case 'multi-upload':
        return MultiUploadCustomFieldDefinition.fromInput(input);
      case 'boolean':
        return BooleanCustomFieldDefinition.fromInput(input);
      case 'number':
        return NumberCustomFieldDefinition.fromInput(input);
      case 'url':
        return UrlCustomFieldDefinition.fromInput(input);
      case 'fieldset':
        return FieldsetCustomFieldDefinition.fromInput(input);
      default:
        throw new Error('Unreachable');
      // assertUnreachable(input.type as CustomFieldType);
    }

    throw new Error('Unreachable');
  }

  static fromSchema(schema: Schema) {
    switch (schema.metadata['payaca:field-type']) {
      case 'text':
        return new TextCustomFieldDefinition(schema);
      case 'textarea':
        return new TextareaCustomFieldDefinition(schema);
      case 'email':
        return new EmailCustomFieldDefinition(schema);
      case 'select':
        return new SelectCustomFieldDefinition(schema);
      case 'duration':
        return new DurationCustomFieldDefinition(schema);
      case 'multi-upload':
        return new MultiUploadCustomFieldDefinition(schema);
      case 'boolean':
        return new BooleanCustomFieldDefinition(schema);
      case 'number':
        return new NumberCustomFieldDefinition(schema);
      case 'url':
        return new UrlCustomFieldDefinition(schema);
      case 'fieldset':
        return new FieldsetCustomFieldDefinition(schema);
      default:
        assertUnreachable(schema.metadata['payaca:field-type']);
    }

    throw new Error('Unreachable');
  }

  constructor(schema: Schema) {
    this.schema = schema;
    this.type = schema.metadata['payaca:field-type'] as T;
  }

  get label(): string {
    return this.schema?.metadata?.['payaca:label'];
  }

  set label(value: string) {
    this.schema.metadata['payaca:label'] = value;
  }

  get identifier(): string {
    return this.schema?.metadata?.['payaca:identifier'];
  }

  public get isRequired(): boolean {
    return !(
      Array.isArray(this.schema.type) && this.schema.type.includes('null')
    );
  }

  public set isRequired(value: boolean) {
    if (value) {
      if (Array.isArray(this.schema.type)) {
        this.schema.type = this.schema.type.filter((x) => x !== 'null');
      }
    } else {
      if (Array.isArray(this.schema.type)) {
        this.schema.type = ['null', ...this.schema.type];
      } else {
        this.schema.type = ['null', this.schema.type];
      }

      this.schema.type = ['null'];
    }
  }
}
export class TextCustomFieldDefinition extends CustomFieldDefinition<'text'> {
  getExampleValue() {
    return 'Lorem Ipsum';
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'text') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'string' : ['string', 'null'],
    };

    return new TextCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['text']['value']) {
    return value;
  }
}

export class TextareaCustomFieldDefinition extends CustomFieldDefinition<'textarea'> {
  getExampleValue() {
    return 'Lorem Ipsum';
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'textarea') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'string' : ['string', 'null'],
    };

    return new TextareaCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['textarea']['value']) {
    return value;
  }
}

export class EmailCustomFieldDefinition extends CustomFieldDefinition<'email'> {
  getExampleValue() {
    return 'example@email.com';
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'email') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'string' : ['string', 'null'],
      format: 'email',
    };

    return new EmailCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['email']['value']) {
    return value;
  }
}

export class SelectCustomFieldDefinition extends CustomFieldDefinition<'select'> {
  getExampleValue() {
    return this.options.filter((x) => x)[0] || 'selectable option';
  }

  static fromInput(input: SelectCustomFieldDefinitionInput) {
    if (input.type !== 'select') throw new Error('Invalid type');

    const options: (string | null)[] | undefined = input.options;

    if (options && !input.isRequired) {
      options.push(null);
    }

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'string' : ['string', 'null'],
      enum: options,
    };

    return new SelectCustomFieldDefinition(schema);
  }

  get options(): (string | null)[] {
    return this.schema.enum as (string | null)[];
  }

  set options(value: string[]) {
    if (this.isRequired) {
      this.schema.enum = value;
    } else {
      this.schema.enum = [null, ...value];
    }
  }

  set isRequired(value: boolean) {
    super.isRequired = value;

    if (value) {
      this.schema.enum = this.schema.enum?.filter((x) => x !== null);
    } else {
      this.schema.enum = [null, ...(this.schema.enum || [])];
    }
  }

  defaultFormatter(value: CustomFieldHelperType['select']['value']) {
    return value;
  }
}

export class DurationCustomFieldDefinition extends CustomFieldDefinition<'duration'> {
  getExampleValue(): CustomFieldHelperType['duration']['value'] {
    return 'P0Y0M0DT1H0M0S';
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'duration') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'string' : ['string', 'null'],
    };

    return new DurationCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['duration']['value']) {
    const readableDuration = getReadableStringFromDurationString(
      value.toString(),
      {}
    );
    return readableDuration;
  }
}

export class MultiUploadCustomFieldDefinition extends CustomFieldDefinition<'multi-upload'> {
  getExampleValue() {
    return {
      uploadIds: ['abc123'],
      uploadIntentPublicIds: [],
    };
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'multi-upload') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'object' : ['object', 'null'],
      properties: {
        uploadIds: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
        uploadIntentPublicIds: {
          type: 'array',
          items: {
            type: 'string',
          },
        },
      },
    };

    return new MultiUploadCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['multi-upload']['value']) {
    const count = value.uploadIds.length + value.uploadIntentPublicIds.length;
    return `${count} files uploaded`;
  }
}

export class BooleanCustomFieldDefinition extends CustomFieldDefinition<'boolean'> {
  getExampleValue(): CustomFieldHelperType['boolean']['value'] {
    return true;
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'boolean') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'boolean' : ['boolean', 'null'],
    };

    return new BooleanCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['boolean']['value']) {
    return value ? 'True' : 'False';
  }
}

export class NumberCustomFieldDefinition extends CustomFieldDefinition<'number'> {
  getExampleValue() {
    return 100;
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'number') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'number' : ['number', 'null'],
    };

    return new NumberCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['number']['value']) {
    return value.toString();
  }
}

export class UrlCustomFieldDefinition extends CustomFieldDefinition<'url'> {
  getExampleValue() {
    return {
      title: 'Payaca',
      url: 'https://payaca.com/',
    };
  }

  static fromInput(input: SimpleCustomFieldDefinitionInput) {
    if (input.type !== 'url') throw new Error('Invalid type');

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'object' : ['object', 'null'],
      properties: {
        title: {
          type: ['string', 'null'],
        },
        url: {
          type: 'string',
          format: 'url',
        },
      },
    };

    return new UrlCustomFieldDefinition(schema);
  }

  defaultFormatter(value: CustomFieldHelperType['url']['value']) {
    if (value.title?.length) {
      return `${value.title} (${value.url})`;
    }

    return value.url;
  }

  validateValue(value: any) {
    const res = super.validateValue(value);

    if (!res.isValid) {
      res.validationMessages = ['URL must start with http:// or https://'];
    }

    return res;
  }
}

export class FieldsetCustomFieldDefinition extends CustomFieldDefinition<'fieldset'> {
  getExampleValue(): CustomFieldHelperType['fieldset']['value'] {
    const result: Record<
      keyof CustomFieldHelperType['fieldset']['value'],
      Exclude<
        CustomFieldHelperType['fieldset']['value'][keyof CustomFieldHelperType['fieldset']['value']],
        null
      >
    > = {};
    this.children.forEach((child) => {
      result[child.identifier] = child.getExampleValue();
    });

    return result;
  }

  static fromInput(input: FieldsetCustomFieldDefinitionInput) {
    if (input.type !== 'fieldset') throw new Error('Invalid type');

    const properties: Record<string, Schema> = {};

    for (const child of input.children || []) {
      try {
        properties[child.identifier] =
          CustomFieldDefinition.fromInput(child).schema;
      } catch (e) {
        console.error('Error creating fieldset property', e);
      }
    }

    const schema: Schema = {
      ...this.getSharedSchemaPropertiesFromInput(input),
      type: input.isRequired ? 'object' : ['object', 'null'],
      properties,
      additionalProperties: false,
    };

    return new FieldsetCustomFieldDefinition(schema);
  }

  get children(): CustomFieldHelperType[Exclude<
    CustomFieldType,
    'fieldset'
  >]['definition'][] {
    const children = Object.values(
      this.schema.properties as Record<string, Schema>
    );
    const keys = Object.keys(this.schema.properties as Record<string, Schema>);

    if (!children?.length)
      throw new Error('Children are required for fieldset');

    if (
      children.some(
        (child) => child.metadata['payaca:field-type'] === 'fieldset'
      )
    ) {
      throw new Error('Fieldsets cannot be multiply nested');
    }

    const duplicateKeys = keys.filter(
      (item, index) => keys.indexOf(item) !== index
    );

    if (duplicateKeys.length) {
      throw new Error(
        `Duplicate keys found in fieldset: ${duplicateKeys.join(', ')}`
      );
    }

    return Object.values(children).map(
      (child) =>
        CustomFieldDefinition.fromSchema(
          child
        ) as MultiUploadCustomFieldDefinition
    );
  }

  defaultFormatter(value: CustomFieldHelperType['fieldset']['value']) {
    return JSON.stringify(value);
  }
}
