import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import materialKeys from '@/api/queries/materials/keyFactory';
import { graphql } from '@/gql';
import {
  GetMaterialFulfillmentsInput,
  GetMaterialsInput,
  MaterialsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetMaterials = graphql(`
  query Materials(
    $pagination: PaginationInput!
    $input: GetMaterialsInput
    $getMaterialFulfillmentsInput: GetMaterialFulfillmentsInput
  ) {
    materials(pagination: $pagination, input: $input) {
      items {
        id
        internalId
        name
        category {
          id
          name
        }
        image {
          id
          thumbnailUrl
        }
        suppliedBy {
          supplier {
            id
            name
          }
          price {
            taxRate {
              id
            }
            unitPrice {
              value
              currency {
                id
                code
                exponent
              }
            }
            unitPriceExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
          }
        }
        fulfillments(input: $getMaterialFulfillmentsInput) {
          supplier {
            id
          }
          fulfillmentOptions {
            __typename
            isFullFulfillment
            branchStock
            supplier {
              id
            }
            branch {
              name
            }
            ... on SupplierMaterialCollectionFulfillment {
              collectionFromDate
            }
            ... on SupplierMaterialDeliveryFulfillment {
              deliveryFromDate
            }
          }
        }
      }
      limit
      offset
      totalCount
    }
  }
`);

export const useGetInfiniteMaterials = (
  input?: GetMaterialsInput,
  fulfillmentsInput?: GetMaterialFulfillmentsInput
) => {
  return useInfiniteQuery({
    queryKey: materialKeys.infiniteMaterials(input),
    queryFn: ({ pageParam = 0 }) => {
      return gqlClient.request(GetMaterials, {
        pagination: {
          offset: pageParam || PAGINATION_ARG_DEFAULTS.offset,
          limit: PAGINATION_ARG_DEFAULTS.limit,
        },
        input: input,
        getMaterialFulfillmentsInput: fulfillmentsInput,
      });
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage.materials) {
        return null;
      }

      const newOffset = lastPage.materials.offset + lastPage.materials.limit;

      if (lastPage.materials.totalCount <= newOffset) {
        return null;
      }

      return newOffset;
    },
  });
};

export type MaterialsPage = NonNullable<MaterialsQuery['materials']>;

export type Material = NonNullable<MaterialsPage>['items'][0];

const useGetMaterials = (
  pagination?: PaginationInput,
  input?: GetMaterialsInput,
  fulfillmentsInput?: GetMaterialFulfillmentsInput
) => {
  return useQuery({
    queryKey: materialKeys.materials(pagination, input),
    queryFn: () => {
      return gqlClient.request(GetMaterials, {
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
        input: input,
        getMaterialFulfillmentsInput: fulfillmentsInput,
      });
    },
  });
};

export default useGetMaterials;
