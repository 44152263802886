import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  PreviewEmailTemplateInput,
  PreviewEmailTemplateMutation,
} from '@/gql/graphql';

const PREVIEW_EMAIL_TEMPLATE_MUTATION = graphql(`
  mutation PreviewEmailTemplate($input: PreviewEmailTemplateInput!) {
    previewEmailTemplate(input: $input) {
      ... on EmailTemplatePreviewSuccess {
        __typename
        preview
      }
      ... on EmailTemplatePreviewError {
        __typename
        message
      }
    }
  }
`);

const usePreviewEmailTemplate = (
  options?: UseMutationOptions<
    PreviewEmailTemplateMutation,
    unknown,
    PreviewEmailTemplateInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(PREVIEW_EMAIL_TEMPLATE_MUTATION, { input });
    },
    ...options,
  });
};

export default usePreviewEmailTemplate;
