// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

import { GetEmailTemplatesInput } from '@/gql/graphql';

const emailTemplateKeys = {
  all: ['emailTemplate'] as const,
  emailTemplate: (emailTemplateId: string) => {
    return [...emailTemplateKeys.all, emailTemplateId] as const;
  },
  emailTemplates: (input?: GetEmailTemplatesInput) => {
    if (!input) {
      return [...emailTemplateKeys.all, 'emailTemplates'] as const;
    }

    return [...emailTemplateKeys.all, 'emailTemplates', input] as const;
  },
  emailTemplateVariables: (type: string) => {
    return [...emailTemplateKeys.all, 'emailTemplateVariables', type] as const;
  },
};

export default emailTemplateKeys;
