import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetChildAccountsInput,
  GetChildAccountsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import childAccountsKeys from './keyFactory';

const GetChildAccounts = graphql(`
  query GetChildAccounts(
    $input: GetChildAccountsInput
    $pagination: PaginationInput!
  ) {
    childAccounts(input: $input, pagination: $pagination) {
      items {
        id
        name
        phone
        email
      }
      limit
      offset
      totalCount
    }
  }
`);

const useGetChildAccounts = (
  opts?: {
    input?: GetChildAccountsInput;
    pagination?: PaginationInput;
  },
  queryOptions?: UseQueryOptions<GetChildAccountsQuery>
) => {
  const { data, ...rest } = useQuery({
    queryKey: childAccountsKeys.childAccounts(),
    queryFn: () => {
      return gqlClient.request(GetChildAccounts, {
        input: opts?.input,
        pagination: opts?.pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
    ...queryOptions,
  });
  return {
    childAccounts: data?.childAccounts,
    ...rest,
  };
};

export default useGetChildAccounts;
