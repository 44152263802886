import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import { GetProjectCustomFieldsQuery } from '@/gql/graphql';

const GetProjectCustomFields = graphql(`
  query GetProjectCustomFields($projectId: Int!) {
    project(id: $projectId) {
      customFields {
        value
        field {
          identifier
          isRequired
          label
          type
          options
          schema
        }
      }
    }
  }
`);

export type ProjectCustomField = NonNullable<
  GetProjectCustomFieldsQuery['project']['customFields']
>;

const useGetProjectCustomFields = (projectId?: number) => {
  const { data, ...rest } = useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.customFields(projectId!),
    queryFn: () => {
      return gqlClient.request(GetProjectCustomFields, {
        projectId: projectId!,
      });
    },
  });

  return { customFields: data?.project.customFields, data, ...rest };
};

export default useGetProjectCustomFields;
