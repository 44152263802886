import { useSelector } from '@/api/state';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
  PublicEntityTemplate,
  PublicHydratedEntityTemplate,
  TemplatableEntity,
} from '@payaca/types/entity-templates';
import { parseHtmlToReact } from '@payaca/utilities/htmlUtilities';
import './EntityTemplateReadView.sass';

import './EntityTemplateTaskReadView.sass';

import * as formActions from '@payaca/store/forms/formsActions';

type Props = {
  entityTemplate: PublicEntityTemplate<'task'>;
  onUpdateEntityTemplateSuccess?: (
    updatedEntityTemplate: PublicHydratedEntityTemplate<TemplatableEntity>
  ) => void;
};
const EntityTemplateTaskReadView: FC<Props> = ({
  entityTemplate,
  onUpdateEntityTemplateSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  useEffect(() => {
    // get available forms
    dispatch(formActions.requestGetAvailableForms());
  }, []);

  const availableForms = useSelector((state) => state.forms.availableForms);
  const form = availableForms.find(
    (f) => f.value.toString() == entityTemplate.data.formTemplateId
  );

  if (!entityTemplate) return null;

  return (
    <div className={'entity-template-task-read-view'}>
      <div>
        {entityTemplate?.data.name && (
          <h1 className="name">{entityTemplate?.data.name}</h1>
        )}
      </div>
      {entityTemplate?.data.notes && (
        <div className="notes">
          <div className="label">Notes</div>
          <div>{parseHtmlToReact(entityTemplate.data.notes)}</div>
        </div>
      )}
      {!!entityTemplate?.data.checklistItems?.length && (
        <div className="checklist-items">
          <div className="label">Checklist Items</div>
          <ul>
            {entityTemplate?.data.checklistItems.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {!!entityTemplate?.data.formTemplateId && (
        <div className="form">
          <div className="label">Form Template</div>
          {form?.label}
        </div>
      )}
    </div>
  );
};
export default EntityTemplateTaskReadView;
