import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  DeleteChangeProposalInput,
  DeleteChangeProposalMutation,
} from '@/gql/graphql';

const DELETE_CHANGE_PROPOSAL_MUTATION = graphql(`
  mutation DeleteChangeProposal($input: DeleteChangeProposalInput!) {
    deleteChangeProposal(input: $input)
  }
`);

const useDeleteChangeProposal = (
  options?: UseMutationOptions<
    DeleteChangeProposalMutation,
    unknown,
    DeleteChangeProposalInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_CHANGE_PROPOSAL_MUTATION, { input });
    },
    ...options,
  });
};

export default useDeleteChangeProposal;
