import { FC, useEffect, useState } from 'react';

import useGetMyAccountFeatures, {
  useMyAccountHasFeature,
} from '@/api/queries/me/useGetMyAccountFeatures';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import Field, { FieldValidationState } from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import Modal from '@payaca/components/plModal/Modal';
import { Link } from 'react-router-dom';
import useCreateSubdomain from '../../../api/mutations/account/useCreateSubdomain';
import useGetMyAccountSubdomain from '../../../api/queries/me/useGetMyAccountSubdomain';

const subdomainStatusMap: { [key: string]: string } = {
  pending: 'Pending',
};
type Props = {
  readOnly: boolean;
};
const CompanySettingsBrandingSubdomainSection: FC<Props> = (props) => {
  const { readOnly } = props;
  const { refetch: getMyAccountFeatures } = useGetMyAccountFeatures();
  const accountHasClientPortalFeature = useMyAccountHasFeature('clientPortal');

  const [subittedValidationState, setSubmittedValidationState] =
    useState<FieldValidationState | null>(null);

  const [subdomain, setSubdomain] = useState('');
  const { mutateAsync: createSubdomainMutation, isLoading } =
    useCreateSubdomain();
  const [showSubdomainSubmittedModal, setShowSubdomainSubmittedModal] =
    useState(false);
  const { data: accountSubdomain, refetch } = useGetMyAccountSubdomain();

  const clientPortalUrl = accountSubdomain?.url;

  useEffect(() => {
    getMyAccountFeatures();
  }, []);

  const subdomainValidationState =
    subdomain &&
    typeof subdomain === 'string' &&
    /* eslint-disable-next-line */
    !/^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/.test(subdomain)
      ? {
          isValid: false,
          validationMessages: [
            'Only letters, numbers and intermediate hyphens are allowed',
          ],
        }
      : undefined;

  const validationState = subittedValidationState || subdomainValidationState;

  const createSubdomain = async () => {
    const response = await createSubdomainMutation({ name: subdomain });
    if (
      response.createSubdomain?.__typename ===
      'CreateSubdomainResponseUnavailableError'
    ) {
      setSubmittedValidationState({
        isValid: false,
        validationMessages: [response.createSubdomain.message],
      });
    } else {
      setShowSubdomainSubmittedModal(true);
    }
    refetch();
  };

  return (
    <>
      <Card>
        <Card.Body className="flex flex-col gap-2">
          <h2>Client Portal Subdomain</h2>

          {!accountHasClientPortalFeature && (
            <p>
              <Link to="/upgradeAccount">Upgrade</Link> to get access to the
              client portal
            </p>
          )}

          {accountHasClientPortalFeature && (
            <>
              {/* subdomain exists */}
              {accountSubdomain &&
                (!accountSubdomain.isActive ? (
                  // inactive
                  (<p>Your subdomain is not active yet.
                                        {accountSubdomain?.status && (
                      <>
                        {' '}
                        Status:{' '}
                        <b>
                          {/* currently only surfacing pending */}
                          {subdomainStatusMap[accountSubdomain.status] ||
                            'Pending'}
                        </b>
                      </>
                    )}
                  </p>)
                ) : (
                  // active
                  (<p>Your Client Portal URL:{' '}
                    <a target="_blank" rel="noreferrer" href={clientPortalUrl}>
                      {clientPortalUrl}
                    </a>
                  </p>)
                ))}

              {/* subdomain does not exist */}
              {!readOnly && !accountSubdomain && (
                <div>
                  <Field validationState={validationState}>
                    <Field.Label>Subdomain</Field.Label>
                    <div className="flex flex-row items-center gap-2">
                      <Input
                        value={subdomain || ''}
                        onChange={(value) => {
                          // clear submitted validation state
                          setSubmittedValidationState(null);
                          setSubdomain(value);
                        }}
                      />
                      <Button
                        onClick={createSubdomain}
                        variant={EBtnVariant.Outline}
                        disabled={validationState?.isValid === false}
                        isProcessing={isLoading}
                      >
                        Create subdomain
                      </Button>
                    </div>
                  </Field>
                </div>
              )}
            </>
          )}
        </Card.Body>
        <Modal
          title="Subdomain submitted"
          isOpen={showSubdomainSubmittedModal}
          onClose={() => setShowSubdomainSubmittedModal(false)}
        >
          <Modal.Body>
            <p>
              Your subdomain has been submitted. It may take around an hour to
              process and get verified. We will email you once it is ready.
            </p>
            <Modal.Footer.Actions>
              <Button onClick={() => setShowSubdomainSubmittedModal(false)}>
                OK
              </Button>
            </Modal.Footer.Actions>
          </Modal.Body>
        </Modal>
      </Card>
    </>
  );
};

export default CompanySettingsBrandingSubdomainSection;
