import { AutomationDataAccessType } from './automationDataAccessTypes';
import { AutomationEntityTypes } from './automationTypes';

const accountAutomationVariableTypes: AutomationDataAccessType[] = [
  'account.companyName',
  'account.vatNumber',
  'account.accountName',
  'account.accountNumber',
  'account.address',
  'account.businessNotes',
  'account.city',
  'account.companyName',
  'account.contactNumber',
  'account.email',
  'account.paymentTerms',
  'account.postcode',
  'account.sortCode',
];

const customerAutomationVariableTypes: AutomationDataAccessType[] = [
  'customer.name',
  'primaryContact.emailAddress',
  'primaryContact.name',
  'primaryContact.telephoneNumber',
];

const dealAutomationVariableTypes: AutomationDataAccessType[] = [
  'deal.createdAt',
  'deal.description',
  'deal.reference',
  'deal.siteaddresses.arraystring',
  'project.customField.value',
];

const scheduledEventAutomationVariableTypes: AutomationDataAccessType[] = [
  'event.name',
  'event.beginAt',
  'event.createdBy.fullName',
  'event.beginAt.formattedDate',
  'event.beginAt.formattedTime',
  'event.description',
  'event.endAt',
  'event.endAt.formattedDate',
  'event.endAt.formattedTime',
  'event.location.address.string',
  'event.assignedusers.name.arraystring',
  'event.taskStatus',
];

const invoiceAutomationVariableTypes: AutomationDataAccessType[] = [
  'invoice.amountDue',
  'invoice.createdAt',
  'invoice.createdAt.formattedDate',
  'invoice.dueAt',
  'invoice.dueAt.formattedDate',
  'invoice.readableStatus',
  'invoice.reference',
  'invoice.sentAt',
  'invoice.viewLink',
  'invoice.sentAt.formattedDate',
  'invoice.viewButton',
  'invoice.viewedAt',
  'invoice.viewedAt.formattedDate',
];

const proposalAutomationVariableTypes: AutomationDataAccessType[] = [
  'proposal.acceptedAt',
  'proposal.acceptedAt.formattedDate',
  'proposal.acceptedAt.formattedTime',
  'proposal.createdAt',
  'proposal.createdAt.formattedDate',
  'proposal.readableStatus',
  'proposal.reference',
  'proposal.sentAt',
  'proposal.sentAt.formattedDate',
  'proposal.validUntil',
  'proposal.viewLink',
  'proposal.validUntil.formattedDate',
  'proposal.viewButton',
  'proposal.viewedAt',
  'proposal.viewedAt.formattedDate',
];

const userAutomationVariableTypes: AutomationDataAccessType[] = [
  'user.contactNumber',
  'user.email',
  'user.firstname',
  'user.gasSafeIdCardNumber',
  'user.lastname',
  'user.systemRole',
];

export const automationEntityTypeActionVariablesMap: {
  [key in AutomationEntityTypes]?: AutomationDataAccessType[];
} = {
  Deal: [
    ...accountAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...customerAutomationVariableTypes,
  ],
  Estimate: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  Quote: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  Proposal: [
    ...proposalAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  Invoice: [
    ...invoiceAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...userAutomationVariableTypes,
  ],
  'Invoice-v2': [
    ...invoiceAutomationVariableTypes,
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
  ],
  Event: [
    ...accountAutomationVariableTypes,
    ...customerAutomationVariableTypes,
    ...dealAutomationVariableTypes,
    ...scheduledEventAutomationVariableTypes,
  ],
};
