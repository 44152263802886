import { actions as usersActions } from '@/api/users';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import Modal from '@payaca/components/plModal/Modal';
import UntitledIcon from '@payaca/untitled-icons';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  disableConnection?: boolean;
  disableConnectionMessage?: JSX.Element;
  allowConnectDisconnect: boolean;
  logo?: string;
  connectionName: string;
  disconnectionError?: string;
  hasConnectionExpired?: boolean;
  isConnected: boolean | undefined;
  onAddConnection: () => void;
  onRemoveConnection: (callback?: any) => void;
  disconnectionWarningMessage?: React.ReactNode;
};
const Connection: FC<PropsWithChildren<Props>> = ({
  disableConnection = false,
  disableConnectionMessage,
  allowConnectDisconnect,
  logo,
  children,
  connectionName,
  disconnectionError,
  hasConnectionExpired = false,
  isConnected,
  onAddConnection,
  onRemoveConnection,
  disconnectionWarningMessage,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();

  const [removingConnection, setRemovingConnection] = useState(false);
  const [hasDisconnectionError, setHasDisconnectionError] = useState(false);
  const [showRemoveConnectionModal, setShowRemoveConnectionModal] =
    useState<boolean>(false);

  const connectButtonComponent = useMemo(() => {
    return (
      <div>
        {hasConnectionExpired && (
          <UntitledIcon
            name="alert-triangle.3"
            className="mr-2 h-6 w-6 text-red-600"
          />
        )}

        <Button
          onClick={onAddConnection}
          variant={EBtnVariant.White}
          disabled={disableConnection}
        >
          {logo && <img src={logo} className="mr-2 size-[2rem]" />}
          Connect to {connectionName}
        </Button>
      </div>
    );
  }, [hasConnectionExpired]);

  const disconnectionErrorMessage = useMemo(() => {
    return disconnectionError
      ? disconnectionError
      : `Sorry, there was an error disconnecting from ${connectionName}. Please try again.`;
  }, [disconnectionError]);

  const removeConnection = useCallback(() => {
    setRemovingConnection(true);
    setHasDisconnectionError(false);
    onRemoveConnection((err: any, resp: any) => {
      if (err) {
        setHasDisconnectionError(true);
      }
      dispatch(usersActions.getProfile());
      setRemovingConnection(false);
      if (!err) {
        setShowRemoveConnectionModal(false);
      }
    });
  }, [dispatch, onRemoveConnection]);

  const disconnectButtonComponent = useMemo(() => {
    return (
      <div>
        <Button
          onClick={() => setShowRemoveConnectionModal(true)}
          isProcessing={removingConnection}
          variant={EBtnVariant.White}
          className="disconnect"
        >
          <UntitledIcon name="check" className="mr-2 h-5 w-5 text-blue-600" />
          Disconnect from {connectionName}
        </Button>
      </div>
    );
  }, [
    connectionName,
    disconnectionErrorMessage,
    dispatch,
    hasDisconnectionError,
    removeConnection,
    removingConnection,
  ]);

  return (
    <Card>
      <Card.Body className="flex h-full flex-col">
        {/* title and icon */}
        <div className="flex pb-5">
          {logo && <img src={logo} className="mr-5 size-[44px]" />}

          <h2>{connectionName}</h2>
        </div>

        {/* content */}
        <div className="flex-grow">{children}</div>
        {disableConnection && disableConnectionMessage}
        {/* connect button */}
        {allowConnectDisconnect && (
          <div className="pt-4">
            {/* Connect/disconenct button */}
            {!isConnected ? connectButtonComponent : disconnectButtonComponent}
          </div>
        )}
        <Modal
          isOpen={showRemoveConnectionModal}
          onClose={() => setShowRemoveConnectionModal(false)}
          title={`Disconnect from ${connectionName}`}
          className="disconnect-warning-modal"
        >
          <Modal.Body>
            {disconnectionWarningMessage && (
              <p>{disconnectionWarningMessage}</p>
            )}
            <p>Are you sure you want to disconnect from {connectionName}?</p>
            {hasDisconnectionError && (
              <ConnectionContolAlert>
                {disconnectionErrorMessage}
              </ConnectionContolAlert>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                variant={EBtnVariant.Outline}
                onClick={() => setShowRemoveConnectionModal(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={removeConnection}
                isProcessing={removingConnection}
              >
                Yes, disconnect
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </Modal>
      </Card.Body>
    </Card>
  );
};

export const ConnectionContolAlert: FC<PropsWithChildren> = ({
  children,
}): JSX.Element => {
  return (
    <div className="flex flex-row px-3 pt-5 text-red-600">
      <UntitledIcon name="alert-triangle.3" className="mr-4 mt-2 h-5 w-5" />
      <div>{children}</div>
    </div>
  );
};

export default Connection;
