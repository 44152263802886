import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { DeleteAccountTermMutation, DeleteTermInput } from '@/gql/graphql';

const DELETE_ACCOUNT_TERM_MUTATION = graphql(`
  mutation DeleteAccountTerm($input: DeleteTermInput!) {
    deleteTerm(input: $input)
  }
`);

const useDeleteAccountTerm = (
  options?: UseMutationOptions<
    DeleteAccountTermMutation,
    unknown,
    DeleteTermInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_ACCOUNT_TERM_MUTATION, { input });
    },
    ...options,
  });
};

export default useDeleteAccountTerm;
