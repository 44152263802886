import { useSelector } from '@/api/state';
import { getUserRoles } from '@/utils/stateAccessors';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import TextareaFieldFormatter from '@payaca/components/textareaField/TextareaFieldFormatter';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { UploadsPermissions } from '@payaca/permissions/uploads/uploads.permissions';
import { useDeal, useProposal } from '@payaca/store/hooks/appState';
import * as jobsActions from '@payaca/store/jobs/jobsActions';
import { Deal } from '@payaca/types/dealTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { PartialUpdateJobRequestData } from '@payaca/types/jobRequestTypes';
import { Job } from '@payaca/types/jobTypesV2';
import { UPLOAD_MAX_FILESIZE_LIMIT } from '@payaca/types/uploadTypes';
import { FC, useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import EditJobSection from '../editJob/EditJobSection';
import JobCustomerControl from '../editJob/JobCustomerControl';
import InvoiceLineItemsControl from '../invoiceLineItemsControl/InvoiceLineItemsControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ReferenceControl from '../referenceControl/ReferenceControl';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import './EditProtoInvoiceControl.sass';

const acceptFileTypes = getAcceptedFileTypes([
  'document',
  'image',
  'video',
  'other',
]);

type Props = {
  protoInvoiceId: number;
};

const EditProtoInvoiceControl: FC<Props> = ({
  protoInvoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const [isSaveRequired, setIsSaveRequired] = useState(false);

  const protoInvoice: Job | undefined = useProposal(protoInvoiceId);

  const deal: Deal | undefined = useDeal(protoInvoice?.dealId);

  const handleUpdateProtoInvoice = useCallback(
    (requestData: PartialUpdateJobRequestData) => {
      dispatch(
        jobsActions.partialUpdateJob.request({
          jobId: protoInvoiceId,
          data: requestData,
        })
      );
    },
    [protoInvoiceId]
  );

  const initialFormState = useMemo(() => {
    if (!protoInvoice) return {};
    return {
      customReference: protoInvoice.customReference,
      invoiceConfig: protoInvoice.invoiceConfig,
      contactId: protoInvoice.contactId,
    };
  }, [protoInvoice?.id]);

  const userRoles = useSelector(getUserRoles);

  const userCanDeleteAttachment = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      UploadsPermissions.DELETE_UPLOAD,
    ]);
  }, [userRoles]);

  const renderFormContents = (
    isValid: boolean,
    formState: {
      [key: string]: any;
    },
    validationState: {
      [key: string]: FieldValidationResult;
    },
    touchedState: {
      [key: string]: boolean;
    },
    onFieldChange: (value: { [key: string]: any }) => void,
    onFieldTouch: (fieldName: string) => void
  ) => {
    if (isSaveRequired) {
      handleUpdateProtoInvoice(formState as PartialUpdateJobRequestData);
      setIsSaveRequired(false);
    }

    return (
      <div className="edit-invoice-control-form-contents">
        <ReferenceControl
          customReference={formState.customReference}
          referencedEntityName={'Invoice'}
          onChange={(value) => {
            onFieldChange(value);
            setIsSaveRequired(true);
          }}
          editPermissions={[InvoicesPermissions.EDIT_INVOICE_REFERENCE]}
        />
        <JobCustomerControl
          canSelectCustomer={false}
          customerId={deal?.customerId}
          jobContactId={formState.contactId || null}
          onSelectContact={(contactId: number) => {
            onFieldChange({ contactId: contactId });
            setIsSaveRequired(true);
          }}
        />

        {protoInvoice?.jobContentId && (
          <InvoiceLineItemsControl
            jobContentId={protoInvoice.jobContentId}
            jobIsInvoice={true}
          />
        )}
        <EditJobSection title={'Notes'}>
          <TextareaFieldFormatter
            name="invoiceConfig.notes"
            value={formState.invoiceConfig?.notes}
            onChange={onFieldChange}
            placeholder={`Give yourself an edge over your competitors by adding additional information to your invoice.`}
            changeTimeoutMs={2000}
            onChangeTimeout={() => setIsSaveRequired(true)}
            onBlur={() => setIsSaveRequired(true)}
          />
        </EditJobSection>

        <PermissionGuard
          renderIfHasPermissions={[UploadsPermissions.GET_UPLOADS]}
        >
          <EditJobSection
            title="Attachments"
            description="Attach images of completed work or documents relating to the works completed."
          >
            <UploadCollectionControl
              uploadIds={formState.invoiceConfig?.attachmentUploadIds || []}
              onUploadCollectionChange={(uploadIds) => {
                onFieldChange({
                  'invoiceConfig.attachmentUploadIds': uploadIds,
                });
                setIsSaveRequired(true);
              }}
              allowMultipleUploads={true}
              canRemove={userCanDeleteAttachment}
              acceptFileTypes={acceptFileTypes}
              fileSizeLimitBytes={UPLOAD_MAX_FILESIZE_LIMIT}
            />
          </EditJobSection>
        </PermissionGuard>
      </div>
    );
  };

  if (!protoInvoice) return null;

  return (
    <ResponsiveViewWrapper
      className="edit-invoice-control"
      downBreakpointSm={700}
      downBreakpointXs={450}
    >
      <ValidatedForm<{ [key: string]: any }>
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </ResponsiveViewWrapper>
  );
};
export default EditProtoInvoiceControl;
