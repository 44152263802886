import { FC } from 'react';

import EmailPreview from './EmailPreview';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Checkbox from '@payaca/components/plCheckbox/Checkbox';
import TruncatedShadowDomRenderer from '@payaca/components/shadowDomRenderer/TruncatedShadowDomRenderer';
import './SendableDocumentEmailControl.sass';

type Props = {
  emailSubject: string;
  emailState: {
    // will take priority over the template
    htmlTemplate?: string;
    sendMeACopy: boolean;
    emailCopy: {
      preButton: string;
      postButton: string;
    };
  };
  onChange: (value?: { [key: string]: any }) => void;
  sendToAddress?: string;
  buttonText: string;
};

const SendableDocumentEmailControl: FC<Props> = (props) => {
  const { emailSubject, emailState, onChange, sendToAddress, buttonText } =
    props;

  return (
    <div className="sendable-document-email-control">
      <div className="addressee-and-subject-container">
        <div className="addressee-container">
          {sendToAddress && (
            <>
              <div>
                <strong>To:</strong>
              </div>
              <span>{sendToAddress}</span>
            </>
          )}
        </div>
        <div className="subject-container">
          <div>
            <strong>Subject:</strong>
          </div>
          <div>{emailSubject}</div>
        </div>
      </div>
      <div className="email-options-container">
        <Checkbox
          label="Send me a copy"
          onChange={(e) => onChange({ sendMeACopy: e.target.checked })}
          checked={emailState.sendMeACopy}
        />
      </div>

      {emailState.htmlTemplate ? (
        <Card className="overflow-hidden" sizeVariant={CardSizeVariant.SM}>
          <Card.Body>
            <TruncatedShadowDomRenderer html={emailState.htmlTemplate} />
          </Card.Body>
        </Card>
      ) : (
        <EmailPreview
          buttonText={buttonText}
          emailCopy={emailState.emailCopy}
          onChange={(value) =>
            onChange({ emailCopy: { ...emailState.emailCopy, ...value } })
          }
        />
      )}
    </div>
  );
};

export default SendableDocumentEmailControl;
