import { MaterialsListMaterial } from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import { TFormState } from '@/ui/components/createPurchaseOrderSidebar/CreatePurchaseOrderSidebar';
import { MaterialCardWithTransition } from '@/ui/components/materialCard/MaterialCard';
import { parseFulfillments } from '@/ui/pages/dealPage/components/MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/fulfillment';
import LiveBranchStockMaterialSupplierBadge from '@/ui/pages/dealPage/components/MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/LiveBranchStockMaterialSupplierBadge';
import MaterialSuppliersBadges from '@/ui/pages/dealPage/components/MaterialSuppliersBadges';
import Conditional from '@payaca/components/conditional/Conditional';
import List from '@payaca/components/list/List';
import EclipseBadge from '@payaca/components/plBadge/EclipseBadge';
import EmptyState from '@payaca/components/plEmptyState/EmptyState';
import { clstx } from '@payaca/components/utils';
import { FC, useEffect, useReducer, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useScroll } from 'react-use';

type MaterialsLeftToOrderState = {
  hasNoMoreMaterialsLeftToOrder: boolean;
  transitionComplete: boolean;
};

type MaterialsLeftToOrderAction =
  | { type: 'HAS_NO_MORE_MATERIALS_LEFT_TO_ORDER' }
  | { type: 'TRANSITION_COMPLETE' }
  | { type: 'RESET' };

const materialsLeftToOrderReducer = (
  state: MaterialsLeftToOrderState,
  action: MaterialsLeftToOrderAction
): MaterialsLeftToOrderState => {
  switch (action.type) {
    case 'HAS_NO_MORE_MATERIALS_LEFT_TO_ORDER':
      if (state.hasNoMoreMaterialsLeftToOrder === false) {
        return {
          hasNoMoreMaterialsLeftToOrder: true,
          transitionComplete: false,
        };
      }

      return state;
    case 'TRANSITION_COMPLETE':
      if (state.hasNoMoreMaterialsLeftToOrder) {
        return { ...state, transitionComplete: true };
      }

      return state;
    case 'RESET':
      return defaultMaterialsLeftToOrderState;
    default:
      return state;
  }
};

const defaultMaterialsLeftToOrderState: MaterialsLeftToOrderState = {
  hasNoMoreMaterialsLeftToOrder: false,
  transitionComplete: false,
};

export interface IProps {
  className?: string;
  materialListMaterials: MaterialsListMaterial[];
  hideList?: boolean;
}

const MaterialsLeftToOrderList: FC<IProps> = (props) => {
  const { className, materialListMaterials, hideList = false } = props;

  /**
   * State
   */
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const { y: scrollY } = useScroll({ current: scrollRef });
  const [materialsLeftToOrderState, materialsLeftToOrderDispatch] = useReducer(
    materialsLeftToOrderReducer,
    defaultMaterialsLeftToOrderState
  );

  /**
   * use form methods
   */
  const formMethods = useFormContext<TFormState>();
  const selectedSupplierId = formMethods.watch('supplierId');
  const selectedMaterials = formMethods.watch('materials');

  useEffect(() => {
    const numOfMaterialsLeftToOrder = materialListMaterials.reduce(
      (acc, materialListMaterial) => {
        const foundSelectedMaterial = selectedMaterials.find(
          (selectedMaterial) => selectedMaterial.id === materialListMaterial.id
        );

        const selectedQuantity = foundSelectedMaterial?.selectedQuantity || 0;

        return acc + (materialListMaterial.materialQuantity - selectedQuantity);
      },
      0
    );

    if (numOfMaterialsLeftToOrder === 0) {
      materialsLeftToOrderDispatch({
        type: 'HAS_NO_MORE_MATERIALS_LEFT_TO_ORDER',
      });
    } else {
      materialsLeftToOrderDispatch({ type: 'RESET' });
    }
  });

  return (
    <div
      className={clstx(
        'overflow-auto',
        className,
        materialsLeftToOrderState.hasNoMoreMaterialsLeftToOrder &&
          materialsLeftToOrderState.transitionComplete &&
          'flex flex-col'
      )}
      ref={setScrollRef}
    >
      <div
        className={clstx(
          'p-3.5 pb-2.5 position sticky top-0 bg-white z-10',
          scrollY > 0 && 'shadow'
        )}
      >
        <h4>Materials left to order</h4>
      </div>

      <List
        className={clstx('p-3.5 pt-0', hideList && 'hidden')}
        items={materialListMaterials}
        item={(material) => {
          const foundSelectedMaterial = selectedMaterials.find(
            (selectedMaterial) => selectedMaterial.id === material.id
          );

          const selectedQuantity = foundSelectedMaterial?.selectedQuantity || 0;

          const actionDisabled =
            !material.materialsListMaterial.material.suppliedBy.find(
              (suppliedBy) =>
                suppliedBy.supplier.id === selectedSupplierId?.toString()
            );

          return (
            <MaterialCardWithTransition
              key={material.id}
              show={material.materialQuantity - selectedQuantity > 0}
              afterLeave={() => {
                materialsLeftToOrderDispatch({
                  type: 'TRANSITION_COMPLETE',
                });
              }}
              name={material.materialsListMaterial.material.name}
              price={
                material.materialsListMaterial.material.suppliedBy[0]?.price
                  .unitPriceExcTax
              }
              thumbnailUrl={
                material.materialsListMaterial.material.image?.thumbnailUrl ||
                undefined
              }
              actionOnClick={() => {
                if (actionDisabled) {
                  return;
                }

                const merged = new Map(
                  selectedMaterials.map((i) => [i['id'], i])
                );

                merged.set(material.id, {
                  ...merged.get(material.id),
                  ...material,
                  purchaseOrderQuantity: material.materialQuantity,
                  selectedQuantity: material.materialQuantity,
                });

                formMethods.setValue('materials', Array.from(merged.values()));
              }}
              actionDisabled={actionDisabled}
              childrenClassName="w-full flex justify-between"
            >
              {actionDisabled ? (
                <EclipseBadge
                  badges={material.materialsListMaterial.material.suppliedBy.map(
                    (i) => i.supplier.name
                  )}
                  variant="soft"
                  colour="gray"
                />
              ) : (
                <MaterialSuppliersBadges
                  suppliers={material.materialsListMaterial.material.suppliedBy.map(
                    (s) => ({
                      id: s.supplier.id,
                      name: s.supplier.name,
                    })
                  )}
                  LiveAvailabilityMaterialSupplierBadge={
                    LiveBranchStockMaterialSupplierBadge
                  }
                  maxSuppliersToShow={1}
                  fulfillments={parseFulfillments(
                    material.materialsListMaterial.fulfillments
                  )}
                />
              )}

              <p>Qty: {material.materialQuantity - selectedQuantity}</p>
            </MaterialCardWithTransition>
          );
        }}
      />

      <Conditional
        condition={
          materialsLeftToOrderState.hasNoMoreMaterialsLeftToOrder &&
          materialsLeftToOrderState.transitionComplete
        }
      >
        <EmptyState
          className="my-auto"
          text="No more Materials left to order"
        />
      </Conditional>
    </div>
  );
};

export default MaterialsLeftToOrderList;
