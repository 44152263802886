import useCreateAccountTerm from '@/api/mutations/account/useCreateAccountTerm';
import useUpdateAccountTerm from '@/api/mutations/account/useUpdateAccountTerm';
import meKeys from '@/api/queries/me/keyFactory';
import useGetMyAccountTerms from '@/api/queries/me/useGetMyAccountTerms';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import RichTextarea, {
  RichTextContent,
} from '@payaca/components/plRichTextarea/RichTextarea';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

type TFormState = {
  content: RichTextContent;
};

const formSchema = z.object({
  content: z.any(),
});

const CreateEditWrittenTermContent: FC<{
  onSubmit: (state: TFormState) => void;
  defaultValues?: TFormState;
  isProcessing?: boolean;
}> = (props) => {
  const { onSubmit, isProcessing, defaultValues } = props;

  const formMethods = useForm<TFormState>({
    defaultValues,
  });

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
      <Modal.Body>
        <Field>
          <Controller
            render={({ field: { onChange, value } }) => {
              return <RichTextarea value={value} onChange={onChange} />;
            }}
            name="content"
            control={formMethods.control}
          />
        </Field>
      </Modal.Body>

      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button isProcessing={isProcessing} type="submit">
            {defaultValues ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </form>
  );
};

export interface IProps extends Omit<IModalProps, 'title'> {
  termId?: string;
}

const CreateEditWrittenTerm: FC<IProps> = (props) => {
  const { termId, onClose, ...rest } = props;

  const { pushToast } = useToastContext();

  /**
   * Queries
   */
  const queryClient = useQueryClient();
  const { terms, isLoading: isFetchingAccountTerms } = useGetMyAccountTerms({
    types: ['WRITTEN'],
  });

  /**
   * Mutations
   */
  const { mutateAsync: createAccountTerm, isLoading: isCreatingTerm } =
    useCreateAccountTerm();
  const { mutateAsync: updateAccountTerm, isLoading: isUpdatingTerm } =
    useUpdateAccountTerm();

  const isEditTerm = !!termId && termId !== 'new';

  const handleSubmit = async (state: TFormState) => {
    if (isEditTerm) {
      await updateAccountTerm({
        writtenTerm: {
          termId,
          content: state.content,
        },
      }).catch(() => {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Failed to update Term',
        });
      });
    } else {
      await createAccountTerm({
        writtenTerm: {
          content: state.content,
        },
      }).catch(() => {
        pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Failed to create Term',
        });
      });
    }

    await queryClient.invalidateQueries({
      queryKey: meKeys.accountTerms(),
    });

    onClose?.();
  };

  const foundTerm = terms?.find((term) => {
    return term.__typename === 'WrittenTerm' && term.id === termId;
  });

  return (
    <Modal
      title={isEditTerm ? 'Update required Term' : 'Create required Term'}
      onClose={onClose}
      {...rest}
    >
      {isFetchingAccountTerms ? (
        <Modal.Body>
          <SkeletonLoader.Textarea />
        </Modal.Body>
      ) : (
        <CreateEditWrittenTermContent
          defaultValues={
            isEditTerm && foundTerm && 'content' in foundTerm
              ? {
                  content: foundTerm.content,
                }
              : undefined
          }
          isProcessing={isCreatingTerm || isUpdatingTerm}
          onSubmit={handleSubmit}
        />
      )}
    </Modal>
  );
};

export default CreateEditWrittenTerm;
