import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { GetChildAccountQuery } from '../../../gql/graphql';
import childAccountsKeys from './keyFactory';

const GetChildAccount = graphql(`
  query GetChildAccount($childAccountId: ID!) {
    childAccount(childAccountId: $childAccountId) {
      id
      name
      phone
      email
      branding {
        logoUrl
        theme {
          primaryColour
        }
      }
      users {
        fullName
        email
        phone
        systemRole
        avatarUrl
        status
      }
    }
  }
`);

const useGetChildAccount = (
  childAccountId?: string,
  queryOptions?: UseQueryOptions<GetChildAccountQuery>
) => {
  const { data, ...rest } = useQuery({
    enabled: !!childAccountId,
    queryKey: childAccountsKeys.childAccount(childAccountId!),
    queryFn: () => {
      return gqlClient.request(GetChildAccount, {
        childAccountId: childAccountId!,
      });
    },
    ...queryOptions,
  });
  return {
    data: data?.childAccount,
    ...rest,
  };
};

export default useGetChildAccount;
