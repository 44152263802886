import usePreviewEmailTemplate from '@/api/mutations/emailTemplates/usePreviewEmailTemplate';
import { EmailTemplateType } from '@/gql/graphql';
import { EmailTemplatePreview } from '@/ui/components/companySettings/components/EmailTemplatePreview';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Field, { FieldValidationState } from '@payaca/components/plField/Field';
import FileDropZone from '@payaca/components/plFileDropZone/FileDropZone';
import { FC, useEffect, useState } from 'react';

export interface IProps {
  validationState?: FieldValidationState;
  type: EmailTemplateType;
  label?: string;
  value?: {
    raw: string;
    preview: string;
  };
  onChange?: (value?: { raw: string; preview: string }) => void;
}

const EmailTemplateUploadField: FC<IProps> = (props) => {
  const { validationState, type, value, onChange, label = 'Template' } = props;

  const [isReadingFileLocally, setIsReadingFileLocally] = useState(false);
  const [hasError, setHasError] = useState<string | null>(null);

  const { mutateAsync: generateEmailTemplatePreview, isLoading } =
    usePreviewEmailTemplate();

  useEffect(() => {
    setHasError(null);
  }, [type]);

  const onFileUpload = (file: File) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        if (!reader.result) {
          return;
        }

        const rawTemplate = reader.result as string;

        generateEmailTemplatePreview({
          type,
          template: rawTemplate,
        })
          .then((res) => {
            if (
              res.previewEmailTemplate.__typename ===
              'EmailTemplatePreviewSuccess'
            ) {
              onChange?.({
                preview: res.previewEmailTemplate.preview,
                raw: rawTemplate,
              });
            } else if (
              res.previewEmailTemplate.__typename ===
              'EmailTemplatePreviewError'
            ) {
              setHasError(res.previewEmailTemplate.message);
            }
          })
          .catch((e) => {
            if (e instanceof Error) {
              setHasError(e.message);
            } else {
              setHasError(
                'An unknown error occurred while previewing the email template'
              );
            }
          });
      },
      false
    );

    reader.addEventListener('loadstart', () => {
      setIsReadingFileLocally(true);
    });

    reader.addEventListener('loadend', () => {
      setIsReadingFileLocally(false);
    });

    if (file) {
      setHasError(null);
      reader.readAsText(file);
    }
  };

  return (
    <>
      <Field validationState={validationState}>
        <Field.Label>{label}</Field.Label>

        {value ? (
          <EmailTemplatePreview
            template={value.preview}
            onRemove={() => onChange?.(undefined)}
          />
        ) : (
          <FileDropZone
            isLoading={isReadingFileLocally || isLoading}
            dropzoneOptions={{
              maxFiles: 1,
              onDrop: (files) => {
                onFileUpload(files[0]);
              },
            }}
          />
        )}

        {hasError && (
          <Alert colour={EAlertColour.SOFT_RED} className="mt-4">
            {hasError}
          </Alert>
        )}
      </Field>
    </>
  );
};

export default EmailTemplateUploadField;
