import { FunctionComponent, useMemo } from 'react';

import { currencyPrice } from '@payaca/helpers/financeHelper';
import {
  getProductPriceCostExcludingVat,
  hasUnlimitedUsers,
  recurringIntervalLabelMap,
} from '@payaca/helpers/subscriptionHelper';

import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import { getRegionalTextString } from '@payaca/helpers/internationalHelper';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import QuantityControl from '../../quantityControl/QuantityControl';

import {
  ProductPrice,
  RecurringInterval,
  SubscriptionProduct,
} from '@payaca/types/subscriptionProductTypes';

import { useSelector } from '@/api/state';
import LabelValuePair from '../LabelValuePair';

type Props = {
  recurringInterval: RecurringInterval;
  selectedProduct: SubscriptionProduct;
  selectedPrice: ProductPrice;
  additionalUserSeats: number; // total addiitonal user seats (min reqd + optional)
  taxRatePercentage: number;
  onChangeAdditionalUserSeats?: (additionalUserSeats: number) => void;
};

const SelectedProductOverview: FunctionComponent<Props> = ({
  recurringInterval,
  selectedProduct,
  selectedPrice,
  additionalUserSeats,
  taxRatePercentage,
  onChangeAdditionalUserSeats,
}: Props): JSX.Element | null => {
  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const costExcludingVat = useMemo(() => {
    if (!selectedPrice) return null;

    return (
      getProductPriceCostExcludingVat(selectedPrice, additionalUserSeats) ||
      null
    );
  }, [additionalUserSeats, additionalUserSeats]);

  const vat = useMemo(() => {
    if (!costExcludingVat) return 0;
    return costExcludingVat * (taxRatePercentage / 100);
  }, [costExcludingVat, taxRatePercentage]);

  const costIncludingVat = useMemo(() => {
    if (!costExcludingVat) return null;
    return costExcludingVat + vat;
  }, [costExcludingVat, vat]);

  const userSeatsElement = useMemo(() => {
    const includedNumberOfUserSeats = selectedProduct?.numberOfUserSeats || 0;
    const additionalUserSeatCost = selectedPrice?.additionalUserSeatCost || 0;
    const requiredNumberOfUserSeats =
      selectedProduct?.minRequiredUserSeats || 0;
    const optionalAdditionalUserSeats =
      additionalUserSeats - requiredNumberOfUserSeats;
    const requiredUserSeatsCost =
      requiredNumberOfUserSeats * additionalUserSeatCost;

    const tooltipCopy = `${includedNumberOfUserSeats} User spaces are provided as part of your plan. Additional spaces cost ${currencyPrice(
      additionalUserSeatCost,
      account.region
    )} + ${getRegionalTextString(
      account.region,
      RegionalStrings.VALUE_ADDED_TAX
    )} per space per ${recurringInterval}`;

    if (!selectedPrice?.canBuyAdditionalUserSeats) {
      return (
        <LabelValuePair
          label="Users"
          value={`${
            hasUnlimitedUsers(includedNumberOfUserSeats)
              ? 'Unlimited'
              : includedNumberOfUserSeats
          }`}
        />
      );
    }
    if (selectedPrice?.canBuyAdditionalUserSeats) {
      return (
        <div>
          {selectedProduct.numberOfUserSeats ? (
            // Cost with included user seats
            (<LabelValuePair
              label={
                <span className="grow">
                  Cost with {includedNumberOfUserSeats} users included:
                  <Tooltip text={tooltipCopy} />
                </span>
              }
              value={currencyPrice(
                selectedPrice?.basicCost || 0,
                account.region
              )}
            />)
          ) : (
            // Cost with no included user seats (min required user seats is hidden in the base cost)
            (<LabelValuePair
              label={`Cost${
                requiredUserSeatsCost
                  ? ` (including up to ${requiredNumberOfUserSeats} Users)`
                  : ''
              }`}
              value={currencyPrice(
                selectedPrice.basicCost + requiredUserSeatsCost,
                account.region
              )}
            />)
          )}
          {/* additional users (does not include any min required user seats) */}
          <LabelValuePair
            label={
              <>
                <span>
                  {`Need more than ${requiredNumberOfUserSeats} Users?`}
                </span>
                <div className="flex flex-grow">
                  <QuantityControl
                    value={optionalAdditionalUserSeats}
                    onChange={(change) =>
                      onChangeAdditionalUserSeats?.(
                        requiredNumberOfUserSeats + change
                      )
                    }
                    minimumValue={0}
                  />
                </div>
              </>
            }
            value={currencyPrice(
              optionalAdditionalUserSeats * additionalUserSeatCost,
              account.region
            )}
            className="mt-3"
          />
        </div>
      );
    }
  }, [
    selectedProduct?.numberOfUserSeats,
    selectedPrice?.additionalUserSeatCost,
    selectedPrice?.canBuyAdditionalUserSeats,
    selectedPrice?.basicCost,
    selectedProduct?.minRequiredUserSeats,
    additionalUserSeats,
    account,
  ]);

  if (!selectedProduct || !selectedPrice) {
    return null;
  }

  const discountedCostIncludingVat =
    (costIncludingVat ?? 0) *
    (1 - (selectedProduct.discountPercentage ?? 0) / 100);
  const discountDurationInYears = Math.ceil(
    (selectedProduct.discountDurationInMonths ?? 0) / 12
  );

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="mb-4">{userSeatsElement}</div>
        <div className="mb-4">
          <LabelValuePair
            label="Subtotal"
            value={currencyPrice(costExcludingVat || 0, account.region)}
          />
          <LabelValuePair
            label={`VAT (${taxRatePercentage}%)`}
            value={currencyPrice(vat, account.region)}
          />
        </div>

        {costIncludingVat && (
          <div className="border-t-2 border-gray-200 pt-4">
            {selectedProduct.discountPercentage && (
              <LabelValuePair
                label={`Total cost${' '}
                    ${
                      selectedProduct.discountDurationInMonths
                        ? recurringInterval === RecurringInterval.MONTH
                          ? `for the first ${
                              selectedProduct.discountDurationInMonths > 1
                                ? `${selectedProduct.discountDurationInMonths} `
                                : ''
                            }month${
                              selectedProduct.discountDurationInMonths > 1
                                ? 's'
                                : ''
                            }`
                          : `for the first ${
                              discountDurationInYears > 1
                                ? `${discountDurationInYears} `
                                : ''
                            }year${discountDurationInYears > 1 ? 's' : ''}`
                        : `recurring ${recurringIntervalLabelMap[recurringInterval]}`
                    }
                    ${' '}
                    (including ${
                      selectedProduct.discountPercentage
                    }% discount)`}
                value={currencyPrice(
                  discountedCostIncludingVat,
                  account.region
                )}
                className="text-lg"
              />
            )}

            <LabelValuePair
              label={`${
                selectedProduct.discountPercentage &&
                typeof selectedProduct.discountDurationInMonths === 'number'
                  ? 'And thereafter'
                  : 'Total cost'
              }${' '}
                  recurring ${recurringIntervalLabelMap[recurringInterval]}`}
              value={currencyPrice(costIncludingVat, account.region)}
              className="text-lg"
            />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default SelectedProductOverview;
