import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import { graphql } from '@/gql';
import { GetEmailTemplateAvailableVariablesInput } from '@/gql/graphql';

const GET_EMAIL_TEMPLATE_AVAILABLE_VARIABLES = graphql(`
  query GetEmailTemplateAvailableVariables(
    $input: GetEmailTemplateAvailableVariablesInput!
  ) {
    emailTemplateAvailableVariables(input: $input) {
      ... on JSONSchemaVariablesResponse {
        __typename
        schema
      }
      ... on AutomationEmailTemplateVariablesResponse {
        __typename
        variables {
          key
          label
          example
        }
      }
    }
  }
`);

const useGetEmailTemplateAvailableVariables = (
  type?: GetEmailTemplateAvailableVariablesInput['type']
) => {
  return useQuery({
    enabled: !!type,
    queryKey: emailTemplateKeys.emailTemplateVariables(type!),
    queryFn: () => {
      return gqlClient.request(GET_EMAIL_TEMPLATE_AVAILABLE_VARIABLES, {
        input: {
          type: type!,
        },
      });
    },
  });
};

export default useGetEmailTemplateAvailableVariables;
