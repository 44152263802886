import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { SortDirection } from '@payaca/types/listViewTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { useState } from 'react';
import { useHistory } from 'react-router';
import useGetChildAccounts from '../../../api/queries/childAccounts/useGetChildAccounts';
import {
  GetChildAccountsInput,
  GetChildAccountsSortBy,
} from '../../../gql/graphql';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';

const initialPagination = {
  limit: 25,
  offset: 0,
};
const ListedChildAccountsPage = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [getChildAccountsInput, setGetChildAccountsInput] =
    useState<GetChildAccountsInput>({ sortBy: 'NAME', sortDirection: 'ASC' });

  const [getChildAccountsPagination, setGetChildAccountsPagination] = useState<{
    limit: number;
    offset: number;
  }>(initialPagination);

  const { childAccounts, isLoading } = useGetChildAccounts({
    input: getChildAccountsInput,
    pagination: getChildAccountsPagination,
  });

  const onClickRow = (accountId: string) => {
    history.push(`/accounts/${accountId}`);
  };
  const onSort = (field?: string, sortDirection?: SortDirection) => {
    setGetChildAccountsInput((s) => ({
      ...s,
      sortBy: field as GetChildAccountsSortBy,
      sortDirection: sortDirection
        ? sortDirection === SortDirection.ASCENDING
          ? 'ASC'
          : 'DESC'
        : undefined,
    }));
  };

  return (
    <AuthenticatedPageWrapper className="bg-gray-50">
      <div className="p-4 pb-6">
        <ManageableItemsList>
          <ManageableItemsList.HeaderBar heading="Accounts" />
          {/* TODO: sda - fix input change not refetching */}
          {/* <ManageableItemsList.ActionBar>
            <ManageableItemsList.ActionBar.SearchInput
              value={searchTerm}
              onChange={setSearchTerm}
              onChangeTimeout={(value) =>
                setGetChildAccountsInput((s) => ({
                  ...s,
                  searchTerm: value,
                }))
              }
              changeTimeoutMs={250}
            />
          </ManageableItemsList.ActionBar> */}
          <ManageableItemsList.Table
            items={childAccounts?.items || []}
            uniqueKey="id"
            isLoading={isLoading}
            onClickRow={(row) => onClickRow(row.id)}
            sortDirection={
              getChildAccountsInput.sortDirection
                ? getChildAccountsInput.sortDirection === 'ASC'
                  ? SortDirection.ASCENDING
                  : SortDirection.DESCENDING
                : undefined
            }
          >
            <ManageableItemsList.Table.Column
              field="name"
              header="Company name"
              onSort={onSort}
              sortByName="NAME"
            />
            <ManageableItemsList.Table.Column
              field="email"
              header="Contact"
              render={(_value, row) => (
                <ContactCell email={row.email} phone={row.phone} />
              )}
            />
          </ManageableItemsList.Table>
          {childAccounts && (
            <ManageableItemsList.PaginationBar
              pageSize={getChildAccountsPagination.limit}
              currentPage={
                getChildAccountsPagination.offset /
                  getChildAccountsPagination.limit +
                1
              }
              totalItems={childAccounts.totalCount || 0}
              onPageChange={(newPage) => {
                setGetChildAccountsPagination((s) => ({
                  ...s,
                  offset: (newPage - 1) * s.limit,
                }));
              }}
            />
          )}
        </ManageableItemsList>
      </div>
    </AuthenticatedPageWrapper>
  );
};

const ContactCell = ({ email, phone }: { email?: string; phone?: string }) => {
  return (
    <td className="flex flex-col">
      {email && (
        <span className="flex items-center">
          <UntitledIcon name="mail-02.3" className="h-4 w-4 mr-2" />
          {email}
        </span>
      )}
      {phone && (
        <span className="flex items-center">
          <UntitledIcon name="phone-outgoing-02.3" className="h-4 w-4 mr-2" />
          {phone}
        </span>
      )}
    </td>
  );
};

export default ListedChildAccountsPage;
