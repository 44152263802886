import { useSelector } from '@/api/state';
import { currencyPrice } from '@/helpers/financeHelper';
import {
  getJobContentsByDealId,
  getJobPaymentsByDealId,
  getJobsByDealId,
} from '@/utils/stateAccessors';
import QuickActionsTableCell from '@payaca/components/quickActionsTableCell/QuickActionsTableCell';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import {
  DateFormats,
  getInternationalMomentDateFormatByRegion,
} from '@payaca/helpers/internationalHelper';
import { getAvailableActionsForJob } from '@payaca/helpers/jobActionsHelper';
import { getOutstandingPaymentValue } from '@payaca/helpers/jobHelperV2';
import { isInvoice } from '@payaca/helpers/jobStatusHelper';
import { formatPhoneNumber } from '@payaca/helpers/phoneNumberHelper';
import { useDeal } from '@payaca/store/hooks/appState';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Customer } from '@payaca/types/customerTypes';
import { JobContent } from '@payaca/types/jobContentTypes';
import { JobPayment } from '@payaca/types/jobPaymentTypes';
import { Job } from '@payaca/types/jobTypesV2';
import moment from 'moment-timezone';
import { FC, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import JobStatusBadge from '../jobStatusBadge/JobStatusBadge';
import './DealListedJobsTable.sass';

interface Props {
  dealId: number;
  jobs: Job[];
  quickActionDefinitions: QuickActionDefinitions;
}
export type QuickActionDefinitions = {
  [key: string]: {
    actionName: string;
    actionBehaviour: (id: number) => void;
    isActionProcessing?: boolean;
  };
};

const renderCustomerContactTableCell = (job: Job, customer: Customer) => {
  const contact = getJobContactFromCustomer(customer, job.contactId);

  return (
    <td className="customer-contact-table-cell">
      <strong>{contact?.name}</strong>
      {contact?.emailAddress && <small>{contact.emailAddress}</small>}
      {contact?.telephoneNumber && (
        <small>{formatPhoneNumber(contact.telephoneNumber)}</small>
      )}
    </td>
  );
};

const renderAmountDueTableCell = (
  job: Job,
  jobContent: JobContent,
  jobPayments: JobPayment[],
  accountRegion: AccountRegions
) => {
  if (!job || !jobPayments || !jobContent) return <td></td>;

  const outstandingPaymentValue = getOutstandingPaymentValue(
    job,
    jobContent,
    jobPayments
  );

  return (
    <td className="amount-due-table-cell align-right">
      {outstandingPaymentValue === null
        ? '-'
        : currencyPrice(outstandingPaymentValue, accountRegion)}
    </td>
  );
};

export const DealListedJobsTable: FC<Props> = ({
  dealId,
  jobs,
  quickActionDefinitions,
}: Props) => {
  const history = useHistory();

  const customer = useSelector((state) => state.customer.currentCustomer);

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );

  const jobPayments = useSelector((state) => {
    return getJobPaymentsByDealId(state, dealId);
  });

  const dealJobs = useSelector((state) => {
    return getJobsByDealId(state, dealId);
  });

  const dealJobContents = useSelector((state) => {
    return getJobContentsByDealId(state, dealId);
  });

  const deal = useDeal(dealId);

  const getJobContentById = useCallback(
    (jobContentId: number) => {
      return dealJobContents?.find((x) => x.id === jobContentId);
    },
    [dealJobContents]
  );

  const getQuickActionsForJob = useCallback(
    (job: Job) => {
      const relatedJobs = dealJobs.filter((x) => x.id !== job.id);

      if (!job || !deal) return [];

      const availableActions = getAvailableActionsForJob(
        job,
        deal,
        customer!, // FIXME
        jobPayments,
        relatedJobs,
        []
      );

      return availableActions
        .map((availableAction) => quickActionDefinitions[availableAction])
        .filter((x) => !!x);
    },
    [
      customer,
      jobPayments,
      dealJobs,
      deal,
      quickActionDefinitions,
      getJobContentById,
    ]
  );

  const navigateToJob = useCallback(
    (job: Job) => {
      const jobIsInvoice = isInvoice(job.status);
      history.push(`/${jobIsInvoice ? 'invoices' : 'quotes'}/${job.id}`);
    },
    [history]
  );

  const shortDateRegionalFormat = useMemo(
    () =>
      getInternationalMomentDateFormatByRegion(
        DateFormats.SHORT,
        account.region
      ),
    [account]
  );

  const renderTableRow = useCallback(
    (job: Job, accountRegion: AccountRegions, index: number) => {
      const quickActions = getQuickActionsForJob(job);
      const jobContent = getJobContentById(job.jobContentId);

      if (!jobContent) return;

      return (
        <tr
          key={`table-row-${index}`}
          className="clickable"
          onClick={() => navigateToJob(job)}
        >
          <td className="reference-table-cell">
            <strong>{job.customReference || job.reference}</strong>
          </td>
          {renderCustomerContactTableCell(job, customer! /* FIXME */)}
          <td className="align-right value-table-cell">
            {currencyPrice(jobContent.total, account.region)}
          </td>
          {renderAmountDueTableCell(
            job,
            jobContent,
            jobPayments,
            accountRegion
          )}
          <td className="status-table-cell">
            <JobStatusBadge status={job.readableStatus} />
          </td>
          <td className="tr-end updated-at-table-cell">
            {moment(job.updatedAt).format(shortDateRegionalFormat)}
          </td>
          <QuickActionsTableCell
            recordId={job.id}
            quickActions={quickActions}
          />
        </tr>
      );
    },
    [
      customer,
      jobPayments,
      getQuickActionsForJob,
      jobPayments,
      navigateToJob,
      shortDateRegionalFormat,
    ]
  );

  return (
    <div className="deal-listed-jobs-table list-view-table-wrapper">
      <table className="list-view-table">
        <thead>
          <tr>
            <th>Reference</th>
            <th>Contact</th>
            <th className="align-right">Value</th>
            <th className="align-right">Due</th>
            <th className="align-center">Status</th>
            <th>Last updated</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((job: Job, index: number) =>
            renderTableRow(job, account.region, index)
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DealListedJobsTable;
