import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import * as jobPaymentsActions from '@payaca/store/jobPayments/jobPaymentsActions';
import { Invoice } from '@payaca/types/invoiceTypes';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import {
  getCustomerByDealId,
  getInvoice,
  getJobPaymentsByDealId,
  getPaymentReconciliationRecordsByDealId,
  getRegion,
  getUserRoles,
} from '@/utils/stateAccessors';
import './PreviewAndSendInvoiceControl.sass';

import { useGetInvoiceEmailTemplate } from '@/api/queries/invoice/useGetInvoice';
import { useSelector } from '@/api/state';
import { useUserHasPermission } from '@/hooks/usePermissions';
import MarkInvoiceAsSentModal from '@/ui/components/markInvoiceAsSentModal/MarkInvoiceAsSentModal';
import { downloadFile } from '@/utils/download-file';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { MOMENT_DATE_SHORT_READABLE_FORMAT } from '@payaca/constants/momentFormatConstants';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getAvailableActionsForInvoice } from '@payaca/helpers/invoiceActionsHelper';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import * as accountActions from '@payaca/store/account/accountActions';
import { useDeal } from '@payaca/store/hooks/appState';
import { getInvoiceGeneratedPdfUrl } from '@payaca/store/invoices/invoicesActions';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';
import { InvoiceActionType } from '@payaca/types/invoiceActionTypes';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import InvoiceDocument from '../invoiceDocument/InvoiceDocument';
import PreviewAndSendSendableDocument from '../previewAndSendSendableDocument/PreviewAndSendSendableDocument';

type Props = {
  invoiceId: number;
};

const PreviewAndSendInvoiceControl: FunctionComponent<Props> = ({
  invoiceId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isProcessing, setIsProcessing] = useState(false);
  const [showMarkAsSentModal, setShowMarkAsSentModal] = useState(false);
  const [isGettingPdfUrl, setIsGettingPdfUrl] = useState(false);

  const userRoles = useSelector(getUserRoles);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const emailTemplates = useSelector((state) => state.account.emailTemplates);

  const emailTemplate = useMemo(() => {
    return emailTemplates?.sendInvoice || '';
  }, [emailTemplates]);

  const invoice: Invoice | undefined = useSelector((state) => {
    return getInvoice(state, invoiceId);
  });

  const jobPayments = useSelector((state) => {
    return invoice ? getJobPaymentsByDealId(state, invoice.dealId) : [];
  });

  const paymentReconciliationRecords = useSelector((state) => {
    return invoice
      ? getPaymentReconciliationRecordsByDealId(state, invoice.dealId)
      : [];
  });

  const region = useSelector(getRegion);

  const customer = useSelector((state) => {
    if (!invoice) return;
    return getCustomerByDealId(state, invoice.dealId);
  });

  const deal = useDeal(invoice?.dealId);

  const customerContact = useMemo(() => {
    if (!customer) return;
    return getJobContactFromCustomer(customer, invoice?.contactId || null);
  }, [customer?.contacts, invoice?.contactId]);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  useEffect(() => {
    dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));

    return () => {
      dispatch(accountActions.clearEmailTemplates());
    };
  }, []);

  const onInvoiceUpdateSuccess = useCallback(() => {
    dispatch(invoicesActions.requestGetInvoice(invoiceId));
  }, [dispatch, invoiceId]);

  const onDealPaymentsUpdateSuccess = useCallback(() => {
    if (invoice) {
      dispatch(invoicesActions.requestGetInvoicesForDeal(invoice.dealId));
      dispatch(
        invoicesActions.requestGetPaymentReconciliationRecordsForDeal(
          invoice.dealId
        )
      );
      dispatch(dealsActions.requestGetDeal(invoice.dealId));
      dispatch(jobPaymentsActions.requestGetJobPaymentsForDeal(invoice.dealId));
      dispatch(uploadsActions.requestGetUploadsForEntity(invoiceId, 'invoice'));
    }
  }, [dispatch, invoice, invoiceId]);

  const navigateToDeal = useCallback(() => {
    history.push(`/deals/${invoice?.dealId}`);
  }, [history, invoice]);

  const sendInvoice = useCallback(
    (
      payload: {
        sendMeACopy: boolean;
        emailCopy: {
          preButton: string;
          postButton: string;
        };
      },
      callback: () => void,
      onErrorCallback: (errorMessages: string[]) => void
    ) => {
      const requestPayload = {
        invoiceId: invoiceId,
        sendMeACopy: payload.sendMeACopy,
        emailCopy: {
          preButton: payload.emailCopy.preButton,
          postButton: payload.emailCopy.postButton,
        },
      };
      dispatch(
        invoicesActions.requestSendInvoice(
          requestPayload,
          () => {
            callback();
            navigateToDeal();
          },
          (errorMessages: string[]) => {
            onErrorCallback(errorMessages);
          }
        )
      );
    },
    [invoiceId, dispatch, navigateToDeal]
  );

  const dueDate = useMemo(() => {
    if (!invoice) return;
    return invoice.dueAt
      ? moment(invoice.dueAt).format(MOMENT_DATE_SHORT_READABLE_FORMAT)
      : moment()
          .add(invoice.dueInDays, 'days')
          .format(MOMENT_DATE_SHORT_READABLE_FORMAT);
  }, [invoice]);

  const availableActions: InvoiceActionType[] = useMemo(() => {
    if (!invoice || !customer) return [];
    return getAvailableActionsForInvoice(
      invoice,
      customer,
      jobPayments || [],
      paymentReconciliationRecords || []
    );
  }, [invoice, customer, jobPayments, paymentReconciliationRecords]);

  const handleInvoiceDownload = useCallback(() => {
    if (!invoice) return [];
    setIsGettingPdfUrl(true);
    dispatch(
      getInvoiceGeneratedPdfUrl.request({
        invoiceId: invoiceId,
        callback: (pdfUrl: string) => {
          setIsGettingPdfUrl(false);
          downloadFile(
            pdfUrl,
            `invoice-${invoice.customReference || invoice.reference}.pdf`
          );
        },
        onErrorCallback: () => setIsGettingPdfUrl(false),
      })
    );
  }, [invoice, invoiceId, setIsGettingPdfUrl]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (
      availableActions.includes(InvoiceActionType.MARK_AS_SENT) &&
      userHasRequiredPermission(userRoles, [InvoicesPermissions.SEND_INVOICE])
    ) {
      actions.push({
        label: 'Mark as sent',
        onClick: () => setShowMarkAsSentModal(true),
      });
    }

    actions.push({
      label: 'Download',
      onClick: handleInvoiceDownload,
    });

    return actions;
  }, [availableActions, userRoles, invoice, handleInvoiceDownload]);

  const { emailTemplate: invoiceHTMLEmailTemplate } =
    useGetInvoiceEmailTemplate(invoiceId.toString());

  const canGetEmailTemplates = useUserHasPermission({
    permissions: [AccountsPermissions.GET_EMAIL_TEMPLATES],
  });

  if (!customer || !invoice) return null;

  const emailConfig = useMemo(() => {
    return {
      htmlTemplate: canGetEmailTemplates
        ? invoiceHTMLEmailTemplate?.preview
        : undefined,
      subject: `${
        deal && buildEmailSubjectPrefixForProjectFromProject(deal)
      }Your invoice from ${companyName}`,
      bodyTemplate: emailTemplate,
      substitutions: [
        {
          find: 'reference',
          replaceWith: invoice.customReference || String(invoice.reference),
        },
        { find: 'company_name', replaceWith: companyName },
        { find: 'customer_name', replaceWith: customerContact?.name },
        {
          find: 'amount_due',
          replaceWith: currencyPrice(invoice.dueValue, region),
        },
        {
          find: 'due_date',
          replaceWith: dueDate,
        },
      ],
    };
  }, [
    companyName,
    emailTemplate,
    invoice?.customReference,
    invoice.reference,
    invoice.dueValue,
    customerContact?.name,
    region,
    dueDate,
    canGetEmailTemplates,
    invoiceHTMLEmailTemplate,
  ]);

  return (
    <>
      <PreviewAndSendSendableDocument
        documentType="invoice"
        title={`Invoice #${invoice?.customReference || invoice?.reference}`}
        documentPreview={<InvoiceDocument invoiceId={invoiceId} />}
        recipientConfig={{
          customer: customer,
          contactId: invoice?.contactId,
        }}
        emailConfig={emailConfig}
        onSend={sendInvoice}
        actions={availableContextMenuItems}
      />

      <MarkInvoiceAsSentModal
        invoiceId={invoiceId}
        isOpen={showMarkAsSentModal}
        onClose={() => setShowMarkAsSentModal(false)}
        markAsSentCallback={navigateToDeal}
      />
    </>
  );
};

export default PreviewAndSendInvoiceControl;
