import { TIconName } from '@payaca/untitled-icons';
import React, {
  forwardRef,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { twMerge } from 'tailwind-merge';
import PlButton, { IButtonProps } from '../plButton/Button';
import { EBtnSize } from '../plButton/useButtonClassName';
import {
  default as BasicInput,
  InputIcon,
  TProps as InputProps,
  InputShapeVariant,
  InputSizeVariant,
  InputStyleVariant,
} from '../plInput/Input';
import RawInputBase, { TProps as TRawInputProps } from '../plInput/RawInput';
import BasicSelect, {
  Props as SelectProps,
  SelectSizeVariant,
  ValueType,
} from '../plSelect/Select';
import { clstx } from '../utils';

type Props = {
  sizeVariant?: InputSizeVariant;
  className?: string;
};

export const InputGroupContext = React.createContext<{
  sizeVariant: InputSizeVariant;
}>({
  sizeVariant: InputSizeVariant.MD,
});

const sharedClasses =
  'rounded-l-none rounded-r-none first:rounded-l-md last:rounded-r-md -ml-[1px] first:ml-0';

const InputGroup: FunctionComponent<PropsWithChildren<Props>> = ({
  sizeVariant = InputSizeVariant.MD,
  children,
  className,
}) => {
  return (
    <InputGroupContext.Provider value={{ sizeVariant }}>
      <div
        className={twMerge(
          `relative w-full rounded-lg shadow-sm flex`,
          className
        )}
      >
        {children}
      </div>
    </InputGroupContext.Provider>
  );
};

const Text: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { sizeVariant } = useContext(InputGroupContext);
  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case InputSizeVariant.SM:
        return 'py-2';
      case InputSizeVariant.MD:
        return 'py-3';
      case InputSizeVariant.LG:
        return 'py-3 sm:py-5';
    }
  }, [sizeVariant]);

  return (
    <span
      className={`${sizeClasses} inline-flex min-w-fit items-center px-4 ${sharedClasses} border border-gray-200 bg-gray-50 text-base`}
    >
      {children}
    </span>
  );
};

const Icon: FunctionComponent<{ name: TIconName }> = ({ name }) => {
  return (
    <Text>
      <InputIcon name={name} />
    </Text>
  );
};

export type InputGroupInput = Omit<
  InputProps,
  'styleVariant' | 'shapeVariant' | 'sizeVariant'
>;

const Input: FunctionComponent<InputGroupInput> = ({ className, ...props }) => {
  const { sizeVariant } = useContext(InputGroupContext);

  return (
    <BasicInput
      styleVariant={InputStyleVariant.BASIC}
      shapeVariant={InputShapeVariant.ROUNDED}
      sizeVariant={sizeVariant}
      className={`${sharedClasses} w-full grow focus-within:z-10 ${className}`}
      {...props}
    />
  );
};

export type InputGroupRawInput = Omit<
  TRawInputProps,
  'styleVariant' | 'shapeVariant' | 'sizeVariant'
>;

const RawInput = React.forwardRef<HTMLInputElement, InputGroupRawInput>(
  (props, ref) => {
    const { className, ...rest } = props;
    const { sizeVariant } = useContext(InputGroupContext);

    return (
      <RawInputBase
        ref={ref}
        styleVariant={InputStyleVariant.BASIC}
        shapeVariant={InputShapeVariant.ROUNDED}
        sizeVariant={sizeVariant}
        className={clstx(
          sharedClasses,
          'w-full grow focus-within:z-10',
          className
        )}
        {...rest}
      />
    );
  }
);

const Select: FunctionComponent<
  Omit<SelectProps<ValueType, boolean>, 'sizeVariant'>
> = ({ className, ...props }) => {
  const { sizeVariant } = useContext(InputGroupContext);

  const selectSizeVariant = useMemo(() => {
    switch (sizeVariant) {
      case InputSizeVariant.SM:
        return SelectSizeVariant.SM;
      case InputSizeVariant.MD:
        return SelectSizeVariant.MD;
      case InputSizeVariant.LG:
        return SelectSizeVariant.LG;
    }
  }, [sizeVariant]);

  return (
    <BasicSelect
      {...props}
      sizeVariant={selectSizeVariant}
      className={`${sharedClasses} grow shadow-none focus-within:z-10 ${className}`}
    />
  );
};

const Button = forwardRef<HTMLButtonElement, Omit<IButtonProps, 'size'>>(
  ({ children, className, ...rest }, ref) => {
    const { sizeVariant } = useContext(InputGroupContext);

    const buttonSizeVariant = useMemo(() => {
      switch (sizeVariant) {
        case InputSizeVariant.SM:
          return EBtnSize.Small;
        case InputSizeVariant.MD:
          return EBtnSize.Default;
        case InputSizeVariant.LG:
          return EBtnSize.Large;
      }
    }, [sizeVariant]);

    return (
      <PlButton
        ref={ref}
        size={buttonSizeVariant}
        className={
          sharedClasses +
          ' focus-within:z-10' +
          (className ? ' ' + className : '')
        }
        {...rest}
      >
        {children}
      </PlButton>
    );
  }
);

export default Object.assign(InputGroup, {
  Input,
  RawInput,
  Text,
  Icon,
  Select,
  Button,
});
