import { FC } from 'react';
import { clstx } from '../utils';

export interface IProps {
  className?: string;
  label?: string;
  labelPosition?: 'left' | 'center' | 'right';
}

const DividerWithLabel: FC<IProps> = (props) => {
  const { className, label, labelPosition = 'center' } = props;

  return (
    <>
      <div
        className={clstx(
          className,
          'flex items-center text-gray-800 dark:text-white',
          labelPosition === 'left' &&
            "after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:after:border-neutral-600 after:content-['']",
          labelPosition === 'center' &&
            "after:content-[''] before:content-[''] before:flex-1 before:border-t before:border-gray-200 before:me-6 after:flex-1 after:border-t after:border-gray-200 after:ms-6 dark:before:border-neutral-600 dark:after:border-neutral-600",
          labelPosition === 'right' &&
            "before:content-[''] before:flex-1 before:border-t before:border-gray-200 before:me-6 dark:before:border-neutral-600"
        )}
      >
        {label}
      </div>
    </>
  );
};

export default DividerWithLabel;
