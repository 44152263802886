import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import RouterTabs, {
  TabPanel,
} from '@payaca/components/plRouterTabs/RouterTabs';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useStartChildAccountUserSession from '../../../api/mutations/childAccounts/useStartChildAccountUserSession';
import { clearLocalStorage } from '../../../helpers/localStorageKeyHelper';
import { PermissionGuard } from '../../components/permissionGuard/PermissionGuard';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import ChildAccountCompany from './ChildAccountCompany';
import ChildAccountPageHeaders from './ChildAccountPageHeaders';
import ChildAccountUsers from './ChildAccountUsers';

const ChildAccountPage = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { mutateAsync: startChildAccountUserSessionMutation } =
    useStartChildAccountUserSession();
  const [isStartingSession, setIsStartingSession] = useState(false);

  const routerTabPanels = useMemo<TabPanel[]>(() => {
    return [
      {
        icon: 'building-01.3',
        label: 'Company',
        slug: 'company',
        render: () => <ChildAccountCompany />,
      },
      {
        icon: 'user-03',
        label: 'Users',
        slug: 'users',
        render: () => <ChildAccountUsers />,
      },
    ];
  }, []);

  const startSession = async () => {
    setIsStartingSession(true);
    await startChildAccountUserSessionMutation(accountId);
    clearLocalStorage();
    window.location.reload();
  };

  return (
    <AuthenticatedPageWrapper className="bg-gray-50">
      <RouterTabs panels={routerTabPanels}>
        <div className="flex flex-col p-6 pb-4">
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body>
              <ChildAccountPageHeaders />
              <div className="flex flex-row justify-between items-end">
                <RouterTabs.Nav
                  className="md:-mb-5 mt-7"
                  defaultTabSlug="company"
                />
                <PermissionGuard
                  renderIfHasPermissions={[
                    AccountsPermissions.JOIN_CHILD_ACCOUNT,
                  ]}
                >
                  <Button
                    variant={EBtnVariant.White}
                    size={EBtnSize.Small}
                    className="-mb-2"
                    onClick={startSession}
                    isProcessing={isStartingSession}
                  >
                    Start session
                  </Button>
                </PermissionGuard>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="p-6 pt-0">
          <RouterTabs.Panels />
        </div>
      </RouterTabs>
    </AuthenticatedPageWrapper>
  );
};

export default ChildAccountPage;
