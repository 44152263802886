import Badge from '@payaca/components/plBadge/Badge';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { ValidationMessages } from '@payaca/components/plField/Field';
import { InputSizeVariant } from '@payaca/components/plInput/RawInput';
import InputGroup from '@payaca/components/plInputGroup/InputGroup';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, useCallback, useState } from 'react';

export type Props = {
  options?: string[];
  onChange: (options: string[]) => void;
};

const CustomFieldGroupOptionsInput: FC<Props> = ({ options, onChange }) => {
  const [optionToAdd, setOptionToAdd] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleAddOption = useCallback(() => {
    if (!optionToAdd.length) return;
    if (options?.includes(optionToAdd)) {
      setErrorMessage('This option has already been added');
      return;
    }
    onChange([...(options || []), optionToAdd]);
    setOptionToAdd('');
  }, [options, optionToAdd]);

  return (
    <div>
      <InputGroup sizeVariant={InputSizeVariant.MD}>
        <InputGroup.Input
          placeholder="Type the option you want to add..."
          value={optionToAdd}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleAddOption();
              e.preventDefault();
            }
          }}
          onChange={(option) => {
            setOptionToAdd(option);
            setErrorMessage('');
          }}
        ></InputGroup.Input>
        <InputGroup.Button
          variant={EBtnVariant.Outline}
          disabled={!optionToAdd?.length}
          onClick={handleAddOption}
        >
          Add
        </InputGroup.Button>
      </InputGroup>
      {errorMessage && (
        <ValidationMessages
          validationState={{
            isValid: false,
            validationMessages: [errorMessage],
          }}
        />
      )}
      {!!options && (
        <div className="mt-2 flex flex-row flex-wrap gap-2">
          {options?.map((option, index) => {
            return (
              <Badge
                key={`${option}-${index}`}
                rounded={false}
                size="md"
                variant="soft"
                colour="blue"
              >
                {option}

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    onChange(options.filter((x) => x !== option));
                  }}
                  className={`ml-2 flex w-2 shrink-0 grow-0 cursor-pointer items-center border-none bg-transparent p-0 text-inherit outline-none transition-all`}
                >
                  <UntitledIcon name="x-close" className="h-2 w-2" />
                </button>
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomFieldGroupOptionsInput;
