import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetProjectPurchaseOrdersQuery,
  GetPurchaseOrderForProjectInput,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import projectKeys from './keyFactory';

const GET_PROJECT_PURCHASE_ORDERS = graphql(`
  query GetProjectPurchaseOrders(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: GetPurchaseOrderForProjectInput!
  ) {
    project(id: $projectId) {
      purchaseOrders(pagination: $pagination, input: $input) {
        limit
        offset
        totalCount
        items {
          __typename
          id
          reference
          status
          project {
            id
            reference
            customer {
              id
              name
            }
          }
          supplier {
            id
            name
          }
          totals {
            predictedValueExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
            purchasedValueExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
          }
          ... on PurchaseOrderCityPlumbing {
            cityPlumbingOrder {
              orderId
              status
            }
          }
        }
      }
    }
  }
`);

export type ProjectPurchaseOrder =
  GetProjectPurchaseOrdersQuery['project']['purchaseOrders']['items'][0];

const useGetProjectPurchaseOrders = (
  opts: {
    projectId: number;
    pagination?: PaginationInput;
    input?: GetPurchaseOrderForProjectInput;
  },
  queryOptions?: UseQueryOptions<GetProjectPurchaseOrdersQuery>
) => {
  const pagination = opts.pagination || PAGINATION_ARG_DEFAULTS;
  const { data, ...rest } = useQuery({
    queryKey: projectKeys.purchaseOrders(opts.projectId, pagination),
    queryFn: () => {
      return gqlClient.request(GET_PROJECT_PURCHASE_ORDERS, {
        projectId: opts.projectId,
        pagination,
        input: opts.input || {},
      });
    },
    ...queryOptions,
  });

  return {
    purchaseOrders: data?.project.purchaseOrders,
    data,
    ...rest,
  };
};

export default useGetProjectPurchaseOrders;
