import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import { graphql } from '@/gql';
import { GetEmailTemplateInput, GetEmailTemplateQuery } from '@/gql/graphql';

const GET_EMAIL_TEMPLATE = graphql(`
  query GetEmailTemplate($input: GetEmailTemplateInput!) {
    emailTemplate(input: $input) {
      id
      name
      type
      preview
      template
    }
  }
`);

const useGetEmailTemplate = (
  id?: GetEmailTemplateInput['id'],
  options?: UseQueryOptions<GetEmailTemplateQuery>
) => {
  return useQuery({
    enabled: !!id,
    queryKey: emailTemplateKeys.emailTemplate(id!),
    queryFn: () => {
      return gqlClient.request(GET_EMAIL_TEMPLATE, { input: { id: id! } });
    },
    ...options,
  });
};

export default useGetEmailTemplate;
