export enum InvoicesRoles {
  VIEWER = 'roles.invoicesViewer',
  LISTED_VIEWER = 'roles.invoicesListedViewer',
  ASSIGNEE_VIEWER = 'roles.invoicesAssigneeViewer',
  PERSISTER_SENDER = 'roles.invoicePersisterSender',
  MANAGER = 'roles.invoiceManager',

  VALUE_EDITOR = 'roles.invoiceValueEditor',

  DUE_IN_DAYS_EDITOR = 'roles.invoiceDueInDaysEditor',
  REFERENCE_EDITOR = 'roles.invoicesReferenceEditor',
}
