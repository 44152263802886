import useDeleteAccountTerm from '@/api/mutations/account/optimistic/useDeleteAccountTerm';
import meKeys from '@/api/queries/me/keyFactory';
import useGetMyAccountTerms from '@/api/queries/me/useGetMyAccountTerms';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import RichTextarea from '@payaca/components/plRichTextarea/RichTextarea';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { useQueryClient } from '@tanstack/react-query';
import { FC } from 'react';

export interface IProps extends Omit<IModalProps, 'title'> {
  termId?: string;
}

const DeleteTermModal: FC<IProps> = (props) => {
  const { onClose, termId, ...rest } = props;

  const { pushToast } = useToastContext();

  /**
   * Queries
   */
  const queryClient = useQueryClient();
  const { terms } = useGetMyAccountTerms({
    types: ['WRITTEN'],
  });

  /**
   * Mutations
   */
  const { mutateAsync: deleteAccountTerm, isLoading } = useDeleteAccountTerm();

  const handleDelete = async () => {
    if (!termId) {
      return;
    }

    await deleteAccountTerm({ termId }).catch(() => {
      pushToast({
        variant: 'white',
        icon: 'error',
        message: 'Failed to delete Term',
      });
    });

    await queryClient.invalidateQueries({
      queryKey: meKeys.accountTerms(),
    });

    onClose?.();
  };

  const foundTerm = terms?.find((term) => {
    return term.__typename === 'WrittenTerm' && term.id === termId;
  });

  return (
    <Modal title="Delete Term" onClose={onClose} {...rest}>
      {foundTerm ? (
        <>
          <Modal.Body>
            <p className="mb-4">
              Are you sure you want to delete this Term? This action cannot be
              undone.
            </p>

            <Card sizeVariant={CardSizeVariant.SM}>
              <Card.Body>
                <RichTextarea
                  value={'content' in foundTerm ? foundTerm.content : ''}
                  readOnly
                />
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Modal.Footer.Actions>
              <Button
                onClick={onClose}
                disabled={isLoading}
                variant={EBtnVariant.Outline}
              >
                Cancel
              </Button>
              <Button
                isProcessing={isLoading}
                disabled={isLoading}
                onClick={handleDelete}
                colour={EBtnColour.Red}
              >
                Delete
              </Button>
            </Modal.Footer.Actions>
          </Modal.Footer>
        </>
      ) : (
        <Modal.Body>
          <SkeletonLoader.Input />
        </Modal.Body>
      )}
    </Modal>
  );
};

export default DeleteTermModal;
