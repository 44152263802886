import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import Input from '@payaca/components/plInput/Input';
import Select from '@payaca/components/plSelect/Select';
import { Textarea } from '@payaca/components/plTextarea/Textarea';
import {
  CustomFieldHelperType,
  CustomFieldType,
  FieldsetCustomFieldDefinition,
  SelectCustomFieldDefinition,
  UrlCustomFieldDefinition,
} from '@payaca/custom-fields/types/index';
import { assertUnreachable, isNotNullish } from '@payaca/utilities/guards';
import { CustomField } from './CustomField';

export const CustomFieldInput = <T extends CustomFieldType>({
  definition,
  value,
  onChange,
  onChangeTimeout,
}: {
  definition: CustomFieldHelperType[T]['definition'];
  value?: CustomFieldHelperType[T]['value'] | null;
  onChange: (value?: CustomFieldHelperType[T]['value'] | null) => void;
  onChangeTimeout?: () => void;
}) => {
  switch (definition.type) {
    case 'textarea': {
      return (
        <Textarea
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    }
    case 'text':
      return (
        <Input
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    case 'email':
      return (
        <Input
          value={value as string}
          onChange={onChange as (value: string) => void}
          onChangeTimeout={onChangeTimeout}
        />
      );
    case 'number':
      return (
        <Input
          type="number"
          value={value as number}
          onChange={(v) => {
            if (!v.length) {
              onChange(null);
              return;
            }
            const parsed = parseFloat(v);
            if (!isNaN(parsed)) {
              onChange(parsed);
            }
          }}
          onChangeTimeout={onChangeTimeout}
        />
      );
    case 'select':
      if (definition instanceof SelectCustomFieldDefinition) {
        return (
          <Select
            value={value as string}
            options={
              definition.options
                ?.filter((x) => x)
                .map((x) => ({ value: x, label: x || '' })) || []
            }
            multiple={false}
            onChange={(value) => {
              onChange(value as any);
              onChangeTimeout?.();
            }}
          />
        );
      }
      return;
    case 'boolean':
      return (
        <Select
          value={value?.toString()}
          options={[
            {
              value: true.toString(),
              label: 'True',
            },
            {
              value: false.toString(),
              label: 'False',
            },
          ]}
          multiple={false}
          onChange={(value) => {
            let v = undefined;
            if (value === 'true') v = true;
            if (value === 'false') v = false;

            onChange(v);
            onChangeTimeout?.();
          }}
        />
      );
    case 'multi-upload':
    case 'duration':
      throw new Error('Not implemented');
    case 'fieldset':
      if (definition instanceof FieldsetCustomFieldDefinition) {
        const fieldsetValue =
          value as CustomFieldHelperType['fieldset']['value'];

        return (
          <Card sizeVariant={CardSizeVariant.SM}>
            <Card.Body className="space-y-4">
              {definition.children.map((child) => {
                const childValue = fieldsetValue?.[child.identifier];
                return (
                  <CustomField
                    key={child.identifier}
                    definition={child}
                    value={childValue}
                    onChange={(value) => {
                      onChange({
                        ...fieldsetValue,
                        [child.identifier]: isNotNullish(value)
                          ? (value as CustomFieldHelperType[Exclude<
                              CustomFieldType,
                              'fieldset'
                            >]['value'])
                          : null,
                      });
                    }}
                    onChangeTimeout={onChangeTimeout}
                  />
                );
              })}
            </Card.Body>
          </Card>
        );
      }
      return;
    case 'url':
      if (definition instanceof UrlCustomFieldDefinition) {
        const fieldsetValue = value as CustomFieldHelperType['url']['value'];

        const handleOnChange = (
          v: Partial<CustomFieldHelperType['url']['value']>
        ) => {
          if (v.title?.length || v.url?.length) {
            onChange(v);
          } else {
            onChange(null);
          }
        };

        return (
          <div className="space-y-2">
            <Input
              placeholder="Title"
              value={fieldsetValue?.title}
              onChange={(v) => {
                handleOnChange({
                  ...(fieldsetValue || {}),
                  title: v.length === 0 ? undefined : v,
                });
              }}
              onChangeTimeout={onChangeTimeout}
            />
            <Input
              placeholder="https://"
              value={fieldsetValue?.url}
              onChange={(v) => {
                handleOnChange({
                  ...(fieldsetValue || {}),
                  url: v.length === 0 ? undefined : v,
                });
              }}
              onChangeTimeout={onChangeTimeout}
            />
          </div>
        );
      }
      return;
    default: {
      assertUnreachable(definition);
    }
  }
};

type c = {
  [x: string]:
    | string
    | boolean
    | { uploadIds: string[]; uploadIntentPublicIds: string[] }
    | Record<
        string,
        | string
        | boolean
        | { uploadIds: string[]; uploadIntentPublicIds: string[] }
        | null
      >
    | null;
};
