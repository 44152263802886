import { Children, FC, PropsWithChildren } from 'react';
import Accordion, { TProps as TAccordionProps } from '../plAccordion/Accordion';
import Badge from '../plBadge/Badge';

type TProps = {
  title: string;
  hideCount?: boolean;
  count?: number | string;
} & Omit<TAccordionProps, 'title'>;

const AccordionWithChildCount: FC<PropsWithChildren<TProps>> = (props) => {
  const {
    title,
    hideCount = false,
    count,
    children,
    disabled,
    ...rest
  } = props;

  const shouldDisable =
    (typeof count === 'number' && count === 0) ||
    (typeof count !== 'number' && Children.count(children) === 0);

  return (
    <Accordion
      title={
        <h4 className="font-bold text-left">
          {title}
          {!hideCount && (
            <Badge className="ml-2" variant="white" size="sm" rounded>
              {count !== undefined ? count : Children.count(children)}
            </Badge>
          )}
        </h4>
      }
      disabled={disabled || shouldDisable}
      {...rest}
    >
      {children}
    </Accordion>
  );
};

export default AccordionWithChildCount;
