import {
  GetMaterialsForSupplierInput,
  GetPurchaseOrdersForSupplierInput,
  GetSuppliersInput,
  PaginationInput,
} from '@/gql/graphql';

const supplierKeys = {
  all: ['suppliers'],
  search: (input: GetSuppliersInput, pagination: PaginationInput) => [
    ...supplierKeys.all,
    'search',
    input,
    pagination,
  ],
  supplier: (id: string) => [...supplierKeys.all, 'supplier', id] as const,
  purchaseOrders: (
    supplierId: string,
    input?: GetPurchaseOrdersForSupplierInput,
    pagination?: PaginationInput
  ) => {
    return [
      ...supplierKeys.supplier(supplierId),
      'purchaseOrders',
      input || null,
      pagination || null,
    ] as const;
  },
  materials: (
    supplierId: string,
    input?: GetMaterialsForSupplierInput,
    pagination?: PaginationInput
  ) => {
    return [
      ...supplierKeys.supplier(supplierId),
      'materials',
      input || null,
      pagination || null,
    ] as const;
  },
  branches: (
    supplierId: string,
    input?: GetMaterialsForSupplierInput,
    pagination?: PaginationInput
  ) => {
    return [
      ...supplierKeys.supplier(supplierId),
      'branches',
      input || null,
      pagination || null,
    ] as const;
  },
};

export default supplierKeys;
