import { actions as appActions } from '@/api/app';
import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import './NavigationSidebar.sass';

import { AnalyticsPermissions } from '@payaca/permissions/analytics/analytics.permissons';
import { DashboardPermissions } from '@payaca/permissions/dashboard/dashboard.permissions';

import useGetMyAccountAnalytics from '@/api/queries/me/useGetMyAccountAnalytics';
import { useSelector } from '@/api/state';
import useLogout from '@/hooks/auth/useLogout';
import { usePermissions } from '@/hooks/usePermissions';
import { useTranslation } from '@/i18n';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { Disclosure, Transition } from '@headlessui/react';
import NavigationSidebarBase from '@payaca/components/navigationSidebar/NavigationSidebar';
import Avatar, { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import Badge from '@payaca/components/plBadge/Badge';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import FloatingTooltip from '@payaca/components/plTooltip/FloatingTooltip';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import TooltipCore from '@payaca/components/plTooltip/TooltipCore';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { AutomationsPermissions } from '@payaca/permissions/automations/automations.permissions';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { ServicePlanPermissions } from '@payaca/permissions/service-plans/service-plans.permissions';
import { ServicePlanRoles } from '@payaca/permissions/service-plans/service-plans.roles';
import { SuppliersPermissions } from '@payaca/permissions/suppliers/suppliers.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { TimelogsRoles } from '@payaca/permissions/timelogs/timelogs.roles';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import { assertUnreachable } from '@payaca/utilities/guards';
import clsx from 'clsx';
import { LinkProps, matchPath, NavLink } from 'react-router-dom';
import { useShowParentAccountAdminLayout } from '../../../hooks/useShowParentAccountAdminLayout';

type BaseNavigationItem = {
  title: string;
  isDisabled: boolean;
  hasUpgradeIcon?: boolean;
  requiredPermissions?: string[];
};

type SimpleNavigationItem = BaseNavigationItem & {
  type: 'simple';
  to: LinkProps['to'];
  icon: TIconName;
};

type ChildNavigationItem = BaseNavigationItem & {
  type: 'child';
  to: LinkProps['to'];
};

type NavigationItemWithChildren = BaseNavigationItem & {
  type: 'withChildren';
  children: ChildNavigationItem[];
  icon: TIconName;
  defaultOpen?: boolean;
  path: string;
};

type NavigationItem =
  | SimpleNavigationItem
  | NavigationItemWithChildren
  | ChildNavigationItem;

type Props = {
  isSmallView?: boolean;
};
const NavigationSidebar: FC<Props> = ({
  isSmallView = false,
}: Props): JSX.Element => {
  const translate = useTranslation('navigation.persistent');
  const dispatch = useDispatch();
  const logout = useLogout();
  const { userHasRequiredRole } = usePermissions();
  const { showParentAccountAdminLayout } = useShowParentAccountAdminLayout();

  const accountAnalytics = useGetMyAccountAnalytics();

  const isHidden = useSelector(
    (state: any) => state.app.navigationSidebarIsHidden
  );

  const isCollapsed = useSelector(
    (state: any) => state.app.navigationSidebarIsCollapsed
  );

  const navigationSidebarAutoExpandedAfterDeploy = useSelector((state: any) => {
    return state.app.navigationSidebarAutoExpandedAfterDeploy;
  });

  useEffect(() => {
    if (!navigationSidebarAutoExpandedAfterDeploy) {
      dispatch(appActions.expandNavigationSidebar());
      dispatch(appActions.setNavigationSidebarAutoExpandedAfterDeploy());
    }
  }, [navigationSidebarAutoExpandedAfterDeploy]);

  const accountAccessInformation = useSelector(
    (state) => state.account.accountAccessInformation
  );

  const myProfile = useSelector((state: any) => state.users.myProfile);

  const trialPeriodIsExpired = useMemo(() => {
    return accountAccessInformation
      ? !accountAccessInformation.hasActiveSubscription &&
          accountAccessInformation.isTrialPeriodExpired
      : false;
  }, [accountAccessInformation]);

  const hasExceededUserSeats = useMemo(() => {
    if (!accountAccessInformation) return false;

    return (
      accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.hasExceededActiveSubscriptionUserSeats
    );
  }, [accountAccessInformation]);

  const menuItems = useMemo<NavigationItem[]>(() => {
    if (showParentAccountAdminLayout) {
      return [
        {
          type: 'simple',
          title: 'Accounts',
          to: '/accounts',
          isDisabled: false,
          icon: 'building-01.3',
          requiredPermissions: [AccountsPermissions.VIEW_CHILD_ACCOUNTS],
        },
      ];
    }
    const arr: NavigationItem[] = [
      {
        type: 'simple',
        title: translate('dashboard.label'),
        to: '/dashboard',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'home-02.3',
        requiredPermissions: [
          DashboardPermissions.GET_ACTION_REQUIRED_DRAFT_JOBS,
        ],
      },
    ];

    if (!accountAnalytics.data) {
      arr.push({
        type: 'simple',
        title: translate('analytics.label'),
        to: '/analytics',
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'line-chart-up-03.3',
        requiredPermissions: [AnalyticsPermissions.GET_CHART_DATA],
      });
    } else {
      arr.push({
        type: 'withChildren',
        title: translate('analytics.label'),
        isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
        icon: 'line-chart-up-03.3',
        requiredPermissions: [AnalyticsPermissions.GET_CHART_DATA],
        defaultOpen: matchPath(location.pathname, '/analytics') !== null,
        path: '/analytics',
        children: [
          ...accountAnalytics.data.embeddableDashboards.map((item) => {
            return {
              type: 'child' as const,
              title: item.title,
              to: `/analytics/embed/${item.uniqueIdentifier}`,
              isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
              requiredPermissions: [
                AnalyticsPermissions.VIEW_EMBEDDED_DASHBOARD,
              ],
            };
          }),
          {
            type: 'child',
            title: 'Legacy',
            to: '/analytics/performance',
            isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
            requiredPermissions: [AnalyticsPermissions.GET_CHART_DATA],
          },
        ],
      });
    }

    arr.push(
      ...([
        {
          // ToDo: projects not deals
          type: 'simple',
          title: translate('deals.label'),
          to: '/deals',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'lightbulb-05.3',
          requiredPermissions: [
            DealsPermissions.GET_LISTED_DEALS,
            DealsPermissions.GET_MY_LISTED_DEALS,
          ],
        },
        {
          type: 'simple',
          title: translate('invoices.label'),
          to: '/invoices',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'file-06.3',
          requiredPermissions: [InvoicesPermissions.GET_LISTED_INVOICES],
        },
        {
          type: 'simple',
          title: translate('servicePlans.label'),
          to: '/service-plans',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'repeat-04.3',
          hasUpgradeIcon: !userHasRequiredRole([ServicePlanRoles.VIEWER]),
          requiredPermissions: [ServicePlanPermissions.PREVIEW_SERVICE_PLANS],
        },
        {
          type: 'simple',
          title: translate('customers.label'),
          to: '/customers',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'users-01.3',
          requiredPermissions: [
            CustomersPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
          ],
        },
        {
          type: 'simple',
          title: translate('schedule.label'),
          to: '/schedule',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'calendar-check-02.3',
          requiredPermissions: [
            ScheduledEventsPermissions.GET_MY_EVENTS,
            ScheduledEventsPermissions.GET_EVENTS,
          ],
        },
        {
          type: 'simple',
          title: translate('tasks.label'),
          to: '/tasks',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'list.3',
          requiredPermissions: [
            TasksPermissions.GET_TASKS,
            TasksPermissions.GET_SELF_ASSIGNED_TASKS,
          ],
        },
        {
          type: 'simple',
          title: translate('timelogs.label'),
          to: '/timelogs',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'clock-stopwatch.3',
          hasUpgradeIcon: !userHasRequiredRole([TimelogsRoles.ASSIGNEE_VIEWER]),
          requiredPermissions: [TimelogsPermissions.PREVIEW_TIMELOGS],
        },
        {
          type: 'simple',
          title: translate('items.label'),
          to: '/items',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'shopping-cart-02.3',
          requiredPermissions: [
            LineItemsPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
          ],
        },
        {
          type: 'simple',
          title: translate('materials.label'),
          to: '/materials',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'file-check-02.3',
          requiredPermissions: [
            MaterialsPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
          ],
        },
        {
          type: 'simple',
          title: translate('suppliers.label'),
          to: '/suppliers',
          isDisabled: trialPeriodIsExpired || hasExceededUserSeats,
          icon: 'truck-01.3',
          requiredPermissions: [
            SuppliersPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
          ],
        },
        {
          type: 'simple',
          title: translate('automations.label'),
          to: '/automations',
          isDisabled: trialPeriodIsExpired,
          icon: 'dataflow-03.3',
          requiredPermissions: [AutomationsPermissions.GET_LISTED_AUTOMATIONS],
        },
      ] as NavigationItem[])
    );

    return arr;
  }, [
    translate,
    trialPeriodIsExpired,
    hasExceededUserSeats,
    showParentAccountAdminLayout,
    !!accountAnalytics.data,
  ]);

  useEffect(() => {
    if (isSmallView) {
      dispatch(appActions.hideNavigationSidebar());
      dispatch(appActions.expandNavigationSidebar());
    } else {
      dispatch(appActions.showNavigationSidebar());
    }
  }, [isSmallView]);

  return (
    <NavigationSidebarBase
      isCollapsed={isCollapsed}
      isHidden={isHidden}
      isSmallView={isSmallView}
      onToggleIsCollapsed={(setIsCollapsed: boolean) =>
        !setIsCollapsed
          ? dispatch(appActions.expandNavigationSidebar())
          : dispatch(appActions.collapseNavigationSidebar())
      }
      onToggleIsHidden={(setIsHidden: boolean) =>
        !setIsHidden
          ? dispatch(appActions.showNavigationSidebar())
          : dispatch(appActions.hideNavigationSidebar())
      }
    >
      <ul
        className={
          'flex-shrink-1 m-0 list-none space-y-1.5 overflow-y-auto py-6 transition-[padding]' +
          (isCollapsed ? ' px-3' : ' px-6')
        }
      >
        {menuItems.map((menuItem) => (
          <PermissionGuard
            key={menuItem.title}
            renderIfHasPermissions={menuItem.requiredPermissions}
          >
            <li>
              <NavigationItem
                navigationItem={menuItem}
                isCollapsed={isCollapsed}
              />
            </li>
          </PermissionGuard>
        ))}
      </ul>

      <div
        className={
          'mt-auto flex w-full items-center border-t pb-2 pt-5' +
          (isCollapsed ? ' px-3' : ' px-5')
        }
      >
        <Avatar
          sizeVariant={
            isCollapsed ? AvatarSizeVariant.XS : AvatarSizeVariant.BASE
          }
          placeholderInitials={`${myProfile.firstname[0]}${myProfile.lastname[0]}`}
          imgSrc={myProfile.avatarUrl}
        />

        <div
          className={
            'prose ml-3 w-0 flex-[1_1_0px] whitespace-nowrap transition-opacity' +
            (isCollapsed ? ' hidden' : '')
          }
        >
          <p className="overflow-hidden text-ellipsis">
            {myProfile.firstname} {myProfile.lastname}
          </p>
          <Tooltip
            tooltipContent={myProfile.email}
            className="supporting-body !block overflow-hidden text-ellipsis"
          >
            {myProfile.email}
          </Tooltip>
        </div>

        <div className={isCollapsed ? 'hidden' : ''}>
          <FloatingTooltip>
            <Button
              className="!inline-flex"
              aria-label={'Log out'}
              size={EBtnSize.XSmall}
              shape={EBtnShapeVariant.PILL}
              variant={EBtnVariant.Ghost}
              colour={EBtnColour.Black}
              onClick={logout}
            >
              <UntitledIcon className="h-4 w-4" name="log-out-03.3" />
            </Button>

            <TooltipCore>Log out</TooltipCore>
          </FloatingTooltip>
        </div>
      </div>
    </NavigationSidebarBase>
  );
};

export default NavigationSidebar;

const NavigationItem: FC<{
  navigationItem: NavigationItem;
  isCollapsed: boolean;
}> = ({ navigationItem, isCollapsed }) => {
  switch (navigationItem.type) {
    case 'simple':
    case 'child':
      return (
        <NavLink
          to={navigationItem.to}
          activeClassName="bg-blue-50 !font-medium"
          className={clsx(
            'flex w-full items-center gap-x-3.5 whitespace-nowrap rounded-lg px-2.5 py-2 text-base font-normal text-blue-900 hover:bg-blue-50 hover:no-underline',
            !!isCollapsed && '!px-1.5'
          )}
        >
          <NavigationItemButtonContent
            navigationItem={navigationItem}
            isCollapsed={isCollapsed}
          />
        </NavLink>
      );
    case 'withChildren':
      return (
        <NavigationItemWithChildren
          navigationItem={navigationItem}
          isCollapsed={isCollapsed}
        />
      );
    default:
      assertUnreachable(navigationItem);
  }
};

const NavigationItemButtonContent: FC<{
  navigationItem: NavigationItem;
  isCollapsed: boolean;
}> = ({ navigationItem, isCollapsed }) => {
  return (
    <>
      {'icon' in navigationItem && (
        <span className="flex w-[20px] shrink-0 items-center justify-center">
          <UntitledIcon className="h-5 w-5" name={navigationItem.icon} />{' '}
        </span>
      )}
      <span
        className={clsx(
          'transition-all duration-300 flex flex-1',
          !!isCollapsed && 'opacity-0'
        )}
      >
        {navigationItem.title}{' '}
        {navigationItem.hasUpgradeIcon && (
          <Badge size="sm" variant="soft" colour="yellow">
            <UntitledIcon className="h-3 w-3" name="stars-03.3" />
          </Badge>
        )}
      </span>
    </>
  );
};

const NavigationItemWithChildren: FC<{
  navigationItem: NavigationItemWithChildren;
  isCollapsed: boolean;
}> = ({ navigationItem, isCollapsed }) => {
  return (
    <Disclosure
      defaultOpen={matchPath(location.pathname, navigationItem.path) !== null}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={clsx(
              'flex w-full items-center gap-x-3.5 whitespace-nowrap rounded-lg px-2.5 py-2 text-base font-normal text-blue-900 hover:bg-blue-50 hover:no-underline',
              !!isCollapsed && '!px-1.5',
              matchPath(location.pathname, navigationItem.path) !== null || open
                ? 'bg-blue-50'
                : 'bg-transparent'
            )}
          >
            <NavigationItemButtonContent
              navigationItem={navigationItem}
              isCollapsed={isCollapsed}
            />
            <div className=" ml-auto flex place-content-center flex-wrap">
              <UntitledIcon
                name="chevron-down"
                className={`h-4 w-4 origin-center transition-transform ease duration-300 ${
                  open ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </div>
          </Disclosure.Button>
          <div className="overflow-hidden">
            <Transition
              enter="transition ease duration-500 transform"
              enterFrom="opacity-100 -translate-y-full"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease duration-300 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-100 -translate-y-full"
            >
              <Disclosure.Panel>
                <ul className="list-none ps-7 mt-1.5 flex flex-col gap-y-1.5 relative before:content-[''] before:absolute before:top-0 before:left-4 before:w-0.5 before:h-full before:bg-blue-50">
                  {navigationItem.children.map((child, i) => (
                    <PermissionGuard
                      key={child.title}
                      renderIfHasPermissions={child.requiredPermissions}
                    >
                      <li key={i}>
                        <NavigationItem
                          navigationItem={child}
                          isCollapsed={isCollapsed}
                        />
                      </li>
                    </PermissionGuard>
                  ))}
                </ul>
              </Disclosure.Panel>
            </Transition>
          </div>
        </>
      )}
    </Disclosure>
  );
};
