import { FC } from 'react';

import CreateProjectProgressionAutomationActionConfigFieldset from '@/ui/components/automationActionConfigFieldset/CreateProjectTaskAutomationActionConfigFieldset';
import {
  ActionConfigHelperType,
  AutomationActionTypes,
  AutomationEntityTypes,
  CreateProjectTaskActionConfig,
  DealProgressionActionConfig,
  EmailNotificationActionConfig,
  SMSActionConfig,
} from '@payaca/types/automationTypes';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Upload } from '@payaca/types/uploadTypes';
import './AutomationActionConfigFieldset.sass';
import DealProgressionAutomationActionConfigFieldset from './DealProgressionAutomationActionConfigFieldset';
import EmailNotificationAutomationActionConfigFieldset from './EmailNotificationAutomationActionConfigFieldset';
import SmsNotificationAutomationActionConfigFieldset from './SmsNotificationAutomationActionConfigFieldset';

type Props<T extends AutomationActionTypes> = {
  type: T;
  config: ActionConfigHelperType[T];
  automationEntityType: AutomationEntityTypes;
  onChange: (changeValue: { [key: string]: any }) => void;
  isDefaultAutomation?: boolean;
  uploadIds?: Upload['id'][];
  onUploadCollectionChange?: (uploadIds: Upload['id'][]) => void;
  validationState?: Record<string, FieldValidationResult>;
  touchedState?: Record<string, boolean>;
  onTouch?: (fieldName: string) => void;
  showFieldValidationMessagesUnconditionally?: boolean;
};

const AutomationActionConfigFieldset: FC<Props<AutomationActionTypes>> = ({
  type,
  config,
  automationEntityType,
  onChange,
  isDefaultAutomation,
  uploadIds,
  onUploadCollectionChange,
  validationState,
  touchedState,
  onTouch,
  showFieldValidationMessagesUnconditionally,
}) => {
  switch (type) {
    case "E-mail Notification":
      return (
        <EmailNotificationAutomationActionConfigFieldset
          // Don't feel I should need this typecast, why isn't the type being inferred
          config={config as EmailNotificationActionConfig}
          onChange={onChange}
          automationEntityType={automationEntityType}
          isDefaultAutomation={isDefaultAutomation}
          uploadIds={uploadIds}
          onUploadCollectionChange={onUploadCollectionChange}
          validationState={validationState}
          touchedState={touchedState}
          onTouch={onTouch}
          showFieldValidationMessagesUnconditionally={
            showFieldValidationMessagesUnconditionally
          }
        />
      );
    case "SMS Notification":
      return (
        <SmsNotificationAutomationActionConfigFieldset
          // Don't feel I should need this typecast, why isn't the type being inferred
          config={config as SMSActionConfig}
          onChange={onChange}
          automationEntityType={automationEntityType}
          isDefaultAutomation={isDefaultAutomation}
          validationState={validationState}
          touchedState={touchedState}
          onTouch={onTouch}
          showFieldValidationMessagesUnconditionally={
            showFieldValidationMessagesUnconditionally
          }
        />
      );
    case "Deal Progression":
      return (
        <DealProgressionAutomationActionConfigFieldset
          // Don't feel I should need this typecast, why isn't the type being inferred
          config={config as DealProgressionActionConfig}
          onChange={onChange}
          validationState={validationState}
          touchedState={touchedState}
          onTouch={onTouch}
          showFieldValidationMessagesUnconditionally={
            showFieldValidationMessagesUnconditionally
          }
        />
      );
    case "Create repeat Project":
      return (
        <p>
          This Automation action will:
          <ul>
            <li>
              Create a new Project with tags &quot;Repeat&quot; and
              &quot;Reminder&quot; for the same Customer at the same site
              address
            </li>
            <li>
              Create Event with tags &quot;Repeat&quot; and &quot;Reminder&quot;
              linked to the new Project for 12 months time
            </li>
          </ul>
        </p>
      );
    case "Create Project Task":
      return (
        <CreateProjectProgressionAutomationActionConfigFieldset
          config={config as CreateProjectTaskActionConfig}
          onChange={onChange}
          validationState={validationState}
          touchedState={touchedState}
          onTouch={onTouch}
          showFieldValidationMessagesUnconditionally={
            showFieldValidationMessagesUnconditionally
          }
        />
      );
    default:
      return null;
  }
};

export default AutomationActionConfigFieldset;
