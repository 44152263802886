import { FC, useEffect, useMemo } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import DropdownField from '@payaca/components/dropdownField/DropdownField';

import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  AutomationCondition,
  AutomationTriggerEvents,
} from '@payaca/types/automationTypes';

import { useSelector } from '@/api/state';
import TypeToSearchField from '@payaca/components/typeToSearchField/TypeToSearchField';
import { usePipelines } from '@payaca/store/hooks/appState';
import { requestGetAccountPipelines } from '@payaca/store/pipelines/pipelineActions';
import { requestGetTags } from '@payaca/store/tags/tagsActions';
import { ReadableJobStatus } from '@payaca/types/jobTypes';
import { Pipeline, PipelineStage } from '@payaca/types/pipelineTypes';
import { ScheduledEventTaskStatus } from '@payaca/types/scheduledEventsTypes';
import { Tag } from '@payaca/types/tagTypes';
import { useDispatch } from 'react-redux';

export enum FieldTypes {
  TEXT = 'text',
  EMAIL = 'e-mail',
  PHONE_NUMBER = 'phoneNumber',
  PIPELINE_STAGE = 'pipelineStage',
  PROPOSAL_STATUS = 'proposalStatus',
  INVOICE_STATUS = 'invoiceStatus',
  TAG = 'tag',
  TRIGGER = 'trigger',
  PIPELINE_ID = 'pipelineId',
  EVENT_TASK_STATUS = 'eventTaskStatus',
  DAY_OF_WEEK = 'dayOfWeek',
}

type Props = {
  field?: AutomationDataAccessType;
  value: AutomationCondition['value'];
  automationTriggerEvent: AutomationTriggerEvents;
  isDisabled?: boolean;
  onConditionValueChange: (value: AutomationCondition['value']) => void;
  labelConfig?: {
    label?: string;
    description?: string;
  };
};

const AutomationConditionValueField: FC<Props> = ({
  field,
  value,
  automationTriggerEvent,
  isDisabled = false,
  onConditionValueChange,
  labelConfig,
}) => {
  const fieldType = useMemo(() => {
    switch (field) {
      case "proposal.readableStatus":
        return FieldTypes.PROPOSAL_STATUS;
      case "invoice.readableStatus":
        return FieldTypes.INVOICE_STATUS;
      case "deal.pipelineStage":
      case "trigger.newPipelineStage":
      case "trigger.oldPipelineStage":
        return FieldTypes.PIPELINE_STAGE;
      case "deal.pipelineId":
        return FieldTypes.PIPELINE_ID;
      case "event.tags.X.tagText":
      case "event.not.tags.X.tagText":
      case "deal.tags.X.tagText":
      case "deal.not.tags.X.tagText":
        return FieldTypes.TAG;
      case "trigger.X.tagText":
        return FieldTypes.TRIGGER;
      case "primaryContact.telephoneNumber":
      case "event.assignedusers.telephonenumber.array":
        return FieldTypes.PHONE_NUMBER;
      case "primaryContact.emailAddress":
      case "user.email":
      case "event.assignedusers.emailaddress.array":
        return FieldTypes.EMAIL;
      case "event.taskStatus":
        return FieldTypes.EVENT_TASK_STATUS;
      case "trigger.triggeredAt.dayOfWeek":
        return FieldTypes.DAY_OF_WEEK;
      default:
        return FieldTypes.TEXT;
    }
  }, [field]);

  switch (fieldType) {
    case FieldTypes.INVOICE_STATUS:
      return (
        <InvoiceStatusField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.PROPOSAL_STATUS:
      return (
        <ProposalStatusField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.EVENT_TASK_STATUS:
      return (
        <EventTaskStatusField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.PIPELINE_ID:
      return (
        <PipelineIdField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.PIPELINE_STAGE:
      return (
        <PipelineStageField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.TAG:
      return (
        <TagField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.TRIGGER:
      if (automationTriggerEvent === "Tagged") {
        return (
          <TagField
            value={value}
            isDisabled={isDisabled}
            onConditionValueChange={onConditionValueChange}
            labelConfig={labelConfig}
          />
        );
      } else {
        // if any other trigger, use default text input
        return (
          <BasicField
            name="value"
            isDisabled={isDisabled}
            onChange={(v) => onConditionValueChange(v.value)}
            value={value}
            {...labelConfig}
          />
        );
      }
    case FieldTypes.DAY_OF_WEEK:
      return (
        <DayOfWeekField
          value={value}
          isDisabled={isDisabled}
          onConditionValueChange={onConditionValueChange}
          labelConfig={labelConfig}
        />
      );
    case FieldTypes.TEXT:
    case FieldTypes.EMAIL:
    case FieldTypes.PHONE_NUMBER:
    default:
      return (
        <BasicField
          name="value"
          isDisabled={isDisabled}
          onChange={(v) => onConditionValueChange(v.value)}
          value={value}
          {...labelConfig}
        />
      );
  }
};

export default AutomationConditionValueField;

type ValueFieldProps = {
  value: AutomationCondition['value'];
  isDisabled: boolean;
  onConditionValueChange: (value: AutomationCondition['value']) => void;
  labelConfig?: {
    label?: string;
    description?: string;
  };
};

const PipelineIdField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  const pipelines = usePipelines();
  const pipelineOptions = useMemo(() => {
    const o = pipelines.map((x) => ({
      label: x.title,
      value: !x.id ? 'NULL' : x.id.toString(),
    }));

    return o;
  }, [pipelines]);

  return (
    <DropdownField
      name="pipelineId"
      value={value}
      options={pipelineOptions}
      onChange={(value) => onConditionValueChange(value.pipelineId)}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const PipelineStageField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestGetAccountPipelines());
  }, []);

  const pipelines = usePipelines();
  const pipelineStageOptions = useMemo(() => {
    if (!pipelines) return [];
    return pipelines
      .reduce((acc: any[], { stages }: Pipeline) => {
        stages.forEach((stage: PipelineStage) => {
          if (acc.includes(stage.title)) return;
          acc.push(stage.title);
        });
        return acc;
      }, [])
      .map((label: string) => ({ label, value: label }));
  }, [pipelines]);

  return (
    <TypeToSearchField
      value={value}
      onSearchTermChange={onConditionValueChange}
      options={pipelineStageOptions}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const TagField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(requestGetTags());
  }, []);

  const existingTags = useSelector((state) => state.tags.tags);
  const tagOptions = useMemo(
    () =>
      existingTags.map(({ tagText: label }: Tag) => ({ label, value: label })),
    [existingTags]
  );

  return (
    <TypeToSearchField
      value={value}
      onSearchTermChange={onConditionValueChange}
      options={tagOptions}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const ProposalStatusField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const statusOptions = [
    ReadableJobStatus.ARCHIVED,
    ReadableJobStatus.INACTIVE,
    ReadableJobStatus.DEPOSIT_PAID,
    ReadableJobStatus.DEPOSIT_DUE,
    ReadableJobStatus.PAYMENT_PENDING,
    ReadableJobStatus.ACCEPTED,
    ReadableJobStatus.DECLINED,
    ReadableJobStatus.SENT,
    ReadableJobStatus.EXPIRED,
  ].map((label: ReadableJobStatus) => ({ label, value: label }));

  return (
    <TypeToSearchField
      value={value}
      onSearchTermChange={onConditionValueChange}
      options={statusOptions}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const InvoiceStatusField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const statusOptions = [ReadableJobStatus.PAID, ReadableJobStatus.SENT].map(
    (label: ReadableJobStatus) => ({ label, value: label })
  );

  return (
    <TypeToSearchField
      value={value}
      onSearchTermChange={onConditionValueChange}
      options={statusOptions}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const EventTaskStatusField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const taskStatuses: ScheduledEventTaskStatus[] = [
    'No tasks',
    'Completed',
    'Partially completed',
    'Not started',
  ];
  const statusOptions = taskStatuses.map((label: ScheduledEventTaskStatus) => ({
    label,
    value: label,
  }));

  return (
    <DropdownField
      name="taskStatus"
      value={value}
      onChange={(v) => onConditionValueChange(v.taskStatus)}
      options={statusOptions}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};

const DayOfWeekField: FC<ValueFieldProps> = ({
  value,
  isDisabled,
  onConditionValueChange,
  labelConfig,
}) => {
  const daysOfWeek: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];
  const options = daysOfWeek.map((label: string) => ({
    label,
    value: label,
  }));

  return (
    <DropdownField
      name="taskStatus"
      value={value}
      onChange={(v) => onConditionValueChange(v.taskStatus)}
      options={options}
      isDisabled={isDisabled}
      {...labelConfig}
    />
  );
};
