import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetPurchaseOrdersForSupplierInput,
  GetSupplierPurchaseOrdersQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import supplierKeys from './keyFactory';

const GET_SUPPLIER_PURCHASE_ORDERS = graphql(`
  query GetSupplierPurchaseOrders(
    $supplierId: ID!
    $pagination: PaginationInput!
    $input: GetPurchaseOrdersForSupplierInput
  ) {
    supplier(id: $supplierId) {
      purchaseOrders(pagination: $pagination, input: $input) {
        limit
        offset
        totalCount
        items {
          __typename
          id
          reference
          status
          project {
            id
            reference
            customer {
              id
              name
            }
          }
          supplier {
            id
            name
          }
          totals {
            predictedValueExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
            purchasedValueExcTax {
              value
              currency {
                id
                code
                exponent
              }
            }
          }
          ... on PurchaseOrderCityPlumbing {
            cityPlumbingOrder {
              orderId
              status
            }
          }
        }
      }
    }
  }
`);

export type SupplierPurchaseOrder =
  GetSupplierPurchaseOrdersQuery['supplier']['purchaseOrders']['items'][0];

const useGetSupplierPurchaseOrders = (
  opts: {
    supplierId: number;
    input?: GetPurchaseOrdersForSupplierInput;
    pagination?: PaginationInput;
  },
  queryOptions?: UseQueryOptions<GetSupplierPurchaseOrdersQuery>
) => {
  const pagination = opts.pagination || PAGINATION_ARG_DEFAULTS;
  const { data, ...rest } = useQuery({
    queryKey: supplierKeys.purchaseOrders(
      opts.supplierId.toString(),
      opts.input,
      pagination
    ),
    queryFn: () => {
      return gqlClient.request(GET_SUPPLIER_PURCHASE_ORDERS, {
        supplierId: opts.supplierId.toString(),
        pagination: pagination || { limit: 10, offset: 0 },
        input: opts.input,
      });
    },
    ...queryOptions,
  });

  return {
    purchaseOrders: data?.supplier.purchaseOrders,
    data,
    ...rest,
  };
};

export default useGetSupplierPurchaseOrders;
