import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { actions as appActions } from '@/api/app';
import * as userActions from '@payaca/store/user/userActions';

import { AccountAccessBanner, AuthenticatedPageHeader } from '@/ui/components';
import NavigationSidebar from '@/ui/components/navigationSidebar/NavigationSidebar';
import TermsAndPrivacyUpdatesModal from '@/ui/components/termsAndPrivacyUpdatesModal/TermsAndPrivacyUpdatesModal';
import PageWrapper from '../pageWrapper/PageWrapper';

import { useSelector } from '@/api/state';
import { IUseUserHasRoleArgs, useUserHasRole } from '@/hooks/usePermissions';
import { Routes } from '@/routes/RoutesConfig';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import { TBreadcrumbItem } from '@payaca/components/plBreadcrumb/Breadcrumb';
import { setDefaultTimezoneUsingAccountRegionAndPreferredTimezone } from '@payaca/helpers/internationalHelper';
import { IntercomAPI } from 'react-intercom';
import { useHistory } from 'react-router-dom';
import { PreviousPageNavigationConfig } from '../../../components/authenticatedPageHeader/AuthenticatedPageHeader';
import './AuthenticatedPageWrapper.sass';
import SessionStatusBanner from './SessionStatusBanner';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const PayWallCopyForRoutes = {
  [Routes.DISPATCH]:
    'Plan work more efficiently to optimise travel time with our map based dispatch feature.',
  [Routes.TIMELOG_REPORTING]:
    'Track team time spent on Projects and gain an enhanced view of profitability?',
  [Routes.SERVICE_PLANS]:
    'Service Plans help you build and manage recurring revenue. The feature can be added to any paid plan via the subscription page.',
};

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    IntercomAPI('update', {
      hide_default_launcher: false,
    });

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export type Props = {
  pageRouteId?: string;
  pageBanners?: JSX.Element[];
  className?: string;
  title?: string | JSX.Element;
  searchFieldConfig?: {
    onSearchTermChange: (searchTerm?: string) => void;
    placeholder?: string;
  };
  previousPageNavigationConfig?: PreviousPageNavigationConfig;
  sidebarContent?: React.ReactNode;
  requiredRoles?: IUseUserHasRoleArgs['roles'];
  breadcrumbItems?: TBreadcrumbItem[];
};
const AuthenticatedPageWrapper: FC<PropsWithChildren<Props>> = ({
  pageRouteId,
  pageBanners,
  children,
  className,
  title,
  searchFieldConfig,
  previousPageNavigationConfig,
  sidebarContent,
  requiredRoles,
  breadcrumbItems,
}: PropsWithChildren<Props>): JSX.Element => {
  useEffect(() => {
    // Clear any previously-stored UTM parameters... we've logged in and hit an
    // authenticated page, ergo we must have signed up at some point already,
    // so the tracking history from landing page -> signup screen is no longer of interest.
    document.cookie =
      'payaca-utms-v0=;domain=payaca.com;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }, []);

  const history = useHistory();
  const dispatch = useDispatch();
  const userHasPermissionToViewContent = useUserHasRole({
    roles: requiredRoles,
  });
  const windowDimensions = useWindowDimensions();
  const isSmallView = useMemo(() => {
    return windowDimensions.width < 1000;
  }, [windowDimensions.width]);

  const isSidebarHidden = useSelector(
    (state: any) => state.app.navigationSidebarIsHidden
  );

  const updatedTermsAndPrivacy = useSelector(
    (state) => state.user.updatedTermsAndPrivacy
  );
  const isAgreeingToTermsAndPrivacyVersion = useSelector(
    (state) => state.user.isAgreeingToTermsAndPrivacyVersion
  );
  const agreedToTermsAndPrivacyVersionSuccess = useSelector(
    (state) => state.user.agreedToTermsAndPrivacyVersionSuccess
  );

  const account = useSelector(
    (state: any) => state.users.myProfile.accounts[0]
  );
  useEffect(
    () =>
      setDefaultTimezoneUsingAccountRegionAndPreferredTimezone(
        account.region,
        account.preferredTimezone
      ),
    [account]
  );

  useEffect(() => {
    dispatch(userActions.requestGetTermsAndPrivacyUpdates());
    return () => {
      dispatch(userActions.clearTermsAndPrivacyUpdates());
    };
  }, []);

  const agreeToTermsAndPrivacy = useCallback(() => {
    if (updatedTermsAndPrivacy?.version) {
      dispatch(
        userActions.requestAgreeToTermsAndPrivacyVersion(
          updatedTermsAndPrivacy.version
        )
      );
    }
  }, [dispatch, updatedTermsAndPrivacy]);

  useEffect(() => {
    if (agreedToTermsAndPrivacyVersionSuccess) {
      dispatch(userActions.requestGetTermsAndPrivacyUpdates());
    }
  }, [agreedToTermsAndPrivacyVersionSuccess]);

  return (
    <PageWrapper
      className={`authenticated-page-wrapper ${className ? className : ''} ${
        isSmallView ? 'sm-view' : ''
      }`}
      pageBanners={[
        <SessionStatusBanner key="session-status-banner" />,
        <AccountAccessBanner key="account-access-banner" />,
        ...(pageBanners ? pageBanners : []),
      ]}
    >
      <div
        className={`navigation-sidebar-container border-r shadow ${
          isSmallView ? 'z-sidebar1' : 'z-10'
        } ${isSidebarHidden ? '' : 'sidebar-visible'}`}
        onClick={
          !isSidebarHidden && isSmallView
            ? (event) => {
                //@ts-ignore
                if (event.target.classList.contains('navigation-sidebar')) {
                  dispatch(appActions.hideNavigationSidebar());
                }
              }
            : undefined
        }
      >
        <NavigationSidebar isSmallView={isSmallView} />
      </div>
      <div className={'page-content'}>
        <AuthenticatedPageHeader
          isSmallView={windowDimensions.width < 900}
          enableNavigationSidebarToggle={isSmallView}
          searchFieldConfig={searchFieldConfig}
          previousPageNavigationConfig={previousPageNavigationConfig}
          breadcrumbItems={breadcrumbItems}
        />

        <div
          className={
            'header-body-sidebar-container' +
            (userHasPermissionToViewContent ? '' : ' page-content-with-blur')
          }
          tabIndex={userHasPermissionToViewContent ? undefined : -1}
        >
          {sidebarContent && (
            <div className="sidebar-content">{sidebarContent}</div>
          )}
          {title && (
            <div className="header-content">
              <div className="title-bar">
                {typeof title === 'string' ? <h1>{title}</h1> : title}
              </div>
            </div>
          )}
          <div className="body-content">{children}</div>
        </div>

        {!userHasPermissionToViewContent && (
          <div className="blocked-page-content">
            <div className="blocked-page-content-modal">
              <span>
                {pageRouteId && PayWallCopyForRoutes[pageRouteId]
                  ? PayWallCopyForRoutes[pageRouteId]
                  : 'This feature is not available on your current plan.'}
              </span>

              <span>
                <Button
                  styleVariant={ButtonStyleVariant.OUTSIZE}
                  colourVariant={ButtonColourVariant.PRIMARY}
                  onClick={() => history.push('/upgradeAccount')}
                >
                  Explore options
                </Button>
              </span>
            </div>
          </div>
        )}
      </div>
      <TermsAndPrivacyUpdatesModal
        isOpen={!!updatedTermsAndPrivacy}
        onAgree={agreeToTermsAndPrivacy}
        updatedTerms={
          updatedTermsAndPrivacy?.termsMarkdown as string /* FIXME */
        }
        updatedPrivacy={
          updatedTermsAndPrivacy?.privacyMarkdown as string /* FIXME */
        }
        isAgreeingToTermsAndPrivacyVersion={isAgreeingToTermsAndPrivacyVersion}
      />
    </PageWrapper>
  );
};

export default AuthenticatedPageWrapper;
