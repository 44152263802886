import { FC, PropsWithChildren } from 'react';
import { clstx } from '../utils';

const Fieldset: FC<PropsWithChildren<{ className?: string }>> = (props) => {
  const { className, children } = props;

  return <div className={clstx('space-y-4', className)}>{children}</div>;
};

export default Fieldset;
