import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { CustomField } from '@/gql/graphql';

const GetMyCustomFieldGroups = graphql(`
  query MyCustomFieldGroups {
    me {
      user {
        account {
          customFieldGroups {
            id
            role
            customFields {
              id
              identifier
              isRequired
              label
              type
              archivedAt
              options
              schema
            }
          }
        }
      }
    }
  }
`);

const useGetMyCustomFieldGroups = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.customFieldGroups(),
    queryFn: () => {
      return gqlClient.request(GetMyCustomFieldGroups);
    },
  });

  return { data: data?.me.user.account.customFieldGroups, ...rest };
};

const getExistingIdentifiersFromCustomFields = (
  customFields: Pick<CustomField, 'identifier' | 'archivedAt'>[]
) => {
  const identifiers: {
    all: string[];
    archived: string[];
    unarchived: string[];
  } = {
    all: [],
    archived: [],
    unarchived: [],
  };

  customFields.forEach((x) => {
    identifiers.all.push(x.identifier);
    if (x.archivedAt) {
      identifiers.archived.push(x.identifier);
    } else {
      identifiers.unarchived.push(x.identifier);
    }
  });

  return identifiers;
};

export const useGetMyProjectCustomFieldGroup = (customFieldId?: string) => {
  const { data: customFieldGroups, ...rest } = useGetMyCustomFieldGroups();

  const projectCustomFieldGroup = customFieldGroups?.find(
    (x) => x.role === 'project'
  );

  return {
    projectCustomFieldGroup,
    identifiers: getExistingIdentifiersFromCustomFields(
      projectCustomFieldGroup?.customFields || []
    ),
    customField: projectCustomFieldGroup?.customFields.find(
      (customField) => customField.id === customFieldId
    ),
    data: customFieldGroups,
    ...rest,
  };
};

export default useGetMyCustomFieldGroups;
