import { FC, useCallback, useMemo, useState } from 'react';

import Modal from '@payaca/components/modal/Modal';

import { useBlockedEmails } from '@/utils/customHooks';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import ValidationFeedbackBlock from '@payaca/components/validationFeedbackBlock/ValidationFeedbackBlock';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { useCustomer } from '@payaca/store/hooks/appState';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { get } from 'lodash-es';
import { useDispatch } from 'react-redux';
import SendableDocumentEmailControl from '../previewAndSendSendableDocument/SendableDocumentEmailControl';
import './ResendModal.sass';

const emailCopyValidator = (
  fieldName: string,
  formState: { [key: string]: any }
) => {
  const value = get(formState, fieldName);

  if (!value.preButton?.trim().length && !value.postButton?.trim().length) {
    return {
      isValid: false,
      errors: ['The email must have some content'],
    };
  }

  return {
    isValid: true,
  };
};

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  customerId: number;
  customerContactId?: number;
  resendConfig: {
    handleResend: (requestData: {
      sendMeACopy?: boolean;
      emailCopy: {
        preButton: string;
        postButton: string;
      };
    }) => Promise<void>;
    prompt?: string;
    documentType: 'invoice' | 'quote' | 'estimate' | 'change proposal';
    emailConfig: {
      htmlTemplate?: string;
      subject: string;
      bodyTemplate: string;
      substitutions?: { find: string; replaceWith: string }[];
    };
  };
};
const ResendModal: FC<Props> = ({
  title,
  isOpen,
  onClose,
  customerId,
  customerContactId,
  resendConfig,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const blockedEmails = useBlockedEmails();

  const [isProcessing, setIsProcessing] = useState(false);
  const [showValidationFeedback, setShowValidationFeedback] = useState(false);
  const customer = useCustomer(customerId);

  const customerContact = useMemo(
    () =>
      customer
        ? getJobContactFromCustomer(customer, customerContactId || null)
        : undefined,
    [customer, customerContactId]
  );

  const fieldValidators = useMemo(() => {
    return {
      emailCopy: [emailCopyValidator],
    };
  }, [blockedEmails]);

  const initialFormState = useMemo(() => {
    let bodyTemplate = resendConfig.emailConfig.bodyTemplate;

    resendConfig.emailConfig.substitutions?.forEach((substitution) => {
      bodyTemplate = bodyTemplate.replaceAll(
        `[${substitution.find}]`,
        substitution.replaceWith
      );
    });

    let splitTemplate: string[];
    if (bodyTemplate.includes('\n[button]\n')) {
      splitTemplate = bodyTemplate.split('\n[button]\n');
    } else {
      splitTemplate = bodyTemplate.split('[button]');
    }

    return {
      htmlTemplate: resendConfig.emailConfig.htmlTemplate,
      emailAddress: customerContact?.emailAddress,
      emailCopy: {
        preButton: splitTemplate[0],
        postButton: splitTemplate[1],
      },
      sendMeACopy: true,
    };
  }, [customer, customerContact, resendConfig.emailConfig]);

  const onSubmit = useCallback((formState: { [key: string]: any }) => {
    setIsProcessing(true);

    void resendConfig
      .handleResend({
        sendMeACopy: formState.sendMeACopy,
        emailCopy: formState.emailCopy,
      })
      .then(() => {
        setIsProcessing(false);
        onClose();
      });
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <div className="form-content">
          <span className="to-container">
            <strong>To:</strong>
            <span>{customerContact?.emailAddress}</span>
          </span>
          {customerContact && (
            <SendableDocumentEmailControl
              buttonText={`View ${resendConfig.documentType}`}
              emailSubject={resendConfig.emailConfig.subject}
              emailState={{
                htmlTemplate: formState.htmlTemplate,
                sendMeACopy: formState.sendMeACopy,
                emailCopy: formState.emailCopy,
              }}
              onChange={(value) => value && onFieldChange(value)}
            />
          )}

          <div className="actions-container">
            {showValidationFeedback && (
              <ValidationFeedbackBlock
                validationResults={Object.values(validationState)}
                isDismissable={true}
                onDismissed={() => setShowValidationFeedback(false)}
              />
            )}
            <Button
              onClick={() =>
                isValid
                  ? !isProcessing && onSubmit(formState)
                  : setShowValidationFeedback(true)
              }
              isProcessing={isProcessing}
              styleVariant={ButtonStyleVariant.OUTSIZE}
            >
              {resendConfig.prompt || 'Resend'}
            </Button>
          </div>
        </div>
      );
    },
    [isProcessing, onSubmit, showValidationFeedback, customerContact]
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onClose={onClose}
      size={'sm'}
      className="resend-modal"
    >
      <ValidatedForm<{ [key: string]: any }>
        fieldValidators={fieldValidators}
        initialFormState={initialFormState}
        renderFormContents={renderFormContents}
      />
    </Modal>
  );
};

export default ResendModal;
