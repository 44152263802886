import { backOff } from 'exponential-backoff';
import moment from 'moment-timezone';
import { FC, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealsActions from '@payaca/store/deals/dealsActions';
import * as invoicesActions from '@payaca/store/invoices/invoicesActions';
import * as jobContentActions from '@payaca/store/jobContent/jobContentActions';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';

import { useGetInvoiceEmailTemplate } from '@/api/queries/invoice/useGetInvoice';
import { useSelector } from '@/api/state';
import { useUserHasPermission } from '@/hooks/usePermissions';
import BounceResendModal from '@/ui/components/bounceResendModal/BounceResendModal';
import InvoiceDocument from '@/ui/components/invoiceDocument/InvoiceDocument';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import PreviewDocument from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import RecordPaymentModal from '@/ui/components/recordPaymentModal/RecordPaymentModal';
import ResendModal from '@/ui/components/resendModal/ResendModal';
import { downloadFile } from '@/utils/download-file';
import { getRegion, getUserRoles } from '@/utils/stateAccessors';
import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import FeedbackBlock from '@payaca/components/feedbackBlock/FeedbackBlock';
import Modal from '@payaca/components/modal/Modal';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { MOMENT_DATE_SHORT_READABLE_FORMAT } from '@payaca/constants/momentFormatConstants';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { buildEmailSubjectPrefixForProjectFromProject } from '@payaca/helpers/dealHelper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { getInvoicePLBadgeState } from '@payaca/helpers/invoiceHelper';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { InvoicesPermissions } from '@payaca/permissions/invoices/invoices.permissions';
import { PaymentsPermissions } from '@payaca/permissions/payments/payments.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import * as accountActions from '@payaca/store/account/accountActions';
import { requestGetDeal } from '@payaca/store/deals/dealsActions';
import {
  useCustomerForDeal,
  useDeal,
  useInvoice,
} from '@payaca/store/hooks/appState';
import {
  getInvoiceGeneratedPdfUrl,
  requestGetInvoice,
  requestGetInvoicesForDeal,
  requestSendInvoice,
  voidInvoice,
} from '@payaca/store/invoices/invoicesActions';
import { requestGetJobPaymentsForDeal } from '@payaca/store/jobPayments/jobPaymentsActions';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import UntitledIcon from '@payaca/untitled-icons';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './InvoicePage.sass';

type Props = {
  invoiceId: number;
  dealId: number;
};

const InvoicePage: FC<Props> = ({
  invoiceId,
  dealId,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showRecordPaymentModal, setShowRecordPaymentModal] = useState(false);
  const [showBounceResendModal, setShowBounceResendModal] = useState(false);
  const [showResendModal, setShowResendModal] = useState(false);
  const [isGettingPdfUrl, setIsGettingPdfUrl] = useState(false);
  const [isVoiding, setIsVoiding] = useState(false);
  const [showConfirmVoidInvoiceModal, setShowConfirmVoidInvoiceModal] =
    useState(false);

  const userRoles = useSelector(getUserRoles);

  const currentAccount = useSelector(
    (state: any) => state.users?.myProfile?.accounts[0]
  );

  useEffect(() => {
    dispatch(accountActions.requestGetEmailTemplates(currentAccount.id, true));

    return () => {
      dispatch(accountActions.clearEmailTemplates());
    };
  }, []);

  const invoice = useInvoice(invoiceId);

  const deal = useDeal(invoice?.dealId);

  const companyName = useSelector(
    (state: any) => state.users.myProfile.accounts[0].companyName
  );

  const customer = useCustomerForDeal(invoice?.dealId);

  const customerContact = useMemo(() => {
    if (!customer) return;
    return getJobContactFromCustomer(customer, invoice?.contactId || null);
  }, [customer?.contacts, invoice?.contactId]);

  const region = useSelector(getRegion);

  const fetchPdfRetry = useCallback(() => {
    return backOff(
      async () => {
        return new Promise<void>((resolve, reject) => {
          dispatch(
            requestGetInvoice(invoiceId, (invoice) => {
              if (invoice.pdfUrl) {
                resolve();
              } else {
                reject(new Error());
              }
            })
          );
        });
      },
      {
        numOfAttempts: 5,
        startingDelay: 1500,
        delayFirstAttempt: true,
      }
    );
  }, [invoiceId]);

  useEffect(() => {
    if (invoice && !invoice.pdfUrl) {
      void fetchPdfRetry();
    }
  }, [!invoice]);

  const onRecordOrConfirmPaymentSuccess = useCallback(() => {
    if (!invoice) return;
    dispatch(requestGetDeal(invoice.dealId));
    dispatch(requestGetInvoicesForDeal(invoice.dealId));
  }, [dispatch, invoice?.dealId]);

  const dueDate = useMemo(() => {
    if (!invoice) return;
    return invoice.dueAt
      ? moment(invoice.dueAt).format(MOMENT_DATE_SHORT_READABLE_FORMAT)
      : moment()
          .add(invoice.dueInDays, 'days')
          .format(MOMENT_DATE_SHORT_READABLE_FORMAT);
  }, [invoice]);

  const { emailTemplate: invoiceHTMLEmailTemplate } =
    useGetInvoiceEmailTemplate(invoiceId.toString());

  const canGetEmailTemplates = useUserHasPermission({
    permissions: [AccountsPermissions.GET_EMAIL_TEMPLATES],
  });

  const emailTemplates = useSelector((state) => state.account.emailTemplates);

  const emailTemplate = useMemo(() => {
    return emailTemplates?.sendInvoice || '';
  }, [emailTemplates]);

  useEffect(() => {
    if (invoiceId) {
      dispatch(invoicesActions.requestGetInvoiceLinesForInvoice(invoiceId));
      dispatch(jobContentActions.requestGetJobLineItemsForInvoice(invoiceId));
      dispatch(uploadsActions.requestGetUploadsForEntity(invoiceId, 'invoice'));
    }
  }, [invoiceId]);

  useEffect(() => {
    if (dealId) {
      dispatch(dealsActions.requestGetDeal(dealId));
      dispatch(invoicesActions.requestGetInvoicesForDeal(dealId));
      dispatch(requestGetJobPaymentsForDeal(dealId));
      dispatch(
        invoicesActions.requestGetPaymentReconciliationRecordsForDeal(dealId)
      );
    }
  }, [dealId]);

  useEffect(() => {
    if (deal?.customerId) {
      dispatch(customerActions.requestGetCustomer(deal.customerId));
    }
  }, [deal?.customerId]);

  useEffect(() => {
    if (invoice && !invoice?.sentAt && !invoice.voidedAt) {
      history.push(`/deals/${dealId}/invoices/${invoice.id}/edit`);
    }
  }, [invoice?.sentAt, invoice?.voidedAt]);

  const handleDownloadInvoicePDF = useCallback(() => {
    if (!invoice) return;
    setIsGettingPdfUrl(true);
    dispatch(
      getInvoiceGeneratedPdfUrl.request({
        invoiceId: invoiceId,
        callback: (pdfUrl: string) => {
          setIsGettingPdfUrl(false);
          downloadFile(
            pdfUrl,
            `invoice-${invoice.customReference || invoice.reference}.pdf`
          );
          requestGetInvoice(invoiceId);
        },
        onErrorCallback: () => setIsGettingPdfUrl(false),
      })
    );
  }, [invoiceId, invoice, setIsGettingPdfUrl]);

  const availableContextMenuItems = useMemo(() => {
    const actions: TDropdownItem[] = [];

    if (
      invoice &&
      invoice.dueValue > 0 &&
      userHasRequiredPermission(userRoles, [PaymentsPermissions.RECORD_PAYMENT])
    ) {
      actions.push({
        label: 'Record Payment',
        onClick: () => setShowRecordPaymentModal(true),
      });
    }

    if (invoice?.sentAt && !invoice?.bouncedAt) {
      actions.push({
        label: 'Resend',
        onClick: () => setShowResendModal(true),
      });
    }

    if (
      userHasRequiredPermission(userRoles, [InvoicesPermissions.VOID_INVOICE])
    ) {
      actions.push({
        label: 'Void this Invoice',
        onClick: () => setShowConfirmVoidInvoiceModal(true),
      });
    }

    return actions;
  }, [
    invoice,
    setShowRecordPaymentModal,
    setShowResendModal,
    setShowConfirmVoidInvoiceModal,
    userRoles,
  ]);

  return (
    <AuthenticatedPageWrapper
      className="bg-gray-50"
      previousPageNavigationConfig={
        invoice
          ? {
              route: `/deals/${dealId}/invoices-and-payments`,
              navigationPromptName: 'Back to Project',
            }
          : undefined
      }
    >
      <div className="p-4">
        <PreviewDocument
          title="Invoice"
          badge={{
            variant: 'soft',
            colour: getInvoicePLBadgeState(invoice?.readableStatus),
            children: invoice?.readableStatus,
          }}
          firstAction={
            invoice?.pdfUrl
              ? {
                  children: (
                    <UntitledIcon name="download-01.3" className="h-5 w-5" />
                  ),
                  onClick: () => {
                    // todo: anchor link as a context menu item
                    window.open(invoice.pdfUrl, '_blank');
                  },
                }
              : {
                  children: (
                    <UntitledIcon name="download-01.3" className="h-5 w-5" />
                  ),
                  onClick: handleDownloadInvoicePDF,
                  isProcessing: isGettingPdfUrl,
                  disabled: isGettingPdfUrl,
                }
          }
          actions={availableContextMenuItems}
        >
          {invoice?.bouncedAt && (
            <FeedbackBlock feedbackLevel={FeedbackLevel.ALERT}>
              <p>The email sending this invoice to your customer has bounced</p>
              <PermissionGuard
                renderIfHasPermissions={[InvoicesPermissions.SEND_INVOICE]}
              >
                <Button
                  styleVariant={ButtonStyleVariant.ANCHOR}
                  onClick={() => setShowBounceResendModal(true)}
                >
                  Retry
                </Button>
              </PermissionGuard>
            </FeedbackBlock>
          )}

          <InvoiceDocument isPreview invoiceId={invoiceId} />
        </PreviewDocument>
      </div>
      <RecordPaymentModal
        dealId={invoice?.dealId || 0}
        paymentTarget={{
          invoiceId: invoiceId,
        }}
        isOpen={showRecordPaymentModal}
        onClose={() => setShowRecordPaymentModal(false)}
        recordPaymentCallback={onRecordOrConfirmPaymentSuccess}
        confirmPaymentCallback={onRecordOrConfirmPaymentSuccess}
      />
      {deal?.customerId && (
        <BounceResendModal
          title="Resend Invoice"
          isOpen={showBounceResendModal}
          onClose={() => setShowBounceResendModal(false)}
          customerId={deal.customerId}
          customerContactId={invoice?.contactId}
          resendConfig={{
            handleResend: (requestData: {
              sendMeACopy?: boolean;
              emailCopy: {
                preButton: string;
                postButton: string;
              };
            }) => {
              dispatch(
                requestSendInvoice(
                  {
                    isResend: true,
                    invoiceId: invoiceId,
                    sendMeACopy: requestData.sendMeACopy || false,
                    emailCopy: requestData.emailCopy,
                  },
                  () => {
                    dispatch(requestGetInvoice(invoiceId));
                  }
                )
              );

              return Promise.resolve();
            },
            documentType: 'invoice',
            emailConfig: {
              htmlTemplate: canGetEmailTemplates
                ? invoiceHTMLEmailTemplate?.preview
                : undefined,
              subject: `${buildEmailSubjectPrefixForProjectFromProject(
                deal
              )}Your invoice from ${companyName}`,
              bodyTemplate: emailTemplate,
              substitutions: [
                {
                  find: 'reference',
                  replaceWith:
                    invoice?.customReference || String(invoice?.reference),
                },
                { find: 'company_name', replaceWith: companyName },
                { find: 'customer_name', replaceWith: customerContact?.name },
                {
                  find: 'amount_due',
                  replaceWith: currencyPrice(invoice?.dueValue || 0, region),
                },
                {
                  find: 'due_date',
                  replaceWith: dueDate,
                },
              ],
            },
          }}
        />
      )}
      {deal?.customerId && (
        <ResendModal
          title="Resend Invoice"
          isOpen={showResendModal}
          onClose={() => setShowResendModal(false)}
          customerId={deal.customerId}
          customerContactId={invoice?.contactId}
          resendConfig={{
            handleResend: (requestData: {
              sendMeACopy?: boolean;
              emailCopy: {
                preButton: string;
                postButton: string;
              };
            }) => {
              dispatch(
                requestSendInvoice(
                  {
                    isResend: true,
                    invoiceId: invoiceId,
                    sendMeACopy: requestData.sendMeACopy || false,
                    emailCopy: requestData.emailCopy,
                  },
                  () => {
                    dispatch(requestGetInvoice(invoiceId));
                  }
                )
              );

              return Promise.resolve();
            },
            documentType: 'invoice',
            emailConfig: {
              htmlTemplate: canGetEmailTemplates
                ? invoiceHTMLEmailTemplate?.preview
                : undefined,
              subject: `${buildEmailSubjectPrefixForProjectFromProject(
                deal
              )}Your invoice from ${companyName}`,
              bodyTemplate: emailTemplate,
              substitutions: [
                {
                  find: 'reference',
                  replaceWith:
                    invoice?.customReference || String(invoice?.reference),
                },
                { find: 'company_name', replaceWith: companyName },
                { find: 'customer_name', replaceWith: customerContact?.name },
                {
                  find: 'amount_due',
                  replaceWith: currencyPrice(invoice?.dueValue || 0, region),
                },
                {
                  find: 'due_date',
                  replaceWith: dueDate,
                },
              ],
            },
          }}
        />
      )}
      <Modal
        isOpen={showConfirmVoidInvoiceModal}
        onClose={() => setShowConfirmVoidInvoiceModal(false)}
        title={'Are you sure you want to void the Invoice?'}
        actions={
          <>
            <Button
              colourVariant={ButtonColourVariant.RED}
              styleVariant={ButtonStyleVariant.OUTSIZE}
              onClick={() => {
                if (isVoiding) return;
                setIsVoiding(true);
                dispatch(
                  voidInvoice.request({
                    invoiceId: invoiceId,
                    callback: () => {
                      dispatch(requestGetInvoice(invoiceId));
                      setIsVoiding(false);
                      setShowConfirmVoidInvoiceModal(false);
                    },
                    onErrorCallback: () => setIsVoiding(false),
                  })
                );
              }}
              isProcessing={isVoiding}
            >
              Void
            </Button>
          </>
        }
      ></Modal>
    </AuthenticatedPageWrapper>
  );
};
export default InvoicePage;
