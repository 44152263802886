import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import invoiceKeys from '@/api/queries/invoice/keyFactor';
import { graphql } from '@/gql';
import { GetInvoiceInput } from '@/gql/graphql';

const GET_INVOICE = graphql(`
  query GetInvoice($input: GetInvoiceInput!) {
    invoice(input: $input) {
      id
      emailTemplate {
        id
        preview
      }
    }
  }
`);

const useGetInvoice = (id?: GetInvoiceInput['id']) => {
  return useQuery({
    enabled: !!id,
    queryKey: invoiceKeys.invoice(id!),
    queryFn: () => {
      return gqlClient.request(GET_INVOICE, { input: { id: id! } });
    },
  });
};

export const useGetInvoiceEmailTemplate = (id?: GetInvoiceInput['id']) => {
  const { data, ...rest } = useGetInvoice(id);

  return {
    data,
    emailTemplate: data?.invoice.emailTemplate,
    ...rest,
  };
};

export default useGetInvoice;
