import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { EndChildAccountUserSessionMutation } from '@/gql/graphql';

const EndChildAccountUserSession = graphql(`
  mutation EndChildAccountUserSession {
    endChildAccountUserSession
  }
`);

const useEndChildAccountUserSession = (
  options?: UseMutationOptions<EndChildAccountUserSessionMutation, unknown>
) => {
  return useMutation({
    mutationFn: () => {
      return gqlClient.request(EndChildAccountUserSession);
    },
    ...options,
  });
};

export default useEndChildAccountUserSession;
