import useGetEmailTemplates from '@/api/queries/emailTemplates/useGetEmailTemplates';
import { useSelector } from '@/api/state';
import { useUserHasPermission } from '@/hooks/usePermissions';
import CreateEditEmailTemplateModal from '@/ui/components/companySettings/components/CreateEditEmailTemplateModal';
import EmailTemplateModal from '@/ui/components/companySettings/EmailTemplateModal';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { useAccount } from '@/utils/storeHooks';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import DividerWithLabel from '@payaca/components/plDivider/DividerWithLabel';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import * as accountActions from '@payaca/store/account/accountActions';
import { requestUpdateEmailTemplates } from '@payaca/store/account/accountActions';
import { FC, useEffect, useMemo, useReducer } from 'react';
import { useDispatch } from 'react-redux';

const ProposalEmailTemplateCard: FC<{
  viewLegacyEstimateTemplate?: () => void;
  viewLegacyQuoteTemplate?: () => void;
  viewHTMLTemplate?: () => void;
}> = (props) => {
  const {
    viewLegacyEstimateTemplate,
    viewLegacyQuoteTemplate,
    viewHTMLTemplate,
  } = props;

  const { data, isLoading } = useGetEmailTemplates({
    types: ['PROPOSAL'],
  });

  const canManageEmailTemplates = useUserHasPermission({
    permissions: [AccountsPermissions.MANAGE_EMAIL_TEMPLATES],
  });

  const hasHTMLTemplate =
    !isLoading && !!data?.emailTemplates.length && canManageEmailTemplates;

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="h-full flex flex-col">
        <h4 className="mb-2">Proposal email template</h4>

        <div className="mt-auto space-y-3 pt-2">
          <Conditional condition={!hasHTMLTemplate}>
            <div className="grid md:grid-cols-2 gap-3">
              <Button
                className="w-full"
                size={EBtnSize.Small}
                variant={EBtnVariant.White}
                onClick={viewLegacyEstimateTemplate}
              >
                Edit estimate
              </Button>

              <Button
                className="w-full"
                size={EBtnSize.Small}
                variant={EBtnVariant.White}
                onClick={viewLegacyQuoteTemplate}
              >
                Edit quote
              </Button>
            </div>

            <PermissionGuard
              renderIfHasPermissions={[
                AccountsPermissions.MANAGE_EMAIL_TEMPLATES,
              ]}
            >
              <DividerWithLabel label="or" />
            </PermissionGuard>
          </Conditional>

          <PermissionGuard
            renderIfHasPermissions={[
              AccountsPermissions.MANAGE_EMAIL_TEMPLATES,
            ]}
          >
            <Button
              className="mt-4 w-full"
              size={EBtnSize.Small}
              variant={EBtnVariant.White}
              onClick={viewHTMLTemplate}
            >
              {hasHTMLTemplate ? 'Edit' : 'Create'} HTML template
            </Button>
          </PermissionGuard>
        </div>
      </Card.Body>
    </Card>
  );
};

const InvoiceEmailTemplateCard: FC<{
  viewLegacyInvoiceTemplate?: () => void;
  viewHTMLTemplate?: () => void;
}> = (props) => {
  const { viewLegacyInvoiceTemplate, viewHTMLTemplate } = props;

  const { data, isLoading } = useGetEmailTemplates({
    types: ['INVOICE'],
  });

  const canManageEmailTemplates = useUserHasPermission({
    permissions: [AccountsPermissions.MANAGE_EMAIL_TEMPLATES],
  });

  const hasHTMLTemplate =
    !isLoading && !!data?.emailTemplates.length && canManageEmailTemplates;

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body className="h-full flex flex-col">
        <h4 className="mb-2">Invoice email template</h4>

        <div className="mt-auto space-y-3 pt-2">
          <Conditional condition={!hasHTMLTemplate}>
            <Button
              className="w-full"
              size={EBtnSize.Small}
              variant={EBtnVariant.White}
              onClick={viewLegacyInvoiceTemplate}
            >
              Edit
            </Button>

            <PermissionGuard
              renderIfHasPermissions={[
                AccountsPermissions.MANAGE_EMAIL_TEMPLATES,
              ]}
            >
              <DividerWithLabel label="or" />
            </PermissionGuard>
          </Conditional>

          <PermissionGuard
            renderIfHasPermissions={[
              AccountsPermissions.MANAGE_EMAIL_TEMPLATES,
            ]}
          >
            <Button
              className="w-full"
              size={EBtnSize.Small}
              variant={EBtnVariant.White}
              onClick={viewHTMLTemplate}
            >
              {hasHTMLTemplate ? 'Edit' : 'Create'} HTML template
            </Button>
          </PermissionGuard>
        </div>
      </Card.Body>
    </Card>
  );
};

type ModalState = {
  modalType: 'htmlTemplate' | 'legacyTemplate' | null;
  activeModal: 'proposal' | 'invoice' | 'estimate' | 'quote' | null;
};

type ModalAction =
  | {
      type: 'openLegacyTemplateModal';
      payload: { templateType: 'estimate' | 'quote' | 'invoice' };
    }
  | {
      type: 'openHTMLTemplateModal';
      payload: { templateType: 'proposal' | 'invoice' };
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openLegacyTemplateModal':
      return {
        modalType: 'legacyTemplate',
        activeModal: action.payload.templateType,
      };
    case 'openHTMLTemplateModal':
      return {
        modalType: 'htmlTemplate',
        activeModal: action.payload.templateType,
      };
    case 'closeModal':
      return { modalType: null, activeModal: null };
    default:
      return state;
  }
};

const EmailTemplateCards: FC = () => {
  const [modalState, dispatchModalState] = useReducer(modalReducer, {
    modalType: null,
    activeModal: null,
  });

  /**
   * Redux
   */
  const dispatch = useDispatch();
  const account = useAccount();
  const emailTemplates = useSelector((state) => state.account.emailTemplates);
  const emailTemplatesDefaults = useSelector(
    (state) => state.account.emailTemplates?.defaults
  );

  useEffect(() => {
    dispatch(accountActions.requestGetEmailTemplates(account.id, true));
  }, []);

  const emailTemplateModalProps = useMemo(() => {
    if (modalState.modalType === 'htmlTemplate') {
      return;
    }

    if (modalState.activeModal === 'estimate') {
      return {
        title: 'Estimate email template',
        name: 'sendEstimate',
        defaultValue: emailTemplatesDefaults?.sendEstimate,
        value: emailTemplates?.sendEstimate || '',
      };
    } else if (modalState.activeModal === 'quote') {
      return {
        title: 'Quote email template',
        name: 'sendQuote',
        defaultValue: emailTemplatesDefaults?.sendQuote,
        value: emailTemplates?.sendQuote || '',
      };
    } else if (modalState.activeModal === 'invoice') {
      return {
        title: 'Invoice email template',
        name: 'sendInvoice',
        defaultValue: emailTemplatesDefaults?.sendInvoice,
        value: emailTemplates?.sendInvoice || '',
      };
    }
  }, [emailTemplatesDefaults, emailTemplates, modalState]);

  return (
    <>
      <Card>
        <Card.Body>
          <h2 className="mb-4">Email Templates</h2>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <ProposalEmailTemplateCard
              viewLegacyEstimateTemplate={() =>
                dispatchModalState({
                  type: 'openLegacyTemplateModal',
                  payload: { templateType: 'estimate' },
                })
              }
              viewLegacyQuoteTemplate={() =>
                dispatchModalState({
                  type: 'openLegacyTemplateModal',
                  payload: { templateType: 'quote' },
                })
              }
              viewHTMLTemplate={() =>
                dispatchModalState({
                  type: 'openHTMLTemplateModal',
                  payload: { templateType: 'proposal' },
                })
              }
            />

            <InvoiceEmailTemplateCard
              viewLegacyInvoiceTemplate={() =>
                dispatchModalState({
                  type: 'openLegacyTemplateModal',
                  payload: { templateType: 'invoice' },
                })
              }
              viewHTMLTemplate={() =>
                dispatchModalState({
                  type: 'openHTMLTemplateModal',
                  payload: { templateType: 'invoice' },
                })
              }
            />
          </div>
        </Card.Body>
      </Card>

      <EmailTemplateModal
        isOpen={modalState.modalType === 'legacyTemplate'}
        onClose={() => dispatchModalState({ type: 'closeModal' })}
        onSubmit={(value) => {
          dispatchModalState({ type: 'closeModal' });
          dispatch(
            requestUpdateEmailTemplates(
              account.id,
              {
                [emailTemplateModalProps?.name as string]: value,
              },
              () => null
            )
          );
        }}
        readOnly={false}
        // readOnly={readOnly}
        title={emailTemplateModalProps?.title || ''}
        name={emailTemplateModalProps?.name || ''}
        defaultValue={
          emailTemplateModalProps?.defaultValue as string /* FIXME */
        }
        value={emailTemplateModalProps?.value || ''}
        buttonText={`View ${modalState.activeModal}`}
      />

      <CreateEditEmailTemplateModal
        isOpen={modalState.modalType === 'htmlTemplate'}
        onClose={() => dispatchModalState({ type: 'closeModal' })}
        templateType={
          modalState.activeModal === 'invoice' ? 'INVOICE' : 'PROPOSAL'
        }
      />
    </>
  );
};

export default EmailTemplateCards;
