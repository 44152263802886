import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import customerKeys from '@/api/queries/customers/keyFactory';
import { graphql } from '@/gql';
import { GetProjectsForCustomerInput, PaginationInput } from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetCustomerProjectsQuery = graphql(`
  query CustomerProjects(
    $customerId: Int!
    $pagination: PaginationInput!
    $input: GetProjectsForCustomerInput
  ) {
    customer(id: $customerId) {
      projects(pagination: $pagination, input: $input) {
        items {
          id
          reference
          totals {
            projectValue {
              value
              currency {
                id
                exponent
                code
              }
            }
          }
          updatedAt
          pipeline {
            id
            name
            colour
          }
          currentPipelineStage {
            id
            title
            index
          }
          addresses {
            site {
              id
              line1
              line2
              city
              country
              postalCode
              fullLocalAddress
            }
          }
          owner {
            firstName
            lastName
            fullName
            colour
            email
            avatarUrl
          }
          tags {
            id
            name
            colour
          }
        }
        limit
        offset
        totalCount
      }
    }
  }
`);

const useGetCustomerProjects = (
  customerId?: string,
  input?: GetProjectsForCustomerInput,
  pagination?: PaginationInput
) => {
  return useQuery({
    enabled: !!customerId,
    queryKey: customerKeys.customerProjects(customerId!, input, pagination),
    queryFn: () => {
      return gqlClient.request(GetCustomerProjectsQuery, {
        customerId: parseInt(customerId!),
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });
};

export default useGetCustomerProjects;
