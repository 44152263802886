import Field from '@payaca/components/plField/Field';
import {
  CustomFieldHelperType,
  CustomFieldType,
} from '@payaca/custom-fields/types/index';
import { Nullish } from '@payaca/utilities/types';
import { useMemo, useState } from 'react';
import { CustomFieldInput } from './CustomFieldInput';

export const CustomField = <T extends CustomFieldType>({
  definition,
  value,
  onChange,
  onChangeTimeout,
}: {
  definition: CustomFieldHelperType[T]['definition'];
  value?: Nullish<CustomFieldHelperType[T]['value']>;
  onChange: (value?: Nullish<CustomFieldHelperType[T]['value']>) => void;
  onChangeTimeout?: () => void;
}) => {
  const [showValidation, setShowValidation] = useState(true);

  const validationState = useMemo(() => {
    const state = definition.validateValue(value);
    return state.isValid ? undefined : state;
  }, [definition.type, value]);

  return (
    <Field
      name={definition.identifier}
      validationState={showValidation ? validationState : undefined}
    >
      <Field.Label>{definition.label}</Field.Label>
      <CustomFieldInput
        definition={definition}
        value={value}
        onChange={(value) => {
          setShowValidation(false);
          onChange(value);
        }}
        onChangeTimeout={() => {
          setShowValidation(true);
          onChangeTimeout?.();
        }}
      />
    </Field>
  );
};
