export enum AccountsPermissions {
  GET_ACCOUNT = 'accounts.getAccount',

  GET_USERS = 'accounts.getUsers',
  GET_USER_COST_PER_HOUR = 'accounts.getUserCostPerHour',
  SET_USER_COST_PER_HOUR = 'accounts.setUserCostPerHour',
  INVITE_USER = 'accounts.inviteUser',
  GET_DETAILED_USERS = 'accounts.getDetailedUsers',
  DEACTIVATE_USER = 'accounts.deactivateUser',
  REACTIVATE_USER = 'accounts.reactivateUser',
  UPDATE_ROLE = 'accounts.updateRole',
  SET_USER_COLOUR = 'accounts.setUserColour',

  ADD_CONNECTION = 'accounts.addConnection',
  UPDATE_CONNECTION = 'accounts.updateConnection',
  DELETE_CONNECTION = 'accounts.deleteConnection',

  ADD_CONNECTION_ACCOUNTING = 'accounts.addConnection.accounting',
  ADD_CONNECTION_SUPPLIERS = 'accounts.addConnection.suppliers',
  ADD_CONNECTION_PAYMENT = 'accounts.addConnection.payment',
  ADD_CONNECTION_AUTOMATION = 'accounts.addConnection.automation',
  UPDATE_CONNECTION_ACCOUNTING = 'accounts.updateConnection.accounting',
  UPDATE_CONNECTION_SUPPLIERS = 'accounts.updateConnection.suppliers',
  UPDATE_CONNECTION_PAYMENT = 'accounts.updateConnection.payment',
  UPDATE_CONNECTION_AUTOMATION = 'accounts.updateConnection.automation',
  DELETE_CONNECTION_ACCOUNTING = 'accounts.deleteConnection.accounting',
  DELETE_CONNECTION_SUPPLIERS = 'accounts.deleteConnection.suppliers',
  DELETE_CONNECTION_PAYMENT = 'accounts.deleteConnection.payment',
  DELETE_CONNECTION_AUTOMATION = 'accounts.deleteConnection.automation',

  UPDATE_SUBSCRIPTION = 'accounts.updateSubscription',

  UPDATE_MY_ACCOUNT = 'accounts.updateMyAccount',
  UPDATE_ACCOUNT = 'accounts.updateAccount',

  SET_CUSTOM_EMAIL_DOMAIN = 'accounts.setCustomEmailDomain',
  SEND_FROM_CUSTOM_EMAIL_DOMAIN = 'accounts.sendFromCustomEmailDomain',

  MANAGE_CUSTOM_FIELD_GROUPS = 'accounts.manageCustomFieldGroups',
  MANAGE_CUSTOM_FIELD_VALUES = 'accounts.manageCustomFieldValues',

  MANAGE_TAGS = 'accounts.manageTags',

  GET_EMAIL_TEMPLATES = 'accounts.getEmailTemplates',
  MANAGE_EMAIL_TEMPLATES = 'accounts.manageCustomEmailTemplates',

  VIEW_CHILD_ACCOUNTS = 'accounts.getChildAccounts',
  JOIN_CHILD_ACCOUNT = 'accounts.joinChildAccount',
}
