import Avatar, { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useParams } from 'react-router';
import useGetChildAccount from '../../../api/queries/childAccounts/useGetChildAccount';

const ChildAccountPageHeaders = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { data: childAccount, isLoading } = useGetChildAccount(accountId);

  return (
    <div>
      <div
        className="w-full h-[160px] rounded-xl"
        style={{ background: childAccount?.branding.logoUrl || undefined }}
      />
      <div className="flex flex-col items-center justify-items-center -mt-[90px]">
        {!childAccount ? (
          <>
            <SkeletonLoader.Circle className="w-[5rem] h-[5rem]" />
            <SkeletonLoader.Title className="w-1/6 mt-2" />
          </>
        ) : (
          <div className="flex flex-col items-center">
            {isLoading ? (
              <SkeletonLoader.Circle className="w-[5rem] h-[5rem]" />
            ) : (
              <>
                <div>
                  <Avatar
                    imgSrc={childAccount.branding.logoUrl || ''}
                    sizeVariant={AvatarSizeVariant.LG}
                    imgDescription={`Logo for ${childAccount.name}`}
                    iconName="building-01.3"
                  />
                </div>

                <h1 className="mt-2">{childAccount.name}</h1>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChildAccountPageHeaders;
