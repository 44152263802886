import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { AllSystemRoles } from '@payaca/permissions/default-role.config';
import {
  getUserRoles,
  getUserSubscriptionLevelFromSubscriptionMapping,
  userHasRequiredPermission,
} from '@payaca/permissions/permissions.utils';
import { useAccountSubscription } from '@payaca/store/hooks/appState';
import { FunctionComponent, useMemo } from 'react';
import { useAccountUsers } from '../../../utils/storeHooks';
type Props = {
  currentAssignedUser?: any;
  onAssignedUserChange: (userId: string) => void;
  label?: string;
  assigneeRequiredPermission?: string[];
};

const AssignmentDropdown: FunctionComponent<Props> = ({
  currentAssignedUser = undefined,
  onAssignedUserChange,
  label,
  assigneeRequiredPermission,
}: Props): JSX.Element | null => {
  const accountUsers = useAccountUsers();
  const accountSubscription = useAccountSubscription();

  const getUserRolesForUserSystemRole = (userSystemRole: AllSystemRoles) => {
    const subscriptionLevel = getUserSubscriptionLevelFromSubscriptionMapping(
      accountSubscription?.subscriptionProduct.mapping
    );
    const roles = getUserRoles(subscriptionLevel, userSystemRole, {});
    return roles;
  };

  const selectItems = useMemo(() => {
    const items = accountUsers
      .filter((x) => {
        const userRoles = getUserRolesForUserSystemRole(
          x.systemRole as AllSystemRoles
        );
        const assigneeUserHasPermission = assigneeRequiredPermission?.length
          ? userHasRequiredPermission(userRoles, assigneeRequiredPermission)
          : true;
        return (!x.deactivatedAt || x.inviteToken) && assigneeUserHasPermission;
      })
      .map((user: any) => {
        return {
          label: user.name,
          value: user.id,
        };
      });
    items.push({ label: 'Unassigned', value: '' });
    return items;
  }, [accountUsers, assigneeRequiredPermission, getUserRolesForUserSystemRole]);

  return accountUsers.length ? (
    <div className="assignment-control">
      {accountUsers.length && (
        <DropdownField
          options={selectItems}
          name="assignedUserId"
          onChange={(value: { [key: string]: any }) =>
            onAssignedUserChange(value.assignedUserId)
          }
          value={
            currentAssignedUser?.id
              ? currentAssignedUser.id
              : currentAssignedUser || ''
          }
          label={label}
        />
      )}
    </div>
  ) : null;
};

export default AssignmentDropdown;
