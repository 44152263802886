import { GetFulfillmentOptionsForMaterialsQuery } from '../../../gql/graphql';
import {
  CollectionFulfillmentOption,
  DeliveryFulfillmentOption,
  FulfillmentOption,
} from '../../../helpers/fulfillment';

export const parseFulfillmentOptions = (
  fulfillmentOptions?: GetFulfillmentOptionsForMaterialsQuery['fulfillmentOptionsForMaterials']
): FulfillmentOption[] | null => {
  if (!fulfillmentOptions) {
    return null;
  }
  return fulfillmentOptions.map((f) => {
    const baseFulfillment = {
      __typename: f.__typename,
      isFullFulfillment: f.isFullFulfillment,
      fulfillmentOptionId: f.fulfillmentOptionId,
      stock: 'stock' in f ? f.stock : null,
      branch: f.branch,
    };
    if (f.__typename === 'SupplierMaterialsDeliveryFulfillment') {
      return {
        ...baseFulfillment,
        deliveryFromDate: new Date(f.deliveryFromDate),
        deliveryToDate: new Date(f.deliveryToDate),
      } as DeliveryFulfillmentOption;
    }
    return {
      ...baseFulfillment,
      collectionFromDate: new Date(f.collectionFromDate),
    } as CollectionFulfillmentOption;
  });
};
