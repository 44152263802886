import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyAccountAccountingIntegrations = graphql(`
  query MyAccountAccountingIntegrations {
    me {
      user {
        account {
          accountingIntegrations {
            quickbooks {
              accounts {
                code
                identifier
                isExpense
                isRevenue
                isLiability
                name
              }
              categories {
                identifier
                name
                options {
                  identifier
                  name
                }
              }
            }
            xero {
              accounts {
                code
                identifier
                isExpense
                isRevenue
                isLiability
                name
              }
              categories {
                identifier
                name
                options {
                  identifier
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`);

const useGetMyAccountAccountingIntegrations = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountAccountingIntegrations(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountAccountingIntegrations);
    },
  });

  return { data: data?.me.user.account.accountingIntegrations, ...rest };
};

export default useGetMyAccountAccountingIntegrations;
