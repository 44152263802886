import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import {
  GetPaginatedProjectUploadsInput,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GET_PROJECT_UPLOADS = graphql(`
  query ProjectUploads($projectId: Int!, $pagination: PaginationInput!) {
    project(id: $projectId) {
      uploads(input: { includeAllAssociated: true }, pagination: $pagination) {
        items {
          id
          extension
          fileName
          mimeType
          size
          thumbnailUrl
          url
        }
        totalCount
      }
    }
  }
`);

const useGetProjectUploads = (
  projectId?: number,
  input?: GetPaginatedProjectUploadsInput,
  pagination?: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.uploads(projectId!, input, pagination),
    queryFn: () => {
      return gqlClient.request(GET_PROJECT_UPLOADS, {
        projectId: projectId!,
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });

  return { uploads: data?.project.uploads, data, ...rest };
};

export default useGetProjectUploads;
