export enum ScheduledEventsPermissions {
  GET_EVENTS = 'scheduledEvents.getEvents',
  GET_MY_EVENTS = 'scheduledEvents.getMyEvents',
  GET_MY_DEAL_EVENTS = 'scheduledEvents.getMyDealEvents',
  ADD_EVENT = 'scheduledEvents.addEvent',
  ADD_MY_EVENT = 'scheduledEvents.addMyEvent',
  ADD_MY_DEAL_EVENT = 'scheduledEvents.addMyDealEvent',
  UPDATE_EVENT = 'scheduledEvents.updateEvent',
  UPDATE_MY_EVENT = 'scheduledEvents.updateMyEvent',
  UPDATE_MY_DEAL_EVENT = 'scheduledEvents.updateMyDealEvent',
  TAG_EVENT = 'scheduledEvents.tagEvent',
  BULK_IMPORT_EVENTS = 'scheduledEvents.bulkImport',

  SHARE_CUSTOMER_ON_LEGACY_APP = 'scheduledEvents.shareCustomerOnLegacyApp',

  ADD_CUSTOM_EVENT = 'scheduledEvents.addCustomEvent',
}
