import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { StartChildAccountUserSessionMutation } from '@/gql/graphql';

const StartChildAccountUserSession = graphql(`
  mutation StartChildAccountUserSession($accountId: ID!) {
    startChildAccountUserSession(accountId: $accountId)
  }
`);

const useStartChildAccountUserSession = (
  options?: UseMutationOptions<
    StartChildAccountUserSessionMutation,
    unknown,
    string
  >
) => {
  return useMutation({
    mutationFn: (accountId) => {
      return gqlClient.request(StartChildAccountUserSession, { accountId });
    },
    ...options,
  });
};

export default useStartChildAccountUserSession;
