// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

const childAccountsKeys = {
  all: ['childAccounts'] as const,
  childAccounts: () => [...childAccountsKeys.all, 'accounts'] as const,
  childAccount: (accountId: string) =>
    [...childAccountsKeys.childAccounts(), accountId] as const,
};

export default childAccountsKeys;
