import React, { FunctionComponent } from 'react';

import useGetMyAccountAnalytics from '@/api/queries/me/useGetMyAccountAnalytics';
import { useTranslation } from '@/i18n';
import { Helmet } from 'react-helmet';
import { Redirect, useParams } from 'react-router';
import AuthenticatedPageWrapper from '../pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import './EmbeddableAnalyticsDashboardPage.sass';

export const EmbeddableAnalyticsDashboardPage: FunctionComponent =
  (): JSX.Element => {
    const { data, isLoading } = useGetMyAccountAnalytics();
    const translate = useTranslation();

    const { identifier } = useParams<{ identifier: string }>();

    const embeddableDashboard = data?.embeddableDashboards.find(
      (x) => x.uniqueIdentifier === identifier
    );

    const ref = React.useRef<HTMLIFrameElement>(null);

    if (!embeddableDashboard && !isLoading) {
      return <Redirect to="/analytics" />;
    }

    return (
      <>
        <Helmet
          title={`${translate(
            'pages.analytics.title'
          )} - ${embeddableDashboard?.title}`}
        />
        <AuthenticatedPageWrapper
          title={`Analytics: ${embeddableDashboard?.title || ''}`}
          className="analytics-embed"
        >
          {embeddableDashboard && (
            <iframe
              ref={ref}
              className="w-full h-full m-0 p-0"
              src={embeddableDashboard?.embedUrl || ''}
              key={embeddableDashboard?.embedUrl}
            ></iframe>
          )}
        </AuthenticatedPageWrapper>
      </>
    );
  };
