import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import subscriptionKeys from './keyFactory';

const GetSubscriptionAvailableProducts = graphql(`
  query SubscriptionAvailableProducts {
    availableProducts {
      __typename
      stripeId
      name
      mapping
      numberOfJobsPerMonth
      numberOfUserSeats
      hasZapier
      hasForms
      numberOfSmsPerMonth
      hasCustomAutomations
      displayMetdata {
        featuresList
        description
      }
      minRequiredUserSeats
      hasCustomPrice
      monthlyPrice {
        stripeId
        basicCost
        additionalUserSeatCost
        canBuyAdditionalUserSeats
      }
      annualPrice {
        stripeId
        basicCost
        additionalUserSeatCost
        canBuyAdditionalUserSeats
      }
    }
  }
`);

const useGetAvailableProducts = () => {
  return useQuery({
    queryKey: subscriptionKeys.products(),
    queryFn: () => {
      return gqlClient.request(GetSubscriptionAvailableProducts);
    },
  });
};

export default useGetAvailableProducts;
