import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  DeleteEmailTemplateInput,
  DeleteEmailTemplateMutation,
} from '@/gql/graphql';

const DELETE_EMAIL_TEMPLATE_MUTATION = graphql(`
  mutation DeleteEmailTemplate($input: DeleteEmailTemplateInput!) {
    deleteEmailTemplate(input: $input)
  }
`);

const useDeleteEmailTemplate = (
  options?: UseMutationOptions<
    DeleteEmailTemplateMutation,
    unknown,
    DeleteEmailTemplateInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(DELETE_EMAIL_TEMPLATE_MUTATION, { input });
    },
    ...options,
  });
};

export default useDeleteEmailTemplate;
