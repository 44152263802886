import useCreateCustomField from '@/api/mutations/custom-field-groups/useCreateCustomField';
import useUpdateCustomField from '@/api/mutations/custom-field-groups/useUpdateCustomField';
import meKeys from '@/api/queries/me/keyFactory';
import { useGetMyProjectCustomFieldGroup } from '@/api/queries/me/useGetMyCustomFieldGroups';
import { CreateCustomFieldInput } from '@/gql/graphql';
import CustomFieldGroupOptionsInput from '@/ui/components/customFieldGroupControl/CustomFieldGroupOptionsInput';
import { registerFieldBuilder } from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Conditional from '@payaca/components/conditional/Conditional';
import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import Button from '@payaca/components/plButton/Button';
import Field from '@payaca/components/plField/Field';
import Fieldset from '@payaca/components/plFieldset/Fieldset';
import Input from '@payaca/components/plInput/Input';
import Select from '@payaca/components/plSelect/Select';
import Sidebar from '@payaca/components/plSidebar/Sidebar';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import {
  CUSTOM_FIELD_TYPE_READABLE_NAME_MAP,
  CUSTOM_FIELD_TYPE_VALUES,
  CustomFieldType,
} from '@payaca/custom-fields/types/index';
import { camelize } from '@payaca/utilities/stringUtilities';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

const TYPE_OPTIONS = [
  { value: 'text', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['text'] },
  { value: 'email', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['email'] },
  { value: 'select', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['select'] },
  { value: 'boolean', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['boolean'] },
  { value: 'number', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['number'] },
  { value: 'url', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['url'] },
  // { value: 'fieldset', label: CUSTOM_FIELD_TYPE_READABLE_NAME_MAP['fieldset'] },
];

type FormValues = {
  type: CustomFieldType;
  identification: {
    label: string;
    identifier: string;
  };
  options?: string[];
};

const SCHEMA = z.object({
  type: z.enum(CUSTOM_FIELD_TYPE_VALUES),
  identification: z.object({
    label: z.string(),
    identifier: z.string(),
  }),
  options: z.array(z.string()).optional(),
});

const CreateEditCustomFieldSidebarContent: FC<{
  onFieldsetSelectionChange?: (selected: boolean) => void;
  onSubmit: (values: FormValues) => void;
  allCurrentIdentifiers?: string[];
  defaultValues?: FormValues;
  isProcessing?: boolean;
}> = (props) => {
  const {
    onFieldsetSelectionChange,
    onSubmit,
    allCurrentIdentifiers = [],
    defaultValues,
    isProcessing,
  } = props;

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: defaultValues || {
      identification: {},
    },
    resolver: zodResolver(SCHEMA),
  });

  const registerField = registerFieldBuilder(errors);

  const type = watch('type');
  useEffect(() => {
    onFieldsetSelectionChange?.(type === 'fieldset');
  }, [type]);

  const isEditing = !!defaultValues;

  return (
    <ConditionalWrapper
      condition={type === 'fieldset'}
      Wrapper="div"
      wrapperProps={{
        className: 'grid grid-cols-2 h-full',
      }}
    >
      <form className="h-full" onSubmit={handleSubmit(onSubmit, console.error)}>
        <div className="p-4 h-full flex flex-col">
          <Fieldset>
            <Field {...registerField('type')}>
              <Field.Label>Type</Field.Label>

              <Controller
                render={({ field: { value, onChange } }) => {
                  return (
                    <Select
                      disabled={isEditing}
                      options={TYPE_OPTIONS}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
                name="type"
                control={control}
              />
            </Field>

            <Controller
              render={({ field: { value, onChange } }) => {
                return (
                  <>
                    <Field {...registerField('identification.label')}>
                      <Field.Label>Label</Field.Label>

                      <Input
                        value={value.label}
                        onChange={(newLabel) => {
                          if (isEditing) {
                            onChange({ ...value, label: newLabel });
                            return;
                          }

                          let newIdentifier = camelize(
                            newLabel.replace(/[^a-zA-Z0-9 ]/g, '')
                          ).slice(0, 250);

                          if (allCurrentIdentifiers.includes(newIdentifier)) {
                            newIdentifier = `${newIdentifier}-${uuid().slice(
                              0,
                              5
                            )}`;
                          }

                          onChange({
                            identifier: newIdentifier,
                            label: newLabel,
                          });
                        }}
                      />
                    </Field>

                    <Field {...registerField('identification.identifier')}>
                      <Field.Label>Internal Name</Field.Label>

                      <Input
                        disabled={isEditing}
                        value={value.identifier}
                        onChange={(v) => {
                          onChange({ ...value, identifier: v });
                        }}
                      />

                      <Field.Helper>
                        This unique identifier will be used for integrations.
                      </Field.Helper>
                    </Field>
                  </>
                );
              }}
              name="identification"
              control={control}
            />

            <Conditional condition={type === 'select'}>
              <Field {...registerField('options')}>
                <Field.Label>Options</Field.Label>

                <Controller
                  shouldUnregister
                  render={({ field: { value, onChange } }) => {
                    return (
                      <CustomFieldGroupOptionsInput
                        options={value}
                        onChange={onChange}
                      />
                    );
                  }}
                  name="options"
                  control={control}
                />
              </Field>
            </Conditional>
          </Fieldset>

          <Button
            className="mt-auto"
            type="submit"
            disabled={isEditing && !isDirty}
            isProcessing={isProcessing}
          >
            {isEditing ? 'Edit' : 'Create'} custom field
          </Button>
        </div>

        <Conditional condition={type === 'fieldset'}>
          <div className="bg-gray-50 p-4"></div>
        </Conditional>
      </form>
    </ConditionalWrapper>
  );
};

export interface IProps {
  isOpen: boolean;
  onClose?: () => void;
  customFieldId?: string;
}

const CreateEditCustomFieldSidebar: FC<IProps> = (props) => {
  const { isOpen, onClose, customFieldId } = props;

  /**
   * state
   */
  const [isLargeSidebar, setIsLargeSidebar] = useState(false);
  const toastContext = useToastContext();

  /**
   * queries
   */
  const queryClient = useQueryClient();
  const { projectCustomFieldGroup, identifiers, customField } =
    useGetMyProjectCustomFieldGroup(customFieldId);

  /**
   * mutations
   */
  const { mutateAsync: createCustomField, isLoading: isCreatingCustomField } =
    useCreateCustomField();
  const { mutateAsync: updateCustomField, isLoading: isEditingCustomField } =
    useUpdateCustomField();

  /**
   * callbacks
   */
  const handleSubmit = async (values: FormValues) => {
    if (!projectCustomFieldGroup) {
      return;
    }

    if (customField) {
      await updateCustomField({
        id: customField.id,
        label: values.identification.label,
        identifier: values.identification.identifier,
        options: values.options,
      }).catch(() => {
        toastContext.pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Something went wrong',
        });
      });
    } else {
      let additional: CreateCustomFieldInput['definition']['additional'] = {
        simple: {},
      };

      if (values.type === 'select') {
        additional = {
          select: {
            options: (values.options || []).filter((x) => x),
          },
        };
      }

      await createCustomField({
        groupId: projectCustomFieldGroup.id,
        definition: {
          type: values.type,
          label: values.identification.label,
          identifier: values.identification.identifier,
          additional,
        },
      }).catch(() => {
        toastContext.pushToast({
          variant: 'white',
          icon: 'error',
          message: 'Something went wrong',
        });
      });
    }

    await queryClient.invalidateQueries({
      queryKey: meKeys.customFieldGroups(),
    });

    onClose?.();
  };

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      size={isLargeSidebar ? 'lg' : 'standard'}
      title={`${customFieldId ? 'Edit' : 'Create'} Custom Field`}
    >
      <Sidebar.Body>
        {!customFieldId || (customFieldId && customField) ? (
          <CreateEditCustomFieldSidebarContent
            onSubmit={handleSubmit}
            onFieldsetSelectionChange={setIsLargeSidebar}
            allCurrentIdentifiers={identifiers.all}
            defaultValues={
              customField
                ? {
                    type: customField.type as CustomFieldType,
                    identification: {
                      label: customField.label,
                      identifier: customField.identifier,
                    },
                    options: customField.options?.filter((o) => o) as
                      | string[]
                      | undefined,
                  }
                : undefined
            }
            isProcessing={isCreatingCustomField || isEditingCustomField}
          />
        ) : (
          <div>Loading...</div>
        )}
      </Sidebar.Body>
    </Sidebar>
  );
};

export default CreateEditCustomFieldSidebar;
