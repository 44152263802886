import { FC, useCallback } from 'react';

import {
  Automation,
  AutomationAction,
  AutomationActionTypes,
  AutomationCondition,
  AutomationEntityTypes,
  ListedAutomation,
} from '@payaca/types/automationTypes';
import './AutomationsTable.sass';

import {
  conditionValueLabelMap,
  fieldOptionEntityMap,
} from '@/ui/pages/automationsPageOld/automation.utils';
import Table from '@payaca/components/plTable/Table';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import SwitchField from '@payaca/components/switchField/SwitchField';
import { automationEntityTypeReadableNameMap } from '@payaca/helpers/automationHelper';
import { usePipelines } from '@payaca/store/hooks/appState';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';

type AutomationRowType = Pick<
  Automation<AutomationActionTypes>,
  'id' | 'title' | 'entityType' | 'triggerEvent' | 'isActive'
> & {
  actions: Pick<AutomationAction<AutomationActionTypes>, 'title'>[];
  conditions: Pick<
    AutomationCondition,
    'field' | 'operator' | 'value' | 'isActive'
  >[];
};

type Props = {
  automations: AutomationRowType[];
  onClickRow?: (automationId: ListedAutomation['id']) => void;
  quickActionDefinitions?: {
    actionName: string;
    actionBehaviour: (id: number) => void;
    isActionProcessing?: boolean;
  }[];
  isLoading?: boolean;
};

const AutomationsTable: FC<Props> = ({
  automations,
  onClickRow,
  quickActionDefinitions,
  isLoading,
}) => {
  const pipelines = usePipelines();

  const getConditionAsString = useCallback(
    (
      condition: Pick<AutomationCondition, 'value' | 'operator' | 'field'>,
      entityType: AutomationEntityTypes
    ) => {
      let conditionValue: string | undefined = condition.value;

      switch (condition.field) {
        case "deal.pipelineId":
          conditionValue = pipelines.find(
            (x) => x.id.toString() == condition.value
          )?.title;
          break;
      }

      const [dataAccessType, arg] = condition.field.split(':');
      const label = fieldOptionEntityMap?.[entityType]?.[dataAccessType]?.label;

      return `"${
        label ? `${label}${arg ? `: ${arg}` : ''}` : 'Missing Field'
      }" ${conditionValueLabelMap[condition.operator] || 'Missing Operator'} "${
        conditionValue || 'Missing Value'
      }"`;
    },
    [pipelines]
  );

  return (
    <ResponsiveViewWrapper className="automations-table" downBreakpointSm={800}>
      <Table
        uniqueKey="id"
        data={automations}
        quickActions={quickActionDefinitions?.map((qad) => {
          return {
            name: qad.actionName,
            onClick: (row: AutomationRowType) => qad.actionBehaviour(row.id),
            isProcessing: qad.isActionProcessing,
          };
        })}
        isLoading={isLoading}
        onClickRow={(row: AutomationRowType) => onClickRow?.(row.id)}
      >
        <Table.Column
          header="Name"
          field={'title'}
          render={(title) => {
            return <strong>{title}</strong>;
          }}
        />
        <Table.Column<AutomationRowType, 'entityType'>
          header="Type"
          field={'entityType'}
          render={(entityType) => {
            return (
              <>
                {automationEntityTypeReadableNameMap[entityType] || entityType}
              </>
            );
          }}
        />
        <Table.Column<AutomationRowType, 'triggerEvent'>
          header="Type"
          field={'triggerEvent'}
        />
        <Table.Column<AutomationRowType, 'conditions'>
          header="Conditions"
          className="hide-sm"
          field={'conditions'}
          render={(conditions, automation) => {
            return (
              <>
                {!conditions.length ? (
                  <>None</>
                ) : (
                  <ul>
                    {conditions
                      .filter((x) => x.isActive)
                      .slice(0, 3)
                      .map((x, i) => {
                        return (
                          <li key={i}>
                            {getConditionAsString(x, automation.entityType)}
                          </li>
                        );
                      })}
                  </ul>
                )}
              </>
            );
          }}
        />
        <Table.Column<AutomationRowType, 'actions'>
          header="Actions"
          className="hide-sm"
          field={'actions'}
          render={(actions) => {
            return (
              <>
                {!actions.length ? (
                  <>None</>
                ) : (
                  <ul>
                    {actions.map((x, i) => {
                      return <li key={i}>{x.title}</li>;
                    })}
                  </ul>
                )}
              </>
            );
          }}
        />
        <Table.Column<AutomationRowType, 'isActive'>
          header="On/Off"
          field={'isActive'}
          render={(isActive) => {
            return <SwitchField name="isActive" value={isActive} />;
          }}
        />
      </Table>
    </ResponsiveViewWrapper>
  );
};

export default AutomationsTable;
