import { useSelector } from '@/api/state';
import { getRegion } from '@/utils/stateAccessors';
import EntityCard from '@payaca/components/entityCard/EntityCard';
import { AlertTooltip } from '@payaca/components/iconTooltip/IconTooltip';
import ResponsiveViewWrapper from '@payaca/components/responsiveViewWrapper/ResponsiveViewWrapper';
import { currencyPrice } from '@payaca/helpers/financeHelper';
import { useJobContent, useProposal } from '@payaca/store/hooks/appState';
import { Job } from '@payaca/types/jobTypesV2';
import { FC, PropsWithChildren, useMemo } from 'react';
import JobStatusBadge from '../jobStatusBadge/JobStatusBadge';
import './ProtoInvoiceCard.sass';

interface Props {
  protoInvoiceId: Job['id'];
  onClick?: () => void;
}

const ProtoInvoiceCard: FC<PropsWithChildren<Props>> = ({
  protoInvoiceId,
  onClick,
}) => {
  const protoInvoice = useProposal(protoInvoiceId);
  const region = useSelector((state) => getRegion(state));

  const jobContent = useJobContent(protoInvoice?.jobContentId);

  const issue = useMemo(() => {
    if (protoInvoice?.bouncedAt) {
      return 'E-mail has bounced';
    }
  }, [protoInvoice]);

  if (!protoInvoice) return null;

  return (
    <>
      <EntityCard className="proto-invoice-card" onClick={onClick}>
        <ResponsiveViewWrapper
          className="invoice-card-content"
          downBreakpointSm={500}
        >
          <>
            {protoInvoice.customReference ? (
              <dl>
                <dt>Invoice</dt>
                <dd>{protoInvoice.customReference}</dd>
              </dl>
            ) : (
              <dl>
                <dt></dt>
                <dd>Invoice</dd>
              </dl>
            )}
            <div className="status-and-warning-wrapper">
              {issue && (
                <div className={'card-issue'}>
                  <AlertTooltip tooltipText={issue} />
                </div>
              )}
              <JobStatusBadge status={protoInvoice.readableStatus} />
            </div>
            {jobContent && (
              <dl className="total">
                <dt>Total</dt>
                <dd>{currencyPrice(jobContent?.total, region)}</dd>
              </dl>
            )}
          </>
        </ResponsiveViewWrapper>
      </EntityCard>
    </>
  );
};

export default ProtoInvoiceCard;
