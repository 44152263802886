import { useQuery } from '@tanstack/react-query';

import meKeys from '@/api/queries/me/keyFactory';
import { graphql } from '@/gql';
import { gqlClient } from '../../graphql-client';

const GetMe = graphql(`
  query GetMe {
    me {
      permissions
      emailSignature
      user {
        id
        fullName
        isPrimaryUser
        systemRole
        account {
          id
          name
          phone
          email
          enforceSSO
        }
        originalAccountId
      }
      integrations {
        google {
          isActive
          email
        }
      }
    }
  }
`);

const useGetMe = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.all,
    queryFn: async () => gqlClient.request(GetMe),
  });
  return {
    data: data?.me,
    ...rest,
  };
};

export default useGetMe;
