import useGetPurchaseOrderDeliveryAddress from '@/api/queries/purchase-orders/useGetPurchaseOrderAddress';
import { useAccount } from '@/utils/storeHooks';
import { OptionGroup, SelectOption } from '@payaca/components/plSelect/Select';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { useDeal, useSupplier } from '@payaca/store/hooks/appState';
import { Deal } from '@payaca/types/dealTypes';
import { Address, PartialAddress } from '@payaca/types/locationTypes';
import { PurchaseOrder } from '@payaca/types/materialsListTypes';
import { Supplier } from '@payaca/types/supplierTypes';
import { FC, useMemo } from 'react';
import { AddressSelectExistingOrInputNew } from '../addressSelectExistingOrInputNew/AddressSelectExistingOrInputNew';

const getPartialAccountAddress = (account: any): PartialAddress => {
  return {
    line1: account.address || null,
    city: account.city,
    postcode: account.postcode,
    longitude: account.longitude,
    latitude: account.latitude,
  };
};
export const usePurchaseOrderAddressOptions = ({
  projectId,
  supplierId,
  purchaseOrderId,
}: {
  projectId?: Deal['id'];
  supplierId?: Supplier['id'];
  purchaseOrderId?: PurchaseOrder['id'];
}) => {
  const account = useAccount();
  const supplier = useSupplier(supplierId);
  const project = useDeal(projectId);
  const { data: deliveryAddress } =
    useGetPurchaseOrderDeliveryAddress(purchaseOrderId);

  const accountAddress = getPartialAccountAddress(account);

  const {
    options,
    optionGroups,
  }: {
    options: SelectOption<number, PartialAddress>[];
    optionGroups: OptionGroup[];
  } = useMemo(() => {
    const og: OptionGroup[] = [];
    if (supplier?.addresses?.length) {
      og.push({
        label: 'supplier',
        id: 'supplier',
      });
    }
    if (project?.siteAddresses?.length) {
      og.push({
        label: 'project',
        id: 'project',
      });
    }

    const o: SelectOption<number, PartialAddress>[] = [
      ...(supplier?.addresses?.map((a) => {
        return {
          value: a.address.id,
          label: getAddressAsString(a.address) || '',
          metadata: a.address,
          groupId: 'supplier',
        };
      }) || []),
      ...(project?.siteAddresses?.map((a) => {
        return {
          value: a.address.id,
          label: getAddressAsString(a.address) || '',
          metadata: a.address,
          groupId: 'project',
        };
      }) || []),
    ];

    const accountAddressString = getAddressAsString(accountAddress);

    if (accountAddressString?.length) {
      o.push({
        value: 0,
        label: accountAddressString,
        metadata: accountAddress,
      });

      og.push({
        label: 'account',
        id: 'account',
      });
    }

    if (deliveryAddress && !o.find((a) => a.value === +deliveryAddress.id)) {
      const address: PartialAddress = {
        line1: deliveryAddress.line1 || null,
        line2: deliveryAddress.line2 || null,
        city: deliveryAddress.city || null,
        postcode: deliveryAddress.postalCode || null,
        country: deliveryAddress.country || null,
      };

      o.push({
        value: +deliveryAddress.id,
        label: getAddressAsString(address) || '',
        metadata: address,
      });
    }

    return {
      options: o,
      optionGroups: og,
    };
  }, [supplier, project, deliveryAddress, accountAddress]);

  return { options, optionGroups };
};

export type Props = {
  projectId?: Deal['id'];
  supplierId?: Supplier['id'];
  purchaseOrderId?: PurchaseOrder['id'];
  value: {
    deliveryAddressId?: Address['id'];
    newDeliveryAddress?: PartialAddress;
  };
  onChange?: (value: {
    deliveryAddressId?: Address['id'];
    newDeliveryAddress?: PartialAddress;
  }) => void;
  disableManualEdit?: boolean;
  canRevertToExisting?: boolean;
};
export const PurchaseOrderDeliveryAddressControl: FC<Props> = ({
  projectId,
  supplierId,
  value,
  onChange,
  purchaseOrderId,
  disableManualEdit,
  canRevertToExisting,
}) => {
  const { options, optionGroups } = usePurchaseOrderAddressOptions({
    projectId,
    supplierId,
    purchaseOrderId,
  });

  const account = useAccount();

  const accountAddress = getPartialAccountAddress(account);

  return (
    <div>
      <AddressSelectExistingOrInputNew
        addressOptions={options}
        addressOptionGroups={optionGroups}
        value={{
          existingAddressId: value?.deliveryAddressId,
          newAddress: value?.newDeliveryAddress,
        }}
        disableManualEdit={disableManualEdit}
        canRevertToExisting={canRevertToExisting}
        onChange={(v) => {
          if (v.existingAddressId === 0) {
            onChange?.({
              deliveryAddressId: undefined,
              newDeliveryAddress: accountAddress,
            });
          } else {
            onChange?.({
              deliveryAddressId: v.existingAddressId,
              newDeliveryAddress: v.newAddress,
            });
          }
        }}
      />
    </div>
  );
};
