import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import useGetMe from '../api/queries/me/useGetMe';
import { useSelector } from '../api/state';
import { getUserRoles } from '../utils/stateAccessors';

export const useShowParentAccountAdminLayout = () => {
  const { data: me } = useGetMe();
  const userRoles = useSelector(getUserRoles);

  return {
    showParentAccountAdminLayout:
      userHasRequiredPermission(userRoles, [
        AccountsPermissions.VIEW_CHILD_ACCOUNTS,
      ]) &&
      (me?.user?.originalAccountId
        ? me.user.originalAccountId === me.user.account.id
        : false),
  };
};
