// ======================================================================================================
// Private routes config
// - Define private routes, only accessible to authenticated users
// ======================================================================================================

import { Routes } from './RoutesConfig';

import FourOFour from '@/ui/pages/404/404';
import AnalyticsDashboardPage from '@/ui/pages/analyticsDashboardPage/AnalyticsDashboardPage';
import { EmbeddableAnalyticsDashboardPage } from '@/ui/pages/analyticsDashboardPage/EmbeddableAnalyticsDashboardPage';
import AppleCallback from '@/ui/pages/appleCallbackPage/AppleCallbackPage';
import AutomationsPage from '@/ui/pages/automationsPage/AutomationsPage';
import CreateEditAutomationPage from '@/ui/pages/automationsPageOld/CreateEditAutomationPage';
import ChangeProposalPage from '@/ui/pages/changeProposalPage/ChangeProposalPage';
import ClearCachePage from '@/ui/pages/ClearCachePage';
import CompanySettingsPage from '@/ui/pages/companySettingsPage/CompanySettingsPage';
import ConnectionApplication from '@/ui/pages/connectionApplicationPage/ConnectionApplication';
import Connections from '@/ui/pages/connectionsPage/ConnectionsPage';
import CreateEditEntityTemplatePage from '@/ui/pages/createEditEntityTemplatePage/CreateEditEntityTemplatePage';
import CsvUploadsPage from '@/ui/pages/csv-uploads-page/CsvUploadsPage';
import CustomerPage from '@/ui/pages/customerPage/CustomerPage';
import DashboardPage from '@/ui/pages/dashboardPage/DashboardPage';
import DealPage from '@/ui/pages/dealPage/DealPage';
import DefaultAutomationPage from '@/ui/pages/defaultAutomationPage/DefaultAutomationPage';
import DevPage from '@/ui/pages/devPage/DevPage';
import DocumentOverviewPage from '@/ui/pages/documentOverviewPage/DocumentOverviewPage';
import DownloadApp from '@/ui/pages/downloadAppPage/DownloadAppPage';
import EditChangeProposalPage from '@/ui/pages/editChangeProposalPage/EditChangeProposalPage';
import EditInvoicePage from '@/ui/pages/editInvoicePage/EditInvoicePage';
import EditIsolatedPurchaseOrderPage from '@/ui/pages/editIsolatedPurchaseOrderPage/EditIsolatedPurchaseOrderPage';
import EditProtoInvoicePage from '@/ui/pages/editProtoInvoicePage/EditProtoInvoicePage';
import EnableCustomerPayments from '@/ui/pages/enableCustomerPaymentsPage/EnableCustomerPaymentsPage';
import FormCreatePage from '@/ui/pages/formPage/FormCreatePage';
import FormPage from '@/ui/pages/formPage/FormPage';
import GrantAccess from '@/ui/pages/grantAccessPage/GrantAccessPage';
import InvoicePage from '@/ui/pages/invoicePage/InvoicePage';
import { InvoicesPage } from '@/ui/pages/invoicesPage/InvoicesPage';
import Customers from '@/ui/pages/listedCustomersPage/ListedCustomersPage';
import DealsPage from '@/ui/pages/listedDealsPage/ListedDealsPage';
import ItemGroups from '@/ui/pages/listedItemGroupsPage/ListedItemGroupsPage';
import Items from '@/ui/pages/listedItemsPage/ListedItemsPage';
import ListedMaterialsPage from '@/ui/pages/listedMaterialsPage/ListedMaterialsPage';
import ListedSuppliersPage from '@/ui/pages/listedSuppliersPage/ListedSuppliersPage';
import ListedTasksPage from '@/ui/pages/listedTasksPage/ListedTasksPage';
import ListedTemplatesPage from '@/ui/pages/listedTemplatesPage/ListedTemplatesPage';
import Users from '@/ui/pages/listedUsersPage/ListedUsersPage';
import LoginOAuth from '@/ui/pages/loginOAuthPage/LoginOAuthPage';
import ManagePipelinePage from '@/ui/pages/managePipelinePage/ManagePipelinePage';
import NewEditInvoicePage from '@/ui/pages/newEditInvoicePage/NewEditInvoicePage';
import NewEditItemGroup from '@/ui/pages/newEditItemGroupPage/NewEditItemGroupPage';
import NewEditQuote from '@/ui/pages/newEditQuotePage/NewEditQuotePage';
import NewDiscountCodePage from '@/ui/pages/newServicePlanDiscountCode/NewDiscountCodePage';
import PayacaPagesPage from '@/ui/pages/payacaPagesPage/PayacaPagesPage';
import PersonalSettings from '@/ui/pages/personalSettingsPage/PersonalSettingsPage';
import PurchaseOrderPage from '@/ui/pages/purchaseOrderPage/PurchaseOrderPage';
import ResetPassword from '@/ui/pages/resetPasswordPage/ResetPasswordPage';
import ScheduleDispatchPage from '@/ui/pages/scheduleDispatchPage/ScheduleDispatchPage';
import ServicePlanPage from '@/ui/pages/servicePlanPage/ServicePlanPage';
import ServicePlanPeriodPage from '@/ui/pages/servicePlanPeriodPage/ServicePlanPeriodPage';
import ServicePlansCustomerReminders from '@/ui/pages/servicePlansCustomerReminders/ServicePlansCustomerReminders';
import ServicePlansPage from '@/ui/pages/servicePlansPage/ServicePlansPage';
import SetupBusinessPage from '@/ui/pages/setupBusinessPage/SetupBusinessPage';
import SetupUserPage from '@/ui/pages/setupUserPage/SetupUserPage';
import SupplierPage from '@/ui/pages/supplierPage/SupplierPage';
import { TagsPage } from '@/ui/pages/tagsPage/TagsPage';
import TimelogReportingPage from '@/ui/pages/timelogReportingPage/TimelogReportingPage';
import TrialPeriodExpiryHoldingPage from '@/ui/pages/trialPeriodExpiryHoldingPage/TrialPeriodExpiryHoldingPage';
import UserInvite from '@/ui/pages/userInvitePage/UserInvitePage';
import UserPage from '@/ui/pages/userPage/UserPage';
import VerifyUser from '@/ui/pages/verifyUserPage/VerifyUserPage';
import XeroCallback from '@/ui/pages/xeroCallbackPage/XeroCallbackPage';
import { AccountsRoles } from '@payaca/permissions/accounts/accounts.roles';
import { AnalyticsRoles } from '@payaca/permissions/analytics/analytics.roles';
import { AutomationsRoles } from '@payaca/permissions/automations/automations.roles';
import { CustomersRoles } from '@payaca/permissions/customers/customers.roles';
import { DashboardRoles } from '@payaca/permissions/dashboard/dashboard.roles';
import { DealsRoles } from '@payaca/permissions/deals/deals.roles';
import { DocumentsRoles } from '@payaca/permissions/documents/documents.roles';
import { InvoicesRoles } from '@payaca/permissions/invoices/invoices.roles';
import { JobRoles } from '@payaca/permissions/jobs/job.roles';
import { LineItemsRoles } from '@payaca/permissions/lineItems/line-items.roles';
import { MaterialsRoles } from '@payaca/permissions/materials/materials.roles';
import { MaterialsListRoles } from '@payaca/permissions/materialsList/materialsList.roles';
import { userHasRequiredRole } from '@payaca/permissions/permissions.utils';
import { PipelinesRoles } from '@payaca/permissions/pipelines/pipelines.roles';
import { ProposalsRoles } from '@payaca/permissions/proposals/proposals.roles';
import { ScheduledEventsRoles } from '@payaca/permissions/scheduledEvents/scheduled-events.roles';
import { ServicePlanRoles } from '@payaca/permissions/service-plans/service-plans.roles';
import { SuppliersRoles } from '@payaca/permissions/suppliers/suppliers.roles';
import { TasksRoles } from '@payaca/permissions/tasks/tasks.roles';
import { TemplatesRoles } from '@payaca/permissions/templates/templates.roles';
import { TimelogsRoles } from '@payaca/permissions/timelogs/timelogs.roles';
import ChildAccountPage from '../ui/pages/childAccountPage/ChildAccountPage';
import JobLineItemPage from '../ui/pages/jobLineItemPage/JobLineItemPage';
import ListedChildAccountsPage from '../ui/pages/listedChildAccountsPage/ListedChildAccountsPage';
import ManageSubscription from '../ui/pages/manageSubscriptionPage/ManageSubscriptionPage';

const devRoutes = [{ id: Routes.DEV, path: 'dev', component: DevPage }];

const setupUserRoutes = [
  { id: Routes.SETUP_USER, path: 'setupUser', component: SetupUserPage },
];

const requiresSetupAccountRoutes = [
  {
    id: Routes.SETUP_BUSINESS_PAGE,
    path: 'setupBusiness',
    component: SetupBusinessPage,
  },
];

const adminTrialPeriodExpiredRoutes = [
  {
    id: Routes.UPGRADE_ACCOUNT,
    path: 'upgradeAccount',
    component: ManageSubscription,
  },
];

const trialPeriodExpiredRoutes = [
  {
    id: Routes.UPGRADE_ACCOUNT,
    path: 'trialExpired',
    component: TrialPeriodExpiryHoldingPage,
  },
];

const adminUserSeatsExpiredRoutes = [
  {
    id: Routes.UPGRADE_ACCOUNT,
    path: 'upgradeAccount',
    component: ManageSubscription,
  },
  { id: Routes.USERS, path: 'users', component: Users },
  {
    id: Routes.PERSONAL_SETTINGS,
    path: 'personalSettings',
    component: PersonalSettings,
  },
];

const userSeatsExceededRoutes = [
  {
    id: Routes.PERSONAL_SETTINGS,
    path: 'personalSettings',
    component: PersonalSettings,
  },
];

const getParentAccountAdminRoutes = [
  {
    id: Routes.LISTED_CHILD_ACCOUNTS,
    path: 'accounts',
    component: ListedChildAccountsPage,
    requiresRole: [AccountsRoles.CHILD_ACCOUNTS_VIEWER],
  },
  {
    id: Routes.CHILD_ACCOUNT,
    path: 'accounts/:accountId',
    component: ChildAccountPage,
    exact: false,
    requiresRole: [AccountsRoles.CHILD_ACCOUNTS_VIEWER],
  },
  {
    id: Routes.USERS,
    path: 'users',
    component: Users,
    requiresRole: [AccountsRoles.DETAILED_USERS_VIEWER],
  },
  {
    id: Routes.USER,
    path: 'users/:userId',
    component: (props) => <UserPage userId={+props.match.params.userId} />,
    requiresRole: [AccountsRoles.DETAILED_USERS_VIEWER],
    exact: false,
  },
];

const getStandardRoutes = () => [
  {
    id: Routes.APPLE_CALLBACK,
    path: 'apple/callback',
    component: AppleCallback,
  },
  {
    id: Routes.XERO_CALLBACK,
    path: 'xero/callback',
    component: XeroCallback,
  },
  {
    id: Routes.VERIFY_USER,
    path: 'verify/:token',
    renderComponent: (props) => <VerifyUser {...props} isAuthed={true} />,
  },
  {
    id: Routes.ITEM_GROUPS,
    path: 'items/groups',
    component: ItemGroups,
    requiresRole: [LineItemsRoles.GROUP_VIEWER],
  },
  {
    id: Routes.EDIT_ITEM_GROUP,
    path: 'items/groups/:lineItemGroupId',
    component: NewEditItemGroup,
    requiresRole: [LineItemsRoles.GROUP_EDITOR],
  },
  {
    id: Routes.ITEMS,
    path: 'items',
    component: Items,
    exact: false,
    requiresRole: [LineItemsRoles.COLLECTION_MANAGER],
  },
  {
    id: Routes.INVOICES,
    path: 'invoices',
    component: InvoicesPage,
    requiresRole: [InvoicesRoles.LISTED_VIEWER],
  },
  {
    id: Routes.USERS,
    path: 'users',
    component: Users,
    requiresRole: [AccountsRoles.DETAILED_USERS_VIEWER],
  },
  {
    id: Routes.USER,
    path: 'users/:userId',
    component: (props) => <UserPage userId={+props.match.params.userId} />,
    requiresRole: [AccountsRoles.DETAILED_USERS_VIEWER],
    exact: false,
  },
  {
    id: Routes.TAGS,
    path: 'tags',
    component: TagsPage,
    requiresRole: [AccountsRoles.TAG_MANAGER],
  },
  {
    id: Routes.NEW_QUOTE,
    path: 'quotes/new',
    component: NewEditQuote,
    requiresRole: [JobRoles.CREATOR],
  },
  {
    id: Routes.EDIT_QUOTE,
    path: 'quotes/:quoteId',
    component: NewEditQuote,
    requiresRole: [JobRoles.EDITOR],
    exact: false,
  },
  {
    id: Routes.INVOICE,
    path: 'deals/:dealId/invoices/:invoiceId',
    component: (props) => {
      return (
        <InvoicePage
          invoiceId={+props.match.params.invoiceId}
          dealId={+props.match.params.dealId}
        />
      );
    },
    requiresRole: [
      InvoicesRoles.VIEWER,
      InvoicesRoles.ASSIGNEE_VIEWER,
      InvoicesRoles.EDITOR,
    ],
  },
  {
    id: Routes.EDIT_INVOICE_V1_LINE_ITEM,
    path: 'invoices/:invoiceId/items/:jobLineItemId',
    component: (props) => {
      return (
        <JobLineItemPage
          jobLineItemId={+props.match.params.jobLineItemId}
          jobId={+props.match.params.invoiceId}
        />
      );
    },
    requiresRole: [JobRoles.EDITOR],
  },
  {
    id: Routes.EDIT_PROTO_INVOICE,
    path: 'deals/:dealId/invoices/quick/:invoiceId/edit',
    component: (props) => {
      return (
        <EditProtoInvoicePage
          protoInvoiceId={+props.match.params.invoiceId}
          dealId={+props.match.params.dealId}
        />
      );
    },
    exact: false,
    requiresRole: [
      InvoicesRoles.VIEWER,
      InvoicesRoles.ASSIGNEE_VIEWER,
      InvoicesRoles.EDITOR,
      JobRoles.EDITOR,
      JobRoles.VIEWER,
    ],
  },
  {
    id: Routes.EDIT_INVOICE,
    path: 'deals/:dealId/invoices/:invoiceId/edit/:view?',
    component: (props) => {
      return (
        <EditInvoicePage
          invoiceId={+props.match.params.invoiceId}
          dealId={+props.match.params.dealId}
          viewType={props.match.params.view}
        />
      );
    },
    requiresRole: [
      InvoicesRoles.VIEWER,
      InvoicesRoles.ASSIGNEE_VIEWER,
      InvoicesRoles.EDITOR,
    ],
  },

  {
    id: Routes.LISTED_TASKS,
    path: 'tasks',
    component: ListedTasksPage,
    requiresRole: [TasksRoles.VIEWER, TasksRoles.ASSIGNEE_VIEWER],
    exact: false,
  },
  {
    id: Routes.LISTED_SUPPLIERS,
    path: 'suppliers',
    component: ListedSuppliersPage,
    requiresRole: [SuppliersRoles.COLLECTION_MANAGER],
  },
  {
    id: Routes.SUPPLIER,
    path: 'suppliers/:supplierId',
    component: (props) => {
      return <SupplierPage supplierId={+props.match.params.supplierId} />;
    },
    requiresRole: [SuppliersRoles.VIEWER],
    exact: false,
  },
  {
    id: Routes.LISTED_MATERIALS,
    path: 'materials',
    exact: false,
    component: ListedMaterialsPage,
    requiresRole: [MaterialsRoles.COLLECTION_MANAGER],
  },
  {
    id: Routes.CREATE_EDIT_ENTITY_TEMPLATE,
    path: 'templates/edit/:templatePublicId?',
    renderComponent: (props) => {
      return (
        <CreateEditEntityTemplatePage
          templatePublicId={props.match.params.templatePublicId}
        />
      );
    },
    requiresRole: [TemplatesRoles.PERSISTER],
  },
  {
    id: Routes.LISTED_TEMPLATES,
    path: 'templates/:tab?',
    component: ListedTemplatesPage,
    requiresRole: [TemplatesRoles.VIEWER],
  },
  {
    id: Routes.TIMELOG_REPORTING,
    path: 'timelogs',
    component: TimelogReportingPage,
    requiresRole: [TimelogsRoles.PREVIEWER],
  },
  // this is an edit page
  {
    id: Routes.EDIT_ISOLATED_PURCHASE_ORDER,
    path: 'purchaseorders/:purchaseOrderId/edit',
    component: (props) => {
      return (
        <EditIsolatedPurchaseOrderPage
          purchaseOrderId={+props.match.params.purchaseOrderId}
        />
      );
    },
    requiresRole: [MaterialsListRoles.ISOLATED_PURCHASE_ORDER_PERSISTER],
  },
  // this is a preview page
  {
    id: Routes.VIEW_PURCHASE_ORDER,
    path: 'purchaseorders/:purchaseOrderId',
    component: (props) => {
      return (
        <PurchaseOrderPage
          purchaseOrderId={+props.match.params.purchaseOrderId}
        />
      );
    },
    requiresRole: [MaterialsListRoles.PURCHASE_ORDER_VIEWER],
  },
  {
    id: Routes.EDIT_CHANGE_PROPOSAL,
    path: 'deals/:dealId/changeproposals/:changeProposalId/edit',
    component: (props) => {
      return (
        <EditChangeProposalPage
          changeProposalId={+props.match.params.changeProposalId}
          dealId={+props.match.params.dealId}
        />
      );
    },
    requiresRole: [ProposalsRoles.PERSISTER_SENDER],
    exact: false,
  },
  {
    id: Routes.CHANGE_PROPOSAL,
    path: 'deals/:dealId/changeproposals/:changeProposalId',
    component: (props) => {
      return (
        <ChangeProposalPage
          changeProposalId={+props.match.params.changeProposalId}
          dealId={+props.match.params.dealId}
        />
      );
    },
    requiresRole: [ProposalsRoles.VIEWER],
  },
  {
    id: Routes.NEW_INVOICE,
    path: 'invoices/new',
    component: NewEditInvoicePage,
    requiresRole: [JobRoles.CREATOR],
  },
  {
    id: Routes.EDIT_INVOICE_LEGACY,
    path: 'invoices/:invoiceId/:view?',
    component: NewEditInvoicePage,
    requiresRole: [JobRoles.EDITOR],
  },

  {
    id: Routes.EDIT_NEW_INVOICE,
    path: 'invoices/:invoiceId/:view?/new',
    renderComponent: (props) => <NewEditInvoicePage {...props} isNew={true} />,
    requiresRole: [JobRoles.EDITOR],
  },
  {
    id: Routes.CONNECTION_APPLICATION,
    path: 'deals/:projectId/compliance/connection-application/:connectionApplicationId',
    component: ConnectionApplication,
    requiresRole: [DealsRoles.COMPLIANCE_FORM_VIEWER],
  },
  {
    id: Routes.CUSTOMERS,
    path: 'customers',
    component: Customers,
    requiresRole: [CustomersRoles.COLLECTION_MANAGER],
  },
  {
    id: Routes.NEW_CUSTOMER,
    path: 'customers/new',
    component: () => <Customers isCreatingCustomer />,
    requiresRole: [CustomersRoles.EDITOR],
  },
  {
    id: Routes.CUSTOMER,
    path: 'customers/:customerId',
    component: CustomerPage,
    requiresRole: [CustomersRoles.VIEWER],
    exact: false,
  },
  {
    id: Routes.PERSONAL_SETTINGS,
    path: 'personalSettings/:tab?',
    component: PersonalSettings,
  },
  {
    id: Routes.COMPANY_SETTINGS,
    path: 'companySettings/:tab?',
    exact: false,
    component: CompanySettingsPage,
    requiresRole: [AccountsRoles.EDITOR],
  },
  {
    id: Routes.PAYACA_PAGES,
    path: 'pages',
    component: PayacaPagesPage,
    requiresRole: [AccountsRoles.EDITOR],
  },

  {
    id: Routes.CONNECTIONS,
    path: 'connections/:service?',
    component: Connections,
    requiresRole: [
      AccountsRoles.CONNECTION_EDITOR,
      AccountsRoles.CONNECTION_AUTOMATION_EDITOR,
      AccountsRoles.CONNECTION_ACCOUNTING_EDITOR,
      AccountsRoles.CONNECTION_PAYMENT_EDITOR,
      AccountsRoles.CONNECTION_SUPPLIERS_EDITOR,
    ],
  },
  {
    id: Routes.UPGRADE_ACCOUNT,
    path: 'upgradeAccount',
    component: ManageSubscription,
    requiresRole: [AccountsRoles.SUBSCRIPTION_EDITOR],
  },
  {
    id: Routes.EMBEDDABLE_ANALYTICS_DASHBOARD,
    path: 'analytics/embed/:identifier',
    component: EmbeddableAnalyticsDashboardPage,
    requiresRole: [AnalyticsRoles.VIEWER],
  },
  {
    id: Routes.ANALYTICS_DASHBOARD,
    path: 'analytics/:tab?',
    component: AnalyticsDashboardPage,
    requiresRole: [AnalyticsRoles.VIEWER],
  },
  {
    id: Routes.DASHBOARD,
    path: 'dashboard',
    component: DashboardPage,
    requiresRole: [DashboardRoles.VIEWER],
  },

  {
    id: Routes.ENABLE_CUSTOMER_PAYMENTS,
    path: 'enableCustomerPayments',
    component: EnableCustomerPayments,
  },
  {
    id: Routes.RESET,
    path: 'reset/:token',
    renderComponent: (props) => <ResetPassword {...props} isAuthed={true} />,
  },
  {
    id: Routes.USER_INVITE,
    path: 'invite/:token',
    renderComponent: (props) => <UserInvite {...props} isAuthed={true} />,
    requiresRole: [AccountsRoles.EDITOR],
  },
  {
    id: Routes.LOGIN_OAUTH,
    path: 'oauth',
    renderComponent: (props) => <LoginOAuth {...props} isAuthed={true} />,
  },
  { id: Routes.GRANT_ACCESS, path: 'grantAccess', component: GrantAccess },
  {
    id: Routes.DEALS,
    path: 'deals',
    component: DealsPage,
    requiresRole: [DealsRoles.VIEWER, DealsRoles.ASSIGNEE_VIEWER],
  },
  {
    id: Routes.ARCHIVED_DEALS,
    path: 'deals/archived',
    renderComponent: (props) => (
      <DealsPage {...props} showArchivedDeals={true} />
    ),
    requiresRole: [DealsRoles.VIEWER, DealsRoles.ASSIGNEE_VIEWER],
  },
  {
    id: Routes.MANAGE_PIPELINE,
    path: 'deals/pipeline/:pipelineId',
    component: (props) => {
      return <ManagePipelinePage pipelineId={+props.match.params.pipelineId} />;
    },
    requiresRole: [PipelinesRoles.EDITOR],
  },
  {
    id: Routes.DEAL_PAGE,
    path: 'deals/:dealId',
    component: (props) => {
      return <DealPage dealId={+props.match.params.dealId} />;
    },
    requiresRole: [DealsRoles.VIEWER, DealsRoles.ASSIGNEE_VIEWER],
    exact: false,
  },
  {
    id: Routes.CREATE_FORM,
    path: 'forms',
    component: FormCreatePage,
    requiresRole: [DocumentsRoles.CREATOR],
  },
  {
    id: Routes.NEW_EDIT_FORM,
    path: 'forms/:formPreviewToken/:pageIndex',
    component: FormPage,
  },
  {
    id: Routes.DOCUMENT_OVERVIEW_PAGE,
    path: 'documents/:documentId',
    component: DocumentOverviewPage,
    requiresRole: [DocumentsRoles.VIEWER],
  },
  {
    id: Routes.SCHEDULE_DISPATCH,
    path: 'schedule',
    component: ScheduleDispatchPage,
    requiresRole: [
      ScheduledEventsRoles.VIEWER,
      ScheduledEventsRoles.DEAL_ASSIGNEE_VIEWER,
      ScheduledEventsRoles.ASSIGNEE_VIEWER,
    ],
    exact: false,
  },

  {
    id: Routes.AUTOMATIONS_PAGE,
    path: 'automations',
    component: AutomationsPage,
    requiresRole: [AutomationsRoles.DEFAULT_VIEWER],
  },
  {
    id: Routes.DEFAULT_AUTOMATION_PAGE,
    path: 'automations/default/:defaultAutomationDefinitionPublicId',
    component: (props) => {
      return (
        <DefaultAutomationPage
          defaultAutomationDefinitionPublicId={
            props.match.params.defaultAutomationDefinitionPublicId
          }
        />
      );
    },
    requiresRole: [AutomationsRoles.VIEWER, AutomationsRoles.DEFAULT_VIEWER],
  },
  {
    id: Routes.CREATE_EDIT_AUTOMATION_PAGE,
    path: 'automations/:automationId',
    component: CreateEditAutomationPage,
    requiresRole: [
      AutomationsRoles.SIMPLE_CREATOR,
      AutomationsRoles.SIMPLE_EDITOR,
      AutomationsRoles.CREATOR,
      AutomationsRoles.EDITOR,
    ],
  },
  {
    id: Routes.CSV_UPLOADS,
    path: 'csv-uploads',
    component: CsvUploadsPage,
    requiresRole: [
      CustomersRoles.CREATOR,
      LineItemsRoles.CREATOR,
      MaterialsRoles.CREATOR,
      ScheduledEventsRoles.CREATOR,
      SuppliersRoles.CREATOR,
    ],
  },
  {
    id: Routes.SERVICE_PLAN_PERIOD,
    path: 'service-plans/service-period/:servicePlanPeriodId',
    component: (props) => {
      return (
        <ServicePlanPeriodPage
          servicePlanPeriodId={props.match.params.servicePlanPeriodId}
        />
      );
    },
    requiresRole: [ServicePlanRoles.VIEWER],
  },
  {
    id: Routes.SERVICE_PLANS,
    path: 'service-plans/:tab?',
    component: ServicePlansPage,
    requiresRole: [ServicePlanRoles.PREVIEWER],
  },

  {
    id: Routes.SERVICE_PLAN,
    path: 'service-plans/service-plan/new',
    component: ServicePlanPage,
    requiresRole: [ServicePlanRoles.PERSISTER],
  },
  {
    id: Routes.SERVICE_PLAN,
    path: 'service-plans/service-plan/:servicePlanId',
    component: ServicePlanPage,
    requiresRole: [ServicePlanRoles.VIEWER],
  },
  {
    id: Routes.DISCOUNT_CODES,
    path: 'service-plans/discount-codes/new',
    component: NewDiscountCodePage,
    requiresRole: [ServicePlanRoles.DISCOUNT_MANAGER],
  },
  {
    id: Routes.CUSTOMER_REMINDERS,
    path: 'service-plans/customer-reminders/:customerReminderId',
    component: ServicePlansCustomerReminders,
    requiresRole: [ServicePlanRoles.PERSISTER],
  },
];

const sharedRouteConfig = [
  {
    id: Routes.CLEAR_CACHE,
    path: '_clear-cache',
    component: ClearCachePage,
  },
];

const getUsersPrivateRoutes = ({
  userRoles,
  canUpdateSubscription,
  userHasContactNumber,
  accountIsSetup,
  trialPeriodIsExpired,
  hasExceededUserSeats,
  hasBetaFeatures,
  showParentAccountAdminRoutes,
}) => {
  let routeConfig;
  switch (true) {
    case !userHasContactNumber:
      routeConfig = setupUserRoutes;
      break;
    case !accountIsSetup:
      routeConfig = requiresSetupAccountRoutes;
      break;
    case trialPeriodIsExpired:
      routeConfig = canUpdateSubscription
        ? adminTrialPeriodExpiredRoutes
        : trialPeriodExpiredRoutes;
      break;
    case hasExceededUserSeats:
      routeConfig = canUpdateSubscription
        ? adminUserSeatsExpiredRoutes
        : userSeatsExceededRoutes;
      break;
    case showParentAccountAdminRoutes:
      routeConfig = getParentAccountAdminRoutes;
      break;
    default:
      routeConfig = getStandardRoutes({
        hasBetaFeatures,
      });
      break;
  }
  routeConfig = [...routeConfig, ...sharedRouteConfig];
  return routeConfig
    .filter((route) => {
      // is switched off via feature flag?
      if (typeof route.featureFlag === 'boolean') {
        return route.featureFlag;
      }
      // requires a role to be visible?
      if (route.requiresRole)
        return userHasRequiredRole(userRoles, route.requiresRole);
      // no conditions applied - include
      return true;
    })
    .reduce((acc, route) => {
      acc[route.id] = route;
      return acc;
    }, {});
};

const getUsersPrivateRedirects = ({
  userRoles,
  canUpdateSubscription,
  isMobile,
  accountIsSetup,
  hasExceededUserSeats,
  trialPeriodIsExpired,
  userHasContactNumber,
  isFormsRoute,
  showParentAccountAdminRoutes,
}) => {
  switch (true) {
    case isMobile && !isFormsRoute:
      return {
        TO_DOWNLOAD_APP: {
          fromPaths: ['*'],
          toPath: '/',
          component: DownloadApp,
        },
      };
    case !userHasContactNumber:
      return {
        TO_SETUP_USER: {
          fromPaths: ['*'],
          toPath: 'setupUser',
        },
      };
    case !accountIsSetup:
      return {
        TO_SETUP_ACCOUNT_PAGE: {
          fromPaths: ['*'],
          toPath: `setupBusiness${window.location.search}`,
        },
      };
    case trialPeriodIsExpired:
      return canUpdateSubscription
        ? {
            TO_UPGRADE_ACCOUNT: {
              fromPaths: ['*'],
              toPath: 'upgradeAccount',
            },
          }
        : {
            TO_TRIAL_EXPIRED: {
              fromPaths: ['*'],
              toPath: 'trialExpired',
            },
          };
    case hasExceededUserSeats:
      return canUpdateSubscription
        ? {
            TO_UPGRADE_ACCOUNT: {
              fromPaths: ['*'],
              toPath: 'upgradeAccount',
            },
          }
        : {
            TO_TRIAL_EXPIRED: {
              fromPaths: ['*'],
              toPath: 'resources',
            },
          };
    case showParentAccountAdminRoutes: {
      const standardRoutePaths = getStandardRoutes().map((route) => route.path);
      return {
        TO_CHILD_ACCOUNTS: {
          fromPaths: [
            '',
            'setupUser',
            ...standardRoutePaths,
            ...standardRoutePaths.map((x) => `${x}/*`),
          ],
          toPath: 'accounts',
        },
        TO_404: {
          fromPaths: ['*'],
          toPath: '404',
          component: FourOFour,
        },
      };
    }
    default: {
      const canViewDashboard = userHasRequiredRole(userRoles, [
        DashboardRoles.VIEWER,
      ]);

      const canViewDeals = userHasRequiredRole(userRoles, [
        DealsRoles.VIEWER,
        DealsRoles.ASSIGNEE_VIEWER,
      ]);

      return {
        TO_ROOT_PAGE: {
          fromPaths: [
            '',
            'login',
            'register',
            'verifyHolding',
            'quotes',
            'invoices',
            'nonAdminMobileRedirect',
            'setupUser',
            'accounts',
            'accounts/*',
          ],
          toPath: canViewDashboard
            ? 'dashboard'
            : canViewDeals
              ? 'deals'
              : 'schedule',
        },
        TO_404: {
          fromPaths: ['*'],
          toPath: '404',
          component: FourOFour,
        },
      };
    }
  }
};

export { getUsersPrivateRedirects, getUsersPrivateRoutes };
