import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';

const GetProjectAccountingIntegrationCategories = graphql(`
  query GetProjectAccountingIntegrationCategories($projectId: Int!) {
    project(id: $projectId) {
      accountingIntegrationCategories {
        quickbooks {
          categoryIdentifier
          optionIdentifier
        }
        xero {
          categoryIdentifier
          optionIdentifier
        }
      }
    }
  }
`);

const useGetProjectAccountingIntegrationCategories = (projectId?: number) => {
  const { data, ...rest } = useQuery({
    enabled: !!projectId,
    queryKey: projectKeys.accountingIntegrationCategories(projectId!),
    queryFn: () => {
      return gqlClient.request(GetProjectAccountingIntegrationCategories, {
        projectId: projectId!,
      });
    },
  });

  return {
    accountingIntegrationCategories:
      data?.project.accountingIntegrationCategories,
    data,
    ...rest,
  };
};

export default useGetProjectAccountingIntegrationCategories;
