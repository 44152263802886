import Conditional from '@payaca/components/conditional/Conditional';
import List from '@payaca/components/list/List';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import Card from '@payaca/components/plCard/Card';
import { clstx } from '@payaca/components/utils';
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { Currency } from '@payaca/types/currencyTypes';
import { format, isSameDay, isToday, isTomorrow } from 'date-fns';
import { isEqual } from 'lodash-es';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import useGetMyRegionPreferences from '../../../../api/queries/me/useGetMyRegionPreferences';
import { humanizeCurrency } from '../../../../helpers/currency';
import MaterialCard, {
  MaterialCardProps,
} from '../../materialCard/MaterialCard';
import { FormStageStage, TFormState } from '../CreatePurchaseOrderSidebar';

const getReadableDateTime = (d: Date, locale?: string) => {
  const time = format(d, 'HH:mm');
  if (isToday(d)) {
    return `today ${time}`;
  } else if (isTomorrow(d)) {
    return `tomorrow ${time}`;
  } else {
    return `${format(
      d,
      getInternationalDateFormatByLocale(
        DateFormats.TIMESTAMP_DAY_MONTH,
        locale
      )
    )}`;
  }
};

type MaterialsTotal = {
  exTax: { value: number; currency?: Currency };
  incTax: { value: number; currency?: Currency };
};
const getCalculateMaterialsForSupplierTotal = (
  materials: TFormState['materials'],
  supplierId: TFormState['supplierId']
): MaterialsTotal | { error: string } => {
  const result: MaterialsTotal = {
    exTax: { value: 0, currency: undefined },
    incTax: { value: 0, currency: undefined },
  };
  let error;

  for (let i = 0; i < materials.length; i++) {
    const curr = materials[i];

    if (!curr.purchaseOrderQuantity) continue;

    const supplierMaterial =
      curr.materialsListMaterial.material.suppliedBy.find(
        (s) => +s.supplier.id === supplierId
      );

    if (supplierMaterial?.price.unitPriceExcTax) {
      result.exTax.value +=
        supplierMaterial.price.unitPriceExcTax.value *
        curr.purchaseOrderQuantity;

      if (
        (result.exTax.currency &&
          !isEqual(
            result.exTax.currency,
            supplierMaterial.price.unitPriceExcTax.currency
          )) ||
        (result.incTax.currency &&
          !isEqual(
            result.incTax.currency,
            supplierMaterial.price.unitPrice.currency
          ))
      ) {
        error = 'Materials have different currencies';
        break;
      } else {
        result.exTax.currency = supplierMaterial.price.unitPriceExcTax.currency;
      }
    }

    if (supplierMaterial?.price.unitPrice) {
      result.incTax.value +=
        supplierMaterial.price.unitPrice.value * curr.purchaseOrderQuantity;
      result.incTax.currency = supplierMaterial.price.unitPrice.currency;
    }
  }
  if (error) {
    console.error(error);
    return { error };
  }

  return result;
};

export interface IProps {
  formStage: FormStageStage;
  projectId: number;
  isSaving: boolean;
}
const DirectPurchaseOrderSummary: FC<IProps> = (props) => {
  const { formStage, isSaving } = props;
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const formMethods = useFormContext<TFormState>();

  const selectedFulfillmentOptionId = formMethods.watch('fulfillmentOptionId');
  const selectedMaterials = formMethods.watch('materials');
  const selectedSupplierId = formMethods.watch('supplierId');
  const selectedCollection = formMethods.watch('collection');
  const selectedDelivery = formMethods.watch('delivery');
  const fromDate = formMethods.watch('fromDate');
  const toDate = formMethods.watch('toDate');

  const selectedMaterialsTotals = useMemo(() => {
    return getCalculateMaterialsForSupplierTotal(
      selectedMaterials,
      selectedSupplierId
    );
  }, [selectedMaterials, selectedSupplierId]);

  const materialsOnOrder = selectedMaterials.filter(
    (m) => m.purchaseOrderQuantity > 0
  );

  const isCollection = !!selectedCollection?.branchId;

  const readableFromDate = fromDate
    ? getReadableDateTime(fromDate, regionPreferences?.locale)
    : null;

  const getDeliveryFromToDateTimes = (fromDate: Date, toDate: Date) => {
    const isFomAndToDateSameDay = isSameDay(fromDate, toDate);
    if (isFomAndToDateSameDay) {
      return `${readableFromDate} - ${format(toDate, 'HH:mm')}`;
    } else {
      return `${readableFromDate} - ${getReadableDateTime(
        toDate,
        regionPreferences?.locale
      )}`;
    }
  };

  const readableDeliveryDateTime = !toDate
    ? readableFromDate
    : getDeliveryFromToDateTimes(fromDate, toDate);

  return (
    <div className="z-10 overflow-auto flex flex-col h-full transition-colors duration-700 bg-gray-50 border-l">
      <div
        className={clstx(
          'p-3.5 z-10 transition-colors duration-700 sticky top-0 bg-gray-50 flex flex-col gap-2',
          scrollY > 0 && 'shadow'
        )}
      >
        <h4>Order summary</h4>
        <Conditional condition={isCollection}>
          <p>
            Collect from <strong>{readableFromDate}</strong> at{' '}
            <strong>{selectedCollection?.branchName}</strong>
          </p>
        </Conditional>
        <Conditional condition={!isCollection}>
          <p>
            Expected delivery <strong>{readableDeliveryDateTime}</strong> to{' '}
            <strong>{selectedDelivery?.fullLocalAddress}</strong>
          </p>
        </Conditional>
      </div>

      {/* materials on order */}
      <List
        className="px-3.5"
        items={materialsOnOrder}
        item={(material) => {
          let materialCardProps: MaterialCardProps = {
            name: material.materialsListMaterial.material.name,
            price:
              material.materialsListMaterial.material.suppliedBy[0]?.price
                .unitPriceExcTax,
            thumbnailUrl:
              material.materialsListMaterial.material.image?.thumbnailUrl ||
              undefined,
          };
          const suppliedBy =
            material.materialsListMaterial.material.suppliedBy.find(
              (s) => +s.supplier.id === selectedSupplierId
            );
          const unitPriceExTax = suppliedBy?.price.unitPriceExcTax;

          if (formStage === 'MATERIAL_LIST_MATERIALS') {
            materialCardProps = {
              ...materialCardProps,
              quantity: material.selectedQuantity,
              maxQuantity: material.materialQuantity,
              onChangeQuantity: (newQuantity) => {
                if (
                  newQuantity > material.materialQuantity ||
                  newQuantity < 0
                ) {
                  return;
                }

                formMethods.setValue(
                  'materials',
                  selectedMaterials.map((selectedMaterial) => {
                    return selectedMaterial.id === material.id
                      ? {
                          ...selectedMaterial,
                          selectedQuantity: newQuantity,
                          purchaseOrderQuantity: newQuantity,
                        }
                      : selectedMaterial;
                  })
                );
              },
            };
          }

          return (
            <MaterialCard
              key={material.id}
              childrenClassName={'w-full flex justify-end'}
              {...materialCardProps}
            >
              <div className="flex flex-col items-end">
                <span>Qty: {material.purchaseOrderQuantity}</span>
                <span>
                  Total (ex tax):{' '}
                  <strong>
                    {humanizeCurrency({
                      value:
                        material.purchaseOrderQuantity *
                        (unitPriceExTax?.value || 0),
                      currency: unitPriceExTax?.currency,
                    })}
                  </strong>
                </span>
              </div>
            </MaterialCard>
          );
        }}
      />

      <div className="p-3.5 z-10 transition-colors duration-700 sticky bottom-0 shadow bg-gray-50 mt-auto">
        <Card className="mb-4">
          <Card.Body>
            {'error' in selectedMaterialsTotals ? (
              <Alert colour={EAlertColour.SOFT_RED} className="mt-4">
                Unable to get totals - {selectedMaterialsTotals.error}
              </Alert>
            ) : (
              <>
                <p className="text-right">
                  Total (ex tax):{' '}
                  <strong>
                    {humanizeCurrency({
                      value: selectedMaterialsTotals.exTax.value,
                      currency: selectedMaterialsTotals.exTax.currency,
                    })}
                  </strong>
                </p>
                <p className="text-right">
                  Total:{' '}
                  <strong>
                    {humanizeCurrency({
                      value: selectedMaterialsTotals.incTax.value,
                      currency: selectedMaterialsTotals.incTax.currency,
                    })}
                  </strong>
                </p>
              </>
            )}
          </Card.Body>
        </Card>
        <Button
          className="w-full"
          type="submit"
          disabled={
            !selectedFulfillmentOptionId ||
            isSaving ||
            'error' in selectedMaterialsTotals
          }
          isProcessing={isSaving}
        >
          Submit order
        </Button>
      </div>
    </div>
  );
};

export default DirectPurchaseOrderSummary;
