import {
  differenceInHours,
  endOfDay,
  isWithinInterval,
  startOfDay,
} from 'date-fns';

export const eventFallsOnDate = (
  event: {
    beginAt: string;
    endAt: string;
  },
  date: Date
) => {
  return isWithinInterval(date, {
    start: startOfDay(event.beginAt),
    end: endOfDay(event.endAt),
  });
};

/**
 * Checks if an event spans entire calendar days.
 *
 * An event is considered an "all-day" event if:
 * - It starts exactly at the beginning of a day.
 * - Its total duration (in hours) equals the number of full calendar days it covers.
 *
 * Note:
 * - Calendar days are the number of days between the start and end dates excluding the times
 *   e.g. 2024-01-01T10:00:00.000Z to 2024-01-02T09:00:00.000Z is 1 calendar day
 *   e.g. 2024-01-01T00:00:00.000Z to 2024-01-03T09:00:00.000Z is 2 calendar days
 * - This method correctly handles variations such as daylight saving time changes.
 *
 * @param event - The event to check, including its start and end times.
 * @returns True if the event is an all-day event, false otherwise.
 *
 * @example
 * // Full day event
 * eventIsAllDay({
 *   beginAt: '2024-01-01T00:00:00.000Z',
 *   endAt: '2024-01-02T00:00:00.000Z'
 * }); // returns true
 *
 * @example
 * // Not a full day event
 * eventIsAllDay({
 *   beginAt: '2024-01-01T10:00:00.000Z',
 *   endAt: '2024-01-02T09:00:00.000Z'
 * }); // returns false
 */
export const eventIsAllDay = (event: {
  beginAt: Date | string;
  endAt: Date | string;
}) => {
  const beginAt = new Date(event.beginAt);
  const endAt = new Date(event.endAt);

  // Calculate the number of hours between the start of the days for the event
  // This gives the total hours in the calendar days spanned
  const diffHours = differenceInHours(startOfDay(endAt), startOfDay(beginAt));

  return (
    // Verify the event starts at the beginning of its day
    startOfDay(beginAt).getTime() === beginAt.getTime() &&
    // Ensure the event's duration matches the full calendar days it spans
    differenceInHours(endAt, beginAt) === diffHours
  );
};
