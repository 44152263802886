import UntitledIcon from '@payaca/untitled-icons';
import React, { FC, useState } from 'react';
import Button from '../plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '../plButton/useButtonClassName';
import { useToastContext } from '../plToast/ToastContext';
import { clstx } from '../utils';

interface JSONSchema {
  type?: string | string[];
  properties?: Record<string, JSONSchema>;
  required?: string[];
  [key: string]: any; // for other metadata
}

const SchemaTreeLeaf: FC<{
  propertyName: string;
  type?: string | string[];
  recursiveKeys: string[];
}> = (props) => {
  const { propertyName, type, recursiveKeys } = props;

  const types = Array.isArray(type) ? type : [type];

  const { pushToast } = useToastContext();

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    pushToast({
      variant: 'white',
      icon: 'success',
      message: 'Copied to clipboard',
    });
  };

  const variableCopyText = `{{ ${recursiveKeys.reduce((acc, recursiveKey) => {
    if (/^[A-Za-z]+$/.test(recursiveKey)) {
      return `${acc}${acc ? '.' : ''}${recursiveKey}`;
    } else {
      return `${acc}['${recursiveKey}']`;
    }
  }, '')} }}`;

  return (
    <div
      className="grow hover:bg-gray-100 rounded-md cursor-pointer px-2"
      onClick={() => handleCopy(variableCopyText)}
    >
      <div className="flex gap-2 items-baseline">
        <span className="font-semibold">{propertyName}</span>

        <span className="supporting-body">{types.join(' or ')}</span>
      </div>

      <span className="supporting-body text-gray-500">{variableCopyText}</span>
    </div>
  );
};

interface SchemaTreeItemProps {
  propertyName: string;
  recursiveKeys: string[];
  schema: JSONSchema;
  level: number;
}

const SchemaTreeItem: React.FC<SchemaTreeItemProps> = ({
  propertyName,
  recursiveKeys,
  schema,
  level,
}) => {
  const isObjectType = Array.isArray(schema.type)
    ? schema.type.includes('object')
    : schema.type === 'object';

  const [expanded, setExpanded] = useState(false);

  const headingId = `tree-heading-${propertyName}-${level}`;
  const collapseId = `tree-collapse-${propertyName}-${level}`;

  if (isObjectType && schema.properties) {
    return (
      <div role="treeitem" aria-expanded={expanded} id={headingId}>
        <div className="flex gap-x-0.5 w-full py-0.5">
          <div>
            <Button
              size={EBtnSize.XSmall}
              variant={EBtnVariant.Ghost}
              colour={EBtnColour.Gray}
              aria-controls={collapseId}
              onClick={() => setExpanded(!expanded)}
            >
              <UntitledIcon
                className="w-4 h-4"
                name={expanded ? 'chevron-down' : 'chevron-right'}
              />
            </Button>
          </div>

          <SchemaTreeLeaf
            propertyName={propertyName}
            type={schema.type}
            recursiveKeys={recursiveKeys}
          />
        </div>

        <div
          id={collapseId}
          className={clstx(
            'w-full overflow-hidden transition-[height] duration-300',
            expanded ? '' : 'hidden'
          )}
          role="group"
          aria-labelledby={headingId}
        >
          <div
            className="ps-7 relative before:content-[''] before:absolute before:top-0 before:start-4 before:w-0.5 before:-ms-px before:h-full before:bg-gray-100 dark:before:bg-neutral-700"
            role="group"
          >
            {Object.entries(schema.properties).map(
              ([childKey, childSchema]) => (
                <SchemaTreeItem
                  key={childKey}
                  propertyName={childKey}
                  recursiveKeys={[...recursiveKeys, childKey]}
                  schema={childSchema}
                  level={level + 1}
                />
              )
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="py-0.5 ps-1.5" role="treeitem">
        <SchemaTreeLeaf
          propertyName={propertyName}
          type={schema.type}
          recursiveKeys={recursiveKeys}
        />
      </div>
    );
  }
};

interface SchemaTreeViewProps {
  schema: JSONSchema;
}

const SchemaTreeView: React.FC<SchemaTreeViewProps> = ({ schema }) => {
  return (
    <div className="prose" role="tree" aria-orientation="vertical">
      <div role="group">
        {schema.properties
          ? Object.entries(schema.properties).map(([key, childSchema]) => (
              <SchemaTreeItem
                key={key}
                propertyName={key}
                recursiveKeys={[key]}
                schema={childSchema}
                level={0}
              />
            ))
          : null}
      </div>
    </div>
  );
};

export default SchemaTreeView;
