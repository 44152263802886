import { SubscriptionLevels } from '@payaca/permissions/default-role.config';
import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import {
  AutomationActionTypes,
  AutomationEntityTypes,
  AutomationTriggerEvents,
} from '@payaca/types/automationTypes';
import { TaggableEntityType } from '@payaca/types/tagTypes';

export const automationEntityTypeSubscriptionMap: {
  [key in AutomationEntityTypes]?: SubscriptionLevels;
} = {
  ['Estimate']: SubscriptionLevels.SOLO,
  ['Quote']: SubscriptionLevels.SOLO,
  ['Proposal']: SubscriptionLevels.SOLO,
  ['Invoice']: SubscriptionLevels.SOLO,
  ['Event']: SubscriptionLevels.SOLO,
  /*[AutomationEntityTypes.DOCUMENT]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.FORM]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.DEAL]: SubscriptionLevels.TEAM,
  [AutomationEntityTypes.ITEM]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.ITEM_GROUP]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.USER]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.CUSTOMER]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.ADDRESS]: SubscriptionLevels.SOLO,
  [AutomationEntityTypes.TAG]: SubscriptionLevels.SOLO,*/
};

export const automationEntityTypeTriggerEventMap: {
  [key in AutomationEntityTypes]?: AutomationTriggerEvents[];
} = {
  ['Deal']: [
    'Created',
    'Tagged',
    'Added to pipeline',
    'Pipeline stage changed',
    'Pipeline stage changed yesterday',
    'Pipeline stage changed 2 days ago',
    'Pipeline stage changed 3 days ago',
    'Pipeline stage changed 4 days ago',
    'Pipeline stage changed 5 days ago',
    'Pipeline stage changed 6 days ago',
    'Pipeline stage changed 7 days ago',
    'Assigned',
  ],
  ['Estimate']: [
    'Created',
    'Sent',
    'Accepted',
    'Declined',
    'Expires in 28 days',
    'Expires in 21 days',
    'Expires in 14 days',
    'Expires in 7 days',
    'Expires in 3 days',
    'Expires in 1 day',
    'Expires today',
    'Expired yesterday',
    'Expired 3 days ago',
    'Expired 7 days ago',
  ],
  ['Quote']: [
    'Created',
    'Sent',
    'Accepted',
    'Declined',
    'Expires in 28 days',
    'Expires in 21 days',
    'Expires in 14 days',
    'Expires in 7 days',
    'Expires in 3 days',
    'Expires in 1 day',
    'Expires today',
    'Expired yesterday',
    'Expired 3 days ago',
    'Expired 7 days ago',
  ],
  ['Proposal']: [
    'Created',
    'Sent',
    'Accepted',
    'Declined',
    'Expires in 28 days',
    'Expires in 21 days',
    'Expires in 14 days',
    'Expires in 7 days',
    'Expires in 3 days',
    'Expires in 1 day',
    'Expires today',
    'Expired yesterday',
    'Expired 3 days ago',
    'Expired 7 days ago',
  ],
  ['Invoice']: [
    'Created',
    'Sent',
    'Assigned',
    'Due in 7 days',
    'Due in 3 days',
    'Due in 1 day',
    'Due today',
    'Due yesterday',
    'Due 3 days ago',
    'Due 7 days ago',
    'Fully Paid',
  ],
  ['Event']: [
    'Created',
    'Tagged',
    'Task status changed',
    'Starts in 1 month',
    'Starts in 21 days',
    'Starts in 14 days',
    'Starts in 7 days',
    'Starts in 3 days',
    'Starts in 1 day',
    'Starts today',
    'Starts now',
    'Finishes now',
    'Finishes today',
    'Finished yesterday',
    'Finished 3 days ago',
    'Finished 7 days ago',
    'Finished 1 month ago',
    'Finished 11 months ago',
    'Finished 1 year ago',
  ],
};

export const automationEntityActionTypeMap: {
  [key in AutomationEntityTypes]?: AutomationActionTypes[];
} = {
  ['Deal']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create repeat Project',
    'Create Project Task',
  ],
  ['Event']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create Project Task',
  ],
  ['Estimate']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create Project Task',
  ],
  ['Quote']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create Project Task',
  ],
  ['Proposal']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create Project Task',
  ],
  ['Invoice']: [
    'E-mail Notification',
    'SMS Notification',
    'Deal Progression',
    'Create Project Task',
  ],
};

export const automationRequiredDataAccessTypesForEntityTypeMap: {
  [key in AutomationEntityTypes]?: string[];
} = {};

export const automationRealtimeTriggers: AutomationTriggerEvents[] = [
  'Starts in 1 hour',
  'Starts now',
  'Finishes now',
];

export const automationDailyTriggers: AutomationTriggerEvents[] = [
  'Due in 7 days',
  'Due in 3 days',
  'Due in 1 day',
  'Due today',
  'Due yesterday',
  'Due 3 days ago',
  'Due 7 days ago',
  'Expires in 28 days',
  'Expires in 21 days',
  'Expires in 14 days',
  'Expires in 7 days',
  'Expires in 3 days',
  'Expires in 1 day',
  'Expires today',
  'Expired yesterday',
  'Expired 3 days ago',
  'Expired 7 days ago',
  'Starts in 21 days',
  'Starts in 14 days',
  'Starts in 7 days',
  'Starts in 3 days',
  'Starts in 1 day',
  'Starts today',
  'Finishes today',
  'Finished yesterday',
  'Finished 3 days ago',
  'Finished 7 days ago',
];

export const automationTaggableEntityTypeMap: {
  [key in TaggableEntityType]?: AutomationEntityTypes;
} = {
  [TaggableEntityType.SCHEDULED_EVENT]: 'Event',
  [TaggableEntityType.DEAL]: 'Deal',
};

export const automationSoloTriggerEvents = [
  'Due in 3 days',
  'Due today',
  'Due 3 days ago',
  'Due 7 days ago',
];

export const automationRealtimeTriggerQueryMap: {
  [key in AutomationTriggerEvents]?: [string, number, string];
} = {
  ['Due in 7 days']: ['invoice.dueAt', 7, 'days'],
  ['Due in 3 days']: ['invoice.dueAt', 3, 'days'],
  ['Due in 1 day']: ['invoice.dueAt', 1, 'days'],
  ['Due today']: ['invoice.dueAt', 0, 'days'],
  ['Due yesterday']: ['invoice.dueAt', -1, 'days'],
  ['Due 3 days ago']: ['invoice.dueAt', -3, 'days'],
  ['Due 7 days ago']: ['invoice.dueAt', -7, 'days'],
  ['Expires in 28 days']: ['proposal.validUntil', 28, 'days'],
  ['Expires in 21 days']: ['proposal.validUntil', 21, 'days'],
  ['Expires in 14 days']: ['proposal.validUntil', 14, 'days'],
  ['Expires in 7 days']: ['proposal.validUntil', 7, 'days'],
  ['Expires in 3 days']: ['proposal.validUntil', 3, 'days'],
  ['Expires in 1 day']: ['proposal.validUntil', 1, 'days'],
  ['Expires today']: ['proposal.validUntil', 0, 'days'],
  ['Expired yesterday']: ['proposal.validUntil', -1, 'days'],
  ['Expired 3 days ago']: ['proposal.validUntil', -3, 'days'],
  ['Expired 7 days ago']: ['proposal.validUntil', -7, 'days'],
  ['Starts in 1 month']: ['event.beginAt', 1, 'month'],
  ['Starts in 21 days']: ['event.beginAt', 21, 'days'],
  ['Starts in 14 days']: ['event.beginAt', 14, 'days'],
  ['Starts in 7 days']: ['event.beginAt', 7, 'days'],
  ['Starts in 3 days']: ['event.beginAt', 3, 'days'],
  ['Starts in 1 day']: ['event.beginAt', 1, 'days'],
  ['Starts in 1 hour']: ['event.beginAt', 1, 'hours'],
  ['Starts now']: ['event.beginAt', 0, 'hours'],
  ['Starts today']: ['event.beginAt', 0, 'days'],
  ['Finishes now']: ['event.endAt', 0, 'days'],
  ['Finishes today']: ['event.endAt', 0, 'days'],
  ['Finished yesterday']: ['event.endAt', -1, 'days'],
  ['Finished 3 days ago']: ['event.endAt', -3, 'days'],
  ['Finished 7 days ago']: ['event.endAt', -7, 'days'],
  ['Finished 1 month ago']: ['event.endAt', -1, 'month'],
  ['Finished 1 year ago']: ['event.endAt', -1, 'year'],
};

export const automationDataAccessDemoDataMap: {
  [key in AutomationDataAccessType]: string;
} = {
  ['customer.name']: 'John Doe',

  ['primaryContact.name']: 'Jane Smith',
  ['primaryContact.emailAddress']: 'jane.smith@example.com',
  ['primaryContact.telephoneNumber']: '+1234567890',

  ['account.companyName']: 'Doe Enterprises Ltd',
  ['account.vatNumber']: 'GB123456789',
  ['account.accountName']: 'Doe Main Account',
  ['account.accountNumber']: '12345678',
  ['account.sortCode']: '12-34-56',
  ['account.gasSafeRegNo']: 'GS123456',
  ['account.niceicRegNo']: 'NIC123456',
  ['account.email']: 'info@doeenterprises.com',
  ['account.address']: '123 Business Rd',
  ['account.city']: 'London',
  ['account.postcode']: 'EC1A 1BB',
  ['account.contactNumber']: '+441234567890',
  ['account.paymentTerms']: '30 days',
  ['account.businessNotes']: 'Top priority customer',
  ['account.smsSenderHandle']: 'DoeLtd',
  ['account.region']: 'Greater London',

  ['deal.tags.X.tagText']: 'HighPriority',
  ['deal.not.tags.X.tagText']: 'LowPriority',
  ['deal.reference']: 'PROJECT12345',
  ['deal.description']: 'New Project for Q1',
  ['deal.createdAt']: '2025-02-01T10:00:00Z',
  ['deal.pipelineStage']: 'Proposal Sent',
  ['deal.pipelineId']: 'PIPE123',
  ['deal.siteaddresses.arraystring']: '45 King Street, Manchester',
  ['deal.siteaddresses.contacts.emailaddress.array']:
    'site.manager@example.com',
  ['deal.siteaddresses.contacts.telephonenumber.array']: '+441234567891',
  ['project.customField.value']: 'Custom Field Value Example',

  ['user.firstname']: 'Alice',
  ['user.lastname']: 'Brown',
  ['user.email']: 'alice.brown@example.com',
  ['user.systemRole']: 'Admin',
  ['user.gasSafeIdCardNumber']: 'GSID123456',
  ['user.contactNumber']: '+441234567892',

  ['event.tags.X.tagText']: 'Meeting',
  ['event.assignedusers.emailaddress.array']: 'assigned.user@example.com',
  ['event.assignedusers.emailaddress.arraystring']: 'assigned.user@example.com',
  ['event.assignedusers.name.arraystring']: 'John Johnson',
  ['event.assignedusers.telephonenumber.array']: '+441234567893',
  ['event.name']: 'Project Kickoff',
  ['event.description']: 'Initial meeting for project',
  ['event.beginAt']: '2025-02-11T09:00:00Z',
  ['event.createdBy.fullName']: 'Alice Brown',
  ['event.beginAt.formattedDate']: '11-Feb-2025',
  ['event.beginAt.formattedTime']: '09:00 AM',
  ['event.endAt']: '2025-02-11T10:00:00Z',
  ['event.endAt.formattedDate']: '11-Feb-2025',
  ['event.endAt.formattedTime']: '10:00 AM',
  ['event.location.address.string']: '123 Event St, Birmingham',
  ['event.not.tags.X.tagText']: 'Cancelled',
  ['event.taskStatus']: 'Pending',
  ['event.location.contacts.emailaddress.array']:
    'location.contact@example.com',
  ['event.location.contacts.telephonenumber.array']: '+441234567894',

  ['invoice.readableStatus']: 'Paid',
  ['invoice.reference']: 'INV12345',
  ['invoice.amountDue']: '£500.00',
  ['invoice.dueAt']: '2025-02-20T00:00:00Z',
  ['invoice.dueAt.formattedDate']: '20-Feb-2025',
  ['invoice.viewLink']: 'https://example.com/invoice/INV12345',
  ['invoice.viewButton']: 'View Invoice',
  ['invoice.sentAt']: '2025-02-10T08:00:00Z',
  ['invoice.sentAt.formattedDate']: '10-Feb-2025',
  ['invoice.viewedAt']: '2025-02-11T09:30:00Z',
  ['invoice.viewedAt.formattedDate']: '11-Feb-2025',
  ['invoice.createdAt']: '2025-02-09T15:00:00Z',
  ['invoice.createdAt.formattedDate']: '09-Feb-2025',

  ['proposal.readableStatus']: 'Accepted',
  ['proposal.reference']: 'PROP12345',
  ['proposal.validUntil']: '2025-03-01T00:00:00Z',
  ['proposal.viewLink']: 'https://example.com/proposal/PROP12345',
  ['proposal.validUntil.formattedDate']: '01-Mar-2025',
  ['proposal.viewButton']: 'View Proposal',
  ['proposal.sentAt']: '2025-02-10T08:30:00Z',
  ['proposal.sentAt.formattedDate']: '10-Feb-2025',
  ['proposal.viewedAt']: '2025-02-11T09:45:00Z',
  ['proposal.viewedAt.formattedDate']: '11-Feb-2025',
  ['proposal.acceptedAt']: '2025-02-12T10:00:00Z',
  ['proposal.acceptedAt.formattedDate']: '12-Feb-2025',
  ['proposal.acceptedAt.formattedTime']: '10:00 AM',
  ['proposal.createdAt']: '2025-02-09T15:30:00Z',
  ['proposal.createdAt.formattedDate']: '09-Feb-2025',

  ['trigger.X.tagText']: 'Urgent',
  ['trigger.newPipelineStage']: 'Negotiation',
  ['trigger.oldPipelineStage']: 'Lead',

  ['trigger.triggeredAt.dayOfWeek']: 'Monday',

  ['customTelephoneNumber']: '+441234567895',
  ['customEmailAddress']: 'custom.contact@example.com',
};

export const automationDataAccessTypeReadableNameMap: {
  [key in AutomationDataAccessType]: string;
} = {
  ['invoice.dueAt']: 'Invoice due at',
  ['proposal.validUntil']: 'Proposal valid until',
  ['event.beginAt']: 'Event begin at',
  ['event.createdBy.fullName']: 'Event created by',
  ['event.endAt']: 'Event end at',

  ['customer.name']: 'Customer name',
  ['primaryContact.name']: 'Customer contact name',
  ['primaryContact.emailAddress']: 'Customer contact email',
  ['primaryContact.telephoneNumber']: 'Customer contact phone number',

  ['account.companyName']: 'My company name',
  ['account.vatNumber']: 'My VAT number',
  ['account.accountName']: 'My bank account name',
  ['account.accountNumber']: 'My bank account number',
  ['account.sortCode']: 'My bank sort code',
  ['account.gasSafeRegNo']: 'My Gas Safe reg number',
  ['account.niceicRegNo']: 'My NICEIC reg number',
  ['account.email']: 'My company email',
  ['account.contactNumber']: 'My company phone number',
  ['account.paymentTerms']: 'My payment terms',
  ['account.businessNotes']: 'My business notes',

  ['deal.reference']: 'Project ref',
  ['deal.description']: 'Project description',
  ['deal.createdAt']: 'Project created at',
  ['deal.pipelineStage']: 'Project pipeline stage',
  ['project.customField.value']: 'Project custom field',

  ['user.firstname']: 'User firstname',
  ['user.lastname']: 'User lastname',
  ['user.email']: 'User email',
  ['user.systemRole']: 'User system role',
  ['user.contactNumber']: 'User phone number',

  ['event.name']: 'Event name',
  ['event.description']: 'Event description',
  ['event.beginAt.formattedDate']: 'Event begin at date',
  ['event.beginAt.formattedTime']: 'Event begin at time',
  ['event.endAt.formattedDate']: 'Event end at date',
  ['event.endAt.formattedTime']: 'Event end at time',
  ['event.location.address.string']: 'Event location',
  ['event.taskStatus']: 'Event task status',
  ['event.assignedusers.name.arraystring']: 'Event assigned User names',
  ['event.location.contacts.emailaddress.array']:
    'Event location contact emails',

  ['deal.siteaddresses.arraystring']: 'Project site address(es)',

  ['invoice.readableStatus']: 'Invoice status',
  ['invoice.reference']: 'Invoice ref',
  ['invoice.amountDue']: 'Invoice amount due',
  ['invoice.dueAt.formattedDate']: 'Invoice due at date',
  ['invoice.viewLink']: 'Invoice view link',
  ['invoice.viewButton']: 'Invoice view button',
  ['invoice.sentAt.formattedDate']: 'Invoice sent at date',
  ['invoice.viewedAt.formattedDate']: 'Invoice viewed at date',
  ['invoice.createdAt.formattedDate']: 'Invoice created at date',

  ['proposal.readableStatus']: 'Proposal status',
  ['proposal.reference']: 'Proposal ref',
  ['proposal.validUntil.formattedDate']: 'Proposal valid until date',
  ['proposal.viewLink']: 'Proposal view link',
  ['proposal.viewButton']: 'Proposal view button',
  ['proposal.sentAt.formattedDate']: 'Proposal sent at date',
  ['proposal.viewedAt.formattedDate']: 'Proposal viewed at date',
  ['proposal.createdAt.formattedDate']: 'Proposal created at date',
  ['proposal.acceptedAt.formattedDate']: 'Proposal accepted at date',
  ['proposal.acceptedAt.formattedTime']: 'Proposal accepted at time',

  ['account.address']: 'My company address',
  ['account.city']: 'My company city',
  ['account.postcode']: 'My company postcode',
  ['account.smsSenderHandle']: 'My SMS sender handle',
  ['account.region']: 'My company region',

  ['deal.tags.X.tagText']: 'Project tags',
  ['deal.not.tags.X.tagText']: 'Project removed tags',
  ['deal.pipelineId']: 'Project pipeline ID',
  ['deal.siteaddresses.contacts.emailaddress.array']:
    'Project site address contact emails',
  ['deal.siteaddresses.contacts.telephonenumber.array']:
    'Project site address contact phone numbers',

  ['user.gasSafeIdCardNumber']: 'User Gas Safe ID card number',

  ['event.tags.X.tagText']: 'Event tags',
  ['event.assignedusers.emailaddress.array']: 'Event assigned user emails',
  ['event.assignedusers.emailaddress.arraystring']:
    'Event assigned user emails (string)',
  ['event.assignedusers.telephonenumber.array']:
    'Event assigned user phone numbers',
  ['event.not.tags.X.tagText']: 'Event removed tags',
  ['event.location.contacts.telephonenumber.array']:
    'Event location contact phone numbers',

  ['invoice.sentAt']: 'Invoice sent at',
  ['invoice.viewedAt']: 'Invoice viewed at',
  ['invoice.createdAt']: 'Invoice created at',

  ['proposal.sentAt']: 'Proposal sent at',
  ['proposal.viewedAt']: 'Proposal viewed at',
  ['proposal.acceptedAt']: 'Proposal accepted at',
  ['proposal.createdAt']: 'Proposal created at',

  ['trigger.X.tagText']: 'Trigger tags',
  ['trigger.newPipelineStage']: 'Trigger new pipeline stage',
  ['trigger.oldPipelineStage']: 'Trigger old pipeline stage',
  ['trigger.triggeredAt.dayOfWeek']: 'Trigger day of week',

  ['customTelephoneNumber']: 'Custom phone number',
  ['customEmailAddress']: 'Custom email address',
};

export const automationEntityTypeReadableNameMap: {
  [key in AutomationEntityTypes]?: string;
} = {
  ['Invoice-v2']: 'Invoice',
  ['Deal']: 'Project',
};

export const automationActionTypeReadableNameMap: {
  [key in AutomationActionTypes]?: string;
} = {
  ['Deal Progression']: 'Project progression',
};
