import { Editor } from '@tiptap/react';
import { FC } from 'react';

import UntitledIcon, { TIconName } from '@payaca/untitled-icons';

interface IProps {
  editor: Editor | null;
}

const MenuBarActions: {
  label: string;
  name?: string;
  onClick: (editor: Editor) => () => void;
  disabled?: (editor: Editor) => boolean;
  icon: TIconName;
}[] = [
  {
    label: 'Bold',
    name: 'bold',
    onClick: (editor) => () => editor.chain().focus().toggleBold().run(),
    disabled: (editor) => !editor.can().chain().focus().toggleBold().run(),
    icon: 'bold-01.3',
  },
  {
    label: 'Italic',
    name: 'italic',
    onClick: (editor) => () => editor.chain().focus().toggleItalic().run(),
    disabled: (editor) => !editor.can().chain().focus().toggleItalic().run(),
    icon: 'italic-01.3',
  },
  {
    label: 'Strikethrough',
    name: 'strike',
    onClick: (editor) => () => editor.chain().focus().toggleStrike().run(),
    disabled: (editor) => !editor.can().chain().focus().toggleStrike().run(),
    icon: 'strikethrough-01.3',
  },
  {
    label: 'Bullet List',
    name: 'bulletList',
    onClick: (editor) => () => editor.chain().focus().toggleBulletList().run(),
    disabled: (editor) =>
      !editor.can().chain().focus().toggleBulletList().run(),
    icon: 'dotpoints-01.3',
  },
  {
    label: 'Ordered List',
    name: 'orderedList',
    onClick: (editor) => () => editor.chain().focus().toggleOrderedList().run(),
    disabled: (editor) =>
      !editor.can().chain().focus().toggleOrderedList().run(),
    icon: 'list-ordered',
  },
  {
    label: 'Link',
    onClick: (editor) => () => {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);

      if (url) {
        editor.commands.toggleLink({ href: url, target: '_blank' });
      }
    },
    icon: 'link-01.3',
  },
  {
    label: 'Unlink',
    onClick: (editor) => () => editor.chain().focus().unsetLink().run(),
    disabled: (editor) => !editor.isActive('link'),
    icon: 'link-broken-01.3',
  },
  {
    label: 'Undo',
    onClick: (editor) => () => editor.chain().focus().undo().run(),
    disabled: (editor) => !editor.can().chain().focus().undo().run(),
    icon: 'flip-backward.3',
  },
  {
    label: 'Redo',
    onClick: (editor) => () => editor.chain().focus().redo().run(),
    disabled: (editor) => !editor.can().chain().focus().redo().run(),
    icon: 'flip-forward.3',
  },
];

const MenuBar: FC<IProps> = (props) => {
  const { editor } = props;

  if (!editor) {
    return null;
  }

  return (
    <div className="flex gap-x-0.5 border-b border-gray-200 p-2 align-middle dark:border-neutral-700">
      {MenuBarActions.map((action, index) => (
        <button
          key={index}
          type="button"
          aria-label={action.label}
          className={
            'inline-flex h-8 w-8 cursor-pointer items-center justify-center gap-x-2 rounded-full border border-transparent text-sm font-semibold text-gray-800 hover:bg-gray-100 disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-neutral-700' +
            (action.name && editor.isActive(action.name)
              ? ' bg-gray-200'
              : ' bg-transparent')
          }
          onClick={action.onClick(editor)}
          disabled={action.disabled?.(editor)}
        >
          <UntitledIcon className="h-4 w-4 flex-shrink-0" name={action.icon} />
        </button>
      ))}
    </div>
  );
};

export default MenuBar;
