export enum AccountsRoles {
  SUBSCRIPTION_EDITOR = 'roles.accountsSubscriptionEditor',
  PERSONAL_EDITOR = 'roles.accountsPersonalEditor',
  EDITOR = 'roles.accountsEditor',
  VIEWER = 'roles.accountsViewer',

  USERS_VIEWER = 'roles.accountsUsersViewer',
  DETAILED_USERS_VIEWER = 'roles.accountsDetailedUsersViewer',
  USERS_COST_PER_HOUR_EDITOR = 'roles.accountsUsersCostPerHourEditor',
  USERS_COST_PER_HOUR_VIEWER = 'roles.accountsUsersCostPerHourViewer',
  USERS_COLOUR_EDITOR = 'roles.accountsUsersColourEditor',
  USERS_ROLE_EDITOR = 'roles.accountsUsersRoleEditor',

  CUSTOM_EMAIL_DOMAIN_EDITOR = 'roles.accountsCustomEmailDomainEditor',
  CUSTOM_EMAIL_DOMAIN_SENDER = 'roles.accountsCustomEmailDomainSender',
  CUSTOM_FIELD_GROUP_MANAGER = 'roles.accountsCustomFieldGroupManager',
  CUSTOM_FIELD_VALUE_MANAGER = 'roles.accountsCustomFieldValueManager',
  TAG_MANAGER = 'roles.accountsTagManager',

  EMAIL_TEMPLATE_VIEWER = 'roles.accountsEmailTemplateViewer',
  EMAIL_TEMPLATE_MANAGER = 'roles.accountsEmailTemplateManager',

  CHILD_ACCOUNTS_VIEWER = 'roles.accountsChildAccountsViewer',
  CHILD_ACCOUNTS_JOINER = 'roles.accountsChildAccountsJoiner',

  CONNECTION_EDITOR = 'roles.accountsConnectionEditor',
  CONNECTION_AUTOMATION_EDITOR = 'roles.accountsConnectionAutomationEditor',
  CONNECTION_ACCOUNTING_EDITOR = 'roles.accountsConnectionAccountingEditor',
  CONNECTION_PAYMENT_EDITOR = 'roles.accountsConnectionPaymentEditor',
  CONNECTION_SUPPLIERS_EDITOR = 'roles.accountsConnectionSuppliersEditor',
}
