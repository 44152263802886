import { Elements } from '@stripe/react-stripe-js';
import { FunctionComponent } from 'react';

import AddPaymentMethodForm from './AddPaymentMethodForm';

const fonts = [
  {
    cssSrc:
      'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
  },
];
const appearance = {
  variables: {
    colorText: '#263e59', // $blue-darker
    fontFamily: 'Inter',
    borderRadius: '0.571rem',
    fontSizeBase: '14px',
    spacingGridRow: '1rem',
    colorPrimary: '#263e59', // $blue-darker
    colorDanger: '#ef4444', // red-500
    focusBoxShadow:
      '0 0 0 1px var(--colorPrimary), 0 1px 1px 0 var(--colorPrimary)',
  },
  rules: {
    '.Input': {
      padding: `0.857rem 1.143rem`,
    },
    '.Input:focus': {
      borderColor: 'var(--colorPrimary)',
    },
    '.Label': {
      fontWeight: '500',
      marginBottom: '0.571rem',
      fontSize: '1rem',
    },
    '.Error': {
      fontWeight: '500',
      marginTop: '0.571rem',
      fontSize: '1rem',
    },
  },
};

type Props = {
  stripePaymentIntentClientSecret: string;
  stripePromise: any;
  submitStripeAttempted: (confirmPayment: () => Promise<void>) => Promise<void>;
  returnUrlParams?: URLSearchParams;
};

const StripePaymentControl: FunctionComponent<Props> = ({
  stripePaymentIntentClientSecret,
  stripePromise,
  submitStripeAttempted,
  returnUrlParams,
}: Props): JSX.Element => {
  return (
    <div key="pay-stripe" className={`payment-control stripe-payment-control`}>
      <Elements
        stripe={stripePromise}
        options={{
          clientSecret: stripePaymentIntentClientSecret,
          fonts,
          appearance,
        }}
      >
        <AddPaymentMethodForm
          submitStripeAttempted={submitStripeAttempted}
          returnUrlParams={returnUrlParams}
        />
      </Elements>
    </div>
  );
};

export default StripePaymentControl;
