import { Nullish } from '@payaca/utilities/types';
import { Account } from './accountTypes';
import { EntityType } from './entity-type';

export type TimelogLinkedEntityType = Extract<
  EntityType,
  'scheduledEvent' | 'task' | 'deal' | 'customer'
>;

export type TimelogAssigneeType = 'user';

export type TimelogLinkedEntity = {
  timelogId: number;
  timelogPublicId: string;
  entityType: TimelogLinkedEntityType;
  entityId: number;
  entityPublicId: string;
  entityReadableIdentifier: string;
};

export type PublicTimelogLinkedEntity = Omit<
  TimelogLinkedEntity,
  'id' | 'timelogId' | 'entityId'
>;

export type TimelogType = {
  id: number;
  publicId: string;
  type: string;
  description: string;
  accountId: Account['id'];
  category: TimelogTypeSystemCategory; // TODO: introduce string for use custom categories
};

export const TimelogTypeRequiredSystemCategories = [
  'onSite',
  'admin',
  'travelling',
] as const;
export type TimelogTypeRequiredSystemCategory =
  (typeof TimelogTypeRequiredSystemCategories)[number];
export type TimelogTypeUncategorisedSystemCategory = 'uncategorised';
export type TimelogTypeSystemCategory =
  | TimelogTypeRequiredSystemCategory
  | TimelogTypeUncategorisedSystemCategory;

export type PublicTimelogType = Omit<TimelogType, 'id' | 'accountId'>;

export type TimelogAssignee = {
  id: number;
  publicId: string;
  firstName: string;
  lastName: string;
  type: TimelogAssigneeType;
};

export type PublicTimelogAssignee = Omit<TimelogAssignee, 'id'>;

export type Timelog = {
  id: number;
  publicId: string;
  accountId: Account['id'];
  startTime: Date;
  endTime?: Nullish<Date>;
  assigneeId: number;
  assigneePublicId: string;
  assigneeType: TimelogAssigneeType;
  notes?: Nullish<string>;
  costPerHour?: Nullish<number>;
  calculatedCost?: Nullish<number>;
  typeId?: Nullish<number>;
  typePublicId?: Nullish<string>;
  recordedByUserId?: Nullish<number>;
};

export type HydratedTimelog = Timelog & {
  type?: TimelogType;
  assignee?: TimelogAssignee;
  linkedEntities?: TimelogLinkedEntity[];
};

export type PublicTimelog = Omit<
  Timelog,
  'id' | 'accountId' | 'typeId' | 'assigneeId'
>;

export type PublicHydratedTimelog = PublicTimelog & {
  type?: PublicTimelogType;
  assignee?: PublicTimelogAssignee;
  linkedEntities?: PublicTimelogLinkedEntity[];
};
