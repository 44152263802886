import { isNullish } from '@payaca/utilities/guards';
import { useMemo } from 'react';
import useGetMe from '../api/queries/me/useGetMe';
import useGetMyAccountSystemRoles from '../api/queries/me/useGetMyAccountSystemRoles';

export const useUserSystemRoleOptions = () => {
  const { data: accountSystemRoles } = useGetMyAccountSystemRoles();
  const { data: me } = useGetMe();

  const meRoleHierarchy = accountSystemRoles?.find(
    (r) => r.roleName === me?.user.systemRole
  )?.hierarchy;
  const options = useMemo(
    () =>
      accountSystemRoles
        ? accountSystemRoles
            .filter((role) => {
              if (isNullish(meRoleHierarchy) || role.isHidden) {
                return false;
              }
              return role.hierarchy <= meRoleHierarchy;
            })
            .map((role) => ({
              label: role.roleName,
              value: role.roleName,
            }))
        : [],
    [accountSystemRoles, meRoleHierarchy]
  );
  return { options };
};
