import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';

import useArchiveNote from '@/api/mutations/notes/useArchiveNote';
import useCreateEventNote from '@/api/mutations/notes/useCreateEventNote';
import useCreateProjectNote from '@/api/mutations/notes/useCreateProjectNote';
import useUpdateEventNote from '@/api/mutations/notes/useUpdateEventNote';
import useUpdateProjectNote from '@/api/mutations/notes/useUpdateProjectNote';
import useGetNote from '@/api/queries/notes/useGetNote';
import { useUsersWithPermissions } from '@/hooks/useUsersWithPermissions';
import ConfirmDeleteNoteModal from '@/ui/components/confirmDeleteNoteModal/ConfirmDeleteNoteModal';
import CreateEditNoteControlBase, {
  GraphqlSafeNote,
} from '@/ui/components/createEditNoteControl/CreateEditNoteControlBase';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import useGetMyAccountUsers from '../../../api/queries/me/useGetMyAccountUsers';
import useGetProjectOverview from '../../../api/queries/project/useGetProjectOverview';

export interface IProps
  extends Omit<IModalProps, 'title' | 'disableBackdropClick'> {
  projectId?: string;
  eventId?: string;
  notePublicId?: string;
  onSuccess?: () => void;
  onSuccessDelete?: () => void;
}

const CreateEditNoteModal: FC<IProps> = (props) => {
  const {
    projectId,
    eventId,
    notePublicId,
    isOpen,
    onClose,
    onSuccess,
    onSuccessDelete,
    ...rest
  } = props;

  /**
   * State
   */
  const [isProcessing, setIsProcessing] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<
    string | null
  >(null);

  /**
   * Redux
   */
  const usersWithGetAnyDealPermission = useUsersWithPermissions([
    DealsPermissions.GET_DEAL,
  ]);

  /**
   * Queries
   */
  const { data: noteData, isLoading: isLoadingNote } = useGetNote(
    notePublicId,
    {
      onError: () => {
        onClose?.();
      },
    }
  );
  const { data: projectData } = useGetProjectOverview(
    projectId ? +projectId : undefined
  );
  const { data: accountUsers } = useGetMyAccountUsers({
    hasAnyPermission: ['deals.getMyDeal'],
  });

  /**
   * Mutations
   */
  const { mutateAsync: createProjectNoteMutation } = useCreateProjectNote({
    onSuccess,
  });
  const { mutateAsync: createEventNoteMutation } = useCreateEventNote({
    onSuccess,
  });
  const { mutateAsync: updateProjectNoteMutation } = useUpdateProjectNote({
    onSuccess,
  });
  const { mutateAsync: updateEventNoteMutation } = useUpdateEventNote({
    onSuccess,
  });
  const { mutateAsync: archiveNoteMutation, isLoading: isArchivingNote } =
    useArchiveNote({
      onSuccess: onSuccessDelete,
    });

  /**
   * Memorised
   */
  const projectOwnerId = useMemo(() => {
    if (!projectData || !projectData?.project?.owner) return;

    return projectData.project.owner.id;
  }, [projectData]);

  const note = useMemo(() => {
    return {
      id: noteData?.note.id,
      description: noteData?.note.body,
      publicId: noteData?.note.id,
    };
  }, [noteData]);
  const noteProjectId = useMemo(() => {
    return noteData?.note.project?.id;
  }, [noteData]);
  const noteEventId = useMemo(() => {
    return noteData?.note.event?.id;
  }, [noteData]);
  const displayLoadingState = useMemo(() => {
    return isLoadingNote && !!notePublicId;
  }, [notePublicId, isLoadingNote]);
  const titleVariation = useMemo(() => {
    switch (true) {
      case !!eventId || !!noteEventId:
        return 'Event';
      case !!projectId || !!noteProjectId:
        return 'Project';
    }
  }, [projectId, eventId, noteProjectId, noteEventId]);

  const usersForMentioning = useMemo(() => {
    const users = usersWithGetAnyDealPermission.map((u) => ({
      id: u.id,
      name: u.name,
    }));
    if (projectOwnerId) {
      const projectOwnerAlreadyInUsers = users.find(
        (user) => user.id === +projectOwnerId
      );
      if (!projectOwnerAlreadyInUsers) {
        const projectOwner = accountUsers?.find(
          (user) => user.id === projectOwnerId
        );
        if (projectOwner) {
          users.push({
            id: +projectOwner.id,
            name: projectOwner.fullName,
          });
        }
      }
    }
    return users;
  }, [usersWithGetAnyDealPermission, projectOwnerId, accountUsers]);

  /**
   * Effects
   */
  useEffect(() => {
    if (isOpen) {
      setIsProcessing(false);
      setIsConfirmDeleteModalOpen(null);
    }
  }, [isOpen]);

  /**
   * Callbacks
   */
  const onCreateUpdateNote = useCallback(
    async (note: GraphqlSafeNote) => {
      setIsProcessing(true);

      switch (true) {
        // Note already exists and note has an attached ScheduledEvent
        case note.id && !!noteEventId:
          await updateEventNoteMutation({
            publicId:
              typeof note.id === 'number' ? note.id.toString() : note.id,
            eventId: noteEventId,
            body: note.description || '',
          });
          break;
        // Note already exists and note has an attached Project
        case note.id && !!noteProjectId:
          await updateProjectNoteMutation({
            publicId:
              typeof note.id === 'number' ? note.id.toString() : note.id,
            projectId: noteProjectId,
            body: note.description || '',
          });
          break;
        // Note doesn't exist, and a projectId was passed to the component, create a project note
        case !!projectId:
          await createProjectNoteMutation({
            projectId,
            body: note.description || '',
          });
          break;
        // Note doesn't exist, and an eventId was passed to the component, create an event note
        case !!eventId:
          await createEventNoteMutation({
            eventId,
            body: note.description || '',
          });
      }

      setIsProcessing(false);
      onClose?.();
    },
    [
      createProjectNoteMutation,
      createEventNoteMutation,
      updateProjectNoteMutation,
      updateEventNoteMutation,
      setIsProcessing,
      projectId,
      eventId,
      noteProjectId,
      noteEventId,
    ]
  );

  const handleOnDeleteNote = useCallback(
    (notePublicId: string) => {
      setIsConfirmDeleteModalOpen(notePublicId);
    },
    [setIsConfirmDeleteModalOpen]
  );

  if (displayLoadingState) {
    return (
      <Modal title="" isOpen={isOpen} onClose={onClose} {...rest}>
        <Modal.Body>
          <SkeletonLoader.Textarea />
        </Modal.Body>
      </Modal>
    );
  }

  if (isConfirmDeleteModalOpen) {
    return (
      <ConfirmDeleteNoteModal
        isOpen={isOpen}
        onClose={onClose}
        isProcessing={isArchivingNote}
        onConfirm={() => {
          archiveNoteMutation({
            publicId: isConfirmDeleteModalOpen,
          }).then(() => onClose?.());
        }}
      />
    );
  }

  return (
    <Modal
      title={
        noteData?.note.id
          ? `Edit${titleVariation ? ` ${titleVariation}` : ''} Note`
          : `Create${titleVariation ? ` ${titleVariation}` : ''} Note`
      }
      isOpen={isOpen}
      onClose={onClose}
      disableBackdropClick
      {...rest}
    >
      <CreateEditNoteControlBase
        withinModal
        error={null}
        isProcessing={isProcessing}
        onCreateUpdateNote={onCreateUpdateNote}
        usersForMentioning={usersForMentioning}
        note={note}
        onDeleteNote={handleOnDeleteNote}
      />
    </Modal>
  );
};

export default CreateEditNoteModal;
