import Conditional from '@payaca/components/conditional/Conditional';
import Alert, { EAlertColour } from '@payaca/components/plAlert/Alert';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnShapeVariant,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import ShadowDomRenderer from '@payaca/components/shadowDomRenderer/ShadowDomRenderer';
import UntitledIcon from '@payaca/untitled-icons';
import { FC } from 'react';

export const EmailTemplatePreview: FC<{
  template: string;
  onRemove?: () => void;
}> = ({ template, onRemove }) => {
  return (
    <>
      <Alert colour={EAlertColour.SOFT_YELLOW}>
        Please note that the data populated here is{' '}
        <b>not real and is only for preview purposes</b>.
      </Alert>

      <Card className="overflow-hidden relative mt-4">
        <Conditional condition={!!onRemove}>
          <div className="absolute right-2 top-2">
            <Button
              onClick={onRemove}
              variant={EBtnVariant.White}
              shape={EBtnShapeVariant.PILL}
              size={EBtnSize.Small}
            >
              <UntitledIcon className="h-4 w-3" name="x-close" />
            </Button>
          </div>
        </Conditional>

        <ShadowDomRenderer html={template} />
      </Card>
    </>
  );
};
