import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FC, useCallback, useMemo } from 'react';

import Button from '@payaca/components/button/Button';
import {
  ButtonColourVariant,
  ButtonStyleVariant,
} from '@payaca/components/button/enums';
import IconButton from '@payaca/components/button/IconButton';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import Tooltip from '@payaca/components/tooltip/Tooltip';
import {
  automationActionTypeReadableNameMap,
  automationEntityTypeReadableNameMap,
} from '@payaca/helpers/automationHelper';
import {
  automationEntityTypes,
  automationTriggerEvents,
} from '@payaca/types/automationTypes';

type Props = {
  expandFilters: boolean;
  getListedAutomationsRequestData: any;
  onChange: (change: any) => void;
};
const ListedAutomationsFilterDropdownsContent: FC<Props> = ({
  expandFilters,
  getListedAutomationsRequestData,
  onChange,
}: Props) => {
  const clearFilters = useCallback(
    () =>
      onChange({
        entityTypes: [],
        triggerEvents: [],
        actionTypes: [],
      }),
    []
  );

  const {
    entityTypes = [],
    triggerEvents = [],
    actionTypes = [],
  } = useMemo<any>(
    () => getListedAutomationsRequestData || {},
    [getListedAutomationsRequestData]
  );

  const entityTypeOptions = useMemo(
    () =>
      automationEntityTypes
        .filter((x) => !['Invoice-v2'].includes(x))
        .map((value) => ({
          label: automationEntityTypeReadableNameMap[value] || value,
          value: value,
        })),
    []
  );

  const triggerEventOptions = useMemo<any>(
    () =>
      automationTriggerEvents.map((label: string) => {
        return {
          label,
          value: label,
        };
      }),
    []
  );

  const actionTypeOptions = useMemo<any>(
    () =>
      (
        [
          'E-mail Notification',
          'SMS Notification',
          'Deal Progression',
          'Create repeat Project',
        ] as const
      ).map((value) => {
        return {
          label: automationActionTypeReadableNameMap[value] || value,
          value: value,
        };
      }),
    []
  );

  return (
    <>
      <div
        className={
          'listed-automations-filter-dropdowns' +
          (expandFilters ? ' expanded' : '')
        }
      >
        <label className="filter-by-label">Filter by:</label>
        <div className="filter-container">
          <DropdownField
            value={entityTypes}
            name="entityTypes"
            options={entityTypeOptions}
            onChange={onChange}
            multiple={true}
            additionalSelectProps={{
              renderValue: (value: any, label: any) => {
                if (value && value.length > 1) {
                  return 'Multiple selected';
                }
                return value && value.length ? value.join(', ') : 'Type';
              },
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
        <div className="filter-container">
          <DropdownField
            value={triggerEvents}
            name="triggerEvents"
            options={triggerEventOptions}
            onChange={onChange}
            multiple={true}
            additionalSelectProps={{
              renderValue: (value: any, label: any) => {
                if (value && value.length > 1) {
                  return 'Multiple selected';
                }
                return value && value.length
                  ? value.join(', ')
                  : 'Trigger Event';
              },
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
        <div className="filter-container">
          <DropdownField
            value={actionTypes}
            name="actionTypes"
            options={actionTypeOptions}
            onChange={onChange}
            multiple={true}
            additionalSelectProps={{
              renderValue: (value: any, label: any) => {
                if (value && value.length > 1) {
                  return 'Multiple selected';
                }
                return value && value.length
                  ? value.join(', ')
                  : 'Action Types';
              },
              displayEmpty: true,
            }}
            styleVariant={InputStyleVariant.STANDARD}
            hasBoxShadow={false}
            displayCheckboxes={true}
          />
        </div>
        {(entityTypes.length > 0 ||
          triggerEvents.length > 0 ||
          actionTypes.length > 0) && (
          <div
            className={'clear-filters'}
            style={{ position: 'relative', display: 'inline-block' }}
          >
            <Tooltip text="Clear filters">
              <div>
                <Button
                  className="clear-filters-anchor"
                  onClick={clearFilters}
                  styleVariant={ButtonStyleVariant.ANCHOR}
                >
                  Clear filters
                </Button>
                <IconButton
                  icon={faTimes}
                  size={'sm'}
                  colourVariant={ButtonColourVariant.GREY}
                  onClick={clearFilters}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default ListedAutomationsFilterDropdownsContent;
