import useOptimisticCreateCustomFieldGroup from '@/api/mutations/account/optimistic/useOptimisticCreateCustomFieldGroup';
import useGetMyCustomFieldGroups from '@/api/queries/me/useGetMyCustomFieldGroups';

import { FC, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { CustomFieldGroupControl } from '../customFieldGroupControl/CustomFieldGroupControl';

export const CompanySettingsProjectCustomFields: FC = () => {
  const { data: customFieldGroups } = useGetMyCustomFieldGroups();

  const projectCustomFieldGroup = useMemo(
    () => customFieldGroups?.find((x) => x.role === 'project'),
    [customFieldGroups]
  );

  const { mutateAsync: createCustomFieldGroup } =
    useOptimisticCreateCustomFieldGroup();

  useEffect(() => {
    if (
      customFieldGroups &&
      !customFieldGroups.find((x) => x.role === 'project')
    ) {
      void createCustomFieldGroup({
        role: 'project',
        id: uuid(),
      });
    }
  }, [customFieldGroups]);

  if (!projectCustomFieldGroup) {
    return null;
  }

  return (
    <CustomFieldGroupControl
      customFieldGroup={projectCustomFieldGroup}
      heading="Project Custom Fields"
      subHeading="Once created custom fields can be set on each Project page."
    />
  );
};
