import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getReadableTextColour } from '@payaca/utilities/colourUtilities';
import React, { FunctionComponent } from 'react';
import './Button.sass';
import { ButtonColourVariant, ButtonStyleVariant } from './enums';

export type Props = {
  isDisabled?: boolean;
  iconBefore?: IconDefinition | React.ReactNode;
  iconAfter?: IconDefinition | React.ReactNode;
  onClick?: () => void;
  onClickDisabled?: () => void;
  isProcessing?: boolean;
  styleVariant?: ButtonStyleVariant;
  colourVariant?: ButtonColourVariant;
  customColour?: string;
  hasBoxShadow?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  isOutlined?: boolean;
};

const Button: FunctionComponent<React.PropsWithChildren<Props>> = ({
  isDisabled = false,
  children,
  iconBefore,
  iconAfter,
  onClick,
  onClickDisabled,
  isProcessing = false,
  styleVariant = ButtonStyleVariant.STANDARD,
  colourVariant,
  customColour,
  hasBoxShadow = false,
  type = 'button',
  className,
  isOutlined = false,
}: React.PropsWithChildren<Props>): JSX.Element => {
  const internalColourVariant =
    colourVariant ||
    (styleVariant === ButtonStyleVariant.ANCHOR
      ? ButtonColourVariant.MIDBLUE
      : ButtonColourVariant.SECONDARY);

  return (
    <button
      className={`${
        className ? `${className} ` : ''
      }button v2 button-style-${styleVariant}${
        !customColour ? ` button-col-${internalColourVariant}` : ''
      }${isDisabled ? ' disabled' : ''}${isProcessing ? ' processing' : ''}${
        hasBoxShadow ? ' box-shadow' : ''
      }${isOutlined ? ' outlined' : ''}`}
      onClick={isDisabled ? onClickDisabled : onClick}
      disabled={isDisabled && !onClickDisabled}
      type={type}
      style={{
        backgroundColor: customColour,
        color: customColour ? getReadableTextColour(customColour) : undefined,
      }}
    >
      <React.Fragment>
        {iconBefore && (
          <span className="icon">
            {React.isValidElement(iconBefore) ? (
              iconBefore
            ) : (
              // @ts-ignore
              (<FontAwesomeIcon icon={iconBefore} />)
            )}
          </span>
        )}
        {isProcessing && (
          <span className={'spinner-container'}>
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </span>
        )}
        <span className={`button-content`}>{children}</span>
        {iconAfter && (
          <span className="icon">
            {React.isValidElement(iconAfter) ? (
              iconAfter
            ) : (
              // @ts-ignore
              (<FontAwesomeIcon icon={iconAfter} />)
            )}
          </span>
        )}
      </React.Fragment>
    </button>
  );
};

export default Button;
