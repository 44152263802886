import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyAccountSystemRoles = graphql(`
  query MyAccountSystemRoles {
    me {
      user {
        account {
          systemRoles {
            roleName
            hierarchy
            isHidden
          }
        }
      }
    }
  }
`);

const useGetMyAccountSystemRoles = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountSystemRoles(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountSystemRoles);
    },
  });

  return { data: data?.me.user.account.systemRoles, ...rest };
};

export default useGetMyAccountSystemRoles;
