import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { CreateAccountTermMutation, CreateTermInput } from '@/gql/graphql';

const CREATE_ACCOUNT_TERM_MUTATION = graphql(`
  mutation CreateAccountTerm($input: CreateTermInput!) {
    createTerm(input: $input) {
      id
    }
  }
`);

const useCreateAccountTerm = (
  options?: UseMutationOptions<
    CreateAccountTermMutation,
    unknown,
    CreateTermInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(CREATE_ACCOUNT_TERM_MUTATION, { input });
    },
    ...options,
  });
};

export default useCreateAccountTerm;
