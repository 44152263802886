import {
  autoPlacement,
  autoUpdate,
  offset,
  size,
  useFloating,
} from '@floating-ui/react';
import { Listbox, Transition } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import React, {
  ComponentProps,
  FC,
  Fragment,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';
import { createPortal } from 'react-dom';
import { FieldContext } from '../plField/Field';

export enum SelectSizeVariant {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export type ValueType = number | string | null;

export type SelectOption<
  TValueType extends ValueType,
  TMetaDataType = object,
> = {
  label: string;
  value: TValueType;
  disabled?: boolean;
  metadata?: TMetaDataType;
  groupId?: string;
};

export type OptionGroup = {
  id: string;
  label: string;
};

export type Props<
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetaDataType = object,
> = {
  optionGroups?: OptionGroup[];
  options: SelectOption<TValueType, TMetaDataType>[];
  multiple?: TMultipleBoolean;
  onChange?: (
    value: TMultipleBoolean extends true ? TValueType[] : TValueType
  ) => void;
  value?: TMultipleBoolean extends true ? TValueType[] : TValueType;
  disabled?: boolean;
  CustomOption?: FC<{
    option: SelectOption<TValueType, TMetaDataType>;
    active: boolean;
    selected: boolean;
    close: () => void;
  }>;
  CustomSelected?: FC<{
    selectedOptions: SelectOption<TValueType, TMetaDataType>[];
  }>;
  customButtonContent?: React.ReactNode;
  placeholder?: string;
  sizeVariant?: SelectSizeVariant;
  className?: string;
  listboxOptionClassName?: string;
  hideDropdownChevron?: boolean;
};

const Select = <
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetaDataType = object,
>({
  options,
  onChange,
  multiple,
  value,
  disabled,
  CustomOption,
  CustomSelected,
  placeholder,
  children,
  sizeVariant = SelectSizeVariant.MD,
  className,
  listboxOptionClassName,
  optionGroups,
  customButtonContent,
  hideDropdownChevron = false,
}: PropsWithChildren<Props<TValueType, TMultipleBoolean, TMetaDataType>>) => {
  const { id, name, validationState } = useContext(FieldContext);
  const { refs, floatingStyles } = useFloating({
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      autoPlacement({
        alignment: 'start',
        allowedPlacements: ['top-start', 'bottom-start'],
      }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${rects.reference.width}px`,
          });
        },
        padding: 8,
      }),
      offset({
        mainAxis: 4,
      }),
    ],
  });

  const selectedOptions = options.filter((o) =>
    multiple ? (value as TValueType[])?.includes(o.value) : o.value === value
  );

  const borderColour = useMemo(() => {
    switch (validationState?.isValid) {
      case true:
        return 'border-teal-500';
      case false:
        return 'border-red-500';
      default:
        return 'border-gray-200';
    }
  }, [validationState?.isValid]);

  const sizeClasses = useMemo(() => {
    switch (sizeVariant) {
      case SelectSizeVariant.SM:
        return 'py-2 px-3';
      case SelectSizeVariant.MD:
        return 'py-3 px-4';
      case SelectSizeVariant.LG:
        return 'py-3 px-4 sm:p-5';
    }
  }, [sizeVariant]);

  const close = () => {
    // @ts-ignore
    refs.reference?.current.click();
  };

  return (
    <Listbox
      multiple={multiple}
      disabled={disabled}
      onChange={onChange}
      value={value}
      name={name}
    >
      {({ open }) => (
        <>
          <Listbox.Button
            ref={refs.setReference}
            className={`text-pc-blue-darker font-inherit relative text-left shadow-sm disabled:pointer-events-none disabled:text-inherit disabled:opacity-50 ${sizeClasses} block w-full border pr-9 sm:pr-9 ${borderColour} rounded-md bg-white text-base focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 ${className}`}
          >
            {customButtonContent ? (
              customButtonContent
            ) : (
              <SelectButtonContent
                multiple={multiple}
                disabled={disabled}
                value={value}
                selectedOptions={selectedOptions}
                CustomSelected={CustomSelected}
                placeholder={placeholder}
                onChange={onChange}
              />
            )}
            {!hideDropdownChevron && (
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <UntitledIcon
                  name="chevron-down"
                  className="h-5 w-5 text-gray-600"
                />
              </span>
            )}
          </Listbox.Button>

          {createPortal(
            <Transition
              show={open}
              appear
              as={Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="scale-95 opacity-0"
              enterTo="scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="scale-100 opacity-100"
              leaveTo="scale-95 opacity-0"
            >
              <Listbox.Options
                id={id}
                ref={refs.setFloating}
                className="z-dropdown m-0 block flex w-full list-none flex-col gap-1 overflow-y-auto rounded-md border border-gray-200 bg-white p-1 text-base focus:border-blue-500 focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400"
                style={floatingStyles}
              >
                <Options
                  options={options}
                  optionClassName={listboxOptionClassName}
                  CustomOption={CustomOption}
                  optionGroups={optionGroups}
                  OptionComponent={Listbox.Option}
                  close={close}
                />
                {children}
              </Listbox.Options>
            </Transition>,
            document.body
          )}
        </>
      )}
    </Listbox>
  );
};

export default Select;

export const Options = <
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetadataType = object,
>({
  options,
  optionClassName,
  CustomOption,
  optionGroups,
  OptionComponent,
  onOptionClick,
  close,
}: Pick<
  Props<TValueType, TMultipleBoolean, TMetadataType>,
  'options' | 'CustomOption' | 'optionGroups'
> & {
  optionClassName?: string;
  OptionComponent: FC<
    Pick<
      ComponentProps<typeof Listbox.Option>,
      'value' | 'disabled' | 'className' | 'children' | 'onClick'
    >
  >;
  onOptionClick?: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: SelectOption<TValueType, TMetadataType>
  ) => void;
  close: () => void;
}) => {
  const groupIds = optionGroups?.map((g) => g.id) || [];

  return (
    <>
      {optionGroups?.map((group) => {
        return (
          <>
            <OptionComponent
              key={group.id}
              value={group.id}
              disabled={true}
              className={`px-4 py-2 text-sm font-medium uppercase text-gray-400`}
            >
              {group.label}
            </OptionComponent>
            {options
              .filter((o) => o.groupId === group.id)
              .map((option) => (
                <OptionComponent
                  onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
                    onOptionClick?.(e, option)
                  }
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                  className={({
                    active,
                    selected,
                  }: {
                    active: boolean;
                    selected: boolean;
                  }) =>
                    `flex cursor-pointer flex-row items-center gap-2 rounded-sm px-4 py-2 ${
                      selected ? 'bg-gray-300' : active ? 'bg-gray-200' : ''
                    } 
                     ${optionClassName}`
                  }
                >
                  {({ active, selected }) => (
                    <>
                      {CustomOption ? (
                        <CustomOption
                          option={option}
                          active={active}
                          selected={selected}
                          close={close}
                        />
                      ) : (
                        <span>{option.label}</span>
                      )}
                      {selected && (
                        <UntitledIcon
                          name="check"
                          className="ml-auto h-3.5 w-3.5 shrink-0 text-blue-600"
                        />
                      )}
                    </>
                  )}
                </OptionComponent>
              ))}
          </>
        );
      })}
      {options
        .filter((o) => (o.groupId ? !groupIds.includes(o.groupId) : true))
        .map((option) => (
          <OptionComponent
            onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) =>
              onOptionClick?.(e, option)
            }
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            className={({
              active,
              selected,
            }: {
              active: boolean;
              selected: boolean;
            }) =>
              `ui-disabled:text-gray-400 ui-disabled:cursor-not-allowed flex cursor-pointer flex-row items-center gap-2 rounded-sm px-4 py-2 ${
                selected ? 'bg-gray-300' : active ? 'bg-gray-200' : ''
              } ${optionClassName}`
            }
          >
            {({ active, selected }) => (
              <>
                {CustomOption ? (
                  <CustomOption
                    option={option}
                    active={active}
                    selected={selected}
                    close={close}
                  />
                ) : (
                  <span>{option.label}</span>
                )}
                {selected && (
                  <UntitledIcon
                    name="check"
                    className="ml-auto h-3.5 w-3.5 shrink-0 text-blue-600"
                  />
                )}
              </>
            )}
          </OptionComponent>
        ))}
    </>
  );
};

export const DismissableSelectTag: FC<
  PropsWithChildren<{ onDismiss?: () => void }>
> = ({ children, onDismiss }) => {
  return (
    <div className="relative z-10 flex flex-nowrap items-center rounded-full border border-gray-200 p-1 ps-2">
      <span className="mr-1 max-w-[150px] truncate whitespace-nowrap">
        {children}
      </span>
      {!!onDismiss && (
        <button
          onClick={(e) => {
            e.preventDefault();
            onDismiss?.();
          }}
          className="me-1 ms-1 inline-flex h-5 w-5 flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-gray-200 text-base text-gray-800 transition-all hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 dark:bg-gray-700/50 dark:text-gray-400 dark:hover:bg-gray-700"
        >
          <UntitledIcon name="x-close" />
        </button>
      )}
    </div>
  );
};

export const SelectButtonContent = <
  TValueType extends ValueType,
  TMultipleBoolean extends boolean,
  TMetadataType = object,
>({
  multiple,
  disabled,
  placeholder,
  CustomSelected,
  onChange,
  selectedOptions,
  value,
}: Pick<
  Props<TValueType, TMultipleBoolean, TMetadataType>,
  | 'multiple'
  | 'disabled'
  | 'placeholder'
  | 'CustomSelected'
  | 'onChange'
  | 'value'
> & {
  selectedOptions: SelectOption<TValueType, TMetadataType>[];
}) => {
  const renderDismissableTags =
    multiple && !disabled && selectedOptions.length && !CustomSelected;

  return (
    <div
      className={`block truncate ${
        renderDismissableTags ? '-my-[calc(0.333rem+1px)]' : ''
      }`}
    >
      {selectedOptions.length === 0 && (
        <span className="text-gray-600">{placeholder || 'Please select'}</span>
      )}
      {CustomSelected ? (
        <CustomSelected selectedOptions={selectedOptions} />
      ) : multiple && !disabled ? (
        <div className="flex flex-wrap gap-1">
          {selectedOptions.map((x) => {
            return (
              <DismissableSelectTag
                key={x.value}
                onDismiss={
                  x.disabled
                    ? undefined
                    : () => {
                        (onChange as (value: TValueType[]) => void)?.(
                          Array.isArray(value)
                            ? value.filter((v) => v !== x.value)
                            : []
                        );
                      }
                }
              >
                {x.label}
              </DismissableSelectTag>
            );
          })}
        </div>
      ) : (
        selectedOptions.map((o) => o.label).join(', ')
      )}
    </div>
  );
};
