import { AutomationDataAccessType } from '@payaca/types/automationDataAccessTypes';
import { AutomationEntityTypes } from '@payaca/types/automationTypes';

// Config for condtions and action variables
export const FIELD_OPTIONS: {
  label: string;
  entityColumns: { [key in AutomationEntityTypes]?: AutomationDataAccessType };
}[] = [
  {
    label: 'Customer name',
    entityColumns: {
      ["Estimate"]: "customer.name",
      ["Quote"]: "customer.name",
      ["Proposal"]: "customer.name",
      ["Invoice"]: "customer.name",
      ["Deal"]: "customer.name",
    },
  },
  {
    label: 'Location contact(s) e-mail',
    entityColumns: {
      ["Event"]:
        "event.location.contacts.emailaddress.array",
    },
  },
  {
    label: 'Project site address contact(s) e-mail',
    entityColumns: {
      ["Deal"]:
        "deal.siteaddresses.contacts.emailaddress.array",
    },
  },
  {
    label: 'Project site address contact(s) phone number',
    entityColumns: {
      ["Deal"]:
        "deal.siteaddresses.contacts.telephonenumber.array",
    },
  },
  {
    label: 'Project custom field',
    entityColumns: {
      ["Deal"]:
        "project.customField.value",
    },
  },
  {
    label: 'Location contact(s) phone number',
    entityColumns: {
      ["Event"]:
        "event.location.contacts.telephonenumber.array",
    },
  },
  {
    label: 'Customer e-mail',
    entityColumns: {
      ["Estimate"]:
        "primaryContact.emailAddress",
      ["Quote"]:
        "primaryContact.emailAddress",
      ["Proposal"]:
        "primaryContact.emailAddress",
      ["Invoice"]:
        "primaryContact.emailAddress",
      ["Event"]:
        "primaryContact.emailAddress",
      ["Deal"]:
        "primaryContact.emailAddress",
    },
  },
  {
    label: 'Customer phone number',
    entityColumns: {
      ["Estimate"]:
        "primaryContact.telephoneNumber",
      ["Quote"]:
        "primaryContact.telephoneNumber",
      ["Proposal"]:
        "primaryContact.telephoneNumber",
      ["Invoice"]:
        "primaryContact.telephoneNumber",
      ["Event"]:
        "primaryContact.telephoneNumber",
      ["Deal"]:
        "primaryContact.telephoneNumber",
    },
  },
  {
    label: 'Custom number',
    entityColumns: {
      ["Estimate"]:
        "customTelephoneNumber",
      ["Quote"]:
        "customTelephoneNumber",
      ["Proposal"]:
        "customTelephoneNumber",
      ["Invoice"]:
        "customTelephoneNumber",
      ["Event"]:
        "customTelephoneNumber",
      ["Deal"]:
        "customTelephoneNumber",
    },
  },
  {
    label: 'Custom email',
    entityColumns: {
      ["Estimate"]:
        "customEmailAddress",
      ["Quote"]:
        "customEmailAddress",
      ["Proposal"]:
        "customEmailAddress",
      ["Invoice"]:
        "customEmailAddress",
      ["Event"]:
        "customEmailAddress",
      ["Deal"]:
        "customEmailAddress",
    },
  },
  {
    label: 'Assignee e-mail',
    entityColumns: {
      ["Deal"]: "user.email",
    },
  },
  {
    label: 'Assignee(s) e-mail',
    entityColumns: {
      ["Event"]:
        "event.assignedusers.emailaddress.array",
    },
  },
  {
    label: 'Assignee(s) phone number',
    entityColumns: {
      ["Event"]:
        "event.assignedusers.telephonenumber.array",
    },
  },
  {
    label: 'Task status',
    entityColumns: {
      ["Event"]:
        "event.taskStatus",
    },
  },
  {
    label: 'The newly-added Tag',
    entityColumns: {
      ["Event"]:
        "trigger.X.tagText",
      ["Deal"]:
        "trigger.X.tagText",
    },
  },

  {
    label: 'Has a Tag',
    entityColumns: {
      ["Event"]:
        "event.tags.X.tagText",
      ["Deal"]:
        "deal.tags.X.tagText",
    },
  },
  {
    label: 'Does not have a Tag',
    entityColumns: {
      ["Deal"]:
        "deal.not.tags.X.tagText",
      ["Event"]:
        "event.not.tags.X.tagText",
    },
  },
  {
    label: 'Project pipeline',
    entityColumns: {
      ["Deal"]: "deal.pipelineId",
      ["Event"]: "deal.pipelineId",
      ["Estimate"]:
        "deal.pipelineId",
      ["Quote"]: "deal.pipelineId",
      ["Proposal"]:
        "deal.pipelineId",
      ["Invoice"]: "deal.pipelineId",
    },
  },
  {
    label: 'Project has a Tag',
    entityColumns: {
      ["Estimate"]:
        "deal.tags.X.tagText",
      ["Quote"]:
        "deal.tags.X.tagText",
      ["Proposal"]:
        "deal.tags.X.tagText",
      ["Invoice"]:
        "deal.tags.X.tagText",
    },
  },
  {
    label: 'Project does not have a Tag',
    entityColumns: {
      ["Estimate"]:
        "deal.not.tags.X.tagText",
      ["Quote"]:
        "deal.not.tags.X.tagText",
      ["Proposal"]:
        "deal.not.tags.X.tagText",
      ["Invoice"]:
        "deal.not.tags.X.tagText",
    },
  },
  {
    label: 'Project pipeline stage',
    entityColumns: {
      ["Deal"]: "deal.pipelineStage",
      ["Event"]:
        "deal.pipelineStage",
      ["Invoice"]:
        "deal.pipelineStage",
    },
  },
  {
    label: 'The new Project pipeline stage',
    entityColumns: {
      ["Deal"]:
        "trigger.newPipelineStage",
    },
  },
  {
    label: 'The old Project pipeline stage',
    entityColumns: {
      ["Deal"]:
        "trigger.oldPipelineStage",
    },
  },
  {
    label: 'Status',
    entityColumns: {
      ["Estimate"]:
        "proposal.readableStatus",
      ["Quote"]:
        "proposal.readableStatus",
      ["Proposal"]:
        "proposal.readableStatus",
      ["Invoice"]:
        "invoice.readableStatus",
    },
  },
  {
    label: 'Automation triggered day-of-week',
    entityColumns: {
      ["Estimate"]:
        "trigger.triggeredAt.dayOfWeek",
      ["Quote"]:
        "trigger.triggeredAt.dayOfWeek",
      ["Proposal"]:
        "trigger.triggeredAt.dayOfWeek",
      ["Invoice"]:
        "trigger.triggeredAt.dayOfWeek",
      ["Event"]:
        "trigger.triggeredAt.dayOfWeek",
      ["Deal"]:
        "trigger.triggeredAt.dayOfWeek",
    },
  },
];

export const fieldOptionEntityMap = FIELD_OPTIONS.reduce(
  (acc: any, { label, type, entityColumns }: any) => {
    return Object.keys(entityColumns).reduce((acc: any, key: string) => {
      if (!acc[key]) acc[key] = {};
      acc[key][entityColumns[key]] = { label, type };
      return acc;
    }, acc);
  },
  {}
);

export const CONDITION_OPERATOR_OPTIONS = [
  {
    label: 'includes the following text',
    value: 'includes',
    allowableFieldValues: [
      "customer.name",
      "primaryContact.emailAddress",
      "event.tags.X.tagText",
      "event.not.tags.X.tagText",
      "deal.tags.X.tagText",
      "deal.not.tags.X.tagText",
    ],
  },
  {
    label: 'equals',
    value: 'equals',
  },
  {
    label: 'does not equal',
    value: 'not-equals',
    allowableFieldValues: [
      "customer.name",
      "primaryContact.emailAddress",
      "primaryContact.telephoneNumber",
      "user.email",
      "trigger.X.tagText",
      "deal.pipelineId",
      "deal.pipelineStage",
      "invoice.readableStatus",
      "proposal.readableStatus",
      "event.assignedusers.emailaddress.array",
      "event.assignedusers.telephonenumber.array",
      "event.taskStatus",
      "trigger.triggeredAt.dayOfWeek",
    ],
  },
];
export const conditionValueLabelMap = CONDITION_OPERATOR_OPTIONS.reduce(
  (acc: any, { label, value }: any) => ({ ...acc, [value]: label }),
  {}
);
