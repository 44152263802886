export enum JobRoles {
  CREATOR = 'roles.jobsCreator',
  EDITOR = 'roles.jobsEditor',
  VIEWER = 'roles.jobsViewer',

  DUPLICATOR = 'roles.jobsDuplicator',

  VALID_FOR_DUE_IN_DAYS_EDITOR = 'roles.jobsValidForDueInDaysEditor',
  DEPOSIT_EDITOR = 'roles.jobsDepositEditor',
  REFERENCE_EDITOR = 'roles.jobsReferenceEditor',

  CUSTOM_CREATOR = 'roles.jobsCustomCreator',
}
