// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

const invoiceKeys = {
  all: ['invoices'] as const,
  invoice: (invoiceId: string) => {
    return [...invoiceKeys.all, 'invoice', invoiceId] as const;
  },
};

export default invoiceKeys;
