import {
  GetMaterialsListAuditLogsInput,
  GetMaterialsListOrderSummaryItemsInput,
  GetPaginatedActivityFeedForProjectInput,
  GetPaginatedProjectUploadsInput,
  GetScheduledEventsForProjectInput,
  GetTasksInput,
  PaginationInput,
} from '@/gql/graphql';

const projectKeys = {
  all: ['project'] as const,
  project: (projectId: number) => [...projectKeys.all, projectId] as const,
  activityFeed: (
    projectId: number,
    input?: GetPaginatedActivityFeedForProjectInput
  ) => {
    const baseKey = [
      ...projectKeys.project(projectId),
      'activityFeed',
    ] as const;
    if (!input) return baseKey;
    return [...baseKey, input] as const;
  },
  overview: (projectId: number) =>
    [...projectKeys.project(projectId), 'overview'] as const,
  profitBreakdown: (projectId: number) =>
    [...projectKeys.project(projectId), 'profitBreakdown'] as const,
  customFields: (projectId: number) =>
    [...projectKeys.project(projectId), 'customFields'] as const,
  accountingIntegrationCategories: (projectId: number) =>
    [
      ...projectKeys.project(projectId),
      'accountingIntegrationCategories',
    ] as const,
  events: (projectId: number, input?: GetScheduledEventsForProjectInput) => {
    const baseKey = [...projectKeys.project(projectId), 'events'] as const;
    if (!input) return baseKey;
    return [...baseKey, input] as const;
  },
  tasks: (
    projectId: number,
    input?: GetTasksInput,
    pagination?: PaginationInput
  ) => {
    const baseKey = [...projectKeys.project(projectId), 'tasks'] as const;
    if (!input && !pagination) return baseKey;
    return [...baseKey, { input, pagination }] as const;
  },
  materialsList: (projectId: number) => {
    return [...projectKeys.project(projectId), 'materialsList'] as const;
  },
  materialsListMaterials: (projectId: number, pagination?: PaginationInput) => {
    const baseKey = [
      ...projectKeys.materialsList(projectId),
      'materials',
    ] as const;
    if (!pagination) return baseKey;
    return [...baseKey, pagination] as const;
  },
  materialsListSummaryItems: (
    projectId: number,
    input?: GetMaterialsListOrderSummaryItemsInput,
    pagination?: PaginationInput
  ) => {
    const baseKey = [
      ...projectKeys.materialsList(projectId),
      'summaryItems',
    ] as const;
    if (!input && !pagination) return baseKey;
    return [...baseKey, { input, pagination }] as const;
  },
  materialsListAuditLogs: (
    projectId: number,
    input?: GetMaterialsListAuditLogsInput,
    pagination?: PaginationInput
  ) => {
    const baseKey = [
      ...projectKeys.materialsList(projectId),
      'auditLogs',
    ] as const;
    if (!input && !pagination) return baseKey;
    return [...baseKey, { input, pagination }] as const;
  },
  purchaseOrders: (projectId: number, pagination?: PaginationInput) => {
    const baseKey = [
      ...projectKeys.project(projectId),
      'purchaseOrders',
    ] as const;
    if (!pagination) return baseKey;
    return [...baseKey, pagination] as const;
  },
  uploads: (
    projectId: number,
    input?: GetPaginatedProjectUploadsInput,
    pagination?: PaginationInput
  ) => {
    const baseKey = [...projectKeys.project(projectId), 'uploads'] as const;
    if (!input && !pagination) return baseKey;
    return [...baseKey, { input, pagination }] as const;
  },
};

export default projectKeys;
