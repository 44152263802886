import { actions as usersActions } from '@/api/users';
import * as customerActions from '@payaca/store/customer/customerActions';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import './PreviewAndSendSendableDocument.sass';

import { AddCustomerEmailModal } from '..';

import PreviewDocument from '@/ui/components/PreviewQuoteInvoice/PreviewDocument';
import { EAlertColour } from '@payaca/components/plAlert/Alert';
import { TDropdownItem } from '@payaca/components/plDropdown/DropdownCore';
import { getJobContactFromCustomer } from '@payaca/helpers/customerHelper';
import { Customer } from '@payaca/types/customerTypes';
import PreviewUpdateReminders from '../previewUpdateReminders/PreviewUpdateReminders';
import SendableDocumentEmailControl from './SendableDocumentEmailControl';

type Props = {
  documentType: 'invoice' | 'quote' | 'estimate' | 'change proposal';
  title: string;
  documentPreview: React.ReactNode;
  emailConfig: {
    htmlTemplate?: string;
    subject: string;
    bodyTemplate: string;
    substitutions?: { find: string; replaceWith: string }[];
  };
  recipientConfig: {
    customer: Customer;
    contactId?: number;
  };
  onSend: (
    payload: {
      sendMeACopy: boolean;
      emailCopy: {
        preButton: string;
        postButton: string;
      };
    },
    callback: () => void,
    onErrorCallback: (errorMessages: string[]) => void
  ) => void;
  actions?: TDropdownItem[];
};

const PreviewAndSendSendableDocument: FunctionComponent<Props> = ({
  documentType,
  title,
  documentPreview,
  emailConfig,
  recipientConfig,
  onSend,
  actions,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const [showAddCustomerEmailModal, setShowAddCustomerEmailModal] =
    useState(false);
  const [isSending, setIsSending] = useState(false);

  const customerContact = useMemo(() => {
    return getJobContactFromCustomer(
      recipientConfig.customer,
      recipientConfig.contactId || null
    );
  }, [recipientConfig]);
  const [blockedEmails, setBlockedEmails] = useState<string[]>([]);

  useEffect(() => {
    dispatch(
      usersActions.getBlockedEmails(
        (error: Error, _blockedEmails: string[]) => {
          setBlockedEmails(_blockedEmails);
        }
      )
    );
  }, []);

  const [sendErrorMessages, setSendErrorMessages] = useState<string[]>();

  const [sendPayload, setSendPayload] = useState<{
    sendMeACopy: boolean;
    htmlTemplate?: string;
    emailCopy: {
      preButton: string;
      postButton: string;
    };
  }>({
    sendMeACopy: true,
    htmlTemplate: emailConfig.htmlTemplate,
    emailCopy: {
      preButton: '',
      postButton: '',
    },
  });

  useEffect(() => {
    let bodyTemplate = emailConfig.bodyTemplate;

    emailConfig.substitutions?.forEach((substitution) => {
      bodyTemplate = bodyTemplate.replaceAll(
        `[${substitution.find}]`,
        substitution.replaceWith
      );
    });

    let splitTemplate: string[];
    if (bodyTemplate.includes('\n[button]\n')) {
      splitTemplate = bodyTemplate.split('\n[button]\n');
    } else {
      splitTemplate = bodyTemplate.split('[button]');
    }

    setSendPayload((x) => ({
      ...x,
      htmlTemplate: emailConfig.htmlTemplate,
      emailCopy: {
        preButton: splitTemplate[0] || '',
        postButton: splitTemplate[1] || '',
      },
    }));
  }, [
    emailConfig.bodyTemplate,
    emailConfig.htmlTemplate,
    emailConfig.substitutions,
  ]);

  const isCustomerEmailBlocked = useMemo(() => {
    return (
      !!customerContact?.emailAddress &&
      !!blockedEmails &&
      blockedEmails.includes(customerContact.emailAddress)
    );
  }, [blockedEmails?.length, customerContact?.emailAddress]);

  const handleSendClick = useCallback(() => {
    setSendErrorMessages([]);
    setIsSending(true);
    if (!customerContact?.emailAddress?.length) {
      setShowAddCustomerEmailModal(true);
      setIsSending(false);
    } else if (isCustomerEmailBlocked) {
      setSendErrorMessages(["The customer's email is blocked"]);
      setIsSending(false);
    } else if (
      !sendPayload.emailCopy.postButton?.length &&
      !sendPayload.emailCopy.preButton?.length
    ) {
      setSendErrorMessages(['The email must have some content']);
      setIsSending(false);
    } else {
      onSend(
        sendPayload,
        () => {
          setIsSending(false);
        },
        (errorMessages) => {
          setSendErrorMessages(errorMessages);
          setIsSending(false);
        }
      );
    }
  }, [
    sendPayload,
    customerContact,
    setSendErrorMessages,
    sendPayload.emailCopy.postButton,
    sendPayload.emailCopy.preButton,
    isCustomerEmailBlocked,
  ]);

  return (
    <>
      <PreviewDocument
        title={title}
        firstAction={{
          onClick: () => !isSending && handleSendClick(),
          children: 'Send',
          isProcessing: isSending,
        }}
        actions={actions}
        alerts={
          sendErrorMessages?.length
            ? [
                {
                  colour: EAlertColour.SOFT_RED,
                  children: (
                    <>
                      <strong>This {documentType} could not be sent:</strong>
                      <ul>
                        {sendErrorMessages.map((x, i) => (
                          <li key={i}>{x}</li>
                        ))}
                      </ul>
                    </>
                  ),
                },
              ]
            : undefined
        }
      >
        <div className="space-y-4">
          <PreviewUpdateReminders />

          <h2>Preview email</h2>
          {customerContact && (
            <SendableDocumentEmailControl
              buttonText={`View ${documentType}`}
              emailSubject={emailConfig.subject}
              emailState={sendPayload}
              onChange={(value) => {
                setSendPayload((x) => {
                  return { ...x, ...value };
                });
              }}
              sendToAddress={customerContact.emailAddress}
            />
          )}

          <h2>Preview {documentType}</h2>
          {documentPreview}
        </div>
      </PreviewDocument>

      {recipientConfig.customer && (
        <AddCustomerEmailModal
          customer={recipientConfig.customer}
          isOpen={showAddCustomerEmailModal}
          contactId={recipientConfig.contactId || null}
          onClose={() => setShowAddCustomerEmailModal(false)}
          customerUpdateCallback={() => {
            dispatch(
              customerActions.requestGetCustomer(recipientConfig.customer.id)
            );
            setShowAddCustomerEmailModal(false);
          }}
        />
      )}
    </>
  );
};

export default PreviewAndSendSendableDocument;
