import {
  FormStageStage,
  TFormState,
} from '@/ui/components/createPurchaseOrderSidebar/CreatePurchaseOrderSidebar';
import Conditional from '@payaca/components/conditional/Conditional';
import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import { clstx } from '@payaca/components/utils';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { useDeal } from '@payaca/store/hooks/appState';
import { BaseSupplierMaterialsFulfillment } from '@payaca/types/fulfillmentTypes';
import { FC, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useGetFulfillmentOptionsForMaterials from '../../../../api/queries/fulfillment/useGetMaterialsFulfillment';
import {
  CollectionFulfillmentOption,
  DeliveryFulfillmentOption,
  isCollectionFulfillment,
  isDeliveryFulfillment,
} from '../../../../helpers/fulfillment';
import {
  PurchaseOrderDeliveryAddressControl,
  Props as PurchaseOrderDeliveryAddressControlProps,
  usePurchaseOrderAddressOptions,
} from '../../purchaseOrderDeliveryAddressControl/PurchaseOrderDeliveryAddressControl';
import { parseFulfillmentOptions } from '../fulfillment';
import {
  CollectionFulfillmentCardBodyInner,
  DeliveryFulfillmentCardBodyInner,
  ListedFulfillmentOptions,
} from './ListedFulfillmentOptions';

export interface IProps {
  formStage: FormStageStage;
  projectId: number;
  onAction: () => void;
}

const FulfillmentOptions: FC<IProps> = (props) => {
  const { formStage, projectId, onAction } = props;
  const formMethods = useFormContext<TFormState>();
  const selectedSupplierId = formMethods.watch('supplierId');
  const selectedMaterials = formMethods.watch('materials');
  const selectedFulfillmentOptionId = formMethods.watch('fulfillmentOptionId');

  const project = useDeal(projectId);
  const defaultAddress = project?.siteAddresses?.[0] || null;

  const [selectedAddress, setSelectedAddress] = useState<
    PurchaseOrderDeliveryAddressControlProps['value']
  >({
    deliveryAddressId: defaultAddress?.address.id,
  });

  const { options } = usePurchaseOrderAddressOptions({
    projectId,
    supplierId: selectedSupplierId,
  });

  const { fulfillmentOptionsForMaterials, refetch, isLoading } =
    useGetFulfillmentOptionsForMaterials({
      input:
        selectedSupplierId &&
        (selectedAddress.deliveryAddressId ||
          selectedAddress.newDeliveryAddress) &&
        selectedMaterials.length
          ? {
              projectId: projectId.toString(),
              materials: selectedMaterials.map((m) => ({
                id: m.materialsListMaterial.material.id,
                quantity: m.selectedQuantity,
              })),
              supplierId: selectedSupplierId.toString(),
              addressId: selectedAddress.deliveryAddressId?.toString(),
              address: selectedAddress.newDeliveryAddress,
            }
          : undefined,
    });

  useEffect(() => {
    if (formStage === 'FULFILLMENT_OPTIONS') {
      void refetch();
    }
  }, [formStage, selectedAddress]);

  const fulfillmentOptions = parseFulfillmentOptions(
    fulfillmentOptionsForMaterials
  );
  const allCollectionOptions = (fulfillmentOptions || []).filter(
    isCollectionFulfillment
  );
  const allDeliveryOptions = (fulfillmentOptions || []).filter(
    isDeliveryFulfillment
  );

  const updateMaterialsToReflectChosenOption = (
    option: BaseSupplierMaterialsFulfillment
  ) => {
    if (!option.isFullFulfillment) {
      // fulfillment is not full - update materials with purchaseOrderQuantity to reflect the available qtys of materials
      const updatedMaterials = selectedMaterials.map((m) => {
        const fulfillmentMaterial = option?.stock.find(
          (s) => s.material.id === m.materialsListMaterial.material.id
        );
        return {
          ...m,
          purchaseOrderQuantity: fulfillmentMaterial?.availableQuantity || 0,
        };
      });
      formMethods.setValue('materials', updatedMaterials);
    }
  };

  const hasSelectedAddress =
    !!selectedAddress?.deliveryAddressId ||
    (selectedAddress.newDeliveryAddress &&
      !!Object.keys(selectedAddress.newDeliveryAddress).length);

  return (
    <div
      className={clstx(
        'z-10 overflow-auto flex flex-col h-full transition-colors duration-700 bg-gray-50 border-l',
        formStage === 'DIRECT_PURCHASE_ORDER' && 'bg-white'
      )}
    >
      <div
        className={clstx(
          'p-3.5 z-10 bg-gray-50 transition-colors duration-700 sticky top-0 flex flex-col gap-2',
          formStage === 'DIRECT_PURCHASE_ORDER' && 'bg-white',
          scrollY > 0 && 'shadow'
        )}
      >
        <Conditional condition={!selectedFulfillmentOptionId}>
          <Field>
            <Field.Label>Collection/delivery address</Field.Label>
            <PurchaseOrderDeliveryAddressControl
              supplierId={selectedSupplierId}
              projectId={projectId}
              onChange={setSelectedAddress}
              value={selectedAddress}
              disableManualEdit
              canRevertToExisting
            />
          </Field>
        </Conditional>
        <Conditional condition={!hasSelectedAddress}>
          <span className="mt-2">
            Please select a collection/delivery address
          </span>
        </Conditional>
        <Conditional condition={formStage !== 'FULFILLMENT_OPTIONS'}>
          <Button
            variant={EBtnVariant.LinkInline}
            onClick={() => {
              onAction();
            }}
          >
            Edit collection/delivery address
          </Button>
        </Conditional>
      </div>

      <div className="flex flex-col gap-4 px-3.5">
        <Conditional
          condition={formStage === 'FULFILLMENT_OPTIONS' && hasSelectedAddress}
        >
          <strong>Choose collection or delivery option</strong>
        </Conditional>
        <Conditional
          condition={
            formStage === 'FULFILLMENT_OPTIONS' &&
            !fulfillmentOptions?.length &&
            !isLoading &&
            hasSelectedAddress
          }
        >
          <p>
            There are no collection or delivery options available for this
            address
          </p>
        </Conditional>
        {(!!allDeliveryOptions.length || !!allCollectionOptions.length) && (
          <Controller
            name="fulfillmentOptionId"
            control={formMethods.control}
            render={({ field: { onChange } }) => {
              return (
                <div className="flex flex-col gap-2">
                  {/* Delivery options */}
                  <h4>Delivery</h4>
                  <ListedFulfillmentOptions<DeliveryFulfillmentOption>
                    options={allDeliveryOptions}
                    onClick={(option) => {
                      onChange(option.fulfillmentOptionId);
                      formMethods.setValue('fromDate', option.deliveryFromDate);
                      formMethods.setValue('toDate', option.deliveryToDate);
                      const existingAddress = options.find(
                        (o) => o.value === selectedAddress.deliveryAddressId
                      );
                      if (existingAddress) {
                        formMethods.setValue('delivery', {
                          deliveryAddressId: existingAddress?.value,
                          fullLocalAddress: existingAddress?.label,
                        });
                      } else if (selectedAddress.newDeliveryAddress) {
                        formMethods.setValue('delivery', {
                          newDeliveryAddress:
                            selectedAddress.newDeliveryAddress,
                          fullLocalAddress: getAddressAsString(
                            selectedAddress.newDeliveryAddress
                          ),
                        });
                      }

                      // clear collection field
                      formMethods.setValue('collection', undefined);

                      updateMaterialsToReflectChosenOption(option);
                      if (formStage === 'FULFILLMENT_OPTIONS') {
                        onAction();
                      }
                    }}
                    selectedFulfillmentOptionId={selectedFulfillmentOptionId}
                    renderCardBodyInner={(fulfillmentOption) => (
                      <DeliveryFulfillmentCardBodyInner
                        {...fulfillmentOption}
                      />
                    )}
                    isLoading={isLoading}
                  />

                  {/* Collection options */}
                  <h4>Collection</h4>
                  <ListedFulfillmentOptions<CollectionFulfillmentOption>
                    options={allCollectionOptions}
                    onClick={(option) => {
                      onChange(option.fulfillmentOptionId);
                      formMethods.setValue(
                        'fromDate',
                        option.collectionFromDate
                      );
                      formMethods.setValue('collection', {
                        branchId: option.branch.id,
                        branchName: option.branch.name,
                      });
                      // clear delivery field
                      formMethods.setValue('delivery', undefined);

                      updateMaterialsToReflectChosenOption(option);
                      if (formStage === 'FULFILLMENT_OPTIONS') {
                        onAction();
                      }
                    }}
                    selectedFulfillmentOptionId={selectedFulfillmentOptionId}
                    renderCardBodyInner={(fulfillmentOption) => (
                      <CollectionFulfillmentCardBodyInner
                        {...fulfillmentOption}
                      />
                    )}
                    isLoading={isLoading}
                  />
                </div>
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FulfillmentOptions;
