import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import { UpdateAccountTermMutation, UpdateTermInput } from '@/gql/graphql';

const UPDATE_ACCOUNT_TERM_MUTATION = graphql(`
  mutation UpdateAccountTerm($input: UpdateTermInput!) {
    updateTerm(input: $input) {
      id
    }
  }
`);

const useUpdateAccountTerm = (
  options?: UseMutationOptions<
    UpdateAccountTermMutation,
    unknown,
    UpdateTermInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_ACCOUNT_TERM_MUTATION, { input });
    },
    ...options,
  });
};

export default useUpdateAccountTerm;
