export enum DealsPermissions {
  ADD_DEAL = 'deals.addDeal',
  ADD_MY_DEAL = 'deals.addMyDeal',
  GET_DEAL = 'deals.getDeal',
  GET_MY_DEAL = 'deals.getMyDeal',
  UPDATE_DEAL = 'deals.updateDeal',
  UPDATE_MY_DEAL = 'deals.updateMyDeal',
  GET_LISTED_DEALS = 'deals.getListedDeals',
  GET_MY_LISTED_DEALS = 'deals.getMyListedDeals',
  SHARE_DEAL_FILES = 'deals.shareDealFiles',
  UNSHARE_DEAL_FILES = 'deals.unshareDealFiles',
  RAISE_ADDITIONAL_WORK_REQUEST = 'deals.raiseAdditionalWorkRequest',
  GET_COMPLIANCE_FORMS = 'deals.getComplianceForms',

  ARCHIVE_DEAL = 'deals.archiveDeal',
  ARCHIVE_MY_DEAL = 'deals.archiveMyDeal',

  CREATE_TEMPLATE = 'deals.createTemplate',
  APPLY_TEMPLATE = 'deals.applyTemplate',

  MANAGE_PAYMENT_OPTIONS = 'deals.managePaymentOptions',
  MANAGE_PRICE_VISIBILITY = 'deals.managePriceVisibility',
  UPDATE_DEAL_REFERENCE = 'jobs.updateDeal.reference',
  UPDATE_DEAL_DESCRIPTION = 'jobs.updateDeal.description',
}
