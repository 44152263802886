export enum JobPermissions {
  GET_JOB = 'jobs.getJob',
  ADD_JOB = 'jobs.addJob',
  UPDATE_JOB = 'jobs.updateJob',

  DUPLICATE_JOB = 'jobs.duplicateJob',

  EDIT_JOB_VALID_FOR_DUE_IN_DAYS = 'jobs.editJob.validForDueInDays',
  EDIT_JOB_DEPOSIT = 'jobs.editJob.deposit',
  UPDATE_JOB_REFERENCE = 'jobs.updateJob.reference',

  ADD_CUSTOM_JOB = 'jobs.addCustomJob',
}
