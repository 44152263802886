export enum DealsRoles {
  CREATOR = 'roles.dealsCreator',
  VIEWER = 'roles.dealsViewer',
  ASSIGNEE_VIEWER = 'roles.dealsAssigneeViewer',
  EDITOR = 'roles.dealsEditor',
  ASSIGNEE_EDITOR = 'roles.dealsAssigneeEditor',
  UNSHARE_DEAL_FILES = 'roles.dealsUnshareDealFiles',
  ADDITIONAL_WORK_REQUESTER = 'roles.additionalWorkRequester',
  COMPLIANCE_FORM_VIEWER = 'roles.complianceFormViewer',

  ARCHIVER = 'roles.dealsArchiver',
  ASSIGNEE_ARCHIVER = 'roles.dealsAssigneeArchiver',

  TEMPLATE_CREATOR = 'roles.dealsTemplateCreator',
  TEMPLATE_APPLIER = 'roles.dealsTemplateApplier',

  PAYMENT_OPTIONS_MANAGER = 'deals.invoicePaymentOptionsManager',
  PRICE_VISIBILITY_MANAGER = 'deals.invoicePriceVisibilityManager',
  REFERENCE_EDITOR = 'roles.dealsReferenceEditor',
  DESCRIPTION_EDITOR = 'roles.dealsDescriptionEditor',
}
