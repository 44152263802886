import Badge from '@payaca/components/plBadge/Badge';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { FC } from 'react';
import {
  LiveMaterialSupplierBadgeProps,
  SupplierFulfillments,
} from './MaterialsPanel/LiveAvailabilityMaterialSupplierBadge/fulfillment';

export type MaterialSuppliersSuppplier = {
  name: string;
  id: string;
};
type Props = {
  suppliers: MaterialSuppliersSuppplier[];
  fulfillments: SupplierFulfillments[] | null;
  LiveAvailabilityMaterialSupplierBadge: React.FC<LiveMaterialSupplierBadgeProps>;
  maxSuppliersToShow?: number;
};

const MaterialSuppliersBadges: FC<Props> = (props) => {
  const {
    suppliers,
    fulfillments,
    LiveAvailabilityMaterialSupplierBadge,
    maxSuppliersToShow = 2,
  } = props;

  const suppliersToShow = suppliers.slice(0, maxSuppliersToShow);
  const remainingSuppliers = suppliers.length - maxSuppliersToShow;

  return (
    <span className="inline-flex gap-x-1">
      {suppliersToShow.map((supplier) => {
        const supplierFulfillments = fulfillments?.find(
          (f) => f.supplier.id === supplier.id
        )?.fulfillmentOptions;
        if (supplierFulfillments) {
          return (
            <LiveAvailabilityMaterialSupplierBadge
              key={supplier.id}
              supplier={supplier}
              fulfillmentOptions={supplierFulfillments}
            />
          );
        }
        return (
          <Badge key={supplier.id} variant="soft">
            {supplier.name}
          </Badge>
        );
      })}
      {remainingSuppliers > 0 && (
        <Tooltip
          tooltipContent={suppliers
            .slice(maxSuppliersToShow)
            .map((supplier) => supplier.name)
            .join(', ')}
        >
          <Badge variant="soft">+{remainingSuppliers}</Badge>
        </Tooltip>
      )}
    </span>
  );
};

export default MaterialSuppliersBadges;
