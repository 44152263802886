import React, { FC, useMemo } from 'react';
import tinycolor from 'tinycolor2';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  getDefaultColourForFeedbackLevel,
  getDefaultIconForFeedbackLevel,
} from '@payaca/helpers/feedbackHelper';
import {
  DynamicFeedbackMessage as DynamicFeedbackMessageType,
  FeedbackLevel,
} from '@payaca/types/feedbackTypes';
import './DynamicFeedbackMessage.sass';

interface Props {
  dynamicFeedbackMessage: DynamicFeedbackMessageType;
  onCancel: () => void;
}

const DynamicFeedbackMessage: FC<Props> = ({
  dynamicFeedbackMessage,
  onCancel,
}: Props): JSX.Element | null => {
  if (!dynamicFeedbackMessage.title && !dynamicFeedbackMessage.body)
    return null;

  const feedbackLevel = useMemo(() => {
    return dynamicFeedbackMessage.feedbackLevel || FeedbackLevel.NEUTRAL;
  }, [dynamicFeedbackMessage.feedbackLevel]);

  const icon = useMemo(() => {
    if (dynamicFeedbackMessage.icon !== undefined)
      return dynamicFeedbackMessage.icon;

    return getDefaultIconForFeedbackLevel(feedbackLevel);
  }, [dynamicFeedbackMessage.icon, feedbackLevel]);

  const colour = useMemo(() => {
    return (
      dynamicFeedbackMessage.colour ||
      getDefaultColourForFeedbackLevel(feedbackLevel)
    );
  }, [feedbackLevel, dynamicFeedbackMessage.colour]);

  const readableTextColour = useMemo(() => {
    if (!colour) return undefined;

    return tinycolor
      .mostReadable(colour, ['#263e59', 'white'], {
        includeFallbackColors: true,
      })
      .toHexString();
  }, [colour]);

  return (
    <div className="dynamic-feedback-message">
      <div className="dynamic-feedback-message-panel">
        <div
          className="dynamic-feedback-message-header"
          style={{ backgroundColor: colour, color: readableTextColour }}
        >
          {icon && (
            <span className="icon">
              {React.isValidElement(icon) ? (
                icon
              ) : (
                // @ts-ignore
                (<FontAwesomeIcon icon={icon} />)
              )}
            </span>
          )}
          {dynamicFeedbackMessage.title && (
            <h4>{dynamicFeedbackMessage.title}</h4>
          )}
          {dynamicFeedbackMessage.isCancellable && (
            <span
              className="cancel-dynamic-feedback-trigger"
              onClick={onCancel}
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          )}
        </div>
        {!!dynamicFeedbackMessage.body && (
          <div className="dynamic-feedback-message-body">
            {dynamicFeedbackMessage.body}
          </div>
        )}
      </div>
    </div>
  );
};

export default DynamicFeedbackMessage;
