import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import { UserStatus } from '@payaca/types/userTypes';
import { useParams } from 'react-router';
import useGetChildAccount from '../../../api/queries/childAccounts/useGetChildAccount';

const ChildAccountUsers = () => {
  const { accountId } = useParams<{ accountId: string }>();

  const { data: childAccount, isLoading } = useGetChildAccount(accountId);

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <ManageableItemsList>
          <ManageableItemsList.Table
            uniqueKey="fullName"
            items={childAccount?.users || []}
            isLoading={isLoading}
          >
            <ManageableItemsList.Table.Column field="fullName" header="Name" />
            <ManageableItemsList.Table.Column field="email" header="Email" />
            <ManageableItemsList.Table.Column field="phone" header="Phone" />
            <ManageableItemsList.Table.Column
              field="status"
              header="Status"
              render={(status) => (
                <td className={`px-2 ${statusClassMap[status as UserStatus]}`}>
                  {status}
                </td>
              )}
            />
            <ManageableItemsList.Table.Column
              field="systemRole"
              header="Role"
            />
          </ManageableItemsList.Table>
        </ManageableItemsList>
      </Card.Body>
    </Card>
  );
};

const statusClassMap = {
  [UserStatus.ACTIVE]: 'bg-green-700 text-white',
  [UserStatus.DEACTIVATED]: 'bg-gray-200',
  [UserStatus.INVITED]: 'bg-yellow-400',
  [UserStatus.NOT_VERIFIED]: 'bg-red-200',
};

export default ChildAccountUsers;
