import {
  PurchaseOrderCityPlumbingStatus,
  PurchaseOrderStatus,
  ReadablePurchaseOrderCityPlumbingStatus,
} from '@payaca/types/materialsListTypes';
import { BadgeColourVariant } from '@payaca/types/plBadge';
import { checkUnreachable } from '@payaca/utilities/guards';
import { PurchaseOrderStatus as GqlPurchaseOrderStatus } from '../gql/graphql';

export type PurchaseOrderBadgeConfig = {
  colour?: BadgeColourVariant;
  variant?: 'solid' | 'soft' | 'outline';
  label: string;
};
export const getIndirectPurchaseOrderBadgeConfig = (
  status?: PurchaseOrderStatus | GqlPurchaseOrderStatus
): PurchaseOrderBadgeConfig => {
  switch (status) {
    case PurchaseOrderStatus.PURCHASED:
    case 'PURCHASED':
      return {
        variant: 'soft',
        colour: 'teal',
        label: 'Purchased',
      };
    case PurchaseOrderStatus.SENT:
    case 'SENT':
      return {
        variant: 'soft',
        colour: 'yellow',
        label: 'Sent',
      };
    case PurchaseOrderStatus.VOID:
    case 'VOID':
      return {
        variant: 'soft',
        colour: 'red',
        label: 'Void',
      };
    case PurchaseOrderStatus.DRAFT:
    case 'DRAFT':
      return {
        variant: 'soft',
        colour: 'black',
        label: 'Draft',
      };
    default: {
      checkUnreachable(status as never);
      return {
        variant: 'soft',
        colour: 'blue',
        label: status || 'Unknown',
      };
    }
  }
};

export const getCityPlumbingPurchaseOrderBadgeConfig = (
  status?: PurchaseOrderCityPlumbingStatus
): PurchaseOrderBadgeConfig => {
  const label = status
    ? ReadablePurchaseOrderCityPlumbingStatus[status]
    : 'Unknown';
  switch (status) {
    case 'submitted':
      return {
        variant: 'soft',
        colour: 'blue',
        label,
      };
    case 'acknowledged':
      return {
        variant: 'soft',
        colour: 'yellow',
        label,
      };
    case 'cancelled':
      return {
        variant: 'soft',
        colour: 'red',
        label,
      };
    case 'collected':
      return {
        variant: 'soft',
        colour: 'teal',
        label,
      };
    case 'delivered':
      return {
        variant: 'soft',
        colour: 'teal',
        label,
      };
    case 'ready-for-collection':
      return {
        variant: 'soft',
        colour: 'orange',
        label,
      };
    case 'ready-for-delivery':
      return {
        variant: 'soft',
        colour: 'orange',
        label,
      };
    default: {
      checkUnreachable(status as never);
      return {
        variant: 'soft',
        colour: 'black',
        label,
      };
    }
  }
};
