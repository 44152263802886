import { useMemo } from 'react';

import { useAccountSubscription } from '@payaca/store/hooks/appState';
import { useAccountUsers } from '../utils/storeHooks';

import { AllSystemRoles } from '@payaca/permissions/default-role.config';
import {
  getUserRoles,
  getUserSubscriptionLevelFromSubscriptionMapping,
  userHasRequiredPermission,
} from '@payaca/permissions/permissions.utils';

export const useUsersWithPermissions = (userRequiredPermission?: string[]) => {
  const accountUsers = useAccountUsers();
  const accountSubscription = useAccountSubscription();

  const getUserRolesForUserSystemRole = (userSystemRole: AllSystemRoles) => {
    const subscriptionLevel = getUserSubscriptionLevelFromSubscriptionMapping(
      accountSubscription?.subscriptionProduct.mapping
    );
    const roles = getUserRoles(subscriptionLevel, userSystemRole, {});
    return roles;
  };

  const usersWithPermissions = useMemo(() => {
    const users = accountUsers.filter((x) => {
      const userRoles = getUserRolesForUserSystemRole(
        x.systemRole as AllSystemRoles
      );
      const assigneeUserHasPermission = userRequiredPermission?.length
        ? userHasRequiredPermission(userRoles, userRequiredPermission)
        : true;
      return (!x.deactivatedAt || x.inviteToken) && assigneeUserHasPermission;
    });
    return users;
  }, [accountUsers, getUserRolesForUserSystemRole, userRequiredPermission]);

  return usersWithPermissions;
};
