// https://tkdodo.eu/blog/effective-react-query-keys#use-query-key-factories

const proposalKeys = {
  all: ['proposals'] as const,
  proposal: (proposalId: string) => {
    return [...proposalKeys.all, 'proposal', proposalId] as const;
  },
};

export default proposalKeys;
