import { Subset } from '@payaca/utilities/types';
import { EntityType } from './entity-type';
import { TimestampedEntity } from './timestampedEntityTypes';

// 50mb
export const UPLOAD_MAX_FILESIZE_LIMIT = 50 * 1024 * 1024;

export interface Upload extends TimestampedEntity {
  id: number;
  accountId?: number;
  url: string;
  thumbnailUrl?: string;
  fileName: string;
  fileExtension?: string;
  mimeType?: string;
  fileSize: number; // bytes
  isSystemManaged: boolean;
}

export interface UploadAttachableEntity {
  uploadIds: number[];
}

export type UploadAttachableEntityType = Subset<
  EntityType,
  | 'account'
  | 'automationAction'
  | 'changeProposal'
  | 'cityPlumbingProduct'
  | 'deal'
  | 'documentForm'
  | 'invoice'
  | 'material'
  | 'purchaseOrder'
  | 'scheduledEvent'
  | 'timelog'
  | 'user'
>;

export type ImageEditConfig = {
  crop: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
  resize: { width: number; height: number };
};
