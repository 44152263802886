/**
 * For direct purchase orders
 * i.e. Purchase orders with City Plumbing using their API
 */
import Card from '@payaca/components/plCard/Card';
import { useDeal } from '@payaca/store/hooks/appState';
import { HydratedPurchaseOrder } from '@payaca/types/materialsListTypes';
import { FC } from 'react';
import useGetSupplierBranches from '../../../../api/queries/suppliers/useGetSupplierBranches';
import { getCityPlumbingPurchaseOrderBadgeConfig } from '../../../../helpers/purchaseOrders';
import PreviewDocument from '../../../components/PreviewQuoteInvoice/PreviewDocument';
import PurchaseOrderDocument from '../../../components/purchaseOrderDocument/PurchaseOrderDocument';
import SupplierBranchLink from '../../../components/supplierBranchLink/SupplierBranchLink';
import PurchaseOrderPageSkeletonLoader from '../PurchaseOrderPageSkeletonLoader';

type Props = {
  purchaseOrder: HydratedPurchaseOrder;
  projectId?: number;
};
const DirectPurchaseOrder: FC<Props> = (props) => {
  const { purchaseOrder, projectId } = props;
  const deal = useDeal(projectId || undefined);

  const purchaseOrderBadgeConfig = getCityPlumbingPurchaseOrderBadgeConfig(
    purchaseOrder?.metadata?.status
  );
  const status = purchaseOrder?.metadata?.status;

  const { branches } = useGetSupplierBranches(
    purchaseOrder.supplier.id.toString(),
    !!purchaseOrder.metadata?.branchId
  );

  const branch = branches?.find(
    (branch) => branch.id === purchaseOrder.metadata?.branchId
  );

  return (
    <PreviewDocument
      title={`Purchase order ${purchaseOrder?.reference || ''}: ${
        purchaseOrder?.supplier?.name || ''
      }`}
      badge={{
        variant: purchaseOrderBadgeConfig.variant,
        colour: purchaseOrderBadgeConfig.colour,
        children: purchaseOrderBadgeConfig.label,
      }}
    >
      {status === 'ready-for-collection' && branch && (
        <div className="mb-2">
          Collect from <SupplierBranchLink branch={branch} />
        </div>
      )}
      {purchaseOrder ? (
        <Card>
          <Card.Body>
            <PurchaseOrderDocument
              purchaseOrder={purchaseOrder}
              project={
                deal
                  ? {
                      reference:
                        deal.customReference || deal.reference?.toString(),
                    }
                  : undefined
              }
            />
          </Card.Body>
        </Card>
      ) : (
        <PurchaseOrderPageSkeletonLoader />
      )}
    </PreviewDocument>
  );
};

export default DirectPurchaseOrder;
