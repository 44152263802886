import useGetEmailTemplateAvailableVariables from '@/api/queries/emailTemplates/useGetEmailTemplateAvailableVariables';
import { EmailTemplateType } from '@/gql/graphql';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import SchemaTreeView from '@payaca/components/plTree/JSONSchemaTreeView';
import UntitledLoading from '@payaca/components/plUntitledLoading/UntitledLoading';
import { FC } from 'react';

const AvailableEmailTemplateVariablesList: FC<{
  emailTemplateType: EmailTemplateType;
}> = (props) => {
  const { emailTemplateType } = props;

  const { data, isLoading } =
    useGetEmailTemplateAvailableVariables(emailTemplateType);

  const { pushToast } = useToastContext();

  const handleCopy = async (text: string) => {
    await navigator.clipboard.writeText(text);
    pushToast({
      variant: 'white',
      icon: 'success',
      message: 'Copied to clipboard',
    });
  };

  return (
    <div>
      <h4 className="mb-2">Available variables</h4>

      {isLoading ? (
        <UntitledLoading className="w-5 h-5" />
      ) : data?.emailTemplateAvailableVariables?.__typename ===
        'JSONSchemaVariablesResponse' ? (
        <SchemaTreeView schema={data?.emailTemplateAvailableVariables.schema} />
      ) : (
        <div className="grid grid-cols-2">
          {data?.emailTemplateAvailableVariables.variables.map((variable) => {
            return (
              <button
                type="button"
                key={variable.key}
                className="p-4 hover:bg-gray-50 cursor-pointer bg-white text-left border-b odd:border-r odd:[&:nth-last-child(2)]:border-b-0 last:border-b-0"
                onClick={() => handleCopy(`{{${variable.key}}}`)}
              >
                <div className="flex justify-between">
                  <h4>{variable.label}</h4>
                  <p>
                    {'{{'}
                    {variable.key}
                    {'}}'}
                  </p>
                </div>
                <p className="supporting-body text-gray-500">
                  {variable.example}
                </p>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AvailableEmailTemplateVariablesList;
