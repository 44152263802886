import { FC, useEffect, useRef } from 'react';

const ShadowDomRenderer: FC<{ html: string }> = ({ html }) => {
  const shadowHostRef = useRef<HTMLDivElement>(null);
  const shadowRootRef = useRef<ShadowRoot | null>(null);

  useEffect(() => {
    if (shadowHostRef.current && !shadowRootRef.current) {
      // Attach shadow DOM only once
      shadowRootRef.current = shadowHostRef.current.attachShadow({
        mode: 'open',
      });
    }

    if (shadowRootRef.current) {
      // Update shadow DOM content on each html change
      shadowRootRef.current.innerHTML = html;
    }
  }, [html]);

  return <div ref={shadowHostRef}></div>;
};

export default ShadowDomRenderer;
