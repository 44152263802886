import useCreateEmailTemplate from '@/api/mutations/emailTemplates/useCreateEmailTemplate';
import useDeleteEmailTemplate from '@/api/mutations/emailTemplates/useDeleteEmailTemplate';
import useUpdateEmailTemplate from '@/api/mutations/emailTemplates/useUpdateEmailTemplate';
import emailTemplateKeys from '@/api/queries/emailTemplates/keyFactor';
import useGetEmailTemplates from '@/api/queries/emailTemplates/useGetEmailTemplates';
import { EmailTemplateType } from '@/gql/graphql';
import AvailableEmailTemplateVariablesList from '@/ui/components/companySettings/components/AvailableEmailTemplateVariablesList';
import EmailTemplateUploadField from '@/ui/components/companySettings/components/EmailTemplateUploadField';
import { zodHumanFriendlyFormErrorMap } from '@/utils/zod';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Modal, { IProps as IModalProps } from '@payaca/components/plModal/Modal';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { useQueryClient } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';

type AcceptedEmailTemplateType = Extract<
  EmailTemplateType,
  'PROPOSAL' | 'INVOICE'
>;

const emailTemplateTypeToLabel: Record<AcceptedEmailTemplateType, string> = {
  PROPOSAL: 'Proposal',
  INVOICE: 'Invoice',
};

type TFormState = {
  template?: {
    raw: string;
    preview: string;
  };
};

const formSchema = z.object({
  template: z.object({
    raw: z.string().min(1),
    preview: z.string().min(1),
  }),
});

const CreateMailTemplateModalContent: FC<{
  templateType: AcceptedEmailTemplateType;
  defaultValues?: TFormState;
  isProcessing?: boolean;
  onSubmit: (state: TFormState) => void;
  onDelete: () => void;
}> = (props) => {
  const { templateType, isProcessing, onSubmit, defaultValues, onDelete } =
    props;

  const [isBlockDeleteModalOpen, setIsBlockDeleteModalOpen] = useState(false);

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(formSchema, {
      errorMap: zodHumanFriendlyFormErrorMap,
    }),
    defaultValues,
  });

  return (
    <>
      <form onSubmit={formMethods.handleSubmit(onSubmit, console.error)}>
        <Modal.Body className="space-y-4">
          <Controller
            render={({ field: { onChange, value } }) => {
              return (
                <EmailTemplateUploadField
                  label={`${emailTemplateTypeToLabel[templateType]} template`}
                  validationState={
                    formMethods.formState.errors.template
                      ? {
                          isValid: false,
                          validationMessages: ['Template is required'],
                        }
                      : undefined
                  }
                  type={templateType}
                  value={value}
                  onChange={(value) => onChange(value || null)}
                />
              );
            }}
            name={'template'}
            control={formMethods.control}
          />

          <AvailableEmailTemplateVariablesList
            emailTemplateType={templateType}
          />
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            {defaultValues?.template && (
              <Button
                disabled={isProcessing}
                isProcessing={isProcessing}
                variant={EBtnVariant.Outline}
                colour={EBtnColour.Red}
                onClick={() => setIsBlockDeleteModalOpen(true)}
              >
                Delete
              </Button>
            )}

            <Button
              disabled={!formMethods.formState.isDirty || isProcessing}
              isProcessing={isProcessing}
              type="submit"
            >
              {defaultValues?.template ? 'Edit' : 'Create'}{' '}
              {emailTemplateTypeToLabel[templateType]} email template
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </form>

      <Modal
        zIndexLevel={2}
        isOpen={isBlockDeleteModalOpen}
        title="Delete email template"
        onClose={() => setIsBlockDeleteModalOpen(false)}
      >
        <Modal.Body>
          <p>
            Are you sure you want to delete this email template? This action
            cannot be undone.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Modal.Footer.Actions>
            <Button
              className="ml-2"
              variant={EBtnVariant.Outline}
              onClick={() => setIsBlockDeleteModalOpen(false)}
            >
              Cancel
            </Button>

            <Button
              colour={EBtnColour.Red}
              onClick={() => {
                setIsBlockDeleteModalOpen(false);
                onDelete();
              }}
            >
              Delete
            </Button>
          </Modal.Footer.Actions>
        </Modal.Footer>
      </Modal>
    </>
  );
};

type TProps = {
  templateType: AcceptedEmailTemplateType;
} & Omit<IModalProps, 'title'>;

const CreateEditEmailTemplateModal: FC<TProps> = (props) => {
  const { templateType, onClose, ...rest } = props;

  const { pushToast } = useToastContext();

  const { data: emailTemplates, isLoading: isLoadingExistingTemplate } =
    useGetEmailTemplates({
      types: [templateType],
    });
  const existingTemplate = emailTemplates?.emailTemplates[0];

  const queryClient = useQueryClient();
  const { mutateAsync: createEmailTemplate, isLoading: isCreateTemplate } =
    useCreateEmailTemplate();

  const { mutateAsync: updateEmailTemplate, isLoading: isUpdateTemplate } =
    useUpdateEmailTemplate();

  const {
    mutateAsync: deleteEmailTemplateMutation,
    isLoading: isDeletingTemplate,
  } = useDeleteEmailTemplate();

  const handleSubmit = async (state: TFormState) => {
    if (!state.template) {
      return;
    }

    try {
      if (existingTemplate) {
        await updateEmailTemplate({
          publicId: existingTemplate.id,
          name: existingTemplate.name,
          template: state.template.raw,
        });
      } else {
        await createEmailTemplate({
          name: templateType,
          type: templateType,
          template: state.template.raw,
        });
      }
    } catch (e) {
      pushToast({
        variant: 'white',
        icon: 'error',
        message: 'Failed to update email template',
      });
    }

    await queryClient.invalidateQueries({
      queryKey: emailTemplateKeys.emailTemplates(),
    });

    onClose?.();
  };

  const handleDelete = async () => {
    if (!existingTemplate) {
      return;
    }

    try {
      await deleteEmailTemplateMutation({
        publicId: existingTemplate.id,
      });
    } catch (e) {
      pushToast({
        variant: 'white',
        icon: 'error',
        message: 'Failed to delete email template',
      });
    }

    await queryClient.invalidateQueries({
      queryKey: emailTemplateKeys.emailTemplates(),
    });

    onClose?.();
  };

  return (
    <Modal
      title={`${existingTemplate ? 'Edit' : 'Create'} ${
        emailTemplateTypeToLabel[templateType]
      } email template`}
      size="lg"
      onClose={onClose}
      {...rest}
    >
      {isLoadingExistingTemplate ? (
        <>Loading...</>
      ) : (
        <CreateMailTemplateModalContent
          onSubmit={handleSubmit}
          defaultValues={
            existingTemplate
              ? {
                  template: {
                    raw: existingTemplate.template,
                    preview: existingTemplate.preview,
                  },
                }
              : undefined
          }
          templateType={templateType}
          isProcessing={
            isCreateTemplate || isUpdateTemplate || isDeletingTemplate
          }
          onDelete={handleDelete}
        />
      )}
    </Modal>
  );
};

export default CreateEditEmailTemplateModal;
