import { Disclosure } from '@headlessui/react';
import UntitledIcon from '@payaca/untitled-icons';
import { FC, PropsWithChildren, ReactNode } from 'react';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import Tooltip from '../plTooltip/Tooltip';
import { clstx } from '../utils';

export type TProps = {
  title: string | ReactNode;
  titleTooltip?: string;
  chevronPosition?: 'left' | 'right';
  defaultOpen?: boolean;
  panelClassName?: string;
  disabled?: boolean;
};

const Accordion: FC<PropsWithChildren<TProps>> = (props) => {
  const {
    title,
    titleTooltip,
    children,
    chevronPosition = 'right',
    defaultOpen,
    panelClassName,
    disabled = false,
  } = props;

  return (
    <>
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <ConditionalWrapper
              condition={!!titleTooltip}
              Wrapper={Tooltip}
              wrapperProps={{
                className: '!block',
                tooltipContent: titleTooltip,
              }}
            >
              <Disclosure.Button
                className={clstx(
                  'prose bg-transparent p-0 w-full flex gap-2 cursor-pointer text-left',
                  chevronPosition === 'left' && 'flex-row-reverse justify-end',
                  chevronPosition === 'right' && 'justify-between'
                )}
                disabled={disabled}
              >
                {typeof title === 'string' ? (
                  <h4 className="font-bold">{title}</h4>
                ) : (
                  title
                )}

                {!disabled && (
                  <UntitledIcon
                    name={open ? 'chevron-up' : 'chevron-down'}
                    className="h-7 w-7 shrink-0"
                  />
                )}
              </Disclosure.Button>
            </ConditionalWrapper>

            {children && (
              <Disclosure.Panel className={panelClassName}>
                {children}
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Accordion;
