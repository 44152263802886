import { isNullish } from '@payaca/utilities/guards';

type Props = {
  uploadCsvError: any;
};
const BulkCsvErrors = ({ uploadCsvError }: Props) => {
  if (!uploadCsvError) {
    return null;
  }

  const isValidationError = Array.isArray(uploadCsvError);
  if (isValidationError) {
    return (
      <div>Sorry, there was an error importing your CSV file, please address the
                following before trying again.
                <ul className="errors-wrapper">
          {uploadCsvError.map(
            (bulkUploadError: any, bulkUploadErrorIndex: number) => {
              return isNullish(bulkUploadError.rowNumber) ? (
                // invalid row
                (<li key={bulkUploadErrorIndex}>"{bulkUploadError.columnHeader}" is an invalid
                                    column
                                  </li>)
              ) : (
                // invalid cell
                (<li key={bulkUploadErrorIndex}>
                  <span>
                    Error on row {bulkUploadError.rowNumber} with{' '}
                    {bulkUploadError.columnHeader}:{' '}
                  </span>
                  {bulkUploadError.errors.map(
                    (error: string, bulkUploadErrorIndex: number) => {
                      return <span key={bulkUploadErrorIndex}>{error}</span>;
                    }
                  )}
                </li>)
              );
            }
          )}
        </ul>
      </div>
    );
  } else if (uploadCsvError === 'UPLOAD_LIMIT_EXCEEDED') {
    return (
      <div>
        Sorry, your file exceeds the limit. Please provide a file containing
        less than 3000 rows.
      </div>
    );
  } else {
    return <div>Sorry, there was an error uploading your rows</div>;
  }
};

export default BulkCsvErrors;
