import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  GetFulfillmentForMaterialsInput,
  GetFulfillmentOptionsForMaterialsQuery,
} from '@/gql/graphql';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import fulfillmentKeys from './keyFactory';

const GET_FULFILLMENT_OPTIONS_FOR_MATERIALS = graphql(`
  query GetFulfillmentOptionsForMaterials(
    $input: GetFulfillmentForMaterialsInput
  ) {
    fulfillmentOptionsForMaterials(input: $input) {
      __typename
      isFullFulfillment
      fulfillmentOptionId
      stock {
        requestedQuantity
        availableQuantity
        branchStock
        isFullMaterialFulfillment
        productId
        material {
          id
          name
        }
      }
      supplier {
        id
      }
      branch {
        id
        name
        operatingHours
        address {
          line1
          line2
          city
          postcode
          country
          fullLocalAddress
        }
        url
      }
      ... on SupplierMaterialsCollectionFulfillment {
        collectionFromDate
      }
      ... on SupplierMaterialsDeliveryFulfillment {
        deliveryFromDate
        deliveryToDate
      }
    }
  }
`);

const useGetFulfillmentOptionsForMaterials = (
  opts: {
    input?: GetFulfillmentForMaterialsInput;
  },
  queryOptions?: UseQueryOptions<GetFulfillmentOptionsForMaterialsQuery>
) => {
  const { data, ...rest } = useQuery({
    queryKey: fulfillmentKeys.fulfillmentOptionsForMaterials(),
    queryFn: () => {
      return gqlClient.request(GET_FULFILLMENT_OPTIONS_FOR_MATERIALS, {
        input: opts.input,
      });
    },
    enabled: false,
    ...queryOptions,
  });

  return {
    fulfillmentOptionsForMaterials: data?.fulfillmentOptionsForMaterials,
    data,
    ...rest,
  };
};

export default useGetFulfillmentOptionsForMaterials;
