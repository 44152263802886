import useGetProjectCustomFields from '@/api/queries/project/useGetProjectCustomFields';
import EditCustomFieldsModal from '@/ui/components/projectCustomFieldGroup/EditCustomFieldsModal';
import Accordion from '@payaca/components/plAccordion/Accordion';
import AccordionWithChildCount from '@payaca/components/plAccordionWithChildCount/AccordionWithChildCount';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import ViewFieldset from '@payaca/components/plFieldset/ViewFieldset';
import CustomFieldReadonly from '@payaca/custom-fields-react/components/CustomFieldReadonly';
import {
  CustomFieldDefinition,
  CustomFieldHelperType,
  CustomFieldType,
  FieldsetCustomFieldDefinition,
} from '@payaca/custom-fields/types/index';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { Deal } from '@payaca/types/dealTypes';
import { FC, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';

type ModalState = {
  activeModal: 'editCustomFields' | null;
  fields?: CustomFieldDefinition[];
  value?: Record<string, any>;
  identifier?: CustomFieldHelperType[CustomFieldType]['definition']['identifier'];
};

type CustomFieldWithValue<T extends CustomFieldType = CustomFieldType> = {
  definition: CustomFieldHelperType[T]['definition'];
  value: CustomFieldHelperType[T]['value'] | null;
};

type ModalAction =
  | {
      type: 'openEditCustomFields';
      payload: CustomFieldWithValue[];
    }
  | {
      type: 'closeModal';
    };

const modalReducer = (state: ModalState, action: ModalAction): ModalState => {
  switch (action.type) {
    case 'openEditCustomFields': {
      const customFields = action.payload;

      if (
        customFields.length === 1 &&
        customFields[0].definition.type === 'fieldset'
      ) {
        return {
          activeModal: 'editCustomFields',
          fields: customFields[0].definition.children,
          value: customFields[0]
            .value as CustomFieldHelperType['fieldset']['value'],
          identifier: customFields[0].definition.identifier,
        };
      }

      const validCustomFields = customFields.filter(
        (customField) => customField.definition.type !== 'fieldset'
      );

      const fields = validCustomFields.map(
        (customField) => customField.definition
      );

      const value = validCustomFields.reduce(
        (acc, customField) => {
          acc[customField.definition.identifier] = customField.value;

          return acc;
        },
        {} as Record<string, any>
      );

      return {
        activeModal: 'editCustomFields',
        fields,
        value,
      };
    }
    case 'closeModal':
      return { activeModal: null };
    default:
      return state;
  }
};

export const ProjectCustomFieldGroup: FC<{ projectId: Deal['id'] }> = ({
  projectId,
}) => {
  const [modalState, modalDispatch] = useReducer(modalReducer, {
    activeModal: null,
  });
  const { customFields } = useGetProjectCustomFields(projectId);

  const fieldsetCustomFields = customFields?.filter(
    (x) => x.field.type === 'fieldset'
  );
  const simpleCustomFields = customFields?.filter(
    (x) => x.field.type !== 'fieldset'
  );

  if (!customFields?.length) {
    return (
      <Card sizeVariant={CardSizeVariant.SM}>
        <Card.Body>
          <AccordionWithChildCount
            title="Custom Fields"
            count="0"
            chevronPosition="left"
          >
            <div className="mt-2 flex flex-col gap-4">
              <PermissionGuard
                renderIfHasPermissions={[
                  AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS,
                ]}
              >
                <p>
                  <Link to={'/companySettings/custom-fields'}>
                    Configure additional fields
                  </Link>
                </p>
              </PermissionGuard>
              <PermissionGuard
                renderIfMissingPermissions={[
                  AccountsPermissions.MANAGE_CUSTOM_FIELD_GROUPS,
                ]}
              >
                <p>Contact admin to configure additional fields</p>
              </PermissionGuard>
            </div>
          </AccordionWithChildCount>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <div className="space-y-4">
        {fieldsetCustomFields?.map((fieldset) => {
          const fieldsetDefinition = new FieldsetCustomFieldDefinition(
            fieldset.field.schema
          );
          const value = fieldset.value ? JSON.parse(fieldset.value) : null;

          return (
            <CustomFieldsViewCard
              key={fieldsetDefinition.identifier}
              label={fieldsetDefinition.label}
              fields={fieldsetDefinition.children}
              value={value}
              onEdit={() => {
                modalDispatch({
                  type: 'openEditCustomFields',
                  payload: [
                    {
                      value,
                      definition: fieldsetDefinition,
                    },
                  ],
                });
              }}
            />
          );
        })}

        {!!simpleCustomFields?.length && (
          <CustomFieldsViewCard
            label="Additional fields"
            fields={simpleCustomFields.map((customField) =>
              CustomFieldDefinition.fromSchema(customField.field.schema)
            )}
            value={simpleCustomFields.reduce(
              (acc, customField) => {
                acc[customField.field.identifier] = customField.value
                  ? JSON.parse(customField.value)
                  : null;

                return acc;
              },
              {} as Record<string, any>
            )}
            onEdit={() => {
              if (!simpleCustomFields) {
                return;
              }

              modalDispatch({
                type: 'openEditCustomFields',
                payload: simpleCustomFields.map((customField) => {
                  const definition = CustomFieldDefinition.fromSchema(
                    customField.field.schema
                  );

                  const value = customField.value
                    ? JSON.parse(customField.value)
                    : null;

                  if (definition.typecheckValue(value)) {
                    return {
                      value: value,
                      definition: definition,
                    };
                  } else {
                    return {
                      value: null,
                      definition: definition,
                    };
                  }
                }),
              });
            }}
          />
        )}
      </div>

      <EditCustomFieldsModal
        isOpen={modalState.activeModal === 'editCustomFields'}
        onClose={() => modalDispatch({ type: 'closeModal' })}
        projectId={projectId}
        fields={modalState.fields}
        initialValue={modalState.value}
        fieldsetIdentifier={modalState.identifier}
      />
    </>
  );
};

const CustomFieldsViewCard: FC<{
  label: string;
  fields: CustomFieldHelperType[CustomFieldType]['definition'][];
  value: CustomFieldHelperType['fieldset']['value'] | null;
  onEdit?: () => void;
}> = (props) => {
  const { label, fields, value, onEdit } = props;

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <Accordion
          title={
            <div className="flex gap-4 items-start w-full justify-between">
              <h4 className="font-bold">{label}</h4>

              <Button
                className="-m-2"
                variant={EBtnVariant.White}
                size={EBtnSize.Small}
                onClick={(e) => {
                  e.stopPropagation();
                  onEdit?.();
                }}
              >
                Edit
              </Button>
            </div>
          }
          chevronPosition="left"
        >
          <ViewFieldset className="mt-2 grid-cols-[50%_auto]">
            {fields.map((child) => {
              return (
                <CustomFieldReadonly
                  key={child.identifier}
                  value={value?.[child.identifier]}
                  definition={child}
                />
              );
            })}
          </ViewFieldset>
        </Accordion>
      </Card.Body>
    </Card>
  );
};
