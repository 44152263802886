import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import meKeys from './keyFactory';

const GetMyAccountAnalytics = graphql(`
  query MyAccountAnalytics {
    me {
      user {
        account {
          analytics {
            embeddableDashboards {
              title
              uniqueIdentifier
              embedUrl
            }
          }
        }
      }
    }
  }
`);

const useGetMyAccountAnalytics = () => {
  const { data, ...rest } = useQuery({
    queryKey: meKeys.accountAnalytics(),
    queryFn: () => {
      return gqlClient.request(GetMyAccountAnalytics);
    },
    refetchOnWindowFocus: false,
  });

  return { data: data?.me.user.account.analytics, ...rest };
};

export default useGetMyAccountAnalytics;
