import Card, { CardSizeVariant } from '@payaca/components/plCard/Card';
import UntitledIcon, { TIconName } from '@payaca/untitled-icons';
import { Nullish } from '@payaca/utilities/types';
import { FC } from 'react';
import { useParams } from 'react-router';
import useGetChildAccount from '../../../api/queries/childAccounts/useGetChildAccount';

const ChildAccountCompany = () => {
  const { accountId } = useParams<{ accountId: string }>();
  const { data: childAccount } = useGetChildAccount(accountId);

  return (
    <Card sizeVariant={CardSizeVariant.SM}>
      <Card.Body>
        <div className="flex justify-between">
          <h3>About</h3>
        </div>
        <div className="mt-2 flex flex-col gap-y-2">
          <AccountProperty
            iconName="building-01.3"
            value={childAccount?.name}
          />
          <AccountProperty iconName="mail-02.3" value={childAccount?.email} />
          <AccountProperty iconName="phone.3" value={childAccount?.phone} />
        </div>
      </Card.Body>
    </Card>
  );
};

type AccountPropertyProps = {
  iconName: TIconName;
  value?: Nullish<string | number>;
};
const AccountProperty: FC<AccountPropertyProps> = ({ iconName, value }) => {
  if (!value) {
    return null;
  }
  return (
    <div className="flex gap-x-3 items-center">
      <UntitledIcon name={iconName} className="h-4 w-4 text-blue-900" />
      <span>{value}</span>
    </div>
  );
};

export default ChildAccountCompany;
