import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import useGetEmailTemplates from '@/api/queries/emailTemplates/useGetEmailTemplates';
import { actions as userActions } from '@/api/users';
import { EmailTemplate as EmailTemplateGraphql } from '@/gql/graphql';
import CreateEditAutomationEmailTemplateModal, {
  emailTemplateTypeReadableMap,
} from '@/ui/components/companySettings/components/CreateEditAutomationEmailTemplateModal';
import EmailTemplateCards from '@/ui/components/companySettings/components/EmailTemplateCards';
import { useHashFragment } from '@/utils/customHooks';
import { useAccount } from '@/utils/storeHooks';
import {
  faCheckCircle,
  faInfoCircle,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BasicField from '@payaca/components/basicField/BasicField';
import { DynamicFeedbackContext } from '@payaca/components/context/DynamicFeedbackContext';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import Button from '@payaca/components/plButton/Button';
import {
  EBtnColour,
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Card from '@payaca/components/plCard/Card';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import Modal from '@payaca/components/plModal/Modal';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import { VALID_EMAIL_REGEX } from '@payaca/constants';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import * as accountActions from '@payaca/store/account/accountActions';
import { FeedbackLevel } from '@payaca/types/feedbackTypes';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router-dom';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import CompanySettingsTabBase from './CompanySettingsTabBase';

const PUBLIC_EMAIL_DOMAIN_REGEX =
  /@((gmail\.)|(googlemail\.)|(hotmail\.)|(yahoo\.)|(aol\.))/;

enum EmailTemplate {
  ESTIMATE = 'estimate',
  QUOTE = 'quote',
  INVOICE = 'invoice',
}

const EmailTemplateSetting = ({
  title,
  description,
  onClick,
}: {
  title: string;
  description: string;
  onClick: () => void;
}) => {
  return (
    <Card>
      <Card.Body>
        <h4>{title}</h4>
        <p>{description}</p>
        <Button variant={EBtnVariant.LinkInline} onClick={onClick}>
          View/Edit
        </Button>
      </Card.Body>
    </Card>
  );
};

type Props = {
  readOnly: boolean;
};
const CompanySettingsEmailCustomisation: FC<Props> = ({
  readOnly,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const account = useAccount();

  const history = useHistory();
  const { path, url } = useRouteMatch();
  const isCreatingEditingEmailTemplate = useRouteMatch<{
    emailTemplateId: string;
  }>({
    path: `${path}/templates/:emailTemplateId`,
    exact: true,
  });

  const [newEmailAddress, setNewEmailAddress] = useState('');
  const [isNewEmailAddressValid, setIsNewEmailAddressValid] = useState(false);
  const [selectedDomain, setSelectedDomain] = useState<any>(null);
  const [selectedEmail, setSelectedEmail] = useState<any>(null);
  const [
    showConfirmDeleteOutboundEmailAddressModal,
    toggleConfirmDeleteOutboundEmailAddressModal,
  ] = useHashFragment('#confirm-delete-outbound-email-address');
  const [
    showConfirmDeleteOutboundEmailDomainModal,
    toggleConfirmDeleteOutboundEmailDomainModal,
  ] = useHashFragment('#confirm-delete-outbound-email-domain');
  const [showDkimVerificationModal, toggleDkimVerificationModal] =
    useHashFragment('#dkim-verification');
  const [isBusy, setIsBusy] = useState(false);

  const { showDynamicFeedbackMessage } = useContext(DynamicFeedbackContext);

  const { data } = useGetEmailTemplates({
    types: [
      'AUTOMATION_TEMPLATE_ESTIMATE',
      'AUTOMATION_TEMPLATE_EVENT',
      'AUTOMATION_TEMPLATE_PROJECT',
      'AUTOMATION_TEMPLATE_PROPOSAL',
      'AUTOMATION_TEMPLATE_QUOTE',
      'AUTOMATION_TEMPLATE_INVOICE',
    ],
  });

  const persistNewOutboundEmailAddress = useCallback(() => {
    if (isNewEmailAddressValid) {
      setIsBusy(true);
      dispatch(
        accountActions.addOutboundEmailAddress.request(
          newEmailAddress,
          (err) => {
            if (err) {
              showDynamicFeedbackMessage({
                feedbackLevel: FeedbackLevel.ERROR,
                body: (err as any).message.message,
              });
              console.log(err);
              setIsBusy(false);
            } else {
              dispatch(
                userActions.getProfile(() => {
                  setNewEmailAddress('');
                  setIsBusy(false);
                })
              );
            }
          }
        )
      );
    }
  }, [newEmailAddress, isNewEmailAddressValid]);

  const deleteOutboundEmailAddress = useCallback((email: string) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        accountActions.deleteOutboundEmailAddress.request(email, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        })
      );
    });
  }, []);

  const deleteOutboundEmailDomain = useCallback(async (domain: string) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        accountActions.deleteOutboundEmailDomain.request(domain, (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        })
      );
    });
  }, []);

  const checkOutboundEmailAddressConfirmation = useCallback((email: string) => {
    setIsBusy(true);
    dispatch(
      accountActions.checkOutboundEmailAddressConfirmation.request(
        email,
        (err) => {
          if (err) {
            console.log({ err });
            setIsBusy(false);
          } else {
            dispatch(
              userActions.getProfile(() => {
                setIsBusy(false);
              })
            );
          }
        }
      )
    );
  }, []);

  const validEmailAddresses = useMemo(() => {
    return account.outboundEmails.domains.flatMap((domain: any) => {
      if (domain.isDkimVerified) {
        return domain.emails;
      } else {
        return domain.emails.filter((email: any) => email.isConfirmed);
      }
    });
  }, [account.outboundEmails]);

  return (
    <CompanySettingsTabBase className="company-settings-email-customisation">
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.SET_CUSTOM_EMAIL_DOMAIN]}
      >
        {!readOnly && (
          <Card>
            <Card.Body>
              <h2>Outbound Emails</h2>
              <section className="flex flex-col gap-2">
                <h4>Send emails from your own domain</h4>
                <p>
                  If your business has a custom domain (e.g. yourbusiness.com),
                  Payaca can send emails to your customers from an email address
                  on your domain.
                </p>
                {!!account.outboundEmails.domains.length || (
                  <p>
                    To get started, add an email address, and check your inbox
                    for a confirmation email.
                  </p>
                )}
                <div
                  className="add-email-address-row"
                  style={{
                    display: 'flex',
                    gap: '1rem',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <ValidatedFieldWrapper
                    validationResult={{
                      isValid: !newEmailAddress || isNewEmailAddressValid,
                      errors: !newEmailAddress
                        ? []
                        : ([
                            VALID_EMAIL_REGEX.test(newEmailAddress)
                              ? undefined
                              : 'Must be a valid email address',
                            PUBLIC_EMAIL_DOMAIN_REGEX.test(newEmailAddress)
                              ? 'Only custom domains (e.g. yourbusiness.com) are allowed'
                              : undefined,
                          ].filter(Boolean) as Array<string>),
                    }}
                  >
                    <BasicField
                      isDisabled={isBusy}
                      onChange={(x) => {
                        setNewEmailAddress(x['new-email']);
                        setIsNewEmailAddressValid(
                          !x ||
                            (VALID_EMAIL_REGEX.test(x['new-email']) &&
                              !PUBLIC_EMAIL_DOMAIN_REGEX.test(x['new-email']))
                        );
                      }}
                      onKeyPress={(evt) => {
                        if (evt.key === 'Enter') {
                          persistNewOutboundEmailAddress();
                        }
                      }}
                      name="new-email"
                      placeholder="email@domain.com"
                      value={newEmailAddress}
                    ></BasicField>
                  </ValidatedFieldWrapper>
                  <Button
                    variant={EBtnVariant.Outline}
                    size={EBtnSize.Small}
                    disabled={
                      isBusy || !newEmailAddress || !isNewEmailAddressValid
                    }
                    onClick={() => {
                      persistNewOutboundEmailAddress();
                    }}
                  >
                    Add email address
                  </Button>
                </div>
                {!!account.outboundEmails.domains.length && (
                  <>
                    <hr />
                    <h4>Your domains</h4>
                    {account.outboundEmails.domains.map(
                      (x: any, idx: number) => {
                        const { domain, isDkimVerified, emails } = x;
                        return (
                          <div
                            key={idx}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                gap: '1rem',
                                alignItems: 'center',
                                width: '100%',
                                paddingBottom: '0.25rem',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                                marginBottom: '0.5rem',
                              }}
                            >
                              <h5 style={{ flexGrow: 1, margin: 0 }}>
                                {domain}
                              </h5>
                              <FontAwesomeIcon
                                icon={
                                  isDkimVerified ? faCheckCircle : faInfoCircle
                                }
                                color={isDkimVerified ? 'green' : 'orange'}
                                title={
                                  isDkimVerified
                                    ? 'Domain verified'
                                    : 'Domain not verified'
                                }
                              />
                              {!isDkimVerified && (
                                <>
                                  <Button
                                    variant={EBtnVariant.LinkInline}
                                    onClick={() => {
                                      setSelectedDomain(x);
                                      toggleDkimVerificationModal();
                                    }}
                                  >
                                    Verify domain
                                  </Button>
                                </>
                              )}
                              <Button
                                variant={EBtnVariant.LinkInline}
                                onClick={() => {
                                  setSelectedDomain(x);
                                  toggleConfirmDeleteOutboundEmailDomainModal();
                                }}
                              >
                                Delete domain
                              </Button>
                            </div>
                            {emails.map(
                              ({ email, isConfirmed }: any, idx2: number) => (
                                <div
                                  key={idx2}
                                  style={{
                                    display: 'flex',
                                    gap: '1rem',
                                    alignItems: 'center',
                                    marginBottom: '0.25rem',
                                  }}
                                >
                                  <span
                                    style={{
                                      fontFamily: 'monospace',
                                      flexGrow: 1,
                                    }}
                                  >
                                    {email}
                                  </span>
                                  {isConfirmed || isDkimVerified ? (
                                    <FontAwesomeIcon
                                      title="Email confirmed"
                                      icon={faCheckCircle}
                                      color="green"
                                    />
                                  ) : (
                                    <FontAwesomeIcon
                                      title="Email unconfirmed"
                                      icon={faWarning}
                                      color="red"
                                    />
                                  )}

                                  {!(isConfirmed || isDkimVerified) && (
                                    <Button
                                      variant={EBtnVariant.LinkInline}
                                      onClick={() => {
                                        checkOutboundEmailAddressConfirmation(
                                          email
                                        );
                                      }}
                                    >
                                      Check status
                                    </Button>
                                  )}
                                  {!(isConfirmed || isDkimVerified) && (
                                    <Button
                                      variant={EBtnVariant.LinkInline}
                                      onClick={() => {
                                        dispatch(
                                          accountActions.resendOutboundEmailAddressConfirmationEmail.request(
                                            email,
                                            (err) => {
                                              console.log(
                                                'resent confirmation email',
                                                err
                                              );
                                            }
                                          )
                                        );
                                      }}
                                    >
                                      Re-send confirmation email
                                    </Button>
                                  )}
                                  {
                                    <Button
                                      variant={EBtnVariant.LinkInline}
                                      onClick={() => {
                                        setSelectedEmail(email);
                                        toggleConfirmDeleteOutboundEmailAddressModal();
                                      }}
                                    >
                                      Delete email address
                                    </Button>
                                  }
                                </div>
                              )
                            )}
                          </div>
                        );
                      }
                    )}
                  </>
                )}

                {!!account.outboundEmails.domains.length && (
                  <>
                    <hr />
                    <h4>Choose an email address to send from</h4>

                    {Object.entries(account.outboundEmails.defaults).map(
                      ([k, v]: any, idx: number) => (
                        <div
                          key={idx}
                          style={{ display: 'flex', flexDirection: 'row' }}
                        >
                          <DropdownField
                            name={`default-${k}`}
                            label={
                              k === 'default' ? 'Customer-facing emails' : k
                            }
                            options={[
                              {
                                label: 'None selected',
                                value: '',
                              },
                              ...validEmailAddresses.map((x: any) => ({
                                label: x.email,
                                value: x.email,
                              })),
                            ]}
                            value={v?.email || ''}
                            onChange={(x) => {
                              dispatch(
                                accountActions.setDefaultOutboundEmailAddress.request(
                                  'default',
                                  x['default-default'],
                                  (err) => {
                                    if (err) {
                                      console.log(err);
                                    } else {
                                      dispatch(userActions.getProfile());
                                    }
                                  }
                                )
                              );
                            }}
                          />
                        </div>
                      )
                    )}
                  </>
                )}
              </section>
            </Card.Body>
          </Card>
        )}
      </PermissionGuard>

      <EmailTemplateCards />

      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.MANAGE_EMAIL_TEMPLATES]}
      >
        <ManageableItemsList>
          <ManageableItemsList.HeaderBar
            heading="Automation Email Templates"
            buttons={
              <>
                <Button
                  size={EBtnSize.Small}
                  variant={EBtnVariant.White}
                  onClick={() => {
                    history.push(`${url}/templates/new`);
                  }}
                >
                  Create automation email template
                </Button>
              </>
            }
          />

          <ManageableItemsList.Table
            items={data?.emailTemplates || []}
            uniqueKey={'id'}
            onClickRow={(item) => {
              history.push(`${url}/templates/${item.id}`);
            }}
          >
            <ManageableItemsList.Table.Column header="Name" field="name" />
            <ManageableItemsList.Table.Column<EmailTemplateGraphql, 'type'>
              header="Type"
              field="type"
              render={(type) => {
                // @ts-expect-error
                return emailTemplateTypeReadableMap[type] || type;
              }}
            />
          </ManageableItemsList.Table>
        </ManageableItemsList>

        <CreateEditAutomationEmailTemplateModal
          isOpen={!!isCreatingEditingEmailTemplate}
          emailTemplateId={
            isCreatingEditingEmailTemplate?.params.emailTemplateId
          }
          onClose={() => {
            history.push(url);
          }}
        />
      </PermissionGuard>

      <ConfirmDeleteOutboundEmailAddressModal
        isOpen={showConfirmDeleteOutboundEmailAddressModal}
        onClose={() => {
          toggleConfirmDeleteOutboundEmailAddressModal();
          setSelectedEmail(null);
        }}
        onConfirm={async (email) => {
          setIsBusy(true);
          try {
            await deleteOutboundEmailAddress(email);
            dispatch(userActions.getProfile());
          } catch (err) {
            showDynamicFeedbackMessage({
              feedbackLevel: FeedbackLevel.ERROR,
              body: (err as any).message.message,
            });
          } finally {
            setIsBusy(false);
            toggleConfirmDeleteOutboundEmailAddressModal();
            setSelectedEmail(null);
          }
        }}
        email={selectedEmail}
      />
      <ConfirmDeleteOutboundEmailDomainModal
        isOpen={showConfirmDeleteOutboundEmailDomainModal}
        onClose={() => {
          toggleConfirmDeleteOutboundEmailDomainModal();
          setSelectedDomain(null);
        }}
        onConfirm={async (domain) => {
          setIsBusy(true);
          try {
            await deleteOutboundEmailDomain(domain);
            dispatch(userActions.getProfile());
          } catch (err) {
            showDynamicFeedbackMessage({
              feedbackLevel: FeedbackLevel.ERROR,
              body: (err as any).message.message,
            });
          } finally {
            setIsBusy(false);
            toggleConfirmDeleteOutboundEmailDomainModal();
            setSelectedDomain(null);
          }
        }}
        domain={selectedDomain}
      />
      <DkimVerificationModal
        isOpen={showDkimVerificationModal}
        onClose={() => {
          if (selectedDomain) {
            dispatch(
              accountActions.checkOutboundEmailDomainDkimVerification.request(
                selectedDomain.domain,
                (err) => {
                  if (err) {
                    console.log({ err });
                  } else {
                    dispatch(userActions.getProfile());
                  }
                }
              )
            );
          }
          setSelectedDomain(null);
          toggleDkimVerificationModal();
        }}
        domain={selectedDomain}
      />
    </CompanySettingsTabBase>
  );
};

export default CompanySettingsEmailCustomisation;

const ConfirmDeleteOutboundEmailAddressModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  email: any;
  onConfirm: (email: string) => Promise<void>;
}> = ({ isOpen, onClose, email, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete outbound email address"
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete the outbound email address: {email}?
          This will prevent Payaca from sending emails from this address!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            onClick={() => {
              void onConfirm(email);
            }}
          >
            Yes, Delete
          </Button>
          <Button
            variant={EBtnVariant.Outline}
            onClick={() => {
              onClose();
            }}
          >
            No, Cancel
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmDeleteOutboundEmailDomainModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  domain: any;
  onConfirm: (domain: string) => Promise<void>;
}> = ({ isOpen, onClose, domain, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete outbound email domain"
    >
      <Modal.Body>
        <p>
          Are you sure you want to delete the outbound email domain:{' '}
          {domain?.domain}? This will prevent Payaca from sending emails from
          all email addresses on this domain! It will also invalidate any DNS
          records you may have added to verify this domain.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Actions>
          <Button
            colour={EBtnColour.Red}
            onClick={() => {
              void onConfirm(domain?.domain);
            }}
          >
            Yes, Delete
          </Button>
          <Button
            variant={EBtnVariant.Outline}
            onClick={() => {
              onClose();
            }}
          >
            No, Cancel
          </Button>
        </Modal.Footer.Actions>
      </Modal.Footer>
    </Modal>
  );
};

const DkimVerificationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
  domain: any;
}> = ({ isOpen, onClose, domain }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      className="dkim-verification-modal"
      title={
        domain
          ? domain.isDkimVerified
            ? 'Domain verified'
            : `Verify domain: ${domain?.domain}`
          : ''
      }
    >
      <Modal.Body>
        {domain ? (
          <>
            {domain.isDkimVerified ? (
              <>
                <p>
                  You&apos;ve successfully verified your domain:{' '}
                  {domain?.domain}
                </p>
              </>
            ) : (
              <>
                <p>
                  Once a domain has been verified you can add additional email
                  addresses for that domain without needing to confirm them.
                  You&apos;ll also benefit improved deliverability and help to
                  build your domain&apos;s reputation.
                </p>
                <p>
                  You&apos;ll need to update your domain&apos;s DNS
                  configuration by adding the following records. If you&apos;re
                  unsure how to do this, please contact your system
                  administrator or web hosting provider.
                </p>
                <p>
                  <FontAwesomeIcon icon={faWarning} />{' '}
                  <strong>
                    Warning: Do not attempt to modify your DNS configuration
                    unless you are confident you know what you&apos;re doing.
                  </strong>
                </p>
                <hr />
                <h3>DKIM record</h3>
                <dl className="dns-record">
                  <dt>Hostname</dt>
                  <dd>
                    <pre>
                      <code>
                        {domain?.dkimPendingHost.replace(
                          domain?.domain ? `.${domain?.domain}` : '',
                          ''
                        )}
                      </code>
                    </pre>
                  </dd>
                  <dt>TXT</dt>
                  <dd>
                    <pre>
                      <code>{domain?.dkimPendingTextValue}</code>
                    </pre>
                  </dd>
                </dl>
                <hr />
                <h3>Return-Path record</h3>
                <dl className="dns-record">
                  <dt>Hostname</dt>
                  <dd>
                    <pre>
                      <code>pm-bounces</code>
                    </pre>
                  </dd>
                  <dt>CNAME</dt>
                  <dd>
                    <pre>
                      <code>{domain?.returnPathDomainCnameValue}</code>
                    </pre>
                  </dd>
                </dl>
                <hr />
                <p>
                  Please note that DNS records can take up to 24 hours to
                  propagate.
                </p>
                <p>You might want to check back on this page later.</p>
              </>
            )}
          </>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
