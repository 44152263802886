import { nanoid } from 'nanoid';
import React, {
  ComponentProps,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import ConditionalWrapper from '../conditionalWrapper/ConditionalWrapper';
import { clstx } from '../utils';

/**
 *
 * Usage:
    <Field
        {...args}
        validationState={{
        isValid: false,
        validationMessages: [
            "Field must contain one or more capital letters",
            "Field must contain SENSIBLE content",
        ],
        }}
    >
        <Field.Label>Field label</Field.Label>
        <Select options={[{ label: "option 1", value: 1 }]} value={1} />
    </Field>
 *
 */

export type FieldValidationState = {
  isValid: boolean;
  validationMessages?: string[];
};

export const FieldContext = React.createContext<{
  validationState?: FieldValidationState;
  id?: string;
  name?: string;
  horizontal?: boolean;
}>({});

type Props = {
  validationState?: FieldValidationState;
  id?: string;
  name?: string;
  className?: string;
  horizontal?: boolean;
};

const Field: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  validationState,
  name,
  className,
  id: customId,
  horizontal = false,
}) => {
  const { current: id } = useRef(customId || nanoid());

  return (
    <FieldContext.Provider
      value={{
        validationState,
        id,
        name,
        horizontal,
      }}
    >
      <div className={clstx('prose', className)}>
        <ConditionalWrapper
          condition={horizontal}
          Wrapper={'div'}
          wrapperProps={{
            className: 'grid gap-4 grid-cols-[max-content_auto]',
          }}
        >
          {children}
        </ConditionalWrapper>
        <FieldValidationMessages />
      </div>
    </FieldContext.Provider>
  );
};

const Helper: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return <div className="mt-2 text-sm text-gray-500">{children}</div>;
};

const Label: FunctionComponent<Omit<ComponentProps<'label'>, 'htmlFor'>> = ({
  children,
  className,
  ...rest
}) => {
  const { id, horizontal } = useContext(FieldContext);
  return (
    <label
      htmlFor={id}
      className={twMerge(
        'block',
        horizontal ? 'text-gray-600' : 'mb-2',
        className
      )}
      {...rest}
    >
      {children}
    </label>
  );
};

export const FieldValidationMessages: FunctionComponent = () => {
  const { validationState } = useContext(FieldContext);
  return <ValidationMessages validationState={validationState} />;
};

export const ValidationMessages: FunctionComponent<{
  validationState?: FieldValidationState;
}> = ({ validationState }) => {
  const validationMessages = validationState?.validationMessages;

  const textColour = useMemo(() => {
    switch (validationState?.isValid) {
      case true:
        return 'text-teal-500';
      case false:
        return 'text-red-500';
      default:
        return '';
    }
  }, [validationState?.isValid]);

  if (!validationMessages?.length) return null;

  return (
    <ul className={`m-0 mt-2 list-none p-0`}>
      {validationMessages.map((message, i) => (
        <li key={i} className="mb-2 last:mb-0">
          <p className={`m-0 text-base ${textColour}`}>{message}</p>
        </li>
      ))}
    </ul>
  );
};

export default Object.assign(Field, {
  Label,
  Helper,
});
