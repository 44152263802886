import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, useRouteMatch } from 'react-router';

import useGetMaterialCategories from '@/api/queries/materials/useGetMaterialCategories';
import useGetMaterials from '@/api/queries/materials/useGetMaterials';
import useGetSuppliers from '@/api/queries/suppliers/useGetSuppliers';
import { GetMaterialsInput, MaterialsQuery } from '@/gql/graphql';
import { getMaterialsFiltersLocalStorageKey } from '@/helpers/localStorageKeyHelper';
import { useTranslation } from '@/i18n';
import CreateMaterialModal from '@/ui/components/createEditMaterialModal/CreateMaterialModal';
import AuthenticatedPageWrapper from '@/ui/pages/pageWrappers/authenticatedPageWrapper/AuthenticatedPageWrapper';
import { useHashFragment } from '@/utils/customHooks';
import CurrencyValue from '@payaca/components/currencyValue/CurrencyValue';
import Badge from '@payaca/components/plBadge/Badge';
import EclipseBadge from '@payaca/components/plBadge/EclipseBadge';
import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { AppliedFilters } from '@payaca/components/plManageableItemsList/components/MultiFilterSidebar';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import UntitledIcon from '@payaca/untitled-icons';
import { isNotNullish } from '@payaca/utilities/guards';
import ViewMaterialSidebar from './components/ViewMaterialSidebar';

type ListedMaterial = MaterialsQuery['materials']['items'][number];

const initialPagination = {
  limit: 100,
  offset: 0,
};

const ListedMaterialsPage: FC = () => {
  const translate = useTranslation();
  const history = useHistory();
  const [showCreateMaterialModal, toggleCreateMaterialModal] =
    useHashFragment('#create-material');

  const { path, url } = useRouteMatch();
  const isViewMaterialSidebarOpen = useRouteMatch<{
    materialId: string;
  }>(`${path}/:materialId`);

  const [getMaterialsPagination, setGetMaterialsPagination] = useState<{
    limit: number;
    offset: number;
  }>(initialPagination);

  const [getMaterialsInput, setGetMaterialsInput] = useState<GetMaterialsInput>(
    JSON.parse(
      localStorage.getItem(getMaterialsFiltersLocalStorageKey()) || '{}'
    )
  );

  const [searchTerm, setSearchTerm] = useState('');

  const { data: materials, isLoading } = useGetMaterials(
    getMaterialsPagination,
    getMaterialsInput
  );

  const { suppliers } = useGetSuppliers({}, { limit: 100 });
  const { materialCategories } = useGetMaterialCategories();

  const advancedFilterOptions = [
    {
      name: 'managedBy',
      title: 'Source',
      type: 'checkbox' as const,
      options: [
        { label: 'System', value: 'SYSTEM' },
        { label: 'My Materials', value: 'SELF' },
      ],
    },
    {
      name: 'linkedToItems',
      title: 'Linked to Items',
      type: 'radio' as const,
      options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
      ],
    },
  ];

  if (materialCategories) {
    advancedFilterOptions.unshift({
      name: 'categories',
      title: 'Category',
      type: 'checkbox' as const,
      options: materialCategories.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    });
  }

  if (suppliers) {
    advancedFilterOptions.unshift({
      name: 'suppliers',
      title: 'Supplier',
      type: 'checkbox' as const,
      options: suppliers.items.map((supplier) => ({
        label: supplier.name,
        value: supplier.id,
      })),
    });
  }

  const appliedFilters = {
    managedBy: getMaterialsInput.managedBy || [],
    suppliers: getMaterialsInput.suppliers || [],
    categories: getMaterialsInput.categories || [],
    linkedToItems: isNotNullish(getMaterialsInput.linkedToItems)
      ? getMaterialsInput.linkedToItems
      : null,
  };

  const handleApplyFilters = (filters: AppliedFilters) => {
    setGetMaterialsPagination(initialPagination);
    setGetMaterialsInput((s) => ({
      ...s,
      ...filters,
    }));

    localStorage.setItem(
      getMaterialsFiltersLocalStorageKey(),
      JSON.stringify(filters)
    );
  };

  return (
    <>
      <Helmet title={translate('pages.materials.title')} />
      <AuthenticatedPageWrapper className="bg-gray-50">
        <div className="mb-16 p-4">
          <ManageableItemsList>
            <ManageableItemsList.HeaderBar
              heading="Materials"
              subHeading="What you order from Suppliers, these are not shown to your Customer."
              buttons={
                <>
                  <Button
                    size={EBtnSize.Small}
                    onClick={() => toggleCreateMaterialModal()}
                  >
                    Create Material
                  </Button>
                </>
              }
            />
            <ManageableItemsList.ActionBar>
              <ManageableItemsList.ActionBar.SearchInput
                value={searchTerm}
                onChange={setSearchTerm}
                onChangeTimeout={(value) =>
                  setGetMaterialsInput((s) => ({
                    ...s,
                    searchTerm: value,
                  }))
                }
                changeTimeoutMs={250}
              />
              <ManageableItemsList.ActionBar.AdvancedFilter
                onApplyFilters={handleApplyFilters}
                appliedFilters={appliedFilters}
                options={advancedFilterOptions}
              />
            </ManageableItemsList.ActionBar>
            <ManageableItemsList.Table
              isLoading={isLoading}
              items={materials?.materials.items || []}
              uniqueKey="id"
              onClickRow={(material) => {
                history.push(`/materials/${material.id}`);
              }}
            >
              <ManageableItemsList.Table.Column<ListedMaterial, 'image'>
                header="Image"
                field="image"
                render={(image, row) => {
                  if (!image?.thumbnailUrl) {
                    return (
                      <div className="flex h-14 w-14 items-center justify-center">
                        <UntitledIcon
                          name="image-01.3"
                          vectorEffect="non-scaling-stroke"
                          className="h-8 w-8 text-gray-700"
                        />
                      </div>
                    );
                  }

                  return (
                    <img
                      className="h-14 w-14 rounded-md"
                      alt={row.name}
                      src={image.thumbnailUrl}
                    />
                  );
                }}
                className="w-[100px]"
              />
              <ManageableItemsList.Table.Column<ListedMaterial, 'name'>
                header="Material"
                field="name"
              />
              <ManageableItemsList.Table.Column<ListedMaterial, 'suppliedBy'>
                header="Supplier"
                field="suppliedBy"
                render={(suppliedBy) => {
                  if (!suppliedBy) {
                    return null;
                  }

                  return (
                    <EclipseBadge
                      badges={suppliedBy.map((i) => i.supplier.name)}
                      variant="soft"
                      colour="gray"
                    />
                  );
                }}
              />
              <ManageableItemsList.Table.Column<ListedMaterial, 'suppliedBy'>
                header="Price (ex tax)"
                field="suppliedBy"
                className="min-w-[140px] text-right"
                render={(suppliedBy) => {
                  if (!suppliedBy || suppliedBy.length === 0) {
                    return null;
                  }

                  return (
                    <CurrencyValue
                      value={suppliedBy[0].price.unitPriceExcTax.value}
                      currency={suppliedBy[0].price.unitPriceExcTax.currency}
                    />
                  );
                }}
              />
              <ManageableItemsList.Table.Column<ListedMaterial, 'category'>
                header="Category"
                field="category"
                render={(category) => {
                  if (!category) {
                    return null;
                  }

                  return (
                    <Badge variant="soft" colour="gray">
                      {category.name}
                    </Badge>
                  );
                }}
              />
            </ManageableItemsList.Table>

            <ManageableItemsList.PaginationBar
              pageSize={getMaterialsPagination.limit}
              currentPage={
                getMaterialsPagination.offset / getMaterialsPagination.limit + 1
              }
              totalItems={materials?.materials.totalCount || 0}
              onPageChange={(newPage) => {
                setGetMaterialsPagination((s) => ({
                  ...s,
                  offset: (newPage - 1) * s.limit,
                }));
              }}
            />
          </ManageableItemsList>
        </div>

        <CreateMaterialModal
          isOpen={showCreateMaterialModal}
          onClose={toggleCreateMaterialModal}
          onPersistMaterialSuccess={(material) => {
            history.push(`/materials/${material.id}`);
          }}
          enableSupplierMaterialInput={true}
        />
        <ViewMaterialSidebar
          isOpen={!!isViewMaterialSidebarOpen}
          materialId={isViewMaterialSidebarOpen?.params.materialId}
          onClose={() => history.push('/materials')}
        />
      </AuthenticatedPageWrapper>
    </>
  );
};

export default ListedMaterialsPage;
