import useGetEmailTemplates from '@/api/queries/emailTemplates/useGetEmailTemplates';
import DropdownField from '@payaca/components/dropdownField/DropdownField';
import { AutomationEntityTypes } from '@payaca/types/automationTypes';
import { checkUnreachable } from '@payaca/utilities/guards';
import { FC } from 'react';

export interface IProps {
  automationEntityType: AutomationEntityTypes;
  value?: string;
  onChange?: (value: { [key: string]: string }) => void;
  onTouch?: (fieldName: string) => void;
}

const generateAutomationType = (
  automationEntityType: AutomationEntityTypes
) => {
  switch (automationEntityType) {
    case 'Estimate':
      return 'AUTOMATION_TEMPLATE_ESTIMATE';
    case 'Quote':
      return 'AUTOMATION_TEMPLATE_QUOTE';
    case 'Invoice':
    case 'Invoice-v2':
      return 'AUTOMATION_TEMPLATE_INVOICE';
    case 'Event':
      return 'AUTOMATION_TEMPLATE_EVENT';
    case 'Deal':
      return 'AUTOMATION_TEMPLATE_PROJECT';
    case 'Proposal':
      return 'AUTOMATION_TEMPLATE_PROPOSAL';
    default:
      checkUnreachable(automationEntityType);
  }
};

const EmailTemplateDropdownField: FC<IProps> = (props) => {
  const { automationEntityType, value, onChange, onTouch } = props;

  const { data, isLoading } = useGetEmailTemplates({
    types: [generateAutomationType(automationEntityType)!],
  });

  const handleOnChange = ({
    emailTemplatePublicId,
  }: {
    [value: string]: any;
  }) => {
    onChange?.({
      emailTemplatePublicId:
        emailTemplatePublicId === 'standard'
          ? undefined
          : emailTemplatePublicId,
    });
  };

  // Account has no email templates, but the automation action is set to use a template that no longer exists
  if (!isLoading && !data?.emailTemplates.length && value) {
    return (
      <DropdownField
        label={'Email Template'}
        name={'emailTemplatePublicId'}
        value="unknownTemplate"
        options={[
          {
            label: 'Standard',
            value: 'standard',
          },
          { label: '***Unknown Template***', value: 'unknownTemplate' },
        ]}
        onChange={handleOnChange}
      />
    );
  }

  if (isLoading || !data?.emailTemplates.length) {
    return null;
  }

  return (
    <DropdownField
      label={'Email Template'}
      name={`emailTemplatePublicId`}
      value={value || 'standard'}
      options={[
        {
          label: 'Standard',
          value: 'standard',
        },
        ...data.emailTemplates
          .filter((template) => !!template)
          .map((template) => ({
            label: template.name,
            value: template.id,
          })),
      ]}
      onChange={handleOnChange}
      onTouch={onTouch}
    />
  );
};

export default EmailTemplateDropdownField;
