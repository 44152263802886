import { useQuery } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import projectKeys from '@/api/queries/project/keyFactory';
import { graphql } from '@/gql';
import {
  GetMaterialsListOrderSummaryItemsInput,
  GetProjectMaterialsListOrderSummaryItemsQuery,
  PaginationInput,
} from '@/gql/graphql';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';

const GetProjectMaterialsListOrderSummaryItems = graphql(`
  query GetProjectMaterialsListOrderSummaryItems(
    $projectId: Int!
    $pagination: PaginationInput!
    $input: GetMaterialsListOrderSummaryItemsInput
  ) {
    project(id: $projectId) {
      materialsList {
        id
        materialsListOrderSummaryItems(pagination: $pagination, input: $input) {
          limit
          offset
          totalCount
          items {
            __typename
            id
            materialQuantity
            materialsListMaterial {
              id
              quantity
              quantityOrdered: quantity(statuses: [PURCHASED, ORDERED])
              materialPurchaseIntents {
                id
              }
              materialPurchaseRecords {
                id
              }
              material {
                id
                internalId
                name
                image {
                  id
                  thumbnailUrl
                }
                suppliedBy {
                  supplier {
                    id
                    name
                    isDirectPurchaseOrderSupported
                    accountConnectionMetadata
                  }
                  price {
                    unitPriceExcTax {
                      currency {
                        code
                        exponent
                      }
                      value
                    }
                    unitPrice {
                      currency {
                        code
                        exponent
                      }
                      value
                    }
                  }
                }
              }
              fulfillments {
                supplier {
                  id
                }
                fulfillmentOptions {
                  __typename
                  isFullFulfillment
                  branchStock
                  supplier {
                    id
                  }
                  branch {
                    name
                  }
                  ... on SupplierMaterialCollectionFulfillment {
                    collectionFromDate
                  }
                  ... on SupplierMaterialDeliveryFulfillment {
                    deliveryFromDate
                  }
                }
              }
              requestedByUsers {
                id
                firstName
                lastName
                email
                colour
                avatarUrl
              }
            }
            status
            ... on MaterialsListOrderSummaryOrderedItem {
              materialPurchaseIntent {
                purchaseOrder {
                  id
                  reference
                }
                predictedPrice {
                  unitPriceExcTax {
                    currency {
                      code
                      exponent
                    }
                    value
                  }
                  totalPriceExcTax {
                    currency {
                      code
                      exponent
                    }
                    value
                  }
                }
                supplier {
                  id
                  name
                }
              }
            }
            ... on MaterialsListOrderSummaryPurchasedItem {
              materialPurchaseRecord {
                id
                purchaseOrder {
                  id
                  reference
                }
                supplier {
                  id
                  name
                }
                price {
                  totalPriceExcTax {
                    currency {
                      code
                      exponent
                    }
                    value
                  }
                }
              }
            }
            # ... on MaterialsListOrderSummaryRequiredItem {
            # }
          }
        }
      }
    }
  }
`);

export type MaterialsListSummaryItems = NonNullable<
  GetProjectMaterialsListOrderSummaryItemsQuery['project']['materialsList']
>['materialsListOrderSummaryItems'];

export type MaterialsListMaterial = MaterialsListSummaryItems['items'][0];

export type MaterialAndSuppliers = NonNullable<
  GetProjectMaterialsListOrderSummaryItemsQuery['project']['materialsList']
>['materialsListOrderSummaryItems']['items'][0]['materialsListMaterial'];

export type MaterialPurchaseRecord = Extract<
  MaterialsListSummaryItems['items'][0],
  { __typename: 'MaterialsListOrderSummaryPurchasedItem' }
>['materialPurchaseRecord'];

const useGetProjectMaterialsListSummaryItems = (
  projectId: number,
  input?: GetMaterialsListOrderSummaryItemsInput,
  pagination?: PaginationInput
) => {
  const { data, ...rest } = useQuery({
    queryKey: projectKeys.materialsListSummaryItems(
      projectId,
      input,
      pagination
    ),
    queryFn: () => {
      return gqlClient.request(GetProjectMaterialsListOrderSummaryItems, {
        projectId: projectId,
        input,
        pagination: pagination || PAGINATION_ARG_DEFAULTS,
      });
    },
  });

  return {
    materialsListOrderSummaryItems:
      data?.project.materialsList?.materialsListOrderSummaryItems,
    data,
    ...rest,
  };
};

export default useGetProjectMaterialsListSummaryItems;
