import { FC, useState } from 'react';

import BasicField from '@payaca/components/basicField/BasicField';
import Button from '@payaca/components/button/Button';
import { ButtonStyleVariant } from '@payaca/components/button/enums';
import { InputStyleVariant } from '@payaca/components/inputWrapper/InputWrapper';
import QuickActionsElement from '@payaca/components/quickActionsTableCell/QuickActionsElement';

import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import './ReferenceControl.sass';

type Props = {
  reference?: number;
  customReference?: string;
  referencedEntityName: string;
  onChange: (value: { [key: string]: any }) => void;
  editPermissions?: string[];
};
const ReferenceControl: FC<Props> = ({
  reference,
  customReference,
  referencedEntityName,
  onChange,
  editPermissions,
}: Props): JSX.Element => {
  const [isEditingInvoiceReference, setIsEditingInvoiceReference] =
    useState(false);
  const [_customReference, _setCustomReference] = useState(
    customReference || reference
  );

  return (
    <div className="reference-control">
      {isEditingInvoiceReference ? (
        <>
          <h1>{referencedEntityName}</h1>
          <BasicField
            name="customReference"
            styleVariant={InputStyleVariant.OUTSIZE}
            value={_customReference || ''}
            onChange={(fieldChangeValue: any) => {
              const value = fieldChangeValue['customReference'];
              _setCustomReference(value.substring(0, 20));
            }}
            additionalInputProps={{
              placeholder: reference,
            }}
          />
          <Button
            styleVariant={ButtonStyleVariant.ANCHOR}
            onClick={() => {
              onChange({ customReference: _customReference });
              setIsEditingInvoiceReference(false);
            }}
          >
            Save
          </Button>
        </>
      ) : (
        <h1>
          {referencedEntityName}{' '}
          {(customReference?.length || reference) && (
            <># {customReference || reference}</>
          )}
        </h1>
      )}
      <PermissionGuard renderIfHasPermissions={editPermissions}>
        {!isEditingInvoiceReference && (
          <QuickActionsElement
            recordId={1}
            quickActions={[
              {
                actionName: reference ? 'Edit reference' : 'Set reference',
                actionBehaviour: () => {
                  setIsEditingInvoiceReference(true);
                },
              },
            ]}
          />
        )}
      </PermissionGuard>
    </div>
  );
};
export default ReferenceControl;
