import {
  CustomFieldHelperType,
  CustomFieldType,
  CustomFieldValue,
} from '@payaca/custom-fields/types/index';
import { Account } from './accountTypes';
import { AutomationDataAccessType } from './automationDataAccessTypes';
import { Customer } from './customerTypes';
import { Deal } from './dealTypes';
import { Invoice } from './invoiceTypes';
import { JobContent } from './jobContentTypes';
import { JobPayment } from './jobPaymentTypes';
import { Job } from './jobTypesV2';
import { ListViewPage, SortDirection } from './listViewTypes';
import { ScheduledEvent } from './scheduledEventsTypes';
import { User } from './userTypes';
export const automationEntityTypes = [
  'Estimate',
  'Quote',
  'Invoice',
  'Invoice-v2',
  'Event',
  'Deal',
  'Proposal',
  /*
  'Document',
  'Form',
  'Item',
  'Item Group',
  'User',
  'Customer',
  'Address',
  'Tag',*/
] as const;

export type AutomationEntityTypes = (typeof automationEntityTypes)[number];

export const automationTriggerEvents = [
  'Created',
  'Accepted',
  'Declined',
  'Sent',
  'Expired',
  'Payment Received',
  'Fully Paid',
  'Assigned',
  'Tagged',
  'Added to pipeline',
  'Pipeline stage changed',
  'Task status changed',
  'Pipeline stage changed yesterday',
  'Pipeline stage changed 2 days ago',
  'Pipeline stage changed 3 days ago',
  'Pipeline stage changed 4 days ago',
  'Pipeline stage changed 5 days ago',
  'Pipeline stage changed 6 days ago',
  'Pipeline stage changed 7 days ago',
  'Scheduled Daily Run',
  'Realtime Run',
  'Due in 7 days',
  'Due in 3 days',
  'Due in 1 day',
  'Due today',
  'Due yesterday',
  'Due 3 days ago',
  'Due 7 days ago',
  'Expires in 28 days',
  'Expires in 21 days',
  'Expires in 14 days',
  'Expires in 7 days',
  'Expires in 3 days',
  'Expires in 1 day',
  'Expires today',
  'Expired yesterday',
  'Expired 3 days ago',
  'Expired 7 days ago',
  'Starts in 1 month',
  'Starts in 21 days',
  'Starts in 14 days',
  'Starts in 7 days',
  'Starts in 3 days',
  'Starts in 1 day',
  'Starts today',
  'Finishes today',
  'Finished yesterday',
  'Finished 3 days ago',
  'Finished 7 days ago',
  'Finished 1 month ago',
  'Finished 1 year ago',
  'Starts in 1 hour',
  'Starts now',
  'Finishes now',
  'Finished 11 months ago',
  'Event task status changed',
] as const;

export type AutomationTriggerEvents = (typeof automationTriggerEvents)[number];

export interface AutomationCondition {
  id: number;
  automationId: BaseAutomation['id'];
  field: AutomationDataAccessType;
  operator: AutomationConditionOperator;
  value: string;
  isActive: boolean;
}
export const automationActionTypes = [
  'E-mail Notification',
  'Deal Progression',
  'SMS Notification',
  'Create repeat Project',
  'Create Project Task',
  'Send Invoice to Quickbooks',
  'Send Customer to Quickbooks',
  'Send Invoice to Xero',
  'Send Customer to Xero',
] as const;

export type AutomationActionTypes = (typeof automationActionTypes)[number];

export const automationConditionOperators = [
  'includes',
  'equals',
  'not-equals',
] as const;
export type AutomationConditionOperator =
  (typeof automationConditionOperators)[number];

export type ActionConfigHelperType = {
  'E-mail Notification': EmailNotificationActionConfig;
  'SMS Notification': SMSActionConfig;
  'Deal Progression': DealProgressionActionConfig;
  'Create Project Task': CreateProjectTaskActionConfig;
  'Create repeat Project': void;
  'Send Invoice to Quickbooks': void;
  'Send Customer to Quickbooks': void;
  'Send Customer to Xero': void;
  'Send Invoice to Xero': void;
};

export interface AutomationAction<TActionType extends AutomationActionTypes> {
  id: number;
  automationId: BaseAutomation['id'];
  title: string;
  type: TActionType;
  config: ActionConfigHelperType[TActionType];
  uploadIds: number[];
}

export interface BaseAutomation {
  updatedAt: Date;
  createdAt: Date;
  id: number;
  accountId: number;
  title: string;
  entityType: AutomationEntityTypes;
  triggerEvent: AutomationTriggerEvents;
  isActive: boolean;
  isSystem: boolean;
  conditions: AutomationCondition[];
  defaultAutomationDefinitionPublicId?: DefaultAutomationDefinition['publicId'];
}

export interface AutomationTemplate {
  title: string;
  type: AutomationTemplateType;
}

export enum AutomationTemplateType {
  QUOTE_EXPIRING_REMINDER_DAY_OF = 'quote-expiring-reminder-day-of',
  QUOTE_EXPIRING_REMINDER_1_DAY_PRIOR = 'quote-expiring-reminder-1-day-prior',
  QUOTE_EXPIRING_REMINDER_3_DAYS_PRIOR = 'quote-expiring-reminder-3-days-prior',
  QUOTE_EXPIRING_REMINDER_7_DAYS_PRIOR = 'quote-expiring-reminder-7-days-prior',

  INVOICE_PAYMENT_REMINDER_7_DAYS_LATE = 'invoice-payment-reminder-7-days-late',
  INVOICE_PAYMENT_REMINDER_3_DAYS_LATE = 'invoice-payment-reminder-3-days-late',
  INVOICE_PAYMENT_REMINDER_DAY_OF = 'invoice-payment-reminder-day-of',
  INVOICE_PAYMENT_REMINDER_3_DAYS_PRIOR = 'invoice-payment-reminder-3-days-prior',

  EVENT_REMINDER_CUSTOMER_DAY_OF = 'event-reminder-customer-day-of',
  EVENT_REMINDER_CUSTOMER_1_DAY_PRIOR = 'event-reminder-customer-1-day-prior',
  EVENT_REMINDER_CUSTOMER_3_DAYS_PRIOR = 'event-reminder-customer-3-days-prior',
  EVENT_REMINDER_CUSTOMER_7_DAYS_PRIOR = 'event-reminder-customer-7-days-prior',
}
export interface Automation<T extends AutomationActionTypes>
  extends BaseAutomation {
  actions: AutomationAction<T>[];
}

export type NewAutomation<T extends AutomationActionTypes> = Omit<
  Automation<T>,
  'id' | 'createdAt' | 'updatedAt' | 'conditions' | 'actions'
> & {
  conditions: Omit<AutomationCondition, 'id' | 'automationId'>[];
  actions: Omit<AutomationAction<T>, 'id' | 'automationId'>[];
};

export enum SortBy {
  TITLE = 'title',
  ENTITY_TYPE = 'entityType',
  TRIGGER_EVENT = 'triggerEvent',
  UPDATED_AT = 'updatedAt',
}

export enum ColumnType {
  TITLE = 'Deal Reference',
  ENTITY_TYPE = 'Type',
  TRIGGER_EVENT = 'Trigger Event',
  CONDITIONS = 'Conditions',
  ACTIONS = 'Actions',
}

export interface GetListedAutomationsRequestData {
  pageSize: number;
  pageNumber: number;
  searchTerm?: string;
  sortBy: SortBy;
  sortDirection: SortDirection;
  entityTypes?: AutomationEntityTypes[];
  triggerEvents?: AutomationTriggerEvents[];
  actionTypes?: AutomationActionTypes[];
}

export type DefaultAutomationDefinition = {
  id: number;
  publicId: string;
  title: string;
  description?: string;
  automationConfig: Pick<
    BaseAutomation,
    'title' | 'entityType' | 'triggerEvent'
  > & {
    conditions: Pick<AutomationCondition, 'value' | 'field' | 'operator'>[];
    defaultActions?: Pick<
      AutomationAction<AutomationActionTypes>,
      'type' | 'config'
    >[];
  };
  conditionValueCustomisationConfig: {
    conditionIndex: number;
    label: string;
    description?: string;
    isRequired?: boolean;
  }[];
  sortOrder: number;
};

export type PublicDefaultAutomationDefinition = Omit<
  DefaultAutomationDefinition,
  'id'
>;

export type HydratedDefaultAutomationDefinition<
  T extends AutomationActionTypes,
> = DefaultAutomationDefinition & {
  automation?: Automation<T>;
};

export type PublicHydratedDefaultAutomationDefinition<
  T extends AutomationActionTypes,
> = Omit<HydratedDefaultAutomationDefinition<T>, 'id'>;

export interface AutomationQueueMessageActionPayload<
  T extends AutomationActionTypes,
> {
  automationAction: AutomationAction<T>;
  automationEntityData: AutomationEntityDataClass;
  entityType: AutomationEntityTypes;
  entityId: number;
  triggerMetadata: EntityAutomationQueueMessageEventPayload['metadata'];
}

export interface AutomationQueueMessage {
  message: {
    data: string;
  };
}

export interface ScheduledAutomationQueueMessageEventPayload {
  triggerEvent: AutomationTriggerEvents;
}

export interface EntityAutomationQueueMessageEventPayload
  extends ScheduledAutomationQueueMessageEventPayload {
  entityType: AutomationEntityTypes;
  // not set in case of 'realtime' (i.e no explicit trigger, calculated on the fly)
  data: {
    accountId: number;
    entityId: number;
  };
  metadata: Record<string, any> & {
    triggeredAt: Date;
  };
}

/* CONFIG FOR EACH ACTION TYPE */
export interface EmailNotificationActionConfig {
  sendToEmail:
    | 'primaryContact.emailAddress'
    | 'event.assignedusers.emailaddress.array'
    | 'event.location.contacts.emailaddress.array'
    | 'deal.siteaddresses.contacts.emailaddress.array'
    | 'user.email'
    | 'customEmailAddress';
  customEmail?: string;
  emailTemplatePublicId?: string;
  ccEmails?: string;
  bccEmails?: string;
  emailBody: string;
  emailSubject: string;
  emailVariables?: string[];
  sendACopy?: boolean;
  emailAttachments?: {
    url: string;
    fileName: string;
    mimeType: string;
    fileSize: number;
  }[];
}

export interface SMSActionConfig {
  sendToNumber:
    | 'primaryContact.telephoneNumber'
    | 'event.assignedusers.telephonenumber.array'
    | 'customTelephoneNumber'
    | 'deal.siteaddresses.contacts.telephonenumber.array';
  customNumber?: number;
  sendFromNumber?: number;
  messageBody: string;
  messageVariables?: string[];
}

export interface CreateProjectTaskActionConfig {
  entityTemplatePublicId: string;
}

// add comment to force re-version

export interface DealProgressionActionConfig {
  targetStage: string;
}

export interface AutomationEntityData {
  account: Account;

  dealId?: number;
  customerId?: number;
  jobId?: number;
  scheduledEventId?: number;
  userId?: number;
  invoiceId?: number;

  deals: {
    [key: number]: Deal & {
      customFields?: Record<string, AutomationCustomFieldData<CustomFieldType>>;
    };
  };
  customers: { [key: number]: Customer };
  scheduledEvents: { [key: number]: ScheduledEvent };
  users: { [key: number]: User };
  jobs: { [key: number]: Job };
  invoices: { [key: number]: Invoice };
  invoiceClientUrls: { [key: number]: string };
  jobPayments: { [key: number]: JobPayment };
  jobContents: { [key: number]: JobContent };
  jobClientUrls: { [key: number]: string };
}

export type AutomationCustomFieldData<T extends CustomFieldType> = {
  value?: CustomFieldValue<T>['value'];
  schema: CustomFieldHelperType[T]['definition']['schema'];
};

export class AutomationEntityDataClass implements AutomationEntityData {
  constructor(automationEntityData?: AutomationEntityData) {
    if (automationEntityData) {
      this.account = automationEntityData?.account;

      this.dealId = automationEntityData.dealId;
      this.customerId = automationEntityData.customerId;
      this.userId = automationEntityData.userId;
      this.scheduledEventId = automationEntityData.scheduledEventId;
      this.jobId = automationEntityData.jobId;
      this.invoiceId = automationEntityData.invoiceId;

      this.deals = automationEntityData.deals;
      this.customers = automationEntityData.customers;
      this.users = automationEntityData.users;
      this.scheduledEvents = automationEntityData.scheduledEvents;
      this.jobs = automationEntityData.jobs;
      this.jobPayments = automationEntityData.jobPayments;
      this.jobContents = automationEntityData.jobContents;
      this.jobClientUrls = automationEntityData.jobClientUrls;
      this.invoices = automationEntityData.invoices;
      this.invoiceClientUrls = automationEntityData.invoiceClientUrls;
    }
  }

  account!: Account;

  dealId?: number;
  customerId?: number;
  userId?: number;
  jobId?: number;
  scheduledEventId?: number;
  invoiceId?: number;

  deals: {
    [key: number]: Deal & {
      customFields?: Record<string, AutomationCustomFieldData<CustomFieldType>>;
    };
  } = {};
  customers: { [key: number]: Customer } = {};
  scheduledEvents: { [key: number]: ScheduledEvent } = {};
  users: { [key: number]: User } = {};
  jobs: { [key: number]: Job } = {};
  jobPayments: { [key: number]: JobPayment } = {};
  jobContents: { [key: number]: JobContent } = {};
  jobClientUrls: { [key: number]: string } = {};
  invoices: { [key: number]: Invoice } = {};
  invoiceClientUrls: { [key: number]: string } = {};

  public getDealById(dealId: number) {
    return this.deals[dealId];
  }
  public getDeal() {
    return this.dealId ? this.getDealById(this.dealId) : undefined;
  }

  public getCustomerById(customerId: number) {
    return this.customers[customerId];
  }
  public getCustomer() {
    return this.customerId ? this.getCustomerById(this.customerId) : undefined;
  }

  public getScheduledEventById(scheduledEventId: number) {
    return this.scheduledEvents[scheduledEventId];
  }
  public getScheduledEvent() {
    return this.scheduledEventId
      ? this.getScheduledEventById(this.scheduledEventId)
      : undefined;
  }

  public getUserById(userId: number) {
    return this.users[userId];
  }
  public getUser() {
    return this.userId ? this.getUserById(this.userId) : undefined;
  }

  public getJobById(jobId: number) {
    return this.jobs[jobId];
  }
  public getJob() {
    return this.jobId ? this.getJobById(this.jobId) : undefined;
  }

  public getInvoiceById(invoiceId: number) {
    return this.invoices[invoiceId];
  }
  public getInvoice() {
    return this.invoiceId ? this.getInvoiceById(this.invoiceId) : undefined;
  }
}

export type UpdateDefaultAutomationData<T extends AutomationActionTypes> = {
  isActive: boolean;
  conditions: {
    conditionIndex: DefaultAutomationDefinition['conditionValueCustomisationConfig'][number]['conditionIndex'];
    value: AutomationCondition['value'];
  }[];
  action: Pick<AutomationAction<T>, 'type' | 'config' | 'uploadIds'>;
};

export type ListedAutomation = Automation<AutomationActionTypes>;
export interface ListedAutomationsListViewPage
  extends ListViewPage<ListedAutomation> {
  totalUnfilteredItemCount: number;
}
