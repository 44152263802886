import ViewFieldset from '@payaca/components/plFieldset/ViewFieldset';
import {
  CustomFieldHelperType,
  CustomFieldType,
} from '@payaca/custom-fields/types/index';

export type TProps<T extends CustomFieldType> = {
  definition: CustomFieldHelperType[T]['definition'];
  value?: CustomFieldHelperType[T]['value'] | null;
};

const CustomFieldReadonly = <T extends CustomFieldType>(props: TProps<T>) => {
  const { definition, value } = props;

  let customFormatter: any;
  if (definition.type === 'email') {
    customFormatter = (value: CustomFieldHelperType['email']['value']) => {
      return <a href={`mailto:${value}`}>{value}</a>;
    };
  }
  if (definition.type === 'url') {
    customFormatter = (value: CustomFieldHelperType['url']['value']) => {
      return (
        <a target="_blank" rel="noreferrer" href={value.url}>
          {value.title || value.url}
        </a>
      );
    };
  }

  return (
    <ViewFieldset.Field label={`${definition.label}:`}>
      {definition.formatValue(value, customFormatter) || '-'}
    </ViewFieldset.Field>
  );
};

export default CustomFieldReadonly;
