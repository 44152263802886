import Button from '@payaca/components/plButton/Button';
import { EBtnSize } from '@payaca/components/plButton/useButtonClassName';
import { FC } from 'react';
import { useHistory } from 'react-router';
import useEndChildAccountUserSession from '../../../../api/mutations/childAccounts/useEndChildAccountUserSession';
import useGetMe from '../../../../api/queries/me/useGetMe';

export const useSessionStatus = () => {
  const { data: me } = useGetMe();
  const hasActiveSession =
    me &&
    me.user.originalAccountId &&
    me.user.account.id !== me.user.originalAccountId;
  return {
    hasActiveSession,
  };
};
const SessionStatusBanner: FC = () => {
  const history = useHistory();

  const { data: me, refetch: refetchMe } = useGetMe();
  const {
    mutateAsync: endChildAccountUserSession,
    isLoading: isEndingChildAccountUserSession,
  } = useEndChildAccountUserSession();
  const { hasActiveSession } = useSessionStatus();

  const endAccountSession = async () => {
    if (!isEndingChildAccountUserSession) {
      await endChildAccountUserSession();
      await refetchMe();
    }
  };

  if (!me || !hasActiveSession) {
    return null;
  }
  return (
    <div
      className="flex items-center justify-between bg-orange-400 p-3 cursor-pointer"
      onClick={() => {
        history.push(`/accounts/${me.user.account.id}`);
      }}
    >
      <span>
        You are currently signed into <strong>{me.user.account.name}</strong>
      </span>
      <Button
        size={EBtnSize.Small}
        isProcessing={isEndingChildAccountUserSession}
        onClick={async (e) => {
          e.stopPropagation();
          await endAccountSession();
        }}
      >
        End session
      </Button>
    </div>
  );
};

export default SessionStatusBanner;
