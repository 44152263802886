import { FC, useCallback, useMemo } from 'react';

import { useSelector } from '@/api/state';
import useLogout from '@/hooks/auth/useLogout';
import { useTranslation } from '@/i18n';
import { PermissionGuard } from '@/ui/components/permissionGuard/PermissionGuard';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { CustomersPermissions } from '@payaca/permissions/customers/customers.permissions';
import { LineItemsPermissions } from '@payaca/permissions/lineItems/line-items.permissions';
import { MaterialsPermissions } from '@payaca/permissions/materials/materials.permissions';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { SuppliersPermissions } from '@payaca/permissions/suppliers/suppliers.permissions';
import { TemplatesPermissions } from '@payaca/permissions/templates/templates.permissions';
import { useHistory } from 'react-router';
import { useShowParentAccountAdminLayout } from '../../../hooks/useShowParentAccountAdminLayout';
import './HeaderNavigation.sass';

const HeaderNavigation: FC = (): JSX.Element => {
  const translate = useTranslation('navigation.account');
  const history = useHistory();
  const myProfile = useSelector((state: any) => state.users.myProfile);
  const { showParentAccountAdminLayout } = useShowParentAccountAdminLayout();

  const accountAccessInformation = useSelector(
    (state) => state.account.accountAccessInformation
  );

  const trialPeriodIsExpired = useMemo(() => {
    return accountAccessInformation
      ? !accountAccessInformation.hasActiveSubscription &&
          accountAccessInformation.isTrialPeriodExpired
      : false;
  }, [accountAccessInformation]);

  const hasExpiredConnection = useMemo(() => {
    const hasExpiredXeroConnection =
      !!myProfile?.accounts?.[0]?.integrations?.xero?.hasRefreshTokenExpired;
    const oAuthConnections = myProfile?.oAuthConnections || [];
    const zapierConnection = oAuthConnections.find(
      (p: any) => p.clientName === 'Zapier' || p.clientName === 'zapier test'
    );
    const hasExpiredZapierConnection =
      zapierConnection && zapierConnection.hasConnectionExpired;
    return hasExpiredXeroConnection || hasExpiredZapierConnection;
  }, [myProfile]);

  const hasExceededUserSeats = useMemo(() => {
    if (!accountAccessInformation) return false;

    return (
      accountAccessInformation.hasActiveSubscription &&
      accountAccessInformation.hasExceededActiveSubscriptionUserSeats
    );
  }, [accountAccessInformation]);

  const renderNavigationItem = useCallback(
    (title: string | JSX.Element, onClick: () => void, isDisabled: boolean) => {
      return (
        <div
          role="menuitem"
          className={`navigation-item ${isDisabled ? 'disabled' : ''}`}
          onClick={() => !isDisabled && onClick()}
        >
          {title}
        </div>
      );
    },
    [history]
  );

  const logout = useLogout();

  if (showParentAccountAdminLayout) {
    return (
      <nav className="header-navigation" aria-label="secondary-header">
        <PermissionGuard
          renderIfHasPermissions={[AccountsPermissions.GET_DETAILED_USERS]}
        >
          {
            <>
              {renderNavigationItem(
                <span className="flex-container flex-center">
                  <span className="flex-grow">{translate('users.label')}</span>
                </span>,
                () => history.push('/users'),
                trialPeriodIsExpired
              )}
            </>
          }
        </PermissionGuard>
        <hr />
        {renderNavigationItem(translate('logOut.label'), logout, false)}
      </nav>
    );
  }

  return (
    <nav className="header-navigation" aria-label="secondary-header">
      {renderNavigationItem(
        translate('profile.label'),
        () => history.push('/personalSettings'),
        false
      )}
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.UPDATE_ACCOUNT]}
      >
        {renderNavigationItem(
          translate('myCompany.label'),
          () => history.push('/companySettings'),
          trialPeriodIsExpired || hasExceededUserSeats
        )}
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.GET_DETAILED_USERS]}
      >
        {
          <>
            {renderNavigationItem(
              <span className="flex-container flex-center">
                <span className="flex-grow">{translate('users.label')}</span>
              </span>,
              () => history.push('/users'),
              trialPeriodIsExpired
            )}
          </>
        }
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.UPDATE_ACCOUNT]}
      >
        {renderNavigationItem(
          translate('myPages.label'),
          () => history.push('/pages'),
          trialPeriodIsExpired || hasExceededUserSeats
        )}
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.UPDATE_SUBSCRIPTION]}
      >
        {renderNavigationItem(
          translate('manageSubscription.label'),
          () => history.push('/upgradeAccount'),
          false
        )}
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[
          TemplatesPermissions.GET_COLLECTION_MANAGEMENT_VIEW,
        ]}
      >
        {renderNavigationItem(
          translate('templates.label'),
          () => history.push('/templates'),
          false
        )}
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[AccountsPermissions.MANAGE_TAGS]}
      >
        {renderNavigationItem(
          translate('tags.label'),
          () => history.push('/tags'),
          false
        )}
      </PermissionGuard>
      <hr />
      <PermissionGuard
        renderIfHasPermissions={[
          CustomersPermissions.ADD_CUSTOMER,
          LineItemsPermissions.ADD_LINE_ITEM,
          MaterialsPermissions.PERSIST_MATERIAL,
          ScheduledEventsPermissions.ADD_EVENT,
          SuppliersPermissions.PERSIST_SUPPLIER,
        ]}
        requireAllPermissions={true}
      >
        <>
          {renderNavigationItem(
            translate('csvUploads.label'),
            () => history.push('/csv-uploads'),
            false
          )}
          <hr />
        </>
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[
          AccountsPermissions.UPDATE_CONNECTION,
          AccountsPermissions.UPDATE_CONNECTION_ACCOUNTING,
          AccountsPermissions.UPDATE_CONNECTION_SUPPLIERS,
          AccountsPermissions.UPDATE_CONNECTION_PAYMENT,
          AccountsPermissions.UPDATE_CONNECTION_AUTOMATION,
        ]}
      >
        {
          <>
            {renderNavigationItem(
              <span className="flex-container flex-center">
                <span className="flex-grow">
                  {translate('connections.label')}
                </span>
                {hasExpiredConnection && (
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                )}
              </span>,
              () => history.push('/connections'),
              trialPeriodIsExpired || hasExceededUserSeats
            )}
            <hr />
          </>
        }
      </PermissionGuard>

      {renderNavigationItem(translate('logOut.label'), logout, false)}
    </nav>
  );
};

export default HeaderNavigation;
