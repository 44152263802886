import AnchorButton from '@payaca/components/plButton/AnchorButton';
import {
  EBtnSize,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import Tooltip from '@payaca/components/plTooltip/Tooltip';
import { DaysOfWeekMap } from '@payaca/helpers/scheduleHelper';
import UntitledIcon from '@payaca/untitled-icons';
import { capitaliseFirstLetter } from '@payaca/utilities/stringUtilities';
import { FC } from 'react';
import { SupplierBranch } from '../../../gql/graphql';

type Props = {
  branch: SupplierBranch;
};
const SupplierBranchLink: FC<Props> = (props) => {
  const { branch } = props;
  return (
    <Tooltip
      tooltipContent={
        <div>
          {branch.operatingHours.map((hours, i) => {
            const day = DaysOfWeekMap[i];
            const dayLabel = capitaliseFirstLetter(day);
            const openingTime = hours[0] && hours[0].slice(0, 5);
            const closingTime = hours[1] && hours[1].slice(0, 5);
            return (
              <span key={day}>
                {openingTime !== null
                  ? `${dayLabel}: ${openingTime} - ${closingTime}`
                  : `${dayLabel}: Closed`}
                <br />
              </span>
            );
          })}
        </div>
      }
    >
      <AnchorButton
        variant={EBtnVariant.LinkInline}
        href={
          branch.url
            ? branch.url
            : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                branch.address.fullLocalAddress || ''
              )}`
        }
        size={EBtnSize.XSmall}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
        rel="noreferrer"
      >
        {branch.name} <UntitledIcon name="share-03" className="size-4" />
      </AnchorButton>
    </Tooltip>
  );
};

export default SupplierBranchLink;
