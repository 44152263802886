import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateEmailTemplateInput,
  UpdateEmailTemplateMutation,
} from '@/gql/graphql';

const UPDATE_EMAIL_TEMPLATE_MUTATION = graphql(`
  mutation UpdateEmailTemplate($input: UpdateEmailTemplateInput!) {
    updateEmailTemplate(input: $input) {
      id
      name
    }
  }
`);

const useUpdateEmailTemplate = (
  options?: UseMutationOptions<
    UpdateEmailTemplateMutation,
    unknown,
    UpdateEmailTemplateInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(UPDATE_EMAIL_TEMPLATE_MUTATION, { input });
    },
    ...options,
  });
};

export default useUpdateEmailTemplate;
