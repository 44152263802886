// ======================================================================================================
// Private routing
// - Routes/redirects accessible to authorised users (role dependent)
// ======================================================================================================

import { actions as usersActions } from '@/api/users';
import { useShowParentAccountAdminLayout } from '@/hooks/useShowParentAccountAdminLayout';
import { getIsFetchingUserRoles, getUserRoles } from '@/utils/stateAccessors';
import LoaderOverlay from '@payaca/components/loaderOverlay/LoaderOverlay';
import { AccountsPermissions } from '@payaca/permissions/accounts/accounts.permissions';
import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import WebUtil from '@payaca/shared-web/utilities/index';
import * as accountActions from '@payaca/store/account/accountActions';
import * as userRolesActions from '@payaca/store/userRoles/userRolesActions';
import { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  getUsersPrivateRedirects,
  getUsersPrivateRoutes,
} from './privateRoutingConfig';
import RoutesAndRedirects from './RoutesAndRedirects';

const getAccountAccessProperties = (accountAccessInformation) => {
  return {
    trialPeriodIsExpired: accountAccessInformation
      ? !accountAccessInformation.hasActiveSubscription &&
        accountAccessInformation.isTrialPeriodExpired
      : false,
    hasExceededUserSeats: accountAccessInformation
      ? accountAccessInformation.hasActiveSubscription &&
        accountAccessInformation.hasExceededActiveSubscriptionUserSeats
      : false,
    hasBetaFeatures: accountAccessInformation?.features?.showBetaFeatures,
  };
};

const getProfileProperties = (profile) => {
  return {
    userHasContactNumber: !!profile?.contactNumber,
    accountIsSetup: profile?.accounts?.[0]?.legalBusinessName, // account exists with legal business name - will catch all users who have registered through web or mobile
  };
};

const getUserRolesProperties = (userRoles) => {
  return {
    canUpdateSubscription: userHasRequiredPermission(userRoles, [
      AccountsPermissions.UPDATE_SUBSCRIPTION,
    ]),
  };
};

const PrivateRouting = ({
  match,
  accountAccessInformation,
  profile,
  users,
  userRolesActions,
}) => {
  const location = useLocation();
  const { showParentAccountAdminLayout } = useShowParentAccountAdminLayout();

  useEffect(() => {
    users.getProfile();
    userRolesActions.requestGetUserRoles();
  }, [users, userRolesActions]);

  useEffect(() => {
    accountActions.requestGetAccountAccessInformation();
  }, [accountActions]);

  const isFetchingRoles = useSelector(getIsFetchingUserRoles);
  const userRoles = useSelector(getUserRoles);

  const isMobile = useMemo(() => WebUtil.isMobileScreen(), []);
  const isFormsRoute = useMemo(() => {
    return !location.pathname.includes('/forms');
  }, [location]); // forms is web-optimised

  const { trialPeriodIsExpired, hasExceededUserSeats, hasBetaFeatures } =
    useMemo(
      () => getAccountAccessProperties(accountAccessInformation),
      [accountAccessInformation]
    );
  const { userHasContactNumber, accountIsSetup } = useMemo(
    () => getProfileProperties(profile),
    [profile]
  );

  const canUpdateSubscription = useMemo(
    () => getUserRolesProperties(userRoles),
    [userRoles]
  );

  const availableRoutes = useMemo(() => {
    return getUsersPrivateRoutes({
      canUpdateSubscription,
      isMobile,
      userRoles,
      userHasContactNumber,
      accountIsSetup,
      trialPeriodIsExpired,
      hasExceededUserSeats,
      hasBetaFeatures,
      isFormsRoute,
      showParentAccountAdminRoutes: showParentAccountAdminLayout,
    });
  }, [
    canUpdateSubscription,
    isMobile,
    hasExceededUserSeats,
    accountIsSetup,
    trialPeriodIsExpired,
    userHasContactNumber,
    userRoles,
    hasBetaFeatures,
    isFormsRoute,
    showParentAccountAdminLayout,
  ]);

  const availableRedirects = useMemo(() => {
    return getUsersPrivateRedirects({
      userRoles,
      canUpdateSubscription,
      isMobile,
      accountIsSetup,
      hasExceededUserSeats,
      trialPeriodIsExpired,
      userHasContactNumber,
      isFormsRoute,
      showParentAccountAdminRoutes: showParentAccountAdminLayout,
    });
  }, [
    userRoles,
    canUpdateSubscription,
    isMobile,
    accountIsSetup,
    hasExceededUserSeats,
    trialPeriodIsExpired,
    userHasContactNumber,
    isFormsRoute,
    showParentAccountAdminLayout,
  ]);

  if (isFetchingRoles || !Object.keys(profile).length) {
    return <LoaderOverlay />;
  }

  return (
    <RoutesAndRedirects
      availableRoutes={availableRoutes}
      availableRedirects={availableRedirects}
      matchPath={match.path}
    />
  );
};

export default connect(
  (state) => ({
    dismissMobileMode: state.app.dismissMobileMode,
    profile: state.users.myProfile,
    accountAccessInformation: state.account.accountAccessInformation,
  }),
  (dispatch) => ({
    users: bindActionCreators(usersActions, dispatch),
    accountActions: bindActionCreators(accountActions, dispatch),
    userRolesActions: bindActionCreators(userRolesActions, dispatch),
  })
)(PrivateRouting);
