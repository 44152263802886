import { FC, useCallback, useMemo } from 'react';

import ConditionalWrapper from '@payaca/components/conditionalWrapper/ConditionalWrapper';
import { ErrorMessage } from '@payaca/components/feedbackMessage/FeedbackMessage';
import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import TextareaFieldMentionFormatter from '@payaca/components/textareaField/TextareaFieldMentionFormatter';
import ValidatedFieldWrapper from '@payaca/components/validatedFieldWrapper/ValidatedFieldWrapper';
import ValidatedForm from '@payaca/components/validatedForm/ValidatedForm';
import { getIsRequiredFieldValidator } from '@payaca/helpers/fieldValidationHelper';

import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Note } from '@payaca/types/notesTypes';
import { User } from '@payaca/types/userTypes';

import {
  convertMarkdownToHtml,
  isValidHtml,
} from '@payaca/utilities/htmlUtilities';

import Conditional from '@payaca/components/conditional/Conditional';
import {
  EBtnColour,
  EBtnVariant,
} from '@payaca/components/plButton/useButtonClassName';
import './CreateEditNoteControl.sass';

export type GraphqlSafeNote = Partial<
  Omit<Note, 'id'> & { id?: string | number }
>;

type Props = {
  note?: GraphqlSafeNote;
  error: string | null;
  isProcessing: boolean;
  onCreateUpdateNote: (note: GraphqlSafeNote) => void;
  usersForMentioning: Pick<User, 'id' | 'name'>[];
  withinModal?: boolean;
  onDeleteNote?: (notePublicId: string) => void;
};
const CreateEditNoteControlBase: FC<Props> = ({
  note = {},
  error,
  isProcessing,
  onCreateUpdateNote,
  usersForMentioning,
  withinModal = false,
  onDeleteNote,
}) => {
  const onSubmit = useCallback(
    (form: any) => {
      onCreateUpdateNote({
        ...note,
        description: form.description,
        dealId: form.dealId,
      });
    },
    [note]
  );

  const initialFormState = useMemo(() => {
    // convert old markdown notes to html if editing
    const description = note?.description
      ? isValidHtml(note.description)
        ? note.description
        : convertMarkdownToHtml(note.description)
      : '';
    return {
      dealId: note.dealId,
      description: description,
    };
  }, [note]);

  const fieldValidators = useMemo(() => {
    return {
      description: [getIsRequiredFieldValidator()],
    };
  }, []);

  const renderFormContents = useCallback(
    (
      isValid: boolean,
      formState: {
        [key: string]: any;
      },
      validationState: {
        [key: string]: FieldValidationResult;
      },
      touchedState: {
        [key: string]: boolean;
      },
      onFieldChange: (value: { [key: string]: any }) => void,
      onFieldTouch: (fieldName: string) => void
    ) => {
      return (
        <>
          <ConditionalWrapper condition={withinModal} Wrapper={Modal.Body}>
            <ValidatedFieldWrapper
              validationResult={validationState['description']}
              isTouched={touchedState['description'] || false}
            >
              <TextareaFieldMentionFormatter
                name="description"
                onChange={onFieldChange}
                value={formState.description}
                mentions={usersForMentioning.map((user) => ({
                  id: user.id,
                  value: user.name,
                }))}
              />
            </ValidatedFieldWrapper>
          </ConditionalWrapper>

          <ConditionalWrapper condition={withinModal} Wrapper={Modal.Footer}>
            {error && <ErrorMessage message={error} />}
            <ConditionalWrapper
              condition={withinModal}
              Wrapper={Modal.Footer.Actions}
            >
              <Conditional condition={!!onDeleteNote && !!note.publicId}>
                <Button
                  onClick={() => onDeleteNote?.(note.publicId!)}
                  colour={EBtnColour.Red}
                  variant={EBtnVariant.Outline}
                >
                  Delete
                </Button>
              </Conditional>
              <Button
                onClick={() => onSubmit(formState)}
                disabled={!isValid}
                isProcessing={isProcessing}
              >
                {note?.id ? 'Save' : 'Create Note'}
              </Button>
            </ConditionalWrapper>
          </ConditionalWrapper>
        </>
      );
    },
    [error, isProcessing, note, onSubmit, withinModal, onDeleteNote]
  );
  return (
    <ValidatedForm<{ [key: string]: any }>
      renderFormContents={renderFormContents}
      fieldValidators={fieldValidators}
      initialFormState={initialFormState}
    />
  );
};

export default CreateEditNoteControlBase;
