import { FC, useMemo, useState } from 'react';

import Button from '@payaca/components/plButton/Button';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import Field from '@payaca/components/plField/Field';
import Input from '@payaca/components/plInput/Input';
import { FieldValidationResult } from '@payaca/types/fieldValidationTypes';
import { Address } from '@payaca/types/locationTypes';
import { AddressLookupSelect } from '../addressLookupSelect/AddressLookupSelect';

type AddressType = Pick<
  Address,
  'line1' | 'line2' | 'city' | 'postcode' | 'country'
>;

interface Props {
  address?: Partial<AddressType>;
  onChange: (address?: Partial<AddressType>) => void;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onTouch?: (fieldName: string) => void;
  disabled?: boolean;
  disableManualEdit?: boolean;
  onClear?: () => void;
}

export const AddressLookupOrInput: FC<Props> = ({
  address,
  onChange,
  onTouch,
  touchedState,
  validationState,
  disabled,
  disableManualEdit,
  onClear,
}: Props): JSX.Element => {
  const [enterManually, setEnterManually] = useState(false);

  const hasATouchedField =
    touchedState !== undefined && Object.keys(touchedState).length > 0;
  const hasAnError = !!(
    validationState &&
    Object.values(validationState).find(
      (v) => v && v.errors && v.errors.length > 0
    )
  );
  return (
    <div className="flex flex-col items-start gap-3">
      {!enterManually ? (
        <GeoSearchAddress
          address={address}
          onChange={onChange}
          disabled={disabled}
          onEnterManually={() => setEnterManually(true)}
          isIncomplete={hasATouchedField && hasAnError}
          disableManualEdit={disableManualEdit}
          onClear={onClear}
        />
      ) : (
        <EnterAddressManually
          address={address}
          onChange={onChange}
          onTouch={onTouch}
          touchedState={touchedState}
          validationState={validationState}
          disabled={disabled}
          onLookupAddress={() => setEnterManually(false)}
        />
      )}
    </div>
  );
};

export const GeoSearchAddress: FC<
  Pick<Props, 'address' | 'onChange' | 'disabled' | 'validationState'> & {
    onEnterManually: () => void;
    isIncomplete: boolean;
    disableManualEdit?: boolean;
    onClear?: () => void;
  }
> = (props) => {
  const {
    address,
    onChange,
    onEnterManually,
    disabled,
    isIncomplete,
    disableManualEdit,
    onClear,
  } = props;

  const isDirty = useMemo(() => {
    return (
      !!address?.line1 ||
      !!address?.line2 ||
      !!address?.city ||
      !!address?.postcode
    );
  }, [address]);

  return (
    <>
      <AddressLookupSelect
        address={address}
        onChange={onChange}
        disabled={disabled}
      />
      {isIncomplete && (
        <div className="text-red-500">
          Address incomplete, please edit manually
        </div>
      )}
      {!disableManualEdit && (
        <div>
          <Button
            disabled={disabled}
            variant={EBtnVariant.LinkInline}
            onClick={onEnterManually}
          >
            {isDirty ? 'Edit' : 'Enter'} manually
          </Button>
        </div>
      )}
      {!!onClear && (
        <Button
          disabled={disabled}
          variant={EBtnVariant.LinkInline}
          onClick={onClear}
        >
          Choose existing address
        </Button>
      )}
    </>
  );
};

export const EnterAddressManually: FC<
  Props & { onLookupAddress?: () => void }
> = (props) => {
  const {
    address,
    onChange,
    onTouch,
    touchedState,
    validationState,
    onLookupAddress,
    disabled,
  } = props;
  return (
    <>
      <AddressInput
        address={address}
        onChange={onChange}
        onTouch={onTouch}
        touchedState={touchedState}
        validationState={validationState}
      />
      {onLookupAddress && (
        <div>
          <Button
            disabled={disabled}
            variant={EBtnVariant.LinkInline}
            onClick={onLookupAddress}
          >
            Lookup address
          </Button>
        </div>
      )}
    </>
  );
};

const AddressInput: FC<{
  address?: Partial<AddressType>;
  onChange: (address: Partial<AddressType>) => void;
  touchedState?: { [fieldName: string]: boolean };
  validationState?: { [fieldName: string]: FieldValidationResult };
  onTouch?: (fieldName: string) => void;
  disabled?: boolean;
}> = ({
  address,
  onChange,
  touchedState,
  validationState,
  onTouch,
  disabled,
}) => {
  return (
    <fieldset className="flex w-full flex-col gap-2 border-0 p-0">
      <Field
        name="line1"
        validationState={
          validationState?.['line1']?.isValid === false &&
          touchedState?.['line1']
            ? {
                isValid: false,
                validationMessages: validationState?.['line1']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Address line 1</Field.Label>
        <Input
          value={address?.line1 || ''}
          onChange={(value) => onChange({ ...address, line1: value })}
          onTouch={() => onTouch?.('line1')}
          disabled={disabled}
        />
      </Field>
      <Field
        name="line2"
        validationState={
          validationState?.['line2']?.isValid === false &&
          touchedState?.['line2']
            ? {
                isValid: false,
                validationMessages: validationState?.['line2']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Address line 2</Field.Label>
        <Input
          value={address?.line2 || ''}
          onChange={(value) => onChange({ ...address, line2: value })}
          onTouch={() => onTouch?.('line2')}
          disabled={disabled}
        />
      </Field>
      <Field
        name="city"
        validationState={
          validationState?.['city']?.isValid === false && touchedState?.['city']
            ? {
                isValid: false,
                validationMessages: validationState?.['city']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Town / city</Field.Label>
        <Input
          value={address?.city || ''}
          onChange={(value) => onChange({ ...address, city: value })}
          onTouch={() => onTouch?.('city')}
          disabled={disabled}
        />
      </Field>
      <Field
        name="postcode"
        validationState={
          validationState?.['postcode']?.isValid === false &&
          touchedState?.['postcode']
            ? {
                isValid: false,
                validationMessages: validationState?.['postcode']?.errors,
              }
            : undefined
        }
      >
        <Field.Label>Postcode</Field.Label>
        <Input
          value={address?.postcode || ''}
          onChange={(value) => onChange({ ...address, postcode: value })}
          onTouch={() => onTouch?.('postcode')}
          disabled={disabled}
        />
      </Field>
    </fieldset>
  );
};
