import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  UpdateCustomFieldInput,
  UpdateCustomFieldMutation,
} from '@/gql/graphql';

const UPDATE_CUSTOM_FIELD_MUTATION = graphql(`
  mutation UpdateCustomField($input: UpdateCustomFieldInput!) {
    updateCustomField(input: $input) {
      role
      id
      customFields {
        archivedAt
        id
        identifier
        isRequired
        label
        type
        options
        schema
      }
    }
  }
`);

const useUpdateCustomField = (
  options?: UseMutationOptions<
    UpdateCustomFieldMutation,
    unknown,
    UpdateCustomFieldInput
  >
) => {
  return useMutation({
    mutationFn: (variables) => {
      return gqlClient.request(UPDATE_CUSTOM_FIELD_MUTATION, {
        input: variables,
      });
    },
    ...options,
  });
};

export default useUpdateCustomField;
