export enum ProposalsPermissions {
  GET_CHANGE_PROPOSALS = 'proposals.getChangeProposals',
  PERSIST_CHANGE_PROPOSAL = 'proposals.persistChangeProposal',
  SEND_CHANGE_PROPOSAL = 'proposals.sendChangeProposal',
  MARK_CHANGE_PROPOSAL_ACCEPTED = 'proposals.markChangeProposalAccepted',
  MARK_CHANGE_PROPOSAL_DECLINED = 'proposals.markChangeProposalDeclined',
  VOID_CHANGE_PROPOSAL = 'proposals.voidChangeProposal',
  DELETE_UNSENT_CHANGE_PROPOSAL = 'proposals.deleteUnsentChangeProposal',

  EDIT_PROPOSAL_EXPIRES_IN_DAYS = 'proposals.editProposal.expiresInDays',
  EDIT_PROPOSAL_REFERENCE = 'proposals.editProposal.reference',
}
