import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { gqlClient } from '@/api/graphql-client';
import { graphql } from '@/gql';
import {
  SubmitDirectPurchaseOrderCityPlumbingInput,
  SubmitDirectPurchaseOrderCityPlumbingMutation,
} from '@/gql/graphql';

const SUBMIT_DIRECT_PURCHASE_ORDER_CITY_PLUMBING = graphql(`
  mutation SubmitDirectPurchaseOrderCityPlumbing(
    $input: SubmitDirectPurchaseOrderCityPlumbingInput!
  ) {
    submitDirectPurchaseOrderCityPlumbing(input: $input) {
      __typename
      ... on SubmitDirectPurchaseOrderCityPlumbingResponseSuccess {
        purchaseOrder {
          id
        }
      }
      ... on SubmitDirectPurchaseOrderCityPlumbingResponseError {
        message
      }
    }
  }
`);

const useSubmitDirectPurchaseOrderCityPlumbing = (
  options?: UseMutationOptions<
    SubmitDirectPurchaseOrderCityPlumbingMutation,
    unknown,
    SubmitDirectPurchaseOrderCityPlumbingInput
  >
) => {
  return useMutation({
    mutationFn: (input) => {
      return gqlClient.request(SUBMIT_DIRECT_PURCHASE_ORDER_CITY_PLUMBING, {
        input,
      });
    },
    ...options,
  });
};

export default useSubmitDirectPurchaseOrderCityPlumbing;
