import { nanoid } from 'nanoid';
import { FC, useRef } from 'react';
import Card, { CardSizeVariant } from '../plCard/Card';
import Checkbox from '../plCheckbox/Checkbox';
import Field from '../plField/Field';
import RichTextarea, { RichTextContent } from '../plRichTextarea/RichTextarea';
import { clstx } from '../utils';

export interface IProps {
  className?: string;
  term: RichTextContent;
  checked: boolean;
  onChange?: (value: boolean) => void;
}

const AcceptTermCard: FC<IProps> = (props) => {
  const { className, term, checked, onChange } = props;

  const { current: uId } = useRef(nanoid());

  return (
    <Card
      as="label"
      className={clstx('cursor-pointer', className)}
      htmlFor={`accept-terms-${uId}`}
      sizeVariant={CardSizeVariant.SM}
    >
      <Card.Body className="flex gap-4">
        <Field id={`accept-terms-${uId}`} name={`accept-terms-${uId}`}>
          <Checkbox
            checked={checked}
            onChange={(e) => {
              onChange?.(e.currentTarget.checked);
            }}
          />
        </Field>

        <RichTextarea readOnly value={term} />
      </Card.Body>
    </Card>
  );
};

export default AcceptTermCard;
