/**
 * Only used for DealPage, should move to using src/helpers/fulfillment.ts when have migrated to getting fulfillments for each material in one request
 */
import {
  DateFormats,
  getInternationalDateFormatByLocale,
} from '@payaca/helpers/internationalHelper';
import { format, isToday, isTomorrow } from 'date-fns';
import { MaterialsListSummaryItems } from '../../../../../../api/queries/project/useGetProjectMaterialListSummaryItems';
import { MaterialsQuery, Supplier } from '../../../../../../gql/graphql';

export type DeliveryFulfillmentOption = {
  __typename: 'SupplierMaterialDeliveryFulfillment';
  isFullFulfillment: boolean;
  branchStock: number | null;
  branch: {
    name: string;
  };
  deliveryFromDate: Date;
};
export type CollectionFulfillmentOption = {
  __typename: 'SupplierMaterialCollectionFulfillment';
  isFullFulfillment: boolean;
  branchStock: number | null;
  branch: {
    name: string;
  };
  collectionFromDate: Date;
};
export type FulfillmentOption =
  | DeliveryFulfillmentOption
  | CollectionFulfillmentOption;

export type SupplierFulfillments = {
  supplier: {
    id: string;
  };
  fulfillmentOptions: FulfillmentOption[] | null;
};

export type LiveMaterialSupplierBadgeSupplier = {
  id: Supplier['id'];
  name: Supplier['name'];
};
export type LiveMaterialSupplierBadgeProps = {
  supplier: LiveMaterialSupplierBadgeSupplier;
  fulfillmentOptions: FulfillmentOption[];
};

export const isCollectionFulfillment = (f: FulfillmentOption) =>
  f.__typename === 'SupplierMaterialCollectionFulfillment';

export const getFulfillmentDate = (f: FulfillmentOption) => {
  return isCollectionFulfillment(f) ? f.collectionFromDate : f.deliveryFromDate;
};

export const getFromAvailabilityDate = (
  d: Date,
  isCollection: boolean,
  locale?: string
) => {
  const time = format(d, 'HH:mm');
  if (isToday(d)) {
    return `today${isCollection ? ` from ${time}` : ''}`;
  } else if (isTomorrow(d)) {
    return `tomorrow${isCollection ? ` from ${time}` : ''}`;
  } else {
    return `from ${format(
      d,
      getInternationalDateFormatByLocale(
        isCollection
          ? DateFormats.TIMESTAMP_DAY_MONTH
          : DateFormats.DAY_MONTH_SHORT,
        locale
      )
    )}`;
  }
};

export const parseFulfillments = (
  fulfillments:
    | MaterialsListSummaryItems['items'][0]['materialsListMaterial']['fulfillments']
    | MaterialsQuery['materials']['items'][number]['fulfillments']
): SupplierFulfillments[] | null => {
  if (!fulfillments) {
    return null;
  }
  return fulfillments.map((f) => {
    const fulfillmentOptions =
      f.fulfillmentOptions?.map((fo) => {
        const baseFulfillment = {
          __typename: fo.__typename,
          isFullFulfillment: fo.isFullFulfillment,
          branchStock: 'branchStock' in fo ? fo.branchStock : null,
          branch: {
            name: fo.branch.name,
          },
          supplierId: +fo.supplier.id,
        };
        if (fo.__typename === 'SupplierMaterialDeliveryFulfillment') {
          return {
            ...baseFulfillment,
            deliveryFromDate: new Date(fo.deliveryFromDate),
          } as DeliveryFulfillmentOption;
        }
        return {
          ...baseFulfillment,
          collectionFromDate: new Date(fo.collectionFromDate),
        } as CollectionFulfillmentOption;
      }) || null;
    return {
      supplier: {
        id: f.supplier.id,
      },
      fulfillmentOptions,
    };
  });
};
