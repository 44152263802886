import useGetMyRegionPreferences from '@/api/queries/me/useGetMyRegionPreferences';
import {
  MaterialsListMaterial,
  MaterialsListSummaryItems,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import { humanizeCurrency } from '@/helpers/currency';
import { useUserHasPermission } from '@/hooks/usePermissions';
import { AvatarSizeVariant } from '@payaca/components/plAvatar/Avatar';
import AvatarGroup from '@payaca/components/plAvatarGroup/AvatarGroup';
import { ManageableItemsList } from '@payaca/components/plManageableItemsList/ManageableItemsList';
import UserAvatar from '@payaca/components/userAvatar/UserAvatar';
import {
  getAccountRegionFromCurrencyCodeLegacy,
  getRegionalTextString,
} from '@payaca/helpers/internationalHelper';
import { roundToDecimalPlaces } from '@payaca/helpers/numberHelper';
import { MaterialsListPermissions } from '@payaca/permissions/materialsList/materialsList.permissions';
import { AccountRegions } from '@payaca/types/accountTypes';
import { Deal } from '@payaca/types/dealTypes';
import { RegionalStrings } from '@payaca/types/internationalTypes';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import MaterialSuppliersBadges from '../MaterialSuppliersBadges';
import { DeleteMaterialListMaterial } from './DeleteMaterialsListMaterialModal';
import { EditMaterialListMaterialQuantity } from './EditMaterialsListMaterialModal';
import { parseFulfillments } from './LiveAvailabilityMaterialSupplierBadge/fulfillment';
import LiveSpecifiedQuantityMaterialSupplierBadge from './LiveAvailabilityMaterialSupplierBadge/LiveSpecifiedQuantityMaterialSupplierBadge';
import { useSummaryItemsInputPaginationState } from './materialsPanelHooks';

export const ToOrderPanel: FC<{
  summaryItems: MaterialsListSummaryItems | undefined;
  projectId?: Deal['id'];
  onRecordPurchase: (materialListMaterialId: string) => void;
  onRequestEditMaterialsListMaterial: (
    mlm: EditMaterialListMaterialQuantity
  ) => void;
  onRequestDeleteMaterialsListMaterial: (
    mlm: DeleteMaterialListMaterial
  ) => void;

  inputPaginationState: Omit<
    ReturnType<typeof useSummaryItemsInputPaginationState>,
    'setStatuses'
  >;
}> = ({
  summaryItems,
  projectId,
  onRecordPurchase,
  onRequestEditMaterialsListMaterial,
  onRequestDeleteMaterialsListMaterial,
  inputPaginationState: { pagination, input },
}) => {
  const { data: regionPreferences } = useGetMyRegionPreferences();

  const region = regionPreferences
    ? getAccountRegionFromCurrencyCodeLegacy(regionPreferences?.currency.code)
    : AccountRegions.UK;

  const hasPersistMlmPermission = useUserHasPermission({
    permissions: [MaterialsListPermissions.PERSIST_MATERIALS_LIST_MATERIAL],
  });

  const hasDeleteMlmPermission = useUserHasPermission({
    permissions: [MaterialsListPermissions.DELETE_MATERIALS_LIST_MATERIAL],
  });

  return (
    <div className="@container">
      <ManageableItemsList.ActionBar>
        <ManageableItemsList.ActionBar.SearchInput
          onChange={input.setSearchTerm}
        />
      </ManageableItemsList.ActionBar>
      <ManageableItemsList.Table<MaterialsListMaterial>
        isLoading={!summaryItems}
        items={summaryItems?.items || []}
        itemActions={(row) => {
          const actions = [];
          if (row.__typename === 'MaterialsListOrderSummaryRequiredItem') {
            actions.push({
              label: 'Record purchase',
              onClick: () => onRecordPurchase(row.materialsListMaterial.id),
            });
          }
          if (
            !(
              row.__typename === 'MaterialsListOrderSummaryOrderedItem' &&
              row.materialPurchaseIntent?.purchaseOrder
            )
          ) {
            if (hasPersistMlmPermission) {
              actions.push({
                label: 'Edit quantity',
                onClick: () =>
                  onRequestEditMaterialsListMaterial({
                    id: row.materialsListMaterial.id,
                    quantity: row.materialsListMaterial.quantity,
                    quantityOrdered: row.materialsListMaterial.quantityOrdered,
                    material: row.materialsListMaterial.material,
                  }),
              });
            }

            if (hasDeleteMlmPermission) {
              actions.push({
                label: 'Remove all',
                onClick: () =>
                  onRequestDeleteMaterialsListMaterial(
                    row.materialsListMaterial
                  ),
              });
            }
          }

          return actions;
        }}
        uniqueKey="id"
      >
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Material"
          field="id"
          render={(id, row) => (
            <div className="max-w-[400px] truncate">
              <Link
                to={`/materials/${row.materialsListMaterial.material.internalId}`}
              >
                {row.materialsListMaterial.material.name}
              </Link>
            </div>
          )}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Qty"
          field="id"
          render={(id, row) => roundToDecimalPlaces(row.materialQuantity, 2)}
        />
        <ManageableItemsList.Table.Column<
          MaterialsListMaterial,
          'materialsListMaterial'
        >
          header="Requested by"
          field="materialsListMaterial"
          render={(materialsListMaterial) => {
            return (
              <AvatarGroup>
                {materialsListMaterial.requestedByUsers.map((user) => {
                  return (
                    <UserAvatar
                      key={`user-${user.id}`}
                      sizeVariant={AvatarSizeVariant.XS}
                      user={{
                        firstName: user.firstName,
                        lastName: user.lastName,
                        emailAddress: user.email || '',
                        userColour: user.colour || undefined,
                        imgSrc: user.avatarUrl || undefined,
                      }}
                      enableUserInfoTooltip={true}
                    />
                  );
                })}
              </AvatarGroup>
            );
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="PO Draft"
          field="id"
          render={(id, row) => {
            if (
              row.__typename === 'MaterialsListOrderSummaryOrderedItem' &&
              row.materialPurchaseIntent?.purchaseOrder
            ) {
              return (
                <Link
                  to={`/purchaseOrders/${row.materialPurchaseIntent?.purchaseOrder?.id}?projectId=${projectId}`}
                >
                  {row.materialPurchaseIntent?.purchaseOrder?.reference}
                </Link>
              );
            }
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          header="Supplier"
          field="id"
          render={(_, row) => {
            let suppliers = [];
            if (row.__typename === 'MaterialsListOrderSummaryOrderedItem') {
              const supplier = row.materialPurchaseIntent?.supplier;
              if (!supplier) {
                return null;
              }
              suppliers = [{ id: supplier.id, name: supplier.name }];
            } else {
              suppliers = row.materialsListMaterial.material.suppliedBy.map(
                (s) => ({
                  id: s.supplier.id,
                  name: s.supplier.name,
                })
              );
            }
            return (
              <MaterialSuppliersBadges
                suppliers={suppliers}
                fulfillments={parseFulfillments(
                  row.materialsListMaterial.fulfillments
                )}
                LiveAvailabilityMaterialSupplierBadge={
                  LiveSpecifiedQuantityMaterialSupplierBadge
                }
              />
            );
          }}
        />
        <ManageableItemsList.Table.Column<MaterialsListMaterial, 'id'>
          className="text-right"
          header={`Price Range (ex ${getRegionalTextString(
            region,
            RegionalStrings.VALUE_ADDED_TAX
          )})`}
          field="id"
          render={(id, row) => {
            if (row.__typename === 'MaterialsListOrderSummaryOrderedItem') {
              return row.materialPurchaseIntent.predictedPrice.unitPriceExcTax
                ? humanizeCurrency(
                    row.materialPurchaseIntent.predictedPrice.unitPriceExcTax
                  )
                : '-';
            }
            const prices = row.materialsListMaterial.material.suppliedBy
              .map((suppliedBy) => suppliedBy.price)
              .sort(
                (pricea, priceb) =>
                  pricea.unitPriceExcTax.value - priceb.unitPriceExcTax.value
              );

            if (prices.length === 0) {
              return '-';
            }

            if (prices.length == 1) {
              return humanizeCurrency(prices[0].unitPriceExcTax);
            }

            return `${humanizeCurrency(
              prices[0].unitPriceExcTax
            )} - ${humanizeCurrency(
              prices[prices.length - 1].unitPriceExcTax
            )}`;
          }}
        />
      </ManageableItemsList.Table>
      {summaryItems && (
        <ManageableItemsList.PaginationBar
          totalItems={summaryItems.totalCount}
          pageSize={summaryItems.limit}
          currentPage={summaryItems.offset / summaryItems.limit + 1}
          onPageChange={pagination.setCurrentPage}
        />
      )}
    </div>
  );
};
