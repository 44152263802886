import { getScheduledEvent, getUserRoles } from '@/utils/stateAccessors';
import { TaggableEntityType } from '@payaca/types/tagTypes';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import * as customerActions from '@payaca/store/customer/customerActions';
import * as dealActions from '@payaca/store/deals/dealsActions';
import * as notesActions from '@payaca/store/notes/notesActions';
import * as scheduledEventActions from '@payaca/store/scheduledEvents/scheduledEventsActions';
import * as taskActions from '@payaca/store/tasks/tasksActions';
import * as uploadsActions from '@payaca/store/uploads/uploadsActions';

import { userHasRequiredPermission } from '@payaca/permissions/permissions.utils';
import { ScheduledEventsPermissions } from '@payaca/permissions/scheduledEvents/scheduled-events.permissions';
import { TasksPermissions } from '@payaca/permissions/tasks/tasks.permissions';
import { TimelogsPermissions } from '@payaca/permissions/timelogs/timelogs.permissions';
import { UploadsPermissions } from '@payaca/permissions/uploads/uploads.permissions';

import Button from '@payaca/components/plButton/Button';
import Modal from '@payaca/components/plModal/Modal';
import EntityTagControl from '../entityTagControl/EntityTagControl';
import { PermissionGuard } from '../permissionGuard/PermissionGuard';
import ScheduledEventDetailsAndActionsHeader from '../scheduledEventDetailsAndActionsHeader/ScheduledEventDetailsAndActionsHeader';
import ScheduledEventKeyInformationHeader from '../scheduledEventKeyInformationHeader/ScheduledEventKeyInformationHeader';

import { Customer } from '@payaca/types/customerTypes';

import { useSelector } from '@/api/state';
import LinkUploads from '@/ui/components/scheduledEventReadView/LinkUploads';
import { EBtnVariant } from '@payaca/components/plButton/useButtonClassName';
import { getAcceptedFileTypes } from '@payaca/helpers/fileHelper';
import { getAddressAsString } from '@payaca/helpers/locationHelper';
import { DealsPermissions } from '@payaca/permissions/deals/deals.permissions';
import moment from 'moment-timezone';
import CreateTimelogControl from '../createTimelogControl/CreateTimelogControl';
import EntityTimelogsDrawer from '../entityTimelogsDrawer/EntityTimelogsDrawer';
import { ScheduledEventFileUploadModal } from '../scheduledEventFileUploadModal/ScheduledEventFileUploadModal';
import UploadCollectionControl from '../uploadCollectionControl/UploadCollectionControl';
import { ContactsSection } from './ContactsSection';
import { NotesSection } from './NotesSection';
import './ScheduledEventReadView.sass';
import {
  SectionBodyBlock,
  SectionDivider,
  SectionTitle,
} from './SectionComponents';
import { TasksSection } from './TasksSection';

const acceptFileTypes = getAcceptedFileTypes(['document', 'image', 'video']);

type Props = {
  scheduledEventId: number;
  hideDealLink?: boolean;
  hideNotes?: boolean;
  onDeleteScheduledEventSuccess?: () => void;
  onUpdateScheduledEventSuccess?: () => void;
  onUpdateTasksSuccess?: () => void;
};
const ScheduledEventReadView: FC<Props> = ({
  scheduledEventId,
  hideDealLink,
  hideNotes,
  onDeleteScheduledEventSuccess,
  onUpdateScheduledEventSuccess,
  onUpdateTasksSuccess,
}: Props): JSX.Element | null => {
  const dispatch = useDispatch();

  const userRoles = useSelector(getUserRoles);
  const profile = useSelector((state: any) => state.users.myProfile);

  const scheduledEvent = useSelector((state) =>
    getScheduledEvent(state, scheduledEventId)
  );

  const [customer, setCustomer] = useState<Customer>();
  const [showCreateTimelogModal, setShowCreateTimelogModal] = useState(false);
  const [showTimelogsDrawer, setShowTimelogsDrawer] = useState(false);
  const [showLinkUploadsModal, setShowLinkUploadsModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(scheduledEventActions.requestGetScheduledEvent(scheduledEventId));
    dispatch(taskActions.requestGetTasksForScheduledEvent(scheduledEventId));
    dispatch(
      uploadsActions.requestGetUploadsForEntity(
        scheduledEventId,
        'scheduledEvent'
      )
    );
    {
      !hideNotes &&
        dispatch(
          notesActions.getNotesForScheduledEvent.request({
            scheduledEventId,
            callback: () => {
              dispatch(
                scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
              );
            },
          })
        );
    }
  }, [hideNotes, scheduledEventId]);

  useEffect(() => {
    if (scheduledEvent?.includeCustomerInformation) {
      dispatch(
        customerActions.requestGetCustomerForScheduledEvent(
          scheduledEventId,
          setCustomer
        )
      );
    }
  }, [
    scheduledEventId,
    scheduledEvent?.customerIds,
    scheduledEvent?.includeCustomerInformation,
  ]);

  useEffect(() => {
    if (scheduledEvent?.dealId) {
      dispatch(dealActions.requestGetDeal(scheduledEvent.dealId));
    }
  }, [scheduledEvent?.dealId]);

  const { userCanUpdateEvent, userCanTagEvent } = useMemo(
    () => ({
      userCanUpdateEvent: userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.UPDATE_EVENT,
      ]),
      userCanTagEvent: userHasRequiredPermission(userRoles, [
        ScheduledEventsPermissions.TAG_EVENT,
      ]),
    }),
    [userRoles]
  );

  const userCanDeleteAttachment = useMemo(() => {
    return userHasRequiredPermission(userRoles, [
      UploadsPermissions.DELETE_UPLOAD,
    ]);
  }, [userRoles]);

  const scheduledEventCustomer = useMemo(() => {
    if (scheduledEvent?.includeCustomerInformation) {
      return customer;
    }
  }, [customer, scheduledEvent]);

  const onPersistUploadSuccess = useCallback(() => {
    dispatch(scheduledEventActions.requestGetScheduledEvent(scheduledEventId));
    dispatch(
      uploadsActions.requestGetUploadsForEntity(
        scheduledEventId,
        'scheduledEvent'
      )
    );
  }, [scheduledEventId]);

  const removeUpload = (uploadId: number) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(
        uploadsActions.unlinkUpload.request({
          uploadId,
          entity: {
            type: 'scheduledEvent',
            id: scheduledEventId,
          },
          deleteUploadIfOrphaned: true,
          callback: () => {
            dispatch(
              scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
            );
            dispatch(
              uploadsActions.requestGetUploadsForEntity(
                scheduledEventId,
                'scheduledEvent'
              )
            );
            resolve();
          },
          onErrorCallback: () => {
            reject();
          },
        })
      );
    });
  };

  const addressAsString = scheduledEvent?.location?.address
    ? getAddressAsString(scheduledEvent.location.address)
    : undefined;

  if (!scheduledEvent) return null;

  return (
    <div className={'scheduled-event-read-view'}>
      <ScheduledEventDetailsAndActionsHeader
        scheduledEventId={scheduledEventId}
        hideDealLink={hideDealLink}
        onDeleteScheduledEventSuccess={onDeleteScheduledEventSuccess}
        onUpdateScheduledEventSuccess={onUpdateScheduledEventSuccess}
      />

      {(userCanUpdateEvent ||
        userCanTagEvent ||
        !!scheduledEvent.tags?.length) && (
        <EntityTagControl
          entityId={scheduledEvent.id}
          entityType={TaggableEntityType.SCHEDULED_EVENT}
          entityTags={scheduledEvent.tags || []}
          onEntityTagsChange={() =>
            dispatch(
              scheduledEventActions.requestGetScheduledEvent(scheduledEvent.id)
            )
          }
          isReadOnly={!userCanUpdateEvent && !userCanTagEvent}
        />
      )}

      <ScheduledEventKeyInformationHeader scheduledEvent={scheduledEvent} />
      {scheduledEvent.customerName && (
        <div>
          <SectionTitle>Customer</SectionTitle>
          <SectionDivider />
          <SectionBodyBlock>{scheduledEvent.customerName}</SectionBodyBlock>
        </div>
      )}
      {scheduledEvent.location?.id && (
        <div>
          <SectionTitle>Location</SectionTitle>
          <SectionDivider />
          <SectionBodyBlock>
            <a
              href={`https://maps.google.com/?q=${addressAsString}`}
              target="_blank"
              rel="noreferrer"
            >
              {addressAsString}
            </a>
          </SectionBodyBlock>
        </div>
      )}

      <ContactsSection scheduledEventId={scheduledEventId} />
      <PermissionGuard
        renderIfHasPermissions={[
          TasksPermissions.GET_TASKS,
          TasksPermissions.GET_SELF_ASSIGNED_TASKS,
        ]}
      >
        <TasksSection
          scheduledEventId={scheduledEventId}
          onUpdateTasksSuccess={() => {
            onUpdateTasksSuccess?.();
            dispatch(
              scheduledEventActions.requestGetScheduledEvent(scheduledEvent.id)
            );
          }}
        />
      </PermissionGuard>
      <PermissionGuard
        renderIfHasPermissions={[
          TimelogsPermissions.GET_SELF_ASSIGNED_TIMELOGS,
          TimelogsPermissions.GET_TIMELOGS,
        ]}
      >
        <div>
          <SectionTitle>Timelogs</SectionTitle>
          <SectionDivider />
          <div className="flex flex-row gap-4">
            <Button
              onClick={() => setShowTimelogsDrawer(true)}
              variant={EBtnVariant.LinkInline}
            >
              View timelogs
            </Button>
            <EntityTimelogsDrawer
              isOpen={showTimelogsDrawer}
              onClose={() => setShowTimelogsDrawer(false)}
              entity={{
                entityType: 'scheduledEvent',
                entityId: scheduledEventId,
                entityReadableIdentifier: scheduledEvent.name,
              }}
              newTimelogsInitialStartTime={
                moment(scheduledEvent.beginAt).isBefore(moment())
                  ? scheduledEvent.beginAt
                  : undefined
              }
            />
            <PermissionGuard
              renderIfHasPermissions={[
                TimelogsPermissions.CREATE_TIMELOGS,
                TimelogsPermissions.CREATE_SELF_ASSIGNED_TIMELOGS,
              ]}
            >
              <>
                <Button
                  className="record-timelog-control"
                  variant={EBtnVariant.LinkInline}
                  onClick={() => setShowCreateTimelogModal(true)}
                >
                  Record timelog
                </Button>
                <Modal
                  title="Record a timelog"
                  isOpen={showCreateTimelogModal}
                  onClose={() => setShowCreateTimelogModal(false)}
                >
                  <CreateTimelogControl
                    initialFormState={{
                      primaryLinkedEntity: {
                        entityId: scheduledEventId,
                        entityType: 'scheduledEvent',
                      },
                      startTime: moment(scheduledEvent.beginAt).isBefore(
                        moment()
                      )
                        ? scheduledEvent.beginAt
                        : undefined,
                    }}
                    onCreateTimelogSuccess={() => {
                      setShowCreateTimelogModal(false);
                    }}
                  />
                </Modal>
              </>
            </PermissionGuard>
          </div>
        </div>
      </PermissionGuard>
      {scheduledEvent.description && (
        <div>
          <SectionTitle>Description</SectionTitle>
          <SectionDivider />
          <SectionBodyBlock>
            <div className="whitespace-pre-wrap">
              {scheduledEvent.description}
            </div>
          </SectionBodyBlock>
        </div>
      )}
      <PermissionGuard
        renderIfHasPermissions={[UploadsPermissions.GET_UPLOADS]}
      >
        <div>
          <SectionTitle>Attachments</SectionTitle>
          <SectionDivider />
          <div className="mb-4 flex flex-row gap-4">
            <Button
              variant={EBtnVariant.LinkInline}
              onClick={() => setShowFileUploadModal(true)}
            >
              Add new files
            </Button>
            {scheduledEvent.dealId && (
              <PermissionGuard
                renderIfHasPermissions={[DealsPermissions.GET_DEAL]}
              >
                <Button
                  variant={EBtnVariant.LinkInline}
                  onClick={() => setShowLinkUploadsModal(true)}
                >
                  Link Project files
                </Button>
              </PermissionGuard>
            )}
          </div>
          <UploadCollectionControl
            uploadIds={scheduledEvent.uploadIds || []}
            onUploadCollectionChange={onPersistUploadSuccess}
            allowMultipleUploads={true}
            canRemove={userCanDeleteAttachment}
            canAdd={false}
            acceptFileTypes={acceptFileTypes}
            customRemoveUpload={removeUpload}
          />
        </div>
      </PermissionGuard>
      {!hideNotes && (
        <NotesSection
          scheduledEventId={scheduledEventId}
          onUpdateNotesSuccess={() => {
            dispatch(
              scheduledEventActions.requestGetScheduledEvent(scheduledEvent.id)
            );
          }}
        />
      )}
      {scheduledEvent?.dealId && (
        <LinkUploads
          disabledUploadIds={scheduledEvent.uploadIds.map((x) => x.toString())}
          isOpen={showLinkUploadsModal}
          onClose={() => {
            setShowLinkUploadsModal(false);
          }}
          sourceEntities={[{ type: 'deal', id: scheduledEvent.dealId }]}
          targetEntity={{
            type: 'scheduledEvent',
            id: scheduledEventId,
          }}
          onLinkUploadsSuccess={() => {
            setShowLinkUploadsModal(false);
            dispatch(
              scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
            );
            dispatch(
              uploadsActions.requestGetUploadsForEntity(
                scheduledEventId,
                'scheduledEvent'
              )
            );
          }}
        />
      )}
      <ScheduledEventFileUploadModal
        isOpen={showFileUploadModal}
        onClose={() => setShowFileUploadModal(false)}
        scheduledEventId={scheduledEventId}
        onUploadSuccess={() => {
          setShowFileUploadModal(false);
          dispatch(
            scheduledEventActions.requestGetScheduledEvent(scheduledEventId)
          );
          dispatch(
            uploadsActions.requestGetUploadsForEntity(
              scheduledEventId,
              'scheduledEvent'
            )
          );
        }}
      />
    </div>
  );
};
export default ScheduledEventReadView;
