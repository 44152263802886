import useCreateAddress from '@/api/mutations/addresses/useCreateAddress';
import useGetProjectMaterialsListSummaryItems, {
  MaterialsListMaterial,
} from '@/api/queries/project/useGetProjectMaterialListSummaryItems';
import MaterialsLeftToOrderList from '@/ui/components/createPurchaseOrderSidebar/components/MaterialsLeftToOrderList';
import MaterialsToOrderList from '@/ui/components/createPurchaseOrderSidebar/components/MaterialsToOrderList';
import { zodResolver } from '@hookform/resolvers/zod';
import Conditional from '@payaca/components/conditional/Conditional';
import Sidebar, {
  Props as SidebarProps,
} from '@payaca/components/plSidebar/Sidebar';
import SkeletonLoader from '@payaca/components/plSkeletonLoader/SkeletonLoader';
import { useToastContext } from '@payaca/components/plToast/ToastContext';
import { clstx } from '@payaca/components/utils';
import { PAGINATION_ARG_DEFAULTS } from '@payaca/shared-isomorphic/constants/graphql-pagination';
import { requestPersistPurchaseOrder } from '@payaca/store/materialsList/materialsListActions';
import { PersistPurchaseOrderRequestData } from '@payaca/store/materialsList/materialsListTypes';
import { PartialAddress } from '@payaca/types/locationTypes';
import { FC, useMemo, useReducer, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { z } from 'zod';
import useSubmitDirectPurchaseOrderCityPlumbing from '../../../api/mutations/purchaseOrders/useSubmitDirectPurchaseOrderCityPlumbing';
import { DirectPurchaseOrderOrderType } from '../../../gql/graphql';
import DirectPurchaseOrderSummary from './components/DirectPurchaseOrderSummary';
import FulfillmentOptions from './components/FulfillmentOptions';
import IndirectPurchaseOrderDetails from './components/IndirectPurchaseOrderDetails';

type TBaseFormState = {
  supplierId: number;
  materials: (MaterialsListMaterial & {
    selectedQuantity: number; // the intial qty selected of material
    purchaseOrderQuantity: number; // the qty that is actually going on the order
  })[];
  type: 'indirect' | 'direct';
};
type TDirectPurchaseOrderFormState = {
  type: 'direct';
  fulfillmentOptionId: string;
  fromDate: Date;
  toDate?: Date;
  delivery?: {
    deliveryAddressId?: number;
    fullLocalAddress?: string;
    newDeliveryAddress?: PartialAddress;
  };
  collection?: {
    branchId: string;
    branchName: string;
  };
} & TBaseFormState;

type TIndirectPurchaseOrderFormState = {
  type: 'indirect';
  dueAt?: Date;
  deliveryInfo: {
    deliveryAddressId?: number;
    newDeliveryAddress?: PartialAddress;
  };
  additionalNotes?: string;
  showMaterialPrices?: boolean;
} & TBaseFormState;

export type TFormState =
  | TDirectPurchaseOrderFormState
  | TIndirectPurchaseOrderFormState;
const BASE_PURCHASE_ORDER_SCHEMA = z.object({
  supplierId: z.number(),
  materials: z.array(z.any()),
});
const INDIRECT_PURCHASE_ORDER_SCHEMA = BASE_PURCHASE_ORDER_SCHEMA.extend({
  type: z.literal('indirect'),
  deliveryInfo: z.any(),
  dueAt: z.date(),
  additionalNotes: z.string().optional(),
  showMaterialPrices: z.boolean(),
});
const DIRECT_PURCHASE_ORDER_SCHEMA = BASE_PURCHASE_ORDER_SCHEMA.extend({
  type: z.literal('direct'),
  fulfillmentOptionId: z.string(),
  delivery: z
    .union([
      z.object({
        deliveryAddressId: z.number(),
        newDeliveryAddress: z.undefined(),
      }),
      z.object({
        deliveryAddressId: z.undefined(),
        newDeliveryAddress: z.object({
          line1: z.string(),
          line2: z.string().optional(),
          city: z.string().optional(),
          postcode: z.string().optional(),
          country: z.string().optional(),
        }),
      }),
    ])
    .optional(),
  collection: z
    .object({
      branchId: z.string(),
    })
    .optional(),
  fromDate: z.date(),
  toDate: z.date().optional(),
});
const PURCHASE_ORDER_FORM_SCHEMA = z.discriminatedUnion('type', [
  INDIRECT_PURCHASE_ORDER_SCHEMA,
  DIRECT_PURCHASE_ORDER_SCHEMA,
]);

type FormStageState = {
  formStage: FormStageStage;
  transitionComplete: boolean;
};
export type FormStageStage =
  | 'MATERIAL_LIST_MATERIALS' // materials left to order / materials to order
  | 'FULFILLMENT_OPTIONS' // materials to order / fulfillment options
  | 'DIRECT_PURCHASE_ORDER' // fulfillment options / direct purchase order summary
  | 'INDIRECT_PURCHASE_ORDER_DETAILS'; // materials to order / purchase order details

type FormStageAction =
  | { type: 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE' }
  | { type: 'GO_TO_FULFILLMENT_OPTIONS_STAGE' }
  | { type: 'GO_TO_DIRECT_PURCHASE_ORDER_STAGE' }
  | { type: 'GO_TO_INDIRECT_PURCHASE_ORDER_DETAILS_STAGE' }
  | { type: 'TRANSITION_COMPLETE' }
  | { type: 'RESET' };

const formStageReducer = (
  state: FormStageState,
  action: FormStageAction
): FormStageState => {
  switch (action.type) {
    case 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE':
      return {
        formStage: 'MATERIAL_LIST_MATERIALS',
        transitionComplete: false,
      };
    case 'GO_TO_FULFILLMENT_OPTIONS_STAGE':
      return {
        formStage: 'FULFILLMENT_OPTIONS',
        transitionComplete: false,
      };

    case 'GO_TO_DIRECT_PURCHASE_ORDER_STAGE':
      return { formStage: 'DIRECT_PURCHASE_ORDER', transitionComplete: false };
    case 'GO_TO_INDIRECT_PURCHASE_ORDER_DETAILS_STAGE':
      return {
        formStage: 'INDIRECT_PURCHASE_ORDER_DETAILS',
        transitionComplete: false,
      };
    case 'TRANSITION_COMPLETE':
      return { ...state, transitionComplete: true };
    case 'RESET':
      return defaultFormStageState;
    default:
      return state;
  }
};

const defaultFormStageState: FormStageState = {
  formStage: 'MATERIAL_LIST_MATERIALS',
  transitionComplete: true,
};

const CreatePurchaseOrderForm: FC<{
  projectId: number;
  materialListMaterials: MaterialsListMaterial[];
  onSubmit: (state: TFormState) => Promise<void>;
}> = (props) => {
  const { projectId, materialListMaterials, onSubmit } = props;

  const [formStageState, formStageDispatch] = useReducer(
    formStageReducer,
    defaultFormStageState
  );

  const [isSaving, setIsSaving] = useState(false);

  const formMethods = useForm<TFormState>({
    resolver: zodResolver(PURCHASE_ORDER_FORM_SCHEMA),
    defaultValues: {
      materials: [],
      type: 'indirect',
    },
  });
  const selectedMaterials = formMethods.watch('materials');

  const handleSubmit = async (state: TFormState) => {
    setIsSaving(true);

    await onSubmit(state);

    setIsSaving(false);
  };

  const showDirectPurchaseOrderFlow = formMethods.watch('type') === 'direct';

  const formClassName = useMemo(() => {
    const isFinalStage =
      formStageState.formStage === 'INDIRECT_PURCHASE_ORDER_DETAILS' ||
      formStageState.formStage === 'DIRECT_PURCHASE_ORDER';

    if (showDirectPurchaseOrderFlow) {
      return clstx(
        'absolute top-0 left-0 w-[200%] h-full grid grid-cols-4 transition-transform duration-500',
        isFinalStage
          ? '-translate-x-2/4'
          : formStageState.formStage === 'FULFILLMENT_OPTIONS'
            ? '-translate-x-1/4'
            : 'translate-x-0'
      );
    } else {
      return clstx(
        'absolute top-0 left-0 w-[150%] h-full grid grid-cols-3 transition-transform duration-500',
        isFinalStage ? '-translate-x-1/3' : 'translate-x-0'
      );
    }
  }, [showDirectPurchaseOrderFlow, formStageState.formStage]);

  return (
    <FormProvider {...formMethods}>
      <form
        className={formClassName}
        onTransitionEnd={() => {
          formStageDispatch({ type: 'TRANSITION_COMPLETE' });
        }}
        onSubmit={formMethods.handleSubmit(handleSubmit, console.error)}
      >
        <MaterialsLeftToOrderList
          materialListMaterials={materialListMaterials}
          hideList={
            (formStageState.formStage === 'INDIRECT_PURCHASE_ORDER_DETAILS' ||
              formStageState.formStage === 'FULFILLMENT_OPTIONS') &&
            formStageState.transitionComplete
          }
        />

        <MaterialsToOrderList
          materialListMaterials={materialListMaterials}
          formStage={formStageState.formStage}
          hideList={
            showDirectPurchaseOrderFlow
              ? formStageState.formStage ===
                  'INDIRECT_PURCHASE_ORDER_DETAILS' &&
                formStageState.transitionComplete
              : false
          }
          onAction={() => {
            if (formStageState.formStage === 'MATERIAL_LIST_MATERIALS') {
              if (showDirectPurchaseOrderFlow) {
                formStageDispatch({
                  type: 'GO_TO_FULFILLMENT_OPTIONS_STAGE',
                });
              } else {
                formStageDispatch({
                  type: 'GO_TO_INDIRECT_PURCHASE_ORDER_DETAILS_STAGE',
                });
              }
            } else {
              // reset materials purchase order quantities
              formMethods.setValue(
                'materials',
                selectedMaterials.map((m) => ({
                  ...m,
                  purchaseOrderQuantity: m.selectedQuantity,
                }))
              );
              // clear direct PO fields
              formMethods.resetField('delivery');
              formMethods.resetField('collection');
              formMethods.resetField('fulfillmentOptionId');
              formMethods.resetField('fromDate');
              formMethods.resetField('toDate');

              // clear non-direct PO fields
              formMethods.resetField('deliveryInfo');
              formMethods.resetField('dueAt');
              formMethods.resetField('additionalNotes');
              formMethods.resetField('showMaterialPrices');

              formStageDispatch({
                type: 'GO_TO_MATERIAL_LIST_MATERIALS_STAGE',
              });
            }
          }}
          actionDisabled={isSaving}
        />
        <Conditional condition={showDirectPurchaseOrderFlow}>
          <FulfillmentOptions
            projectId={projectId}
            formStage={formStageState.formStage}
            onAction={() => {
              if (formStageState.formStage === 'FULFILLMENT_OPTIONS') {
                formStageDispatch({
                  type: 'GO_TO_DIRECT_PURCHASE_ORDER_STAGE',
                });
              } else {
                // formMethods.resetField('fulfillment');
                // clear direct PO fields
                formMethods.resetField('delivery');
                formMethods.resetField('collection');
                formMethods.resetField('fulfillmentOptionId');
                formMethods.resetField('fromDate');
                formMethods.resetField('toDate');

                // revert materials to original selected state
                formMethods.setValue(
                  'materials',
                  selectedMaterials.map((m) => ({
                    ...m,
                    purchaseOrderQuantity: m.selectedQuantity,
                  }))
                );
                formStageDispatch({
                  type: 'GO_TO_FULFILLMENT_OPTIONS_STAGE',
                });
              }
            }}
          />
          <DirectPurchaseOrderSummary
            projectId={projectId}
            formStage={formStageState.formStage}
            isSaving={isSaving}
          />
        </Conditional>
        <Conditional condition={!showDirectPurchaseOrderFlow}>
          <IndirectPurchaseOrderDetails
            formStage={formStageState.formStage}
            projectId={projectId}
            hideList={
              formStageState.formStage === 'MATERIAL_LIST_MATERIALS' &&
              formStageState.transitionComplete
            }
            isSaving={isSaving}
          />
        </Conditional>
      </form>
    </FormProvider>
  );
};

export interface IProps extends SidebarProps {
  projectId: number;
  materialsListId: number;
  onSuccess?: (purchaseOrderId: number) => void;
}

const CreatePurchaseOrderSidebar: FC<IProps> = (props) => {
  const { isOpen, projectId, materialsListId, onSuccess, ...rest } = props;

  const { pushToast } = useToastContext();

  /**
   * Redux
   */
  const reduxDispatch = useDispatch();

  /**
   * Queries
   */
  const { materialsListOrderSummaryItems: materialsYetToOrder, isLoading } =
    useGetProjectMaterialsListSummaryItems(
      projectId,
      {
        statuses: ['TO_ORDER'],
      },
      {
        ...PAGINATION_ARG_DEFAULTS,
        limit: 100,
      }
    );

  /**
   * Mutations
   */
  const { mutateAsync: mutateCreateAddress } = useCreateAddress();

  const { mutateAsync: submitDirectPurchaseOrderMutation } =
    useSubmitDirectPurchaseOrderCityPlumbing();
  /**
   * Callbacks
   */
  const handleSubmit = async (state: TFormState) => {
    if (state.type === 'direct') {
      let deliveryAddressId: number | undefined =
        state.delivery?.deliveryAddressId;
      if (state.delivery?.newDeliveryAddress) {
        const {
          createAddress: { id: addressId },
        } = await mutateCreateAddress({
          line1: state.delivery.newDeliveryAddress.line1,
          line2: state.delivery.newDeliveryAddress.line2,
          city: state.delivery.newDeliveryAddress.city,
          postalCode: state.delivery.newDeliveryAddress.postcode,
          country: state.delivery.newDeliveryAddress.country,
        });
        if (addressId) {
          deliveryAddressId = +addressId;
        }
      }
      const response = await submitDirectPurchaseOrderMutation({
        materialsListId: materialsListId.toString(),
        materialPurchaseIntents: state.materials.map((material) => ({
          materialsListMaterialId: material.materialsListMaterial.id.toString(),
          materialQuantity: material.purchaseOrderQuantity,
        })),
        fulfillmentOptionId: state.fulfillmentOptionId,
        orderType: state.collection?.branchId
          ? 'COLLECTION'
          : ('DELIVERY' as DirectPurchaseOrderOrderType),
        deliveryAddressId: deliveryAddressId?.toString() || undefined,
        collectionBranchId: state.collection?.branchId.toString() || undefined,
      });
      if (
        response.submitDirectPurchaseOrderCityPlumbing?.__typename ===
        'SubmitDirectPurchaseOrderCityPlumbingResponseSuccess'
      ) {
        onSuccess?.(
          +response.submitDirectPurchaseOrderCityPlumbing.purchaseOrder.id
        );
      } else {
        pushToast({
          variant: 'white',
          icon: 'error',
          message:
            response.submitDirectPurchaseOrderCityPlumbing.__typename ===
            'SubmitDirectPurchaseOrderCityPlumbingResponseError'
              ? response.submitDirectPurchaseOrderCityPlumbing.message
              : 'Failed to submit Purchase Order',
        });
      }
    } else {
      console.log('Creating indirect purchase order', state);
      let deliveryAddressId: number | undefined =
        state.deliveryInfo?.deliveryAddressId;
      if (state.deliveryInfo?.newDeliveryAddress) {
        const {
          createAddress: { id: addressId },
        } = await mutateCreateAddress({
          line1: state.deliveryInfo.newDeliveryAddress.line1,
          line2: state.deliveryInfo.newDeliveryAddress.line2,
          city: state.deliveryInfo.newDeliveryAddress.city,
          postalCode: state.deliveryInfo.newDeliveryAddress.postcode,
          country: state.deliveryInfo.newDeliveryAddress.country,
        });
        if (addressId) {
          deliveryAddressId = +addressId;
        }
      }
      const requestData: PersistPurchaseOrderRequestData = {
        materialsListId,
        materialPurchaseIntents: state.materials.map((material) => ({
          materialsListMaterialId: +material.materialsListMaterial.id,
          materialQuantity: material.purchaseOrderQuantity,
        })),
        supplierId: state.supplierId,
        dueAt: state.dueAt,
        additionalNotes: state.additionalNotes,
        showMaterialPrices: state.showMaterialPrices,
        deliveryAddressId: deliveryAddressId,
      };
      await new Promise<number>((resolve, reject) => {
        reduxDispatch(
          requestPersistPurchaseOrder(
            requestData,
            (purchaseOrderId) => {
              resolve(purchaseOrderId);
            },
            () => {
              reject(new Error());
            }
          )
        );
      })
        .then((purchaseOrderId) => {
          onSuccess?.(purchaseOrderId);
        })
        .catch(() => {
          pushToast({
            variant: 'white',
            icon: 'error',
            message: 'Something went wrong, please try again.',
          });
        });
    }
  };

  return (
    <Sidebar
      isOpen={isOpen}
      size="lg"
      title="Create a Purchase Order"
      {...rest}
    >
      <Sidebar.Body className="relative overflow-hidden">
        {isLoading || !materialsYetToOrder ? (
          <div className="grid grid-cols-2 h-full">
            <div className="space-y-4 p-4">
              <SkeletonLoader.Title />

              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
              <SkeletonLoader.MaterialCard />
            </div>

            <div className="bg-gray-50"></div>
          </div>
        ) : (
          <CreatePurchaseOrderForm
            projectId={projectId}
            materialListMaterials={materialsYetToOrder.items}
            onSubmit={handleSubmit}
          />
        )}
      </Sidebar.Body>
    </Sidebar>
  );
};

export default CreatePurchaseOrderSidebar;
