export enum ScheduledEventsRoles {
  VIEWER = 'roles.scheduledEventsViewer',
  CREATOR = 'roles.scheduledEventsCreator',
  EDITOR = 'roles.scheduledEventsEditor',
  ASSIGNEE_VIEWER = 'roles.scheduledEventsAssigneeViewer',
  ASSIGNEE_CREATOR = 'roles.scheduledEventsAssigneeCreator',
  ASSIGNEE_EDITOR = 'roles.scheduledEventsAssigneeEditor',
  DEAL_ASSIGNEE_VIEWER = 'roles.scheduledEventsDealAssigneeViewer',
  DEAL_ASSIGNEE_EDITOR = 'roles.scheduledEventsDealAssigneeEditor',
  DEAL_ASSIGNEE_CREATOR = 'roles.scheduledEventsDealAssigneeCreator',
  TAGGER = 'roles.scheduledEventsTagger',
  BULK_IMPORTER = 'roles.scheduledEventsBulkImporter',

  CUSTOMER_ON_LEGACY_APP_SHARER = 'roles.scheduledEventsCustomerOnLegacyAppSharer',

  CUSTOM_CREATOR = 'roles.scheduledEventsCustomCreator',
}
