import { JobPermissions } from './job.permissions';
import { JobRoles } from './job.roles';

export const jobMappings = {
  [JobPermissions.GET_JOB]: [JobRoles.VIEWER],
  [JobPermissions.UPDATE_JOB]: [JobRoles.EDITOR],
  [JobPermissions.ADD_JOB]: [JobRoles.CREATOR],
  [JobPermissions.DUPLICATE_JOB]: [JobRoles.DUPLICATOR],

  [JobPermissions.EDIT_JOB_VALID_FOR_DUE_IN_DAYS]: [
    JobRoles.VALID_FOR_DUE_IN_DAYS_EDITOR,
  ],
  [JobPermissions.EDIT_JOB_DEPOSIT]: [JobRoles.DEPOSIT_EDITOR],
  [JobPermissions.UPDATE_JOB_REFERENCE]: [JobRoles.REFERENCE_EDITOR],

  [JobPermissions.ADD_CUSTOM_JOB]: [JobRoles.CUSTOM_CREATOR],
};
